
@import url("./../icons/font-awesome-old/css/font-awesome.min.css");
@import url("./../icons/flaticon/flaticon.css");
@import url("./../icons/simple-line-icons/css/simple-line-icons.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url(./../vendor/perfect-scrollbar/css/perfect-scrollbar.css);
@import url(./../vendor/metismenu/css/metisMenu.min.css);
/* @import url("./../icons/line-awesome/css/line-awesome.min.css"); */
@import url("./../icons/simple-line-icons/css/simple-line-icons.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");



/* #main-wrapper{
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
  } */



.side-barcolor {
	background-image: linear-gradient(0deg, rgba(91.00000217556953, 207.00000286102295, 153.00000607967377, 1) 0%, rgba(53.87500248849392, 185.00000417232513, 163.31250071525574, 1) 28.64583432674408%, rgba(37.00000159442425, 175.00000476837158, 168.0000051856041, 1) 100%)
}

@keyframes open {
	0% {
		background-position: 166% -25%, 220% -69%;
	}
	100% {
		background-position: 120% -5%, 200% -40%;
	}
}

input[type="file"] {
	display: none;
}


@media only screen and (min-width: 768px) {
	[data-sidebar-style="mini"] .nav-header {
		width: 5rem;
	}
	[data-sidebar-style="mini"] .nav-header .nav-control {
		z-index: -1;
	}
	[data-sidebar-style="mini"] .nav-header .nav-control .hamburger {
		left: 5rem !important;
	}
	[data-sidebar-style="mini"] .nav-header .nav-control .hamburger .line {
		background-color: #6e6e6e !important;
	}
	[data-sidebar-style="mini"] .nav-header .brand-title {
		display: none;
	}
	[data-sidebar-style="mini"] .nav-header .hamburger {
		display: none;
	}
	[data-sidebar-style="mini"] .header .header-content {
		padding-left: 1.875rem;
	}
	[direction="rtl"][data-sidebar-style="mini"] .header .header-content {
		padding-right: 1.875rem;
	}
	[data-sidebar-style="mini"] .content-body {
		margin-left: 5rem;
	}
	[data-sidebar-style="mini"] .footer {
		padding-left: 5rem;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
		display: block;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n+1)>ul {
		bottom: 0;
		top: auto !important;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n+1)>ul:after {
		top: auto;
		bottom: 20px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n+1)>ul {
		bottom: 0;
		top: auto !important;
	}
}

@media only screen and (min-width: 768px) {
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul {
		overflow: visible;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul:after {
		content: none;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul {
		padding: 10px 0;
		width: 13rem;
		left: 13rem;
		top: -10px;
		border: 0;
		margin: 0;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul:after {
		content: none;
	}
	[direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul {
		left: auto;
		right: 13rem;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a {
		background: #25AFA8;
		color: #fff;
		border-radius: 12px;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
		background: #25AFA8;
		color: #fff;
		border-radius: 12px;
		position: unset;
	}
	[direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a .nav-text {
		padding-left: auto;
		padding-right: 1.6875rem;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul {
		height: auto !important;
		overflow: visible;
		border: 0;
		margin-left: 0;
		left: 5rem;
		width: 13rem;
		border-radius: 12px;
		border: 0;
		padding: 10px 0;
		top: 0;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul:after {
		content: "";
		position: absolute;
		background: inherit;
		width: 10px;
		height: 10px;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		left: -5px;
		top: 20px;
	}
	[direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul {
		left: auto;
		right: 5rem;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a {
		padding: 6px 20px 6px 20px;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a:before {
		content: none;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a.has-arrow:after {
		right: 1.25rem;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul ul a {
		padding: 6px 20px 6px 20px;
		margin-left: -1.6px;
	}
	[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul ul a:before {
		content: none;
	}
	[data-sidebar-style="mini"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
		width: 1199px;
	}

}

@media only screen and (max-width: 1199px) {
	[data-sidebar-style="mini"] .deznav li.mm-active ul {
		height: auto !important;
	}
	[data-sidebar-style="mini"] .deznav li a.has-arrow::after {
		transform: rotate(-45deg) translateY(-50%);
	}
}

@media (min-width: 767px) {
	[data-sidebar-style="compact"] .deznav .nav-user {
		display: none;
	}
	[data-sidebar-style="compact"] .deznav .metismenu li {
		text-align: center;
	}
	[data-sidebar-style="compact"] .deznav .metismenu li a {
		padding: 1.5rem 1.625rem 0.625rem 1.5rem;
	}
	[data-sidebar-style="compact"] .deznav .metismenu li a svg {
		max-width: 21px;
		max-height: 21px;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	[data-sidebar-style="compact"] .deznav .metismenu li a i {
		font-size: 2.2rem;
	}
	[data-sidebar-style="compact"] .deznav .metismenu li a:after {
		content: none;
	}
	[data-sidebar-style="compact"] .deznav .metismenu li a:before {
		content: "";
		height: 75px;
		width: 0;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translatey(-50%);
		background: #25AFA8;
		border-radius: 0 0.875rem 0.875rem 0;
		-webkit-transition: all 0.5s;
		-ms-transition: all 0.5s;
		transition: all 0.5s;
	}
	[data-sidebar-style="compact"] .deznav .metismenu li ul:after {
		content: none;
	}
	[data-sidebar-style="compact"] .deznav .metismenu li.mm-active a,
	[data-sidebar-style="compact"] .deznav .metismenu li:hover a {
		background: -moz-linear-gradient(left, #e0e0e4 0%, rgba(125, 185, 232, 0) 100%);
		background: -webkit-linear-gradient(left, #e0e0e4 0%, rgba(125, 185, 232, 0) 100%);
		background: linear-gradient(to right, #e0e0e4 0%, rgba(125, 185, 232, 0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0e0e4', endColorstr='#007db9e8', GradientType=1);
	}
	[data-sidebar-style="compact"] .deznav .metismenu li.mm-active a:before,
	[data-sidebar-style="compact"] .deznav .metismenu li:hover a:before {
		width: 10px;
	}
	[data-sidebar-style="compact"] .nav-text {
		display: inline-block;
		margin-top: 0.7125rem;
	}
	[data-sidebar-style="compact"] .nav-label.first {
		display: none;
	}
	[data-sidebar-style="compact"] .nav-badge {
		display: none;
	}
	[data-sidebar-style="compact"] .footer {
		padding-left: 9.375rem;
	}
	[data-sidebar-style="compact"] .content-body {
		margin-left: 15rem;
	}
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav {
	width: 15rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul {
	margin-left: 0;
	border: 0;
	background: rgba(0, 0, 0, 0.02);
	padding: 0 0;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul a:before {
	content: none;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul ul a {
	padding: 0.625rem 0.9375rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li>a.has-arrow::after {
	top: 1px;
	display: inline-block;
	right: auto;
	margin-left: 5px;
	position: relative;
	width: 7px;
	height: 7px;
	border-width: 2px 0px 0px 2px;
}

[direction="rtl"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li>a.has-arrow::after {
	left: auto;
	margin-left: 0;
	margin-right: 5px;
}


@media (min-width: 767px) {
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header {
		width: 5.063rem;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
		padding-left: 20px;
	}
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .logo-abbr {
		display: block;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .brand-title {
		display: none;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .nav-control {
		display: none;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
		padding-left: 5.063rem;
	}
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
		padding-right: 4.38rem;
		padding-left: 0.9375rem;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
		padding-left: 1.375rem;
	}
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
		padding-right: 1.375rem;
		padding-left: 0;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
		overflow: visible;
		position: absolute;
		left: -12.125rem;
	}
}

@media only screen and (min-width: 767px) and (min-width: 1200px) and (max-width: 1350px) {
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
		left: -10rem;
	}
}

@media (min-width: 767px) {
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
		left: auto;
		right: -12.125rem;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .nav-label {
		display: none;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a {
		display: flex;
		justify-content: space-between;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>svg,
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>i {
		order: 1;
		margin-right: 0;
		margin-top: 0;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
		border-left: 0;
		padding-left: 0;
		padding-right: 28px;
	}
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
		padding-right: 0;
		padding-left: 28px;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
		left: auto;
		right: 28px;
	}
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
		left: 28px;
		right: auto;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a {
		position: relative;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
		left: auto;
		right: -5px;
	}
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
		right: auto;
		left: -5px;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
		right: 5rem;
	}
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
		right: auto;
		left: 5rem;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header {
		width: 17.1875rem;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header .brand-logo {
		padding-left: 1.6rem;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header .brand-logo .brand-title {
		display: block;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .header {
		padding-left: 4.38rem;
	}
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .header {
		padding-right: 4.38rem;
		padding-left: 0.9375rem;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .deznav {
		left: 0;
	}
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .deznav {
		left: auto;
		right: 0;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
		margin-left: 5rem;
	}
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
		margin-left: 0;
		margin-right: 5rem;
	}
	[data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
		padding-left: 4.375rem;
	}
	[direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
		margin-left: 0;
		margin-right: 4.375rem;
	}
}

@media (min-width: 767px) {
	[data-sidebar-style="modern"] .nav-header {
		width: 9.375rem;
	}
	[data-sidebar-style="modern"] .nav-header .brand-logo {
		justify-content: center;
	}
	[data-sidebar-style="modern"] .deznav .metismenu>li {
		text-align: center;
	}
	[data-sidebar-style="modern"] .deznav .metismenu>li>a {
		padding: 20px 15px 20px 15px !important;
	}
	[data-sidebar-style="modern"] .deznav .metismenu>li>a::after {
		display: none;
	}
	[data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
	[data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
	[data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
	[data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
		background-color: #f0fbe6;
	}
	[data-sidebar-style="modern"] .deznav .metismenu>li.mm-active {
		padding: 0;
	}
	[data-sidebar-style="modern"] .deznav .metismenu>li li {
		text-align: left;
	}
	[direction="rtl"][data-sidebar-style="modern"] .deznav .metismenu>li li {
		text-align: right;
	}
	[data-sidebar-style="modern"] .deznav .metismenu li a {
		padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
	}
	[data-sidebar-style="modern"] .deznav .metismenu li ul:after {
		content: none;
	}
	[data-sidebar-style="modern"] .deznav .metismenu li>ul {
		height: auto !important;
	}
	[data-sidebar-style="modern"] .deznav .metismenu .nav-label {
		display: none;
	}
	[data-sidebar-style="modern"] .deznav .nav-label {
		display: none;
	}
	[data-sidebar-style="modern"] .deznav .nav-text {
		display: block;
		margin-top: 0.3125rem;
	}
	[data-sidebar-style="modern"] .footer {
		padding-left: 9.375rem;
	}
	[data-sidebar-style="modern"] .content-body {
		margin-left: 9.375rem;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav {
		width: 9.375rem;
		left: 0;
	}
	[direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav {
		left: auto;
		right: 0;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav .slimScrollDiv,
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav .deznav-scroll {
		overflow: visible !important;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu>li>a i {
		font-size: 20px;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu>li>ul {
		display: none;
		padding: 1.875rem 0.9375rem;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li {
		position: relative;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a {
		padding: 0.625rem 1.5rem;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a:before {
		content: none;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
		position: absolute;
		left: 105%;
		top: 0;
		bottom: auto;
		background-color: #fff;
		border: 1px solid #f0f1f5;
		width: 200px;
	}
	[direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
		left: auto;
		right: 105%;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
		display: block;
		left: 100%;
		padding: 1rem 0;
		margin-left: -10px;
		border: 0;
		box-shadow: 5px 0px 13px 0px rgba(82, 63, 105, 0.05);
	}
	[direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
		left: auto;
		right: 100%;
		box-shadow: -5px 0px 13px 0px rgba(82, 63, 105, 0.05);
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-label {
		display: none;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-text {
		display: block;
		margin-top: 0;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .deznav {
		left: -9.375rem;
	}
	[direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .deznav {
		left: auto;
		right: -9.375rem;
	}
	[data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .content-body {
		margin-left: 0;
	}
	[direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .content-body {
		margin-right: 0;
	}

}

[data-sidebar-style="overlay"] .deznav {
	left: -100%;
}

[direction="rtl"][data-sidebar-style="overlay"] .deznav {
	left: auto;
	right: -100%;
}

[data-sidebar-style="overlay"] .content-body {
	margin-left: 0;
}

[data-sidebar-style="overlay"] .nav-header {
	position: absolute;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
	left: 0;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
	background-color: #25AFA8;
}

[data-sidebar-style="overlay"] .menu-toggle .nav-header {
	position: absolute;
	left: auto;
}

[data-sidebar-style="overlay"] .menu-toggle .deznav {
	left: 0;
}

[direction="rtl"][data-sidebar-style="overlay"] .menu-toggle .deznav {
	left: auto;
	right: 0;
}

[data-sidebar-style="overlay"] .footer {
	padding-left: 0;
}

[data-sidebar-style="overlay"][data-header-position="fixed"] .nav-header {
	position: fixed;
}

[data-sidebar-position="fixed"][data-header-position="fixed"] .nav-header {
	position: fixed;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
	position: fixed;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .deznav {
	position: fixed;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .deznav .deznav-scroll {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .deznav {
	position: fixed;
}
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .deznav {
	position: absolute;
}
[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .deznav {
	position: absolute;
}

.deznav .copyright {
	font-size: 14px;
	padding: 0 45px;
	margin-top: 40px;
	margin-bottom: 40px;
}

.deznav .copyright p {
	margin-bottom: 10px;
}

.deznav .copyright p strong {
	display: block;
}

@media only screen and (max-width: 1400px) {
	.deznav .copyright {
		padding: 0 25px;
	}
}

[direction="rtl"] .deznav {
	text-align: right;
}

[direction="rtl"] .deznav .metismenu ul {
	padding: 0 25px 0 0;
}

[direction="rtl"] .deznav .metismenu ul:after {
	left: auto;
	right: 25px;
}

[direction="rtl"] .deznav .metismenu ul a:before {
	left: auto;
	right: -5px;
}

[direction="rtl"] .deznav .metismenu li>a svg {
	margin-left: 5px;
	margin-right: 0;
}

[data-sidebar-style="compact"][direction="rtl"] .deznav .metismenu li>a svg {
	left: auto;
	margin-left: auto;
	margin-right: auto;
}

[data-sidebar-style="icon-hover"][direction="rtl"] .deznav .metismenu li>a svg {
	margin-left: 0;
}

[direction="rtl"] .deznav .metismenu li.active>.has-arrow:after {
	transform: rotate(45deg) translateY(-50%);
}

[direction="rtl"] .deznav .metismenu .has-arrow:after {
	left: 1.5625rem;
	right: auto;
}

[data-layout="horizontal"][direction="rtl"] .deznav .metismenu .has-arrow:after {
	left: 1.125rem;
}

[data-sidebar-style="modern"][direction="rtl"] .deznav .metismenu .has-arrow:after {
	-webkit-transform: rotate(-45deg) translateY(-50%);
	transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul {
	right: 11.8125rem;
	left: 0;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav {
	width: 5rem;
	overflow: visible;
	/* position: absolute; */
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .nav-text {
	display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .slimScrollDiv,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .deznav-scroll {
	overflow: visible !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li {
	position: relative;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a {
	padding: 0.8125rem 0.9375rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a svg {
	max-width: 24px;
	max-height: 24px;
	margin-right: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a:before {
	content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul {
	position: absolute;
	left: 5rem;
	top: 0;
	width: 12rem;
	z-index: 1001;
	display: none;
	padding-left: 1px;
	height: auto !important;
	box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
	border-radius: 6px;
	margin-left: 0;
	border: 0;
	background: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul:after {
	content: "";
	position: absolute;
	background: inherit;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	left: -5px;
	top: 20px;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul {
	left: auto;
	right: 3rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul {
	left: 11.8125rem;
	top: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul:after {
	content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li:hover>ul {
	display: block;
	height: auto;
	overflow: visible;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li {
	transition: all 0.4s ease-in-out;
	padding: 0 13px;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li>a {
	text-align: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li>a.has-arrow:after {
	display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li>a i {
	padding: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .sidebar-tag {
	display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .sidebar-tag-main {
	display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a {
	background: #25AFA8;
	border-radius: 0.875rem;
	color: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a i {
	color: #fff;
	padding: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover:nth-last-child(-n+1)>ul {
	bottom: 0;
	top: auto;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
	border-radius: 0.875rem;
	background: #25AFA8;
	color: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a i {
	color: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul {
	height: auto !important;
	padding: 10px 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul a {
	padding: 6px 20px 6px 20px;
	margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul ul {
	padding: 10px 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul ul a {
	padding: 6px 20px 6px 20px;
	margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .nav-label,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .nav-badge {
	display: none;
}

[data-header-position="fixed"] .deznav {
	margin-top: 0;
}


[data-sidebar-style="mini"] .deznav {
    width: 5rem;
    overflow: visible;
    position: absolute;
}
[data-sidebar-style="mini"] .deznav .copyright,
[data-sidebar-style="mini"] .deznav .drum-box {
    display: none;
}
[data-sidebar-style="mini"] .deznav .nav-text {
    display: none;
}
[data-sidebar-style="mini"] .deznav .slimScrollDiv,
[data-sidebar-style="mini"] .deznav .deznav-scroll {
    overflow: visible !important;
}
[data-sidebar-style="mini"] .deznav .nav-user {
    padding: 11px;
}
[data-sidebar-style="mini"] .deznav .nav-user .media-body {
    display: none;
}
[data-sidebar-style="mini"] .deznav .metismenu li a {
    padding: 0.813rem 0.875rem;
}
[data-sidebar-style="mini"] .deznav .metismenu li a svg {
    margin-right: 0;
}
[data-sidebar-style="mini"] .deznav .metismenu li>ul {
    position: absolute;
    left: 5rem;
    top: 2.9375rem;
    width: 11.875rem;
    z-index: 1001;
    display: none;
    padding-left: 1px;
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    height: auto !important;
    border-radius: 6px;
    background: #fff;
}

[data-sidebar-style="mini"] .deznav .metismenu>li {
    padding: 2px 15px;
}
[data-sidebar-style="mini"] .deznav .metismenu>li>a.has-arrow:after {
    display: none;
}
[data-sidebar-style="mini"] .deznav .metismenu .nav-label,
[data-sidebar-style="mini"] .deznav .metismenu .nav-badge {
    display: none;
}
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li {
	padding: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li>a {
	font-size: 18px;
	padding: 20px 40px;
	color: #969ba0;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
	position: relative;
}

@media only screen and (max-width: 1400px) {
	[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li>a {
		padding: 15px 25px;
		font-size: 16px;
	}
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li>a i {
	color: #dfdfdf;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a {
	background: #C3EEEB;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
	color: #25AFA8;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a span {
	color: #25AFA8;
}

.sidebar-navtext {
	color: #FFFFFF;
	font-weight: 400 !important;
	font-size: 0.9rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a span {
	color: #25AFA8;
}

.sidebar-navarrow {
	color: #FFFFFF !important;
}

.sidebar-tag {
	color: #FFFFFF !important;
	padding-left: 1.5rem;
	font-size: 0.7rem;
	padding-bottom: 0.5rem;
	padding-top: 0.9rem;
	font-weight: 200;
}

.sidebar-tag-main {
	color: #FFFFFF !important;
	padding-left: 1.5rem;
	font-size: 0.7rem;
	padding-bottom: 0.5rem;
	font-weight: 200;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li .has-arrow:after {
	right: 2.5rem;
}

@media only screen and (max-width: 1400px) {
	[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li .has-arrow:after {
		right: 1.5rem;
	}
}

[direction="rtl"] {
	text-align: right;
}

[direction="rtl"] .deznav {
	text-align: right;
}

[direction="rtl"] .deznav .metismenu ul {
	padding: 0 25px 0 0;
}

[direction="rtl"] .deznav .metismenu ul:after {
	left: auto;
	right: 25px;
}

[direction="rtl"] .deznav .metismenu ul a:before {
	left: auto;
	right: -5px;
}

[direction="rtl"] .deznav .metismenu li>a svg {
	margin-left: 5px;
	margin-right: 0;
}

[data-sidebar-style="compact"][direction="rtl"] .deznav .metismenu li>a svg {
	left: auto;
	margin-left: auto;
	margin-right: auto;
}

[data-sidebar-style="icon-hover"][direction="rtl"] .deznav .metismenu li>a svg {
	margin-left: 0;
}

[direction="rtl"] .deznav .metismenu li.active>.has-arrow:after {
	transform: rotate(45deg) translateY(-50%);
}

[direction="rtl"] .deznav .metismenu .has-arrow:after {
	left: 1.5625rem;
	right: auto;
}


[data-sidebar-style="modern"][direction="rtl"] .deznav .metismenu .has-arrow:after {
	-webkit-transform: rotate(-45deg) translateY(-50%);
	transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul {
	right: 11.8125rem;
	left: 0;
}


.deznav {
	width: 21.563rem;
	padding-bottom: 0;
	height: 100%;
	position: absolute;
	top: 0;
	padding-top: 120px;
	z-index: 3;
	background-color: #FBFBFB;
	transition: all .2s ease;
	box-shadow: 18px 0px 35px 0px rgba(0, 0, 0, 0.02);
}

@media only screen and (max-width: 1199px) {
	.deznav {
		padding-top: 80px;
	}
}

@media only screen and (max-width: 767px) {
	.deznav {
		width: 16rem;
	}
}

.deznav .deznav-scroll {
	position: relative;
	height: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
	.deznav {
		width: 18rem;
	}
}

@media only screen and (min-width: 1400px) and (max-width: 1900px) {
	.deznav {
		width: 20rem;
	}
}

.deznav ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.deznav .metismenu {
	display: flex;
	flex-direction: column;
	padding-top: 0px;
}

@media only screen and (max-width: 767px) {
	.deznav .metismenu {
		padding-top: 0;
	}
}

.deznav .metismenu.fixed {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
}

.deznav .metismenu>li {
	display: flex;
	flex-direction: column;
}

.deznav .metismenu>li a>i {
	font-size: 1.25rem;
	display: inline-block;
	vertical-align: middle;
	padding: 0 1.5rem 0 0;
	position: relative;
	top: 0;
	line-height: 1;
}

@media only screen and (max-width: 1400px) {
	.deznav .metismenu>li a>i {
		padding: 0 0.75rem 0 0;
	}
}

[data-sidebar-style="compact"] .deznav .metismenu>li a>i {
	display: block;
	padding: 0;
}

@media only screen and (max-width: 767px) {
	.deznav .metismenu>li a>i {
		font-size: 1.25rem;
		padding: 0 .75rem 0 0;
	}
}

.deznav .metismenu>li>a {
	font-weight: 500;
	display: inline-block;
	font-size: 15px;
}

.deznav .metismenu>li>a svg {
	max-width: 24px;
	max-height: 24px;
	height: 100%;
	margin-right: 5px;
	margin-top: -3px;
	color: #25AFA8;
}

.deznav .metismenu>li>a g [fill] {
	fill: #25AFA8;
}

.deznav .metismenu>li:hover>a,
.deznav .metismenu>li:focus>a {
	color: #25AFA8;
}

.deznav .metismenu>li:hover>a g [fill],
.deznav .metismenu>li:focus>a g [fill] {
	fill: #25AFA8;
}

.deznav .metismenu>li.mm-active>a {
	color: #25AFA8;
}

.deznav .metismenu>li.mm-active>a g [fill] {
	fill: #25AFA8;
}

.deznav .metismenu li {
	position: relative;
}

.deznav .metismenu ul {
	transition: all .2s ease-in-out;
	position: relative;
	z-index: 1;
	padding: 0.5rem 0;
}

.deznav .metismenu ul a {
	padding-top: .5rem;
	padding-bottom: .5rem;
	position: relative;
	font-size: 16px;
	padding-left: 5.625rem;
}

@media only screen and (max-width: 1400px) {
	.deznav .metismenu ul a {
		padding-left: 3.75rem;
		font-size: 14px;
	}
}

@media only screen and (max-width: 767px) {
	.deznav .metismenu ul a {
		padding-left: 3.5rem;
	}
}

.deznav .metismenu ul a:hover,
.deznav .metismenu ul a:focus,
.deznav .metismenu ul a.mm-active {
	text-decoration: none;
	color: #69fdba;
}

.deznav .metismenu a {
	position: relative;
	display: block;
	padding: 0.625rem 1.875rem;
	outline-width: 0;
	color: #7e7e7e;
	text-decoration: none;
}

@media only screen and (max-width: 767px) {
	.deznav .metismenu a {
		padding: 0.625rem 1.25rem;
	}
}

.deznav .metismenu .has-arrow:after {
	width: .5rem;
	height: .5rem;
	right: 1.875rem;
	top: 48%;
	border-color: inherit;
	-webkit-transform: rotate(-225deg) translateY(-50%);
	transform: rotate(-225deg) translateY(-50%);
}

.deznav .metismenu .has-arrow[aria-expanded=true]:after,
.deznav .metismenu .mm-active>.has-arrow:after {
	-webkit-transform: rotate(-135deg) translateY(-50%);
	transform: rotate(-135deg) translateY(-50%);
}


@media (max-width: 767px) {
	.brand-title {
		display: none;
	}
	.footer {
		padding-left: 0;
	}
	.deznav {
		left: 0;
	}
}


[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu>ul.collapse:not(.in),
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu>ul.collapse:not(.in) {
	height: 252px !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>a {
	width: calc(70vw + 3.75rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>ul {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	max-height: 13.75rem;
	width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>ul ul a {
	width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>a {
	width: calc(70vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
	max-height: 200px;
	width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>ul ul a {
	width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>a {
	width: calc(55vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
	max-height: 200px;
	width: 55vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>ul ul a {
	width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>a {
	width: calc(45vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>ul {
	max-height: 18.75rem;
	width: 45vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>ul ul a {
	width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>a {
	width: calc(30vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
	max-height: 18.125rem;
	width: 30vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>ul ul a {
	width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>a {
	width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>ul {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	max-height: 25rem;
	width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>ul ul a {
	width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-xl:hover>a {
	width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
	max-height: 25.625rem;
	width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-lg:hover>a {
	width: calc(50vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
	max-height: 16.25rem;
	width: 50vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-md:hover>a {
	width: calc(40vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-md:hover>ul {
	max-height: 18.75rem;
	width: 40vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-sm:hover>a {
	width: calc(22vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
	max-height: 18.125rem;
	width: 22vw;
}


.nav-header .brand-logo {
	display: flex;
	height: 100%;
	width: 110%;
	align-items: center;
	font-size: 1.125rem;
	color: #fff;
	text-decoration: none;
	justify-content: center;
	font-weight: 700;
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
	.nav-header .brand-logo {
		padding-left: 30px;
	
	}
}
@media screen and (min-width: 10px) and (max-width: 1300px) {
	.nav-header .brand-logo {
		padding-left: 0px;
	
	}
}



@media screen and (min-width: 0px) and (max-width: 1400px) {
	.sidebar-tag {
		display: none !important;
	}
}

@media screen and (min-width: 0px) and (max-width: 1400px) {
	.sidebar-tag-main {
		display: none !important;
	}
}

[data-sidebar-style="compact"] .nav-header .brand-logo,
[data-sidebar-style="mini"] .nav-header .brand-logo {
	padding-right: 0;
	justify-content: center;
}

@media only screen and (max-width: 767px) {
	.nav-header .brand-logo {
		padding-left: 0;
		padding-right: 0;
		justify-content: center;
	}
}


[direction="rtl"] .nav-header .brand-logo {
	padding-left: 0;
	padding-right: 1.75rem;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo {
	padding-right: 0;
}


[data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
	padding-right: 0;
}

[data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
	padding-right: 0;
}

@media (max-width: 767.98px) {
	[direction="rtl"] .nav-header .brand-logo {
		padding-right: 0;
	}
}


[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
	width: 5rem;
	z-index: 10;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo {
	padding-left: 0;
	padding-right: 0;
	justify-content: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
	background-color: #25AFA8 !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .copyright,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .drum-box {
	display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
	padding-left: 5rem;
	width: 100%;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
	padding: 0 0.9375rem;
	padding-right: 5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
	margin-left: 5rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
	margin-right: 5rem;
	margin-left: auto;
	border: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
	padding-left: 5rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
	padding-left: 0;
	padding-right: 5rem;
}


[direction="rtl"] .nav-header .brand-logo {
	padding-left: 0;
	padding-right: 1.75rem;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo {
	padding-right: 0;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo[data-layout="horizontal"] {
	padding-right: 30px;
}

[data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
	padding-right: 0;
}

[data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
	padding-right: 0;
}

[data-layout="horizontal"][data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
	padding-right: 30px;
}

@media (max-width: 767.98px) {
	[direction="rtl"] .nav-header .brand-logo {
		padding-right: 0;
	}
}

[direction="rtl"] .nav-control {
	right: auto;
	left: -4.0625rem;
}

@media (max-width: 767.98px) {
	[direction="rtl"] .nav-control {
		left: -4.0625rem;
	}
}

@media (max-width: 575.98px) {
	[direction="rtl"] .nav-control {
		left: -4.0625rem;
	}
}

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
	right: 0;
}

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
	padding-right: 30px;
}


@media only screen and (max-width: 767px) {
	[data-sidebar-style="overlay"] .nav-header .logo-abbr {
		display: block;
	}
}

[data-header-position="fixed"] .nav-header {
	position: fixed;
	left: auto;
}

.nav-headerbg {
	background-color: #32B1A7 !important;
	width: 100% !important;
}

.nav-header {
	background-color: #32B1A7 !important;
	height: 6.5rem;
	width: 20rem;
	display: inline-block;
	text-align: left;
	position: absolute;
	left: 0;
	top: 0;
	background-color: #FBFBFB;
	transition: all .2s ease;
	z-index: 5;
}

.nav-header .logo-abbr {
	max-width: 60px;
}

@media only screen and (max-width: 575px) {
	.nav-header .logo-abbr {
		max-width: 40px;
	}
}

.nav-header .logo-compact {
	display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
	.nav-header {
		width: 16rem;
	}
}


.nav-header .brand-title {
	margin-right: 3px;
	max-width: 173px;
	margin-top: 2px;
}

@media only screen and (max-width: 1199px) {
	.nav-header {
		width: 5rem;
		height: 5rem;
	}
	.nav-header .brand-title {
		display: none;
	}
}


@media (min-width: 767px) {
	[data-sidebar-style="compact"] .nav-control {
		display: none;
	}
	[data-sidebar-style="compact"] .nav-header {
		width: 15rem;
	}
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .brand-title {
	display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .logo-abbr {
	display: block;
}

[data-sidebar-style="mini"] .nav-header .logo-abbr {
	display: block;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .logo-compact {
	max-width: 75px;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header {
	width: 9.375rem;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .brand-title {
	display: none;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .logo-compact {
	display: none;
}


[direction="rtl"]:not([data-container="boxed"]) .nav-header {
	left: auto;
	right: 0;
}

[direction="rtl"][data-container="wide-boxed"] .nav-header {
	left: auto;
	right: 0;
}

[direction="rtl"] .nav-header {
	text-align: right;
	right: auto;
}

[direction="rtl"] .nav-header .brand-title {
	margin-left: 0;
	margin-right: 15px;
}
[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
	right: 0;
}

@media screen and (min-width: 700px) and (max-width: 1200px) {
	.nav-header {
		width: 5rem;
	}
}

@media screen and (min-width: 100px) and (max-width: 700px) {
	.nav-header {
		width: 5rem !important;
	}
}
[data-headerbg="color_1"] .nav-header .hamburger.is-active .line,
[data-headerbg="color_1"] .nav-header .hamburger .line {
	background: #25AFA8 !important;
}
[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
	position: absolute;
}

[direction="rtl"]:not([data-container="boxed"]) .nav-header {
	left: auto;
	right: 0;
}

[direction="rtl"][data-container="wide-boxed"] .nav-header {
	left: auto;
	right: 0;
}

[direction="rtl"] .nav-header {
	text-align: right;
	right: auto;
}

[direction="rtl"] .nav-header .brand-title {
	margin-left: 0;
	margin-right: 15px;
}


[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
	width: 16rem;
}


.vl {
	border-left: 2.5px solid #A5A5A5;
	height: 28px;
	left: 0%;
	margin-left: 3px;
	top: 0;
}

@media screen and (min-width: 10px) and (max-width: 1560px) {
	.vl {
		display: none !important;
	}
}

.card-header-bg {
	background-color: #25AFA8 !important;
	color: white;
	justify-content: space-between !important;
}

@media screen and (min-width: 100px) and (max-width: 630px) {
	.nav-drophide {
		display: none !important;
	}
}

.gradient_one {
	background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
}


.header-right .notification_dropdown .dropdown-item:focus a,
.header-right .notification_dropdown .dropdown-item:active a {
	color: #fff;
}

.header-right .notification_dropdown .dropdown-item a {
	color: #3e4954;
}

.header-right .notification_dropdown .dropdown-item a:hover {
	text-decoration: none;
}

.header-right .dropdown-menu {
	border-width: 0;
	box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
}

.header-right .header-profile>a.nav-link {
	padding: 0;
	background: rgba(54, 201, 95, 0.05);
	border-radius: 4rem;
	display: flex;
	align-items: center;
}

.header-right .header-profile>a.nav-link i {
	font-weight: 700;
}

.header-right .header-profile>a.nav-link .header-info {
	padding-right: 20px;
	text-align: right;
	padding-left: 20px;
	background-color: transparent !important;
}

@media only screen and (max-width: 1199px) {
	.header-right .header-profile>a.nav-link .header-info {
		padding-right: 10px;
		padding-left: 10px;
		background-color: transparent !important;
	}
}

@media only screen and (max-width: 767px) {
	.header-right .header-profile>a.nav-link .header-info {
		display: none;
		background-color: transparent !important;
	}
}

.header-right .header-profile>a.nav-link .header-info span {
	font-size: 16x;
	color: #36C95F;
	display: block;
	font-weight: 400;
	background-color: transparent !important;
	white-space: pre;
}

@media only screen and (max-width: 1199px) {
	.header-right .header-profile>a.nav-link .header-info span {
		font-size: 14px!important;
		background-color: transparent !important;
	}
}

.header-right .header-profile>a.nav-link .header-info strong {
	font-weight: 600;
	background-color: transparent !important;
}

.header-right .header-profile .dropdown-menu {
	padding: 15px 0;
	min-width: 12.5rem;
}

.header-right .header-profile img {
	width: 56px;
	height: 56px;
	border: 2px solid #FFFFFF;
	border-radius: 10rem;
}

@media only screen and (max-width: 1199px) {
	.header-right .header-profile img {
		width: 40px;
		height: 40px;
	}
}

.header-right .header-profile .dropdown-toggle i {
	font-size: 1.25rem;
}

@media only screen and (max-width: 575px) {
	.header-right .header-profile .dropdown-toggle span {
		display: none;
	}
}

.header-right .header-profile .profile_title {
	background: #36C95F;
	color: #fff;
	padding: 10px 20px;
}

.header-right .header-profile .profile_title h5 {
	color: #fff;
	margin-bottom: 3px;
}

.header-right .header-profile .dropdown-item {
	padding: 8px 24px;
}

.select-wrapper {
	display: flex;
}


@media only screen and (min-width: 500px) and (max-width: 2500px) {
	.nav-position {
		margin-left: 1.5rem !important;
	}
}



.img-fluid {
	max-width: 100%;
	height: auto;
}

.img-fluid2 {
	max-width: 100%;
	height: 7.5rem;
}


.img-thumbnail {
	padding: 0.25rem;
	background-color: #F5F9F1;
	border: 1px solid #dee2e6;
	border-radius: 0.75rem;
	max-width: 100%;
	height: auto;
}

.figure {
	display: inline-block;
}

.figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
}

.figure-caption {
	font-size: 90%;
	color: #6c757d;
}

code {
	font-size: 87.5%;
	color: #e83e8c;
	word-wrap: break-word;
}

a>code {
	color: inherit;
}

kbd {
	padding: 0.2rem 0.4rem;
	font-size: 87.5%;
	color: #fff;
	background-color: #212529;
	border-radius: 0.2rem;
}

kbd kbd {
	padding: 0;
	font-size: 100%;
	font-weight: 700;
}

pre {
	display: block;
	font-size: 87.5%;
	color: #212529;
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
}





.table {
	width: 100%;
	margin-bottom: 0.6rem;
	color: #7e7e7e;
}

.table th,
.table td {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid #EEEEEE;
}

.table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #EEEEEE;
}

.table tbody+tbody {
	border-top: 2px solid #EEEEEE;
}

.table-sm th,
.table-sm td {
	padding: 0.3rem;
}

.table-bordered {
	border: 1px solid #EEEEEE;
}

.table-bordered th,
.table-bordered td {
	border: 1px solid #EEEEEE;
}

.table-bordered thead th,
.table-bordered thead td {
	border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
	border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
	color: #7e7e7e;
	background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
	background-color: #d6efc0;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
	border-color: #b3e189;
}

.table-hover .table-primary:hover {
	background-color: #c9eaac;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
	background-color: #c9eaac;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
	background-color: #edcbf3;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
	border-color: #de9fe8;
}

.table-hover .table-secondary:hover {
	background-color: #e6b6ee;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
	background-color: #e6b6ee;
}

.table-success,
.table-success>th,
.table-success>td {
	background-color: #c7f2ed;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
	border-color: #97e7de;
}

.table-hover .table-success:hover {
	background-color: #b2ede6;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
	background-color: #b2ede6;
}

.table-info,
.table-info>th,
.table-info>td {
	background-color: #c0e6ef;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
	border-color: #8ad1e1;
}

.table-hover .table-info:hover {
	background-color: #acdeea;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
	background-color: #acdeea;
}

.table-warning,
.table-warning>th,
.table-warning>td {
	background-color: #ffe1c6;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
	border-color: #ffc794;
}

.table-hover .table-warning:hover {
	background-color: #ffd4ad;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
	background-color: #ffd4ad;
}

.table-danger,
.table-danger>th,
.table-danger>td {
	background-color: #ffc3d1;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
	border-color: #ff8faa;
}

.table-hover .table-danger:hover {
	background-color: #ffaabd;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
	background-color: #ffaabd;
}

.table-light,
.table-light>th,
.table-light>td {
	background-color: #f9f9f9;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
	border-color: #f3f3f3;
}

.table-hover .table-light:hover {
	background-color: #ececec;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
	background-color: #ececec;
}

.table-active,
.table-active>th,
.table-active>td {
	background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
	background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-light th {
	color: #495057;
	background-color: #e9ecef;
	border-color: #EEEEEE;
}


@media (max-width: 575.98px) {
	.table-responsive-sm {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.table-responsive-sm>.table-bordered {
		border: 0;
	}
}

@media (max-width: 767.98px) {
	.table-responsive-md {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.table-responsive-md>.table-bordered {
		border: 0;
	}
}

@media (max-width: 991.98px) {
	.table-responsive-lg {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.table-responsive-lg>.table-bordered {
		border: 0;
	}
}

@media (max-width: 1199.98px) {
	.table-responsive-xl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.table-responsive-xl>.table-bordered {
		border: 0;
	}
}

@media (max-width: 1439.98) {
	.table-responsive-xxl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.table-responsive-xxl>.table-bordered {
		border: 0;
	}
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
	border: 0;
}

@media (max-width: 376px) {
	.table-responsive {
		display: block;
		width: 17rem;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.table-responsive-sm>.table-bordered {
		border: 0;
	}
}

@media (max-width: 450.98px) {
	.table-responsive {
		display: block;
		/* width: 85vw; */
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.table-responsive-sm>.table-bordered {
		border: 0;
	}
}

.col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
}

.col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 1.09375rem;
	line-height: 1.5;
}

.col-form-label-sm {
	padding-top: calc(0.25rem + 1px);
	padding-bottom: calc(0.25rem + 1px);
	font-size: 0.765625rem;
	line-height: 1.5;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: 0.375rem 0;
	margin-bottom: 0;
	font-size: 0.875rem;
	line-height: 1.5;
	color: #7e7e7e;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}

.form-control-sm {
	height: calc(1.5em + 0.5rem + 2px);
	padding: 0.25rem 0.5rem;
	font-size: 0.765625rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.form-control-lg {
	height: calc(1.5em + 1rem + 2px);
	padding: 0.5rem 1rem;
	font-size: 1.09375rem;
	line-height: 1.5;
	border-radius: 0.3rem;
}

.table thead th {
	vertical-align: middle !important;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
	color: #89879f;
}

.form-check-input1[disabled]~.form-check-label,
.form-check-input1:disabled~.form-check-label {
	color: #89879f;
}

.form-check-label {
	margin-bottom: 0;
}

.form-check-inline {
	display: inline-flex;
	align-items: center;
	padding-left: 0;
	margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
	position: static;
	margin-top: 0;
	margin-right: 0.3125rem;
	margin-left: 0;
}

.form-check-inline .form-check-input1 {
	position: static;
	margin-top: 0;
	margin-right: 0.3125rem;
	margin-left: 0;
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #37D1BF;
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: .1rem;
	font-size: 0.765625rem;
	line-height: 1.5;
	color: #212529;
	background-color: rgba(55, 209, 191, 0.9);
	border-radius: 0.75rem;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
	display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
	border-color: #37D1BF;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2337D1BF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
	border-color: #37D1BF;
	box-shadow: 0 0 0 0.2rem rgba(55, 209, 191, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
	border-color: #37D1BF;
	padding-right: calc(0.75em + 2.3125rem);
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2337D1BF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
	border-color: #37D1BF;
	box-shadow: 0 0 0 0.2rem rgba(55, 209, 191, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
	color: #37D1BF;
}

.was-validated .form-check-input1:valid~.form-check-label,
.form-check-input1.is-valid~.form-check-label {
	color: #37D1BF;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input1:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
	display: block;
}

.was-validated .form-check-input1:valid~.valid-tooltip,
.form-check-input1.is-valid~.valid-feedback,
.form-check-input1.is-valid~.valid-tooltip {
	display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
	color: #37D1BF;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
	border-color: #37D1BF;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
	border-color: #60dbcc;
	background-color: #60dbcc;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 0.2rem rgba(55, 209, 191, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
	border-color: #37D1BF;
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
	border-color: #37D1BF;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
	border-color: #37D1BF;
	box-shadow: 0 0 0 0.2rem rgba(55, 209, 191, 0.25);
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #FF285C;
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: .1rem;
	font-size: 0.765625rem;
	line-height: 1.5;
	color: #fff;
	background-color: rgba(255, 40, 92, 0.9);
	border-radius: 0.75rem;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
	display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	border-color: #FF285C;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF285C' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF285C' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
	border-color: #FF285C;
	box-shadow: 0 0 0 0.2rem rgba(255, 40, 92, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
	border-color: #FF285C;
	padding-right: calc(0.75em + 2.3125rem);
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF285C' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF285C' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
	border-color: #FF285C;
	box-shadow: 0 0 0 0.2rem rgba(255, 40, 92, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
	color: #FF285C;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
	display: block;
}

.was-validated .form-check-input1:invalid~.form-check-label,
.form-check-input1.is-invalid~.form-check-label {
	color: #FF285C;
}

.was-validated .form-check-input1:invalid~.invalid-feedback,
.was-validated .form-check-input1:invalid~.invalid-tooltip,
.form-check-input1.is-invalid~.invalid-feedback,
.form-check-input1.is-invalid~.invalid-tooltip {
	display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
	color: #FF285C;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
	border-color: #FF285C;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
	border-color: #ff5b83;
	background-color: #ff5b83;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 0.2rem rgba(255, 40, 92, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
	border-color: #FF285C;
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
	border-color: #FF285C;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
	border-color: #FF285C;
	box-shadow: 0 0 0 0.2rem rgba(255, 40, 92, 0.25);
}



.btn-link {
	font-weight: 400;
	color: #25AFA8;
	text-decoration: none;
}

.btn-link:hover {
	color: #478213;
	text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
	text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
	color: #6c757d;
	pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn {
	padding: 0.5rem 1rem;
	font-size: 1.09375rem;
	line-height: 1.5;
	border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm>.btn {
	padding: 0.25rem 0.5rem;
	font-size: 0.765625rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.fade {
	transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
	.fade {
		transition: none;
	}
}

.fade:not(.show) {
	opacity: 0;
}



.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative;
}

.dropdown-toggle {
	white-space: nowrap;
}

.dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 0.875rem;
	color: #7e7e7e;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.75rem;
}

.dropdown-menu-left {
	right: auto;
	left: 0;
}

.dropdown-menu-right {
	right: 0;
	left: auto;
}

@media (min-width: 576px) {
	.dropdown-menu-sm-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-sm-right {
		right: 0;
		left: auto;
	}
}

@media (min-width: 768px) {
	.dropdown-menu-md-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-md-right {
		right: 0;
		left: auto;
	}
}

@media (min-width: 992px) {
	.dropdown-menu-lg-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-lg-right {
		right: 0;
		left: auto;
	}
}

@media (min-width: 1200px) {
	.dropdown-menu-xl-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-xl-right {
		right: 0;
		left: auto;
	}
}

@media (min-width: 1440) {
	.dropdown-menu-xxl-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-xxl-right {
		right: 0;
		left: auto;
	}
}

.dropup .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0;
	border-right: 0.3em solid transparent;
	border-bottom: 0.3em solid;
	border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropright .dropdown-menu {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0;
	border-bottom: 0.3em solid transparent;
	border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropright .dropdown-toggle::after {
	vertical-align: 0;
}

.dropleft .dropdown-menu {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
}

.dropleft .dropdown-toggle::after {
	display: none;
}

.dropleft .dropdown-toggle::before {
	display: inline-block;
	margin-right: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0.3em solid;
	border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropleft .dropdown-toggle::before {
	vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
	right: auto;
	bottom: auto;
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #e9ecef;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: #16181b;
	text-decoration: none;
	background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #25AFA8;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: transparent;
}

.dropdown-menu.show {
	display: block;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 0.765625rem;
	color: #6c757d;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #212529;
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
	position: relative;
	flex: 1 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
	z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
	z-index: 1;
}

.btn-node-join-pending {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.node-join-header {
	margin-left: 3rem !important;
}

@media only screen and (min-width: 500px) and (max-width: 2500px) {
	.node-join-header {
		margin-left: 0.7rem !important;
	}
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.node-join-header {
		margin-left: 3rem !important;
	}
}

.btn-toolbar .input-group {
	width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
	margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
	margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
	margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
	padding-right: 0.375rem;
	padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}

.btn-group-vertical {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
	width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
	margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
	margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
	margin-bottom: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
	margin-left: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
	z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
	z-index: 4;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group>.custom-file {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
	display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
	position: relative;
	z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
	z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
	margin-left: -1px;
}

.input-group-prepend {
	margin-right: -1px;
}

.input-group-append {
	margin-left: -1px;
}

.input-group-text {
	display: flex;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #f0f1f5;
	border-radius: 0.75rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
	margin-top: 0;
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
	height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
	padding: 0.5rem 1rem;
	font-size: 1.09375rem;
	line-height: 1.5;
	border-radius: 0.3rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
	height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
	padding: 0.25rem 0.5rem;
	font-size: 0.765625rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
	padding-right: 1.75rem;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.custom-control {
	position: relative;
	z-index: 1;
	display: block;
	min-height: 1.3125rem;
	padding-left: 1.5rem;
	color-adjust: exact;
}

.custom-control-inline {
	display: inline-flex;
	margin-right: 1rem;
}

.custom-control-input {
	position: absolute;
	left: 0;
	z-index: -1;
	width: 1rem;
	height: 1.15625rem;
	opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	border-color: #25AFA8;
	background-color: #25AFA8;
}

.custom-control-input:focus~.custom-control-label::before {
	box-shadow: 0 0 0 0.2rem rgba(108, 197, 29, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
	border-color: #adeb77;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
	color: #fff;
	background-color: #c8f1a3;
	border-color: #c8f1a3;
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
	color: #6c757d;
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
	background-color: #e9ecef;
}

.custom-control-label {
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
}

.custom-control-label::before {
	position: absolute;
	top: 0.15625rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	content: "";
	background-color: #fff;
	border: #adb5bd solid 1px;
}

.custom-control-label::after {
	position: absolute;
	top: 0.15625rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	content: "";
	background: no-repeat 50% / 50% 50%;
}

.hex-checkbox {
	display: flex;
}

.custom-checkbox .custom-control-label::before {
	border-radius: 0.75rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
	border-color: #25AFA8;
	background-color: #25AFA8;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(108, 197, 29, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
	background-color: rgba(108, 197, 29, 0.5);
}

.custom-radio .custom-control-label::before {
	border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(108, 197, 29, 0.5);
}

.custom-switch {
	padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
	left: -2.25rem;
	width: 1.75rem;
	pointer-events: all;
	border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
	top: calc(0.15625rem + 2px);
	left: calc(-2.25rem + 2px);
	width: calc(1rem - 4px);
	height: calc(1rem - 4px);
	background-color: #adb5bd;
	border-radius: 0.5rem;
	transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.custom-switch .custom-control-label::after {
		transition: none;
	}
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
	background-color: #fff;
	transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(108, 197, 29, 0.5);
}

.custom-select {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
	border: 1px solid #f0f1f5;
	border-radius: 0.75rem;
	appearance: none;
}

.custom-select:focus {
	border-color: #adeb77;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(108, 197, 29, 0.25);
}

.custom-select:focus::-ms-value {
	color: #495057;
	background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
	height: auto;
	padding-right: 0.75rem;
	background-image: none;
}

.custom-select:disabled {
	color: #6c757d;
	background-color: #e9ecef;
}

.custom-select::-ms-expand {
	display: none;
}

.custom-select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
	height: calc(1.5em + 0.5rem + 2px);
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: 0.765625rem;
}

.custom-select-lg {
	height: calc(1.5em + 1rem + 2px);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	font-size: 1.09375rem;
}

.flex-load {
	display: flex;
}

.sec-align {
	display: flex;
	align-items: center;
	color: white!important;
}

.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	margin-bottom: 0;
}

.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	margin: 0;
	opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
	border-color: #adeb77;
	box-shadow: 0 0 0 0.2rem rgba(108, 197, 29, 0.25);
}

.custom-file-input[disabled]~.custom-file-label,
.custom-file-input:disabled~.custom-file-label {
	background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label::after {
	content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
	content: attr(data-browse);
}

.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	border: 1px solid #f0f1f5;
	border-radius: 0.75rem;
}

.custom-file-label::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: block;
	height: calc(1.5em + 0.75rem);
	padding: 0.375rem 0.75rem;
	line-height: 1.5;
	color: #495057;
	content: "Browse";
	background-color: #e9ecef;
	border-left: inherit;
	border-radius: 0 0.75rem 0.75rem 0;
}

.custom-range {
	width: 100%;
	height: 1.4rem;
	padding: 0;
	background-color: transparent;
	appearance: none;
}

.custom-range:focus {
	outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 1px #F5F9F1, 0 0 0 0.2rem rgba(108, 197, 29, 0.25);
}

.custom-range:focus::-moz-range-thumb {
	box-shadow: 0 0 0 1px #F5F9F1, 0 0 0 0.2rem rgba(108, 197, 29, 0.25);
}

.custom-range:focus::-ms-thumb {
	box-shadow: 0 0 0 1px #F5F9F1, 0 0 0 0.2rem rgba(108, 197, 29, 0.25);
}

.custom-range::-moz-focus-outer {
	border: 0;
}

.custom-range::-webkit-slider-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: -0.25rem;
	background-color: #25AFA8;
	border: 0;
	border-radius: 1rem;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	appearance: none;
}

@media (prefers-reduced-motion: reduce) {
	.custom-range::-webkit-slider-thumb {
		transition: none;
	}
}

.custom-range::-webkit-slider-thumb:active {
	background-color: #c8f1a3;
}

.custom-range::-webkit-slider-runnable-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
	width: 1rem;
	height: 1rem;
	background-color: #25AFA8;
	border: 0;
	border-radius: 1rem;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	appearance: none;
}

@media (prefers-reduced-motion: reduce) {
	.custom-range::-moz-range-thumb {
		transition: none;
	}
}

.custom-range::-moz-range-thumb:active {
	background-color: #c8f1a3;
}

.custom-range::-moz-range-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}

.custom-range::-ms-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: 0;
	margin-right: 0.2rem;
	margin-left: 0.2rem;
	background-color: #25AFA8;
	border: 0;
	border-radius: 1rem;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	appearance: none;
}

@media (prefers-reduced-motion: reduce) {
	.custom-range::-ms-thumb {
		transition: none;
	}
}

.custom-range::-ms-thumb:active {
	background-color: #c8f1a3;
}

.custom-range::-ms-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: transparent;
	border-color: transparent;
	border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
	background-color: #dee2e6;
	border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
	margin-right: 15px;
	background-color: #dee2e6;
	border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
	background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
	cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
	background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
	cursor: default;
}

.custom-range:disabled::-ms-thumb {
	background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.custom-control-label::before,
	.custom-file-label,
	.custom-select {
		transition: none;
	}
}

.card-padding {
	padding: 0rem !important;
	border-radius: 0.5rem !important;
}

.card-bodybar {
	background-color: #25AFA8 !important;
	text-align: center !important;
}

.card-body-align {
	text-align: center !important;
	padding: 0rem !important;
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.card-body-align {
		text-align: left !important;
		padding: 0rem 1rem 0rem 1rem !important;
	}
}

.card-header-text {
	justify-content: center !important;
}

.usergroup-dropdown {
	display: flex;
	align-items: center;
}

.group-button-text {
	display: flex;
	align-items: center;
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.card-header-text {
		justify-content: space-between !important;
	}
}

@media screen and (min-width: 10px) and (max-width: 574px) {
	.close-btn-position {
		display: none !important;
	}
}

.user-list-container .row .col-md-12 .card .card-body .form-outline .form-control {
	width: 90vw;
	height: 2.5rem;
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.profile-label-hide {
		display: none;
	}
}

.node-serach {
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.75rem;
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.75rem;
}

.card>hr {
	margin-right: 0;
	margin-left: 0;
}

.card>.list-group {
	border-top: inherit;
	border-bottom: inherit;
}

.card>.list-group:first-child {
	border-top-width: 0;
	border-top-left-radius: calc(0.75rem - 1px);
	border-top-right-radius: calc(0.75rem - 1px);
}

.card>.list-group:last-child {
	border-bottom-width: 0;
	border-bottom-right-radius: calc(0.75rem - 1px);
	border-bottom-left-radius: calc(0.75rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
	border-top: 0;
}

.card-header-userlist {
	justify-content: space-between!important;
	border-bottom: none!important;
}

.card-body {
	flex: 1 1 auto;
	/* min-height: 1px; */
	padding: 1.25rem;
}

.card-title {
	margin-bottom: 0.25rem;
}

.card-subtitle {
	margin-top: -0.375rem;
	margin-bottom: 0;
}

.card-text:last-child {
	margin-bottom: 0;
}

.card-text {
	font-size: 0.65rem !important;
	line-height: 1.3 !important;
}

.card-link:hover {
	text-decoration: none;
}

.card-link+.card-link {
	margin-left: 1.25rem;
}

.card-header {
	padding: 0.5rem 0.5rem !important;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
	border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
}

.card-footer {
	padding: 0.75rem 1.25rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
	border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
}

.card-header-tabs {
	margin-right: -0.625rem;
	margin-bottom: -0.75rem;
	margin-left: -0.625rem;
	border-bottom: 0;
}

.card-header-pills {
	margin-right: -0.625rem;
	margin-left: -0.625rem;
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
	border-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
	flex-shrink: 0;
	width: 100%;
}

.card-img,
.card-img-top {
	border-top-left-radius: calc(0.75rem - 1px);
	border-top-right-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-bottom {
	border-bottom-right-radius: calc(0.75rem - 1px);
	border-bottom-left-radius: calc(0.75rem - 1px);
}

.card-deck .card {
	margin-bottom: 15px;
}

@media (min-width: 576px) {
	.card-deck {
		display: flex;
		flex-flow: row wrap;
		margin-right: -15px;
		margin-left: -15px;
	}
	.card-deck .card {
		flex: 1 0 0%;
		margin-right: 15px;
		margin-bottom: 0;
		margin-left: 15px;
	}
}

.card-group>.card {
	margin-bottom: 15px;
}

@media (min-width: 576px) {
	.card-group {
		display: flex;
		flex-flow: row wrap;
	}
	.card-group>.card {
		flex: 1 0 0%;
		margin-bottom: 0;
	}
	.card-group>.card+.card {
		margin-left: 0;
		border-left: 0;
	}
	.card-group>.card:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.card-group>.card:not(:last-child) .card-img-top,
	.card-group>.card:not(:last-child) .card-header {
		border-top-right-radius: 0;
	}
	.card-group>.card:not(:last-child) .card-img-bottom,
	.card-group>.card:not(:last-child) .card-footer {
		border-bottom-right-radius: 0;
	}
	.card-group>.card:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.card-group>.card:not(:first-child) .card-img-top,
	.card-group>.card:not(:first-child) .card-header {
		border-top-left-radius: 0;
	}
	.card-group>.card:not(:first-child) .card-img-bottom,
	.card-group>.card:not(:first-child) .card-footer {
		border-bottom-left-radius: 0;
	}
}

.card-columns .card {
	margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
	.card-columns {
		column-count: 3;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;
	}
	.card-columns .card {
		display: inline-block;
		width: 100%;
	}
}

.accordion {
	overflow-anchor: none;
}

.accordion>.card {
	overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
	border-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.accordion>.card>.card-header {
	border-radius: 0;
	margin-bottom: -1px;
}


.collapsed  .accordion__header {
	background: #25AFA8;
	border-color: #25AFA8;
	color: #fff;
	box-shadow: 0 15px 20px 0 rgba(108, 197, 29, 0.15);
}

.accordion-primary .accordion__header.collapsed {

	background: #ffffff;
	border:1px solid #f0f1f5;
	color: #211c37;
	box-shadow: none;
}

.accordion-primary-solid .accordion__header {
	background: #25AFA8;
	border-color: #25AFA8;
	color: #fff;
	box-shadow: 0 -10px 20px 0 rgba(108, 197, 29, 0.15);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.accordion-primary-solid .accordion__header.collapsed {
	background: #ddf7c7;
	border-color: #ddf7c7;
	color: #211c37;
	box-shadow: none;
	border-bottom-left-radius: 0.875rem;
	border-bottom-right-radius: 0.875rem;
}

.accordion-primary-solid .accordion__body {
	border: 2px solid #25AFA8;
	border-top: none;
	box-shadow: 0 15px 20px 0 rgba(108, 197, 29, 0.15);
	border-bottom-left-radius: 0.875rem;
	border-bottom-right-radius: 0.875rem;
}

.accordion-danger .accordion__header {
	background: #FF285C;
	border-color: #FF285C;
	color: #fff;
	box-shadow: 0 15px 20px 0 rgba(255, 40, 92, 0.15);
}

.accordion-danger .accordion__header.collapsed {
	background: #ffeaef;
	border-color: #ffeaef;
	color: #211c37;
	box-shadow: none;
}

.accordion-danger-solid .accordion__header {
	background: #FF285C;
	border-color: #FF285C;
	color: #fff;
	box-shadow: 0 -10px 20px 0 rgba(255, 40, 92, 0.15);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.accordion-danger-solid .accordion__header.collapsed {
	background: #ffeaef;
	border-color: #ffeaef;
	color: #211c37;
	box-shadow: none;
	border-bottom-left-radius: 0.875rem;
	border-bottom-right-radius: 0.875rem;
}

.accordion-danger-solid .accordion__body {
	border: 2px solid #FF285C;
	border-top: none;
	box-shadow: 0 15px 20px 0 rgba(255, 40, 92, 0.15);
	border-bottom-left-radius: 0.875rem;
	border-bottom-right-radius: 0.875rem;
}

.accordion__item {
	margin-bottom: 1.25rem;
}

.accordion__header {
	padding: 1rem 1.75rem;
	border: 1px solid #f0f1f5;
	cursor: pointer;
	position: relative;
	color: #333;
	font-weight: 400;
	border-radius: 0.875rem;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.accordion__header--indicator {
	font-family: 'themify';
	position: absolute;
	right: 1.5625rem;
	top: 50%;
	transform: translateY(-50%);
}

[direction="rtl"] .accordion__header--indicator {
	right: auto;
	left: 1.5625rem;
}

.accordion__header--indicator.indicator_bordered {
	display: inline-block;
	width: 25px;
	text-align: center;
	height: 25px;
	border: 1px solid #f0f1f5;
	border-radius: 50%;
	line-height: 25px;
}

.accordion__header:not(.collapsed) .accordion__header--indicator::before {
	content: "\e622";
}

.accordion__header:not(.collapsed) .accordion__header--indicator.style_two::before {
	content: "\e648";
}

.accordion__header.collapsed .accordion__header--indicator::before {
	content: "\e64b";
}

.accordion__header.collapsed .accordion__header--indicator.style_two::before {
	content: "\e64b";
}

.accordion__body--text {
	padding: 0.875rem 1.25rem;
}

.accordion-bordered .accordion__body {
	border: 1px solid #f0f1f5;
	border-top: none;
	border-bottom-left-radius: 0.875rem;
	border-bottom-right-radius: 0.875rem;
}

.accordion-bordered .accordion__header.collapsed {
	border-radius: 0.875rem;
}

.accordion-bordered .accordion__header {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.accordion-no-gutter .accordion__item {
	margin-bottom: 0;
}

.accordion-no-gutter .accordion__item .accordion__header.collapsed {
	border-bottom: none;
}

.accordion-no-gutter .accordion__item:last-child .accordion__header {
	border-bottom: 1px solid #f0f1f5;
}

.accordion-no-gutter.accordion__bordered .accordion__item:not(:last-child) .accordion__body {
	border-bottom: none;
}

.accordion-left-indicator .accordion__header--text {
	padding-left: 2.5rem;
}

.accordion-left-indicator .accordion__header--indicator {
	right: auto;
	left: 1.5625rem;
}

.accordion-with-icon .accordion__header--text {
	padding-left: 2.5rem;
}

[direction="rtl"] .accordion-with-icon .accordion__header--text {
	padding-left: 0;
	padding-right: 2.5rem;
}

.accordion-with-icon .accordion__header--icon {
	position: absolute;
	right: auto;
	left: 1.5625rem;
	font-family: 'themify';
}

[direction="rtl"] .accordion-with-icon .accordion__header--icon {
	left: auto;
	right: 1.5625rem;
}

.accordion-with-icon .accordion__header--icon::before {
	content: "\e645";
}

.accordion-header-bg .accordion__header {
	background-color: #E8E8E8;
}

.accordion-header-bg .accordion__header--primary {
	background-color: #25AFA8;
	color: #fff;
	border-color: #25AFA8;
}

.accordion-header-bg .accordion__header--info {
	background-color: #1EA7C5;
	color: #fff;
	border-color: #1EA7C5;
}

.accordion-header-bg .accordion__header--success {
	background-color: #37D1BF;
	color: #fff;
	border-color: #37D1BF;
}

.accordion-header-bg.accordion-no-gutter .accordion__header {
	border-color: transparent;
	border-radius: 0;
}

.accordion-header-bg.accordion-no-gutter .accordion__item:first-child .accordion__header {
	border-top-left-radius: 0.875rem;
	border-top-right-radius: 0.875rem;
}

.accordion-header-bg.accordion-no-gutter .accordion__item:last-child .accordion__header {
	border-bottom-left-radius: 0.875rem;
	border-bottom-right-radius: 0.875rem;
}

.accordion.accordion-no-gutter .accordion__header {
	border-radius: 0;
}

.accordion.accordion-no-gutter .accordion__header.collapsed {
	border-radius: 0;
}

.accordion.accordion-no-gutter .accordion__body {
	border-radius: 0;
}

.accordion.accordion-no-gutter .accordion__item:first-child .accordion__header {
	border-top-left-radius: 0.875rem;
	border-top-right-radius: 0.875rem;
}

.accordion.accordion-no-gutter .accordion__item:last-child .accordion__header.collapsed {
	border-bottom-left-radius: 0.875rem;
	border-bottom-right-radius: 0.875rem;
}

.accordion.accordion-no-gutter .accordion__item:last-child .accordion__body {
	border-bottom-left-radius: 0.875rem;
	border-bottom-right-radius: 0.875rem;
}

.accordion-solid-bg .accordion__header {
	border-color: transparent;
	background-color: #ddf7c7;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.accordion-solid-bg .accordion__header.collapsed {
	border-radius: 0.875rem;
}

.accordion-solid-bg .accordion__body {
	border-color: transparent;
	background-color: #ddf7c7;
	border-bottom-left-radius: 0.875rem;
	border-bottom-right-radius: 0.875rem;
}

.accordion-active-header .accordion__header:not(.collapsed) {
	background-color: #1EA7C5;
	border-color: #1EA7C5;
	color: #fff;
}

.accordion-header-shadow .accordion__header {
	border: none;
	box-shadow: 0 0 0.9375rem -3px rgba(0, 0, 0, 0.3);
}

.accordion-rounded-stylish .accordion__header {
	border-top-left-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
}

.accordion-rounded-stylish .accordion__body {
	border-bottom-left-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
}

.accordion-rounded .accordion__header {
	border-radius: 0.3125rem;
}

.accordion-gradient .accordion__header {
	color: #fff;
	background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
	border-color: transparent;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.accordion-gradient .accordion__header.collapsed {
	border-bottom-left-radius: 0.875rem;
	border-bottom-right-radius: 0.875rem;
}

.accordion-gradient .accordion__body {
	color: #fff;
	background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
	border-color: transparent;
}


.badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.75rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.badge {
		transition: none;
	}
}


@media screen and (min-width: 10px) and (max-width: 400px) {
	.custom-file-upload {
		margin-bottom: 0px;
	}
}

@media screen and (min-width: 400px) and (max-width: 2500px) {
	.custom-file-upload {
		margin-bottom: 25px;
	}
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.items {
		padding: 0px !important;
	}
}

.items {
	padding: 13px;
}

.img-wrap {
	position: relative;
	width: 9rem;
	height: 9rem;
	overflow: hidden;
	border-radius: 50%;
}

.img-upload:before {
	font-family: "Font Awesome 5 Free";
	content: "\f093";
	font-size: 40px;
	position: absolute;
	padding-top: 80px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: #63d3a6;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	opacity: 0;
	transition: .5s ease;
	background-color: #fff;
}

.img-upload:hover:before {
	opacity: 1;
}

.img1 {
	width: auto;
	height: 100%;
}

.save {
	font-weight: 600;
	left: -20px;
	text-transform: uppercase;
	letter-spacing: 1px;
	width: 20px;
	box-shadow: 20px 0px 40px 0px #63d3a6;
}

.edit {
	color: #fff;
	width: 180px;
}

.circle-avatar {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	font-size: 90%;
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.avatar-icon-1 {
	height: 50px;
	width: 50px;
	font-size: 18px;
	line-height: 43px;
	text-align: center;
	color: #833A2A;
	background: #FEE2DC;
	border-radius: 50%;
	border: 4px solid white;
	margin-left: 5px;
}

.avatar-icon-2 {
	height: 50px;
	width: 50px;
	font-size: 18px;
	line-height: 43px;
	text-align: center;
	color: #84581E;
	background: #FFEDD0;
	border-radius: 50%;
	border: 4px solid white;
	margin-left: 45px;
}

.avatar-icon-3 {
	height: 50px;
	width: 50px;
	font-size: 18px;
	line-height: 43px;
	text-align: center;
	color: #21424E;
	background: #D4E2EE;
	border-radius: 50%;
	border: 4px solid white;
	margin-left: 85px;
}

.avatar-icon-4 {
	height: 50px;
	width: 50px;
	font-size: 8px;
	line-height: 43px;
	text-align: center;
	color: #FFFFFF;
	background: #0F4542;
	border-radius: 50%;
	border: 4px solid white;
	margin-left: 125px;
}

.avatar-icon-5 {
	margin-top: 15px;
	margin-left: 185px;
}

.wave-box {
	width: 20rem !important;
	margin-top: 3rem;
}

.speed-test {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ReactTags__tags {
	position: relative;
}

.ReactTags__clearAll {
	cursor: pointer;
	padding: 10px;
	margin: 10px;
	background: #f88d8d;
	color: #fff;
	border: none;
}


/* Styles for the input */

.ReactTags__tagInput {
	border-radius: 2px;
	display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
	height: 31px;
	margin-left: 0.5rem;
	font-size: 12px;
	border: 1px solid #eee;
	border-radius: 0.3rem;
	width: 20rem;
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.ReactTags__tagInput input.ReactTags__tagInputField,
	.ReactTags__tagInput input.ReactTags__tagInputField:focus {
		width: 9rem !important;
	}
}

.ReactTags__editInput {
	border-radius: 1px;
}

.ReactTags__editTagInput {
	display: inline-flex;
}


/* Styles for selected tags */

.ReactTags__selected span.ReactTags__tag {
	border: 1px solid #ddd;
	background: #25AFA8;
	color: white;
	font-size: 12px;
	display: inline-block;
	padding: 5px;
	margin: 0 5px;
	border-radius: 0.5rem;
}

.ReactTags__selected a.ReactTags__remove {
	color: #aaa;
	margin-left: 5px;
	cursor: pointer;
}


.custom-file-upload {
	border-radius: 50%;
	display: inline-block;
	position: relative;
	padding: 6px;
	cursor: pointer;
}


/* Styles for suggestions */

.ReactTags__suggestions {
	position: absolute;
}

.ReactTags__suggestions ul {
	list-style-type: none;
	box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
	background: white;
	width: 200px;
}

.ReactTags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 5px 10px;
	margin: 0;
}

.ReactTags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
	background: #b7cfe0;
	cursor: pointer;
}

.ReactTags__remove {
	border: none;
	cursor: pointer;
	background: none;
	color: white;
}

.upload-label {
	display: flex;
	margin-top: 1rem;
}

.upload-file-margin {
	margin-top: 2.2rem;
}


.upload-label-top {
	display: flex;
}

.upload-file-block {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0rem 0.5rem 0rem 0.5rem;
}

.upload-file-left {
	display: flex;
}

.upload-file-right {
	display: flex;
	flex-direction: column;
}

.avatar-icon-5-color {
	color: #A5A5A5 !important;
}

@media screen and (min-width: 10px) and (max-width: 1560px) {
	.avatar-icon-1 {
		display: none !important;
	}
}

@media screen and (min-width: 10px) and (max-width: 1560px) {
	.avatar-icon-2 {
		display: none !important;
	}
}

@media screen and (min-width: 10px) and (max-width: 1560px) {
	.avatar-icon-3 {
		display: none !important;
	}
}

@media screen and (min-width: 10px) and (max-width: 1560px) {
	.avatar-icon-4 {
		isplay: none !important;
	}
}

@media screen and (min-width: 10px) and (max-width: 1560px) {
	.avatar-icon-5 {
		display: none !important;
	}
}


.power-title {
	color: white !important;
	font-weight: 400;
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
}

.power-text {
	color: white !important;
	font-weight: 300;
	display: flex;
	justify-content: flex-start;
	margin-top: 1.5rem;
	margin-left: 2.3rem;
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.power-text {
		font-weight: 100;
		font-size: 0.8rem!important;
		margin-left:1rem!important;
	}
}
.node-power-block-body {
	margin: 0vw 0vw 0vw 1.8vw;
}

.node-power-block-body1 {
	margin: 0vw 0vw 0.5vw 1.8vw;
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.node-power-block-body {
		margin: 0vw 1rem 0vw 1rem !important;
	}
	.power-title {
		margin-top: 0.5rem;
	
	}
}

@media screen and (min-width: 500px) and (max-width: 1500px) {
	.node-power-block-body {
		margin: 0vw 1vw 0vw 2vw !important;
	}
	.node-power-block-body1 {
		margin: 0vw 0vw 0.5vw 3vw;
	}
	.pwr-c-r{
		margin-left: 3vw;
	}

}



@media screen and (min-width: 1000px) and (max-width: 2100px) {
	.node-power-block-body1 {
		margin: 0rem 1.8rem 0.5rem 2rem!important;
	}
	}
	




.card-group-bg {
	background-color: transparent !important;
}

.target-info-text {
	color: #0F4542 !important;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
}

.target-info-text-2 {
	color: #0F4542 !important;
	margin-right: 1.8rem;
	margin-bottom: 0.5rem;
}

.target-info-input {
	margin-bottom: 1rem;
}

.header-title {
	color: #0F4542;
	font-weight: 600!important;
}

.label-color {
	color: #0F4542 !important;
}

.target-info-label {
	display: flex;
	align-items: center;
}

.target-info-box {
	display: flex;
	align-items: center;
	flex-direction: column;
	align-content: center;
}

.node-header {
	color: #0F4542;
	margin-top: 1rem;
	margin-bottom: 0.4rem;
	margin-left: 2.9rem;
	margin-right: 2.5rem;
	font-size: 0.9rem;
	font-weight: 500;
	flex-shrink: 0;
}

.profile-header-text {
	color: #0F4542 !important;
}

.profile-header-normal-text {
	font-weight: 400;
	color: #0F4542 !important;
}

.new-nodegroup {
	display: flex;
}

.node-namebox {
	flex-grow: 1;
}

.flex-grow-1 {
	flex-grow: 1
}

.flex-grow-0 {
	flex-grow: 0
}

.new-nodegroup-submit {
	width: 8rem;
}

.nodegroup-submit {
	display: flex;
	justify-content: flex-end;
	margin: 1rem;
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.profile-header-normal-text {
		font-weight: 400!important;
		font-size: 12px !important;
	}
}

@media screen and (min-width: 400px) and (max-width: 2500px) {
	.profile-header-normal-text {
		font-size: 16px !important;
	}
}

.btn-submit {
	padding: 0.75rem 10rem 0.75rem 10rem;
	letter-spacing: 2px;
	width: 8rem;
	
}

@media screen and (min-width: 100px) and (max-width: 576px) {
	.header-title {
		margin-left: 1rem !important;
	}
	.btn-submit {
		letter-spacing: 2px;
		width: 7rem;
		padding: 0.6rem 10rem 0.6rem 10rem;
		margin-right: 2rem !important;
	}
}

@media screen and (min-width: 576px) and (max-width: 2500px) {
	.header-title {
		margin-left: 0.5rem !important;
	}
	.btn-submit {
		margin-right: 2rem !important;
	}
}


.Nodelistbox {
	display: flex !important;
}


@media screen and (min-width:0px) and (max-width: 1200) {
	.Nodelistbox {
		display: flex !important;
		flex-wrap: wrap-reverse;
	}
}

.Nodelist {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	flex: 0 0 74%;
	max-width: 74%;
}

.datatable-select-wrapper {
	display: flex!important;
	justify-content: space-between !important;
	align-items: center;
	padding-right: 0.5rem !important;
}

.datatable-pagination {
	display: flex!important;
	justify-content: flex-end !important;
	align-items: center;
	margin-top: 1rem;
}

.datatable-pagination .datatable-select-wrapper .datatable-select-text {
	margin-bottom: 0rem !important;
	padding-right: 0.5rem;
}

.datatable-pagination .select-wrapper .form-outline .form-control {
	width: 4rem !important;
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.edit-user-table {
		margin-top: 1.7rem !important;
	}
	.user-list-box {
		padding: 0.3rem;
	}
}

@media only screen and (max-width: 500px) {
	.datatable-pagination .datatable-select-wrapper>p {
		font-size: 0.75rem;
		padding-right: 0.5rem !important;
	}
	.datatable-pagination-nav {
		font-size: 0.80rem;
	}
	.form-outline {
		height: 3rem !important;
	}
}

@media screen and (min-width: 10) and (max-width: 450px) {
	.datatable-pagination .datatable-select-wrapper {
		padding-right: 0rem !important;
	}
	.datatable-pagination .select-wrapper .form-outline .form-control {
		border-radius: 10rem !important;
	}
}

.form-outline {
	display: flex!important;
	justify-content: flex-start !important;
	padding-left: 0.5rem !important;
}

@media screen and (min-width: 992px) and (max-width: 2500px) {
	.datatable-pagination .datatable-select-wrapper .datatable-select-text {
		padding-right: 1rem !important;
	}
	.datatable-pagination-nav {
		padding-right: 1rem !important;
	}
	.form-outline {
		height: 5rem !important;
	}
	.edit-user-table {
		padding: 1rem !important;
	}
	.form-outline {
		margin-top: 1.5rem;
	}
}

@media screen and (min-width: 992px) and (max-width: 2500px) {
	.user-list-container .row .col-md-12 .card .card-body .form-outline {
		margin-top: 0rem !important;
	}
	.user-list-box {
		padding: 0.5rem;
	}
}

@media screen and (min-width: 451) and (max-width: 992px) {
	.datatable-pagination .datatable-select-wrapper .datatable-select-text {
		padding-right: 1rem !important;
	}
	.user-list-box {
		padding: 0.5rem;
	}
}

.card-header-userlist .card-body .form-outline {
	margin-top: 0rem !important;
}

@media only screen and (min-width: 500px) and (max-width: 2500px) {
	.profile-position {
		margin-left: 0.9rem !important;
	}
}



.Nodelist .form-outline .form-control {
	width: 56rem !important;
}

.Nodelist .datatable .datatable-inner .table tbody tr td {
	font-size: 0.8rem !important;
}

.Nodelist .datatable .datatable-inner .table thead tr th {
	font-size: 0.8rem !important;
}

.cert-box .form-outline .form-control {
	width: 56rem !important;
}

.cert-box .form-outline .mb-4 {
	margin: 0rem !important;
}

.cert-box .form-outline {
	height: 3rem!important;
	margin-top: 0.5rem !important;
	margin-bottom: 1.3rem !important;
}

.cert-box .datatable .datatable-inner .table tbody tr td {
	font-size: 0.7rem !important;
	/* padding: 12px 0px 12px 20px!important; */
}

.cert-box .datatable .datatable-inner .table thead tr th {
	font-size: 0.70rem !important;
	padding: 0.5rem 0rem 0.5rem 0.8rem!important;
}

.match-fw-block .datatable .datatable-pagination {
	margin-top: 0.3rem!important;
}

.match-fw-block .form-outline .form-control {
	width: 26rem !important;
}

@media (max-width: 600px) {
	.match-fw-block .form-outline .form-control {
		width: unset!important;
	}
}


.match-fw-block .form-outline .mb-4 {
	margin: 0rem !important;
}

.match-fw-block .form-outline {
	height: 3rem!important;
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.match-fw-block .datatable .datatable-inner .table tbody tr td {
	font-size: 0.75rem !important;
	padding: 15px 20px !important;
	text-align: center;
	align-items: center;
}

.match-fw-block .datatable .datatable-inner .table thead tr th {
	font-size: 0.7rem !important;
	text-align: center;
}



.update-fw-block .datatable .datatable-pagination {
	margin-top: 0.3rem!important;
}

.update-fw-block .form-outline .form-control {
	width: 48.5vw !important;
}

@media (max-width: 1700px) {
	.update-fw-block .form-outline .form-control {
		width: 62.5vw!important;
	}
}


.update-fw-block .form-outline .mb-4 {
	margin: 0rem !important;
}

.update-fw-block .form-outline {
	height: 3rem!important;
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.update-fw-block .datatable .datatable-inner .table tbody tr td {
	font-size: 0.95rem !important;
	padding: 15px 20px !important;
	text-align: center;
	align-items: center;
}

.update-fw-block .datatable .datatable-inner .table thead tr th {
	font-size: 0.9rem !important;
	text-align: center;
}

.user-certlist .datatable .datatable-inner .table tbody tr td {
	font-size: 0.95rem !important;
	padding: 15px 20px !important;
	text-align: center;
	align-items: center;
}

.user-certlist .datatable .datatable-inner .table thead tr th {
	font-size: 0.9rem !important;
	text-align: center;
}

.setting-provision-updatelevel {
	display: flex;
	flex-direction: column;
	flex-grow: 0.7
}

.setting-provision-target {
	display: flex;
	flex-direction: column;
	flex-grow: 1
}

.setting-provision-updatelevel-block {
	display: flex;
}

.setting-provision-input-field {
	padding: 0rem 0.4rem 1.1rem 0rem!important;
	;
}

.setting-provision {
	display: flex;
	padding: 0rem 1rem 0.5rem 1rem;
}

.setting-provision-btn {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 1.5rem;
}


.select-file-box .datatable .datatable-pagination {
	margin-top: 0.3rem!important;
}

.select-file-box.form-outline .form-control {
	width: 48rem !important;
}

.select-file-box .form-outline .mb-4 {
	margin: 0rem !important;
}

.select-file-box .form-outline {
	height: 3rem!important;
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.select-file-box .datatable .datatable-inner .table tbody tr td {
	font-size: 0.95rem !important;
	padding: 15px 20px !important;
	text-align: center;
	align-items: center;
}

.select-file-box .datatable .datatable-inner .table thead tr th {
	font-size: 0.9rem !important;
	text-align: center;
}

.update-fw-status-list {
	display: flex;
	justify-content: center;
	align-items: center;
}

.Nodelist .datatable .datatable-inner .table tbody tr td {
	font-size: 0.8rem !important;
}

.Nodelist .datatable .datatable-inner .table thead tr th {
	font-size: 0.8rem !important;
}

.node-join-list-box {
	padding: 0rem 1.5rem 1.5rem 1rem;
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.node-join-list-box {
		padding: 0rem!important;
	}
}

.cert-box .datatable .datatable-pagination {
	margin-top: 0rem!important;
}

.cert-box .datatable .datatable-pagination .datatable-select-wrapper .select-wrapper .form-outline {
	margin-top: 0.5rem!important;
}

.cert-box .datatable .datatable-pagination .datatable-select-wrapper .datatable-select-text {
	font-size: 0.8rem;
}

.cert-box .datatable .datatable-pagination .datatable-pagination-nav {
	font-size: 0.8rem;
}

.node-join-list-box .card-body>edit-user .datatable-pagination .datatable-select-wrapper .select-wrapper .form-outline {
	align-items: center !important;
}

.node-join-list-box .card-body>edit-user .datatable-pagination {
	margin: 0.5rem 0rem 1rem 0rem!important;
}

#step-form-horizontal {
	padding: 0rem 2rem 0rem 1rem;
}

#step-form-horizontal-2 {
	padding: 0rem 1rem 0rem 1rem;
	
}

#step-form-horizontal  div  div:nth-of-type(2)  div button:nth-of-type(2):not(.datatable-pagination-button)  {margin-left:90%!important; };


.manifest-title {
	color: #0F4542;
	margin-left: 1rem!important;
	margin-top: 1.5rem !important;
}

.fw-deploy-title {
	color: #0F4542;
	display: flex;
	font-size: 1.2rem;
	font-weight: 500;
	letter-spacing: 0.1em;
	margin-left: 1rem;
	margin-bottom: 1rem;
	margin-top: 1.5rem;
}

.selected-file {
	display: flex;
	flex-direction: column;
}

.select-file-item {
	display: flex;
	justify-content: flex-start;
	margin-left: 1rem;
	margin-top: 1rem;
}

.upload-file-title {
	color: #0F4542;
	margin-left: 1.5rem!important;
	margin-top: 1.5rem !important;
	margin-bottom: 1rem!important;
}

.upload-manifest-file-block {
	display: flex;
	margin-bottom: 3rem;
	align-content: center;
	flex-direction: column;
	align-content: center;
	flex-grow:1;
}

.upload-manifest-file-box{
	margin-left:2rem!important;
	margin-right:1rem!important;
}
.manifest-next-div{
	display: flex;
	justify-content: flex-end;
	margin-left:4.5rem!important;
	margin-top:1rem!important;

}

.upload-manifest-file-sts{
	display: flex;
	flex-direction: column;
}

.manifest-step-title{
	font-weight: 600;
font-size: 0.95rem;
}


.manifest-next{
	color:blue!important;
	text-decoration: underline;
	padding: 0;
border: none;
background: none;
}

.upload-manifest-file-sts-txt{
	font-size:0.95rem!important;
}

.upload-manifest-file {
	display: flex;
	align-items: center;
}

.cert-submit-btn{
	justify-content: flex-end!important;
	padding: 1rem 0rem 0rem 0rem!important;
}

.upload-cert-modal{
	padding-left:0rem!important;
}

.upload-cert-modal .modal-dialog{
	margin:0rem!important;
	}

.nodesearch {
	width: 14rem;
}

.manifest-block {
	display: flex;
	flex-direction: column;
	padding: 0rem 2rem 0rem 2rem;
}

.node-join-button {
	flex-direction: row !important;
	flex-wrap: wrap !important;
	align-content: center !important;
	justify-content: flex-end !important;
}



@media screen and (min-width: 10px) and (max-width: 500px) {
	.node-list-table {
		margin: 0rem 0rem 0rem 0.6rem;
	}
	.node-lsit-table-position {
		margin: 0rem!important;
	}
}

@media screen and (min-width: 10px) and (max-width: 1050px) {
	.node-search-position {
	display:none!important;
	}

}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.node-search-position {
		width: 20rem !important;
		margin: 1rem 0rem 1rem 1.6rem;
	}
	.nodesearch {
		width: 18rem;
	}
}


.node-search-position {
	margin-top: 0.2rem;
	width: 16rem;
	padding-right: 1rem;
	padding-left: 1rem;
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.mail-align {
		margin: 0rem 0rem 0rem 0.5rem;
	}
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.profile-title {
		justify-content: center;
		
	}
}

@media screen and (min-width: 400px) and (max-width: 2500px) {
	.profile-label-display {
		display: none !important;
	}
}

.profile-header-phone {
	display: flex;
	justify-content: flex-end;
}

@media screen and (min-width: 400px) and (max-width: 2500px) {
	.profile-header-phone {
		display: none !important;
	}
}


@media screen and (min-width: 10px) and (max-width: 400px) {
	.profile-img-box {
		margin-bottom: 0rem;
		padding: 0rem 0rem 1rem 0rem;
	}
}

@media screen and (min-width: 400px) and (max-width: 2500px) {
	.profile-img-box {
		margin-bottom: 1.5rem;
		padding: 1.5rem;
	}
}

.profile-email {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

@media screen and (min-width: 400px) and (max-width: 2500px) {
	.profile-email {
		padding: 1.3rem 0rem 0rem 0rem;
	}
}

@media screen and (min-width: 400px) and (max-width: 2500px) {
	.profile-org-align {
		margin-left: 2rem;
	}
}


.manage-node-tab-side {
	display: flex;
	justify-content: center;
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.node-card-tab {
		justify-content: center !important;
	}
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.manage-node-tab-side {
		margin: 1rem 0.2rem 0rem 0.2rem;
	}
	.profile-email{
		margin-right: 0.25rem;
	}
}


.node-search-input {
	border-radius: 0.1rem!important;
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.mdbbox .datatable .table-responsive {
		width: 19rem !important;
	}
	.mdbbox .datatable .datatable-pagination .datatable-select-wrapper>p {
		font-size: 0.8rem!important;
	}
	.mdbbox .datatable .datatable-pagination .datatable-select-wrapper .select-wrapper .form-outline .form-control {
		width: 3rem!important;
	}
}


.selected-file-block {
	margin: 0.5rem 0.5rem 1rem 0.5rem;
	background-color: rgba(244.3749949336052, 244.3749949336052, 244.3749949336052, 1);
	width: 28rem;
	height: 14rem;
	border-radius: 0.5rem;
}

@media (max-width: 600px) {
	.selected-file-block {
		width: 20rem;
	}
}


.match-fw-block .manifest-title .mb-4 {
	margin: 0rem!important;
}

.update-fw-block .manifest-title .mb-4 {
	margin: 0rem!important;
}

.match-fw {
	display: flex;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.match-fw-block {
	margin: 0.5rem 0.5rem 1rem 0.5rem;
	background-color: rgba(244.3749949336052, 244.3749949336052, 244.3749949336052, 1);
	width: 28rem;
	height: 25rem;
	border-radius: 0.5rem;
}

.update-fw-block {
	margin: 0.5rem 0.5rem 1rem 0.5rem;
	background-color: rgba(244.3749949336052, 244.3749949336052, 244.3749949336052, 1);
	width: 50vw;
	height: 22vw;
	border-radius: 0.5rem;
}

@media (max-width: 1700px) {
	.update-fw-block {
		width: 64vw;
		height: 27vw;
	}
}


.not-match-list-title {
	display: flex;
	color: #0F4542;
	margin-left: 1rem!important;
	margin-top: 0.9rem!important;
}

.support-title {
	color: white !important;
	margin-left: 0.8rem !important;
	margin-bottom: 1rem !important;
}

.form-check-input1 {
	position: absolute;
	margin-top: 0.3rem;
	margin-left: -1.25rem;
	padding: 1rem !important;
}

.support-position {
	color: white !important;
	font-size: 0.7rem !important;
	margin-bottom: 1rem !important;
}

.info-description-block {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.info-description-block {
		width: 15rem!important;
	}
	.file-match-block{
		width: 100%;
		height:unset;
	}
}

.info-description {
	width: 15rem!important;
	margin-left: 6rem;
	margin-top: 3rem;
}

.sw-info-block {
	width: 15rem!important;
	margin-top: 3rem;
}



@media screen and (min-width: 0px) and (max-width: 420px) {
	.info-content-end {
		margin: 0rem 0rem 1rem 3rem!important;
	}
	.info-description-block {
		padding: 0rem 0rem 0rem 0rem;
		margin-top:5vw!important;
		margin-left:5vw!important;
	}
}

@media screen and (min-width: 420px) and (max-width: 500px) {
	.info-content-end {
		margin: 0rem 0rem 1rem 3rem!important;
	}
	.info-description-block {
		padding: 0rem 0rem 0rem 0rem;
		margin-top:5vw!important;
		margin-left:10vw!important;
	}
}

@media screen and (min-width: 500px) and (max-width: 1200px) {
	.info-content-end {
		margin: 0rem 0rem 1rem 3rem!important;
	}
	.info-description-block {
		padding: 0rem 0rem 0rem 0rem;
	
	}
}


@media screen and (min-width: 10px) and (max-width: 700px) {
	.info-description-block {
		align-items: flex-start !important;
	}
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.sw-info-block {
		width: 13rem;
		margin: 0.3rem 1rem 0rem 1rem !important;
	}
	.info-description {
		width: 13rem;
		margin: 0.2rem 1rem 0rem 1rem !important;
	}
}


.edit-btn {
	display: inline-block;
	font-weight: 400;
	color: #ffffff;
	text-align: right;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.75rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.edit-btn {
		transition: none;
	}
}

.edit-btn:hover {
	color: #7e7e7e;
	text-decoration: none;
}

.edit-btn:focus,
.btn.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(108, 197, 29, 0.25);
}

.edit-btn.disabled,
.btn:disabled {
	opacity: 0.65;
}

.edit-btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}

@media (min-width: 576px) {
	.edit-btn {
		padding: 0.375rem 0.65rem;
	}
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.edit-btn {
		padding: 0rem 1rem 0rem 6rem !important;
	}
}

.org-edit-btn{
	position: absolute;
	right:8px;
}

.nodecontrol-buttons {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between
}

.control-block {
	display: flex;
	flex-wrap: wrap;
}

.console-watch-block {
	display: flex;
	flex-wrap: nowrap;
}

.console-watch-tab-box {
	flex: 0 0 79.6666666667%;
	max-width: 79.6666666667%;
	padding: 0rem 1rem 1rem 1rem;
}
@media screen and (min-width: 10px) and (max-width: 683px) {
	.console-watch-block {
		display: flex;
		flex-wrap: wrap;
	}
	.console-watch-tab-box {
		flex: 0 0 95.6666666667%!important;
		max-width: 95.6666666667%!important;
		padding: 0rem 0rem 1rem 1rem;
		
	}
	.csw-tab{
		font-size:0.7rem!important;
	}
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.control-block {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}
}

@media screen and (min-width: 500px) and (max-width: 2500px) {
	.nodecontrol-block {
		padding: 0rem!important;
		margin: 0rem !important;
		

	}
}


.info-block {
	display: flex;
	height: 33.95rem;
}

.test1{
	height:50rem;
}


.info-block-support {
	padding: 2rem 1.5rem 2rem 0.3rem!important;
	height:34.4rem;
}



.nodecontrol-block-bg {
	background-image: linear-gradient(0deg, rgba(38.0000015348196, 175.00000476837158, 168.0000051856041, 1) 0%, rgba(68.00000354647636, 194.00000363588333, 159.0000057220459, 1) 100%);
}



@media screen and (min-width: 400px) and (max-width: 600px) {
	.nodecontrol-block-bg {
		width: 18.3rem!important;
	
	}
}



@media screen and (min-width: 10px) and (max-width: 400px) {
	.nodecontrol-block-bg {
		width: 16.3rem!important;
		margin-right: 0.3rem;
	}
}

.support-block-bg {
	background-image: linear-gradient(0deg, rgba(38.0000015348196, 175.00000476837158, 168.0000051856041, 1) 0%, rgba(68.00000354647636, 194.00000363588333, 159.0000057220459, 1) 100%);
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.support-block-bg {
		margin-right: 0.3rem;
	}
}

.btn-connect {
	background-image: linear-gradient(0deg, rgba(37.00000159442425, 175.00000476837158, 167.00000524520874, 1) 0%, rgba(67.00000360608101, 193.00000369548798, 159.0000057220459, 1) 100%);
	width: 6rem;
	height: 2rem;
	color: white;
	border-radius: 0.35rem !important;
	font-size: 13px !important;
	display : flex;
	align-items : center;
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.btn-connect {
		height: 1.8rem !important;
	}
}

.btn-connect:hover {
	background-image: linear-gradient(0deg, rgba(91.00000217556953, 164.00000542402267, 24.000000469386578, 1) 0%, rgba(90.00000223517418, 164.00000542402267, 24.000000469386578, 1) 100%);
}

.btn-connect:focus,
.btn-connect.focus {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.btn-connect.disabled,
.btn-connect:disabled {
	color: #212529;
	background-color: #25AFA8;
	border-color: #25AFA8;
}

.btn-connect:not(:disabled):not(.disabled):active,
.btn-connect:not(:disabled):not(.disabled).active,
.show>.btn-connect.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.btn-connect:not(:disabled):not(.disabled):active:focus,
.btn-connect:not(:disabled):not(.disabled).active:focus,
.show>.btn-connect.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.add-certificate-btn {
	display: flex;
	justify-content: flex-end;
}

.file-container {
	display: flex;
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.btn-disconnect {
		height: 1.8rem !important;
	}
}

.btn-disconnect {
	background-color: #EE6164;
	width: 6rem;
	height: 2rem;
	color: white;
	border-radius: 0.35rem !important;
	font-size: 13px !important;
	display : flex;
	align-items : center;
}

.btn-disconnect:focus,
.btn-disconnect.focus {
	color: #fff;
	background-color: #EE6164;
	border-color: #EE6164;
	box-shadow: 0 0 0 0.2rem rgba(238, 97, 100, 0.5);
}

.btn-disconnect:hover {
	background-color: #EE6164;
}
.btn-disconnect.disabled,
.btn-disconnect:disabled {
	color: #212529;
	background-color: #899594;
	border-color: #899594;
}

.btn-disconnect:not(:disabled):not(.disabled):active,
.btn-disconnect:not(:disabled):not(.disabled).active,
.show>.btn-disconnect.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.btn-disconnect:not(:disabled):not(.disabled):active:focus,
.btn-disconnect:not(:disabled):not(.disabled).active:focus,
.show>.btn-disconnect.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.btn-pause {
		height: 1.8rem !important;
	}
}



@media screen and (min-width: 10px) and (max-width: 400px) {
	.btn-clean-p80 {
		height: 1.8rem !important;
	}
}

.btn-clean-p80 {
	background-color: #EE6164;
	width: 10rem;
	height: 2rem;
	color: white;
	border-radius: 0.55rem !important;
	font-size: 0.65rem !important;
}

.btn-clean-p80:hover {
	background-color: #ff4824;
}

.btn-clean-p80:focus,
.btn-clean-p80.focus {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.btn-clean-p80.disabled,
.btn-clean-p80:disabled {
	color: #212529;
	background-color: #899594;
	border-color: #899594;
}

.btn-clean-p80:not(:disabled):not(.disabled):active,
.btn-clean-p80:not(:disabled):not(.disabled).active,
.show>.btn-clean-p80.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.btn-clean-p80:not(:disabled):not(.disabled):active:focus,
.btn-clean-p80:not(:disabled):not(.disabled).active:focus,
.show>.btn-clean-p80.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.p80-clean-block {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 1rem;
}

.btn-pause {
	background-color: #F29E40;
	width: 6rem;
	height: 2rem;
	color: white;
	border-radius: 0.35rem !important;
	font-size: 13px !important;
}

.btn-pause:hover {
	background-color: #ff7438;
}

.btn-pause:focus,
.btn-pause.focus {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.btn-pause.disabled,
.btn-pause:disabled {
	color: #212529;
	background-color: #899594;
	border-color: #899594;
}

.btn-pause:not(:disabled):not(.disabled):active,
.btn-pause:not(:disabled):not(.disabled).active,
.show>.btn-pause.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.btn-pause:not(:disabled):not(.disabled):active:focus,
.btn-pause:not(:disabled):not(.disabled).active:focus,
.show>.btn-pause.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.btn-start {
	background-color: #33A6FF;
	width: 6rem;
	height: 2rem;
	color: white;
	border-radius: 0.35rem !important;
	font-size: 0.5rem !important;
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.btn-start {
		height: 1.8rem;
	}
}

.btn-start:hover {
	color: white;
	background-color: #77d524;
}

.btn-start:focus,
.btn-start.focus {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.btn-start.disabled,
.btn-start:disabled {
	color: #212529;
	/* background-color: #25AFA8;
	border-color: #25AFA8; */
}

.btn-start:not(:disabled):not(.disabled):active,
.btn-start:not(:disabled):not(.disabled).active,
.show>.btn-start.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.btn-start:not(:disabled):not(.disabled):active:focus,
.btn-start:not(:disabled):not(.disabled).active:focus,
.show>.btn-start.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.btn-record {
		height: 1.8rem !important;
	}
}

.btn-record {
	color: #212529;
	background-color: #25AFA8;
	border-color: #25AFA8;
	width: 6rem;
	height: 2rem;
	color: white;
	border-radius: 0.35rem !important;
	font-size: 13px !important;
}

.btn-record:hover {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
}

.btn-record:focus,
.btn-record.focus {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.btn-record.disabled,
.btn-record:disabled {
	color: #212529;
	background-color: #25AFA8;
	border-color: #25AFA8;
}

.btn-record:not(:disabled):not(.disabled):active,
.btn-record:not(:disabled):not(.disabled).active,
.show>.btn-record.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.btn-record:not(:disabled):not(.disabled):active:focus,
.btn-record:not(:disabled):not(.disabled).active:focus,
.show>.btn-record.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.btn-loadlog {
		height: 1.8rem !important;
	}
}

.btn-loadlog {
	color: #212529;
	background-color: white;
	border-color: #25AFA8;
	width: 6rem;
	height: 2rem;
	color: #25AFA8;
	border-radius: 0.35rem !important;
	font-size: 13px !important;
}

.btn-loadlog:hover {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
}

.btn-loadlog:focus,
.btn-loadlog.focus {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.btn-loadlog.disabled,
.btn-loadlog:disabled {
	color: #212529;
	background-color: #25AFA8;
	border-color: #25AFA8;
}

.btn-loadlog:not(:disabled):not(.disabled):active,
.btn-loadlog:not(:disabled):not(.disabled).active,
.show>.btn-loadlog.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.btn-loadlog:not(:disabled):not(.disabled):active:focus,
.btn-loadlog:not(:disabled):not(.disabled).active:focus,
.show>.btn-loadlog.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.play-button {
	color: #212529;
	background-color: #C4C4C4 !important;
	border-color: #C4C4C4 !important;
}

.play-button:hover {
	color: #fff;
	background-color: #5aa418 !important;
	border-color: #549916 !important;
}

.play-button:focus,
.play-button.focus {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.play-button.disabled,
.play-button:disabled {
	color: #212529;
	background-color: #25AFA8;
	border-color: #25AFA8;
}

.play-button:not(:disabled):not(.disabled):active,
.play-button:not(:disabled):not(.disabled).active,
.show>.play-button.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.play-button:not(:disabled):not(.disabled):active:focus,
.play-button:not(:disabled):not(.disabled).active:focus,
.show>.play-button.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}


.fowrard-button {
	color: #212529;
	background-color: #8C8C8C !important;
	border-color: #8C8C8C !important;
}

.fowrard-button:hover {
	color: #fff;
	background-color: #5aa418 !important;
	border-color: #549916 !important;
}

.fowrard-button:focus,
.fowrard-button.focus {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.fowrard-button.disabled,
.fowrard-button:disabled {
	color: #212529;
	background-color: #25AFA8;
	border-color: #25AFA8;
}

.fowrard-button:not(:disabled):not(.disabled):active,
.fowrard-button:not(:disabled):not(.disabled).active,
.show>.fowrard-button.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.fowrard-button:not(:disabled):not(.disabled):active:focus,
.fowrard-button:not(:disabled):not(.disabled).active:focus,
.show>.fowrard-button.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.btn-speed {
	background-color: #000000 !important;
	border-color: #000000 !important;
}

.monitor-timeout {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}
.p80-btn{
	margin-right:2rem!important;
}

@media screen and (min-width: 10px) and (max-width: 683px) {
	.monitor-timeout {
		flex-direction: column;
		width:80%;
	}
	.p80-btn{
		width:80%;
		margin-bottom:2rem;
		margin-right:0rem!important;
	}

}

.data-filter {
	margin: 0rem 0.5rem 0rem 0.5rem;
}

.p80-message-title {
	color: #0F4542;
	font-size: 0.9rem !important;
	font-weight: 500 !important;
}


.monitor-timeout-box {
	display: flex;
	align-items: center;
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.monitor-timeout-box {
		margin-bottom: 1rem;
		align-content: center;
		flex-direction: column;
	}
}

.p80-radiobox {
	margin-top: 1rem!important;
	display: flex;
	align-items: center;
	margin-right:1.5rem;
}

.nodestatus-box .datatable .datatable-inner .datatable-table .datatable-body>tr>td {
	font-size: 0.8rem !important;
}

.p80-time {
	margin-top: 1.5rem;
	/* padding-right: 1rem; */
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
}

@media screen and (min-width: 10px) and (max-width: 500px) {

	.monitor-sec {
		width:100%;
		display:none;
	}
	.p80-title-label{
		padding-left:0rem!important;
	}
}

@media screen and (min-width: 10px) and (max-width: 970px) {
	.p80-time {
		justify-content: flex-end;
		flex-wrap: wrap!important;
		margin-top: 0rem;
		flex-direction: column;
	}

}



@media screen and (min-width: 688px) and (max-width: 970px) {

	.monitor-timeout-box{
		margin-bottom: 0.3rem;
	}
}

@media screen and (min-width: 0px) and (max-width: 688px) {

	.monitor-timeout-box{
		margin-bottom: 1rem;
	}
}

@media screen and (min-width: 500px) and (max-width: 2500px) {
	#solload-sm {
		display: none;
	}
	#upload-file-sm {
		display: none;
	}
	.select-file-lg {
		margin-right: 0.25rem;
	}
}
.p80-sec{
	display:none;
}
@media screen and (min-width: 10px) and (max-width: 500px) {
	#upload-file-lg {
		display: none;
	}
	#sol-record-btns {
		display: flex;
		margin-bottom: 0.3rem;
	}
	#sol-connect-btns {
		display: flex;
		margin-bottom: 0.3rem;
	}
	#solloadlog {
		display: none;
	}
	#select-file-text {
		font-size: 0.70rem;
	}
	.sol-file-input {
		margin-left: 0.8rem;
	}
	.data-hex {
		margin-left: 1rem;
		display:none;
	}
	.p80-sec{
		display:block;
	}
	.monitor-sec2{
		display:flex;
	}
}

.p80-message {
	display: flex;
}

.hex-text {
	font-size: 0.9rem;
}

.monitor-timeout {
	font-size: 0.8rem;
}

.p80-message-block {
	padding: 0rem 1.5rem 0rem 1.5rem;
	margin-top: 1.5rem;
}

.task-datatable .datatable .datatable-pagination {
	margin-top: 0.8rem!important;
}

.task-datatable .datatable .datatable-pagination .datatable-select-wrapper .select-wrapper .form-outline {
	margin-top: 0rem!important;
}

.task-datatable .form-outline .form-control {
	width: 70vw;
}

.task-datatable .form-outline {
	height: 3rem !important;
}

.task-datatable .form-outline .mb-4 {
	margin-bottom: 0rem!important;
}

.task-datatable .datatable .datatable-inner .datatable-table .datatable-header>tr>th {
	font-size: 0.8rem !important;
	text-align: center;
}

.task-datatable .datatable .datatable-inner .datatable-table .datatable-body>tr>td {
	font-size: 0.8rem !important;
	text-align: center;
	/* padding: 1rem 1.75rem 1rem 1.75rem; */
	/* padding: 0.7rem 0.7rem 0.7rem 0.7rem; */
	padding: 0.7rem 2.5rem;
}

.nodestatus-box .datatable .datatable-inner .datatable-table .datatable-header>tr>th {
	font-size: 0.9rem !important;
}

.node-status-reachable .cannot-reachable{
	width: unset;
	justify-content: center;
}
.node-status-reachable{
	width: 100%;
}

.fwupdate-cancel-text{
	font-weight: 600;
}

.nodestatus-box {
	padding-bottom: 15rem;
	margin-right:2rem;
	margin-left:2rem;
}

.nodestatus-box .form-outline{
	width: 100%;
	flex:1;
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.sol-delete {
		margin-left: 0rem!important;
	}
	.btn-primary {
		border-radius: 0.5rem !important;
		display: flex;
		align-items: center;
	}
	.nodestatus-box .form-outline .form-control {
		width: 22.5rem;
	}
	.nodestatus-box .datatable .datatable-inner .datatable-table .datatable-body>tr>td {
		font-size: 0.7rem !important;
	}
	.nodestatus-box .datatable .datatable-inner .datatable-table .datatable-header>tr>th {
		font-size: 0.6rem !important;
	}
}


.test-speed-btn {
	margin-top: 2.5vw!important;
	margin-bottom: 1.5rem!important;
	margin-left: 0.5rem !important;
	height:2.5rem!important;
	font-size:0.7vw!important;
}

.download-speed {
	color: #25AFA8;
	margin-bottom: 0.5rem!important;
}

.upload-speed {
	color: #EE6164;
	margin-bottom: 2rem!important;
}

.reachable-label {
	color: #fff;
	background-color: #71C330;
	border-color: #71C330;
	height: 1.5rem;
	font-size: 0.7rem !important;
	padding: 0rem 0.5rem 0rem 0.5rem !important;
	margin-top: 0rem!important;
	margin-bottom: 3rem!important;
}

.reachable-label:hover {
	color: #fff;
	background-color: #71C330;
	border-color: #71C330;
}

.reachable-label:focus,
.reachable-label.focus {
	color: #fff;
	background-color: #71C330;
	border-color: #71C330;
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.reachable-label.disabled,
.reachable-label:disabled {
	color: #212529;
	background-color: #25AFA8;
	border-color: #25AFA8;
}

.reachable-label:not(:disabled):not(.disabled):active,
.reachable-label:not(:disabled):not(.disabled).active,
.show>.reachable-label.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.reachable-label:not(:disabled):not(.disabled):active:focus,
.reachable-label:not(:disabled):not(.disabled).active:focus,
.show>.reachable-label.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.btn-primaryoutline {
	color: #25AFA8;
	background-color: #ffffff;
	box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.1);
	height:3rem;
	font-size:13px!important;
}

.btn-primaryoutline:hover {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
}

.btn-primaryoutline:focus,
.btn-primaryoutline.focus {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.btn-primaryoutline.disabled,
.btn-primaryoutline:disabled {
	color: #212529;
	background-color: #25AFA8;
	border-color: #25AFA8;
}

.btn-primaryoutline:not(:disabled):not(.disabled):active,
.btn-primaryoutline:not(:disabled):not(.disabled).active,
.show>.btn-primaryoutline.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.btn-primaryoutline:not(:disabled):not(.disabled):active:focus,
.btn-primaryoutline:not(:disabled):not(.disabled).active:focus,
.show>.btn-primaryoutline.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.can-reachable {
	color: #ffffff!important;
	background-color: #45C29F;
	border-color: #45C29F;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	width: 5rem !important;
	font-size: 0.8rem !important;
}

.cannot-reachable {
	color: #ffffff!important;
	background-color: #EE6164;
	border-color: #EE6164;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	width: 5rem !important;
	font-size: 0.8rem !important;
}

.reachable-ready {
	color: #ffffff!important;
	background-color: #F29E40;
	border-color: #F29E40;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	width: 5rem !important;
	font-size: 0.7rem !important;
}

.reachable-success {
	color: #ffffff!important;
	background-color: #45C29F;
	border-color: #45C29F;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	font-size: 0.7rem !important;
}

.fw-update-title {
	display: flex;
}


.reachable-success-sts {
	color: #ffffff!important;
	background-color: #45C29F;
	border-color: #45C29F;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	font-size: 0.75rem !important;
}

.reachable-error {
	color: #ffffff!important;
	background-color: #EE6164;
	border-color: #EE6164;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	font-size: 0.70rem !important;
}

.reachable-disable{
	color: #ffffff!important;
	background-color: #e0e0e0;
	border-color: #e0e0e0;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	font-size: 0.70rem !important;
}


.fwupdate-ready {
	color: #ffffff!important;
	background-color: #F29E40;
	border-color: #F29E40;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	font-size: 0.7rem !important;
}


.review-btn {
	margin-bottom: 3.0rem;
}

.policy-notmatch {
	color: #EE6164!important;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	width: 8rem !important;
	font-size: 0.7rem !important;
}

.select-file-confirm {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.deploy-btn {
	padding: 0.4rem 2.5rem 0.4rem 2.5rem!important;
	border-radius: 0.5rem !important;
}

@media only screen and (min-width: 10px) and (max-width: 683px) {
	.power-text{
		flex-grow:0;
	}
	#end-key {
		margin-left: 0rem!important;
		
	}

	#delaytime-block {
		margin: 0.5rem 0rem;
		padding-right: 2rem;
		width:30rem!important;
		flex-grow:1;
	}
	#delaytime {
		display: flex;
		margin: 1rem 0rem 0rem 0rem;
		align-items: center;
	}
	#csw-side-block {
		margin-right: 0rem;
		padding-top: 1rem;
	}
	#filter-key {
		display: flex;
		justify-content: space-between;
	}
	#HS_CswMsg {
		height: 16rem!important;
	}
	#HS_CswFk0 {
		height: 2rem;
		max-width: 6rem;
		margin-right: 0.5rem;
	}
	#HS_CswFk1 {
		height: 2rem;
		max-width: 6rem;
		margin-right: 0.5rem;
	}
	#HS_CswFk2 {
		height: 2rem;
		max-width: 6rem;
	}
	#HS_CswEnd {
		height: 2rem;
	}
	#HS_CswTime {
		height: 2rem;
	}
	#select-mode {
		height: 2rem;
	}
	.power-text{
		margin-right:0.5rem;
	}
	
}

#HS_CswFk0:disabled {
	background-color: #bfbfbfca;
}

#HS_CswFk1:disabled {
	background-color: #bfbfbfca;
}

#HS_CswFk2:disabled {
	background-color: #bfbfbfca;
}

#HS_CswEnd:disabled {
	background-color: #bfbfbfca;
}

#HS_CswFk0 {
	height: 2.5rem;
	margin-bottom: 0.7rem;
}

#HS_CswFk1 {
	height: 2.5rem;
	margin-bottom: 0.7rem;
}

#HS_CswFk2 {
	height: 2.5rem;
}

#HS_CswEnd {
	height: 2.5rem;
}

#HS_CswTime {
	height: 2.5rem;
}

@media only screen and (min-width: 500px) and (max-width: 2500px) {
	#csw-side-block {
		font-size: 0.85rem;
	}
}


.sensor-line {
	display: flex;
	align-items: center;
	margin-bottom: 0.7rem;
}

.sensor-line-block {
	padding-left: 2rem;
	margin-top: 1.2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 0.9rem;
	color: #3E3C4F;
	overflow-y: scroll;
	height:400px;
}

.sensor-filter-block {
	display: flex;
	background-color: rgba(219, 219, 219, 0.661);
	width: 37rem;
	height: 5rem;
	align-items: center;
	justify-content: space-evenly;
	max-width:82vw;
}

@media screen and (min-width: 1500px) and (max-width: 2500px) {
.sensor-filter-block {
	max-width:100vw!important;
}

}

@media screen and (min-width: 762px) and (max-width: 1500px) {
	.sensor-filter-block {
		max-width:45vw!important;
	}
	
	}

	@media screen and (min-width: 667px) and (max-width: 762px) {
		.sensor-filter-block {
			max-width:55vw!important;
		}
		
		}

		@media screen and (min-width: 0px) and (max-width: 667px) {
			.sensor-filter-block {
				max-width:82vw!important;
			}	
			}


			@media screen and (min-width: 0px) and (max-width: 2700px) {	
				.monitor-pic{
					max-width:15vw!important;
					max-height:15vw!important;
				}
				}

.fan-check {
	display: flex;
	align-items: center;
	margin-left: 1rem;
}

.event-switch {
	display: flex;
	align-items: center;
	padding: 1rem;
	margin-top: 1rem;
}

.offline {
	color: rgb(135, 135, 135) !important;
}

.sensor-filter-title {
	color: #464A53;
	font-weight: 500;
}

.sensor-filter-text {
	font-size: 0.85rem;
}


.sensor-time {
	display: flex;
	align-items: center;
}

.sensor-limit-block {
	display: flex;
	justify-content: flex-end;
}

.sensor-monitor-side {
	display: flex;
	flex-direction: column;
	max-width: 33.3333333333%;
	flex: 0 0 33.3333333333%;
}

@media screen and (min-width: 0px) and (max-width: 700px) {
	.sensor-monitor-side {		
		max-width: 93.3333333333%;
		flex: 0 0 93.3333333333%;
	}
	
}


.start-monitor {
	width: 14rem;
	height:2.5rem!important;
font-size:0.7vw!important;

}


.start-monitor-block {
	margin-top: 1.5rem;
	display: flex;
	justify-content: center;
}

.target-info-list .datatable .datatable-inner .datatable-table .datatable-header>tr>th {
	font-size: 0.9rem !important;
	text-align: center;
}

.target-info-list .datatable .datatable-inner .datatable-table .datatable-body>tr>td {
	font-size: 0.9rem !important;
	text-align: center;
}

.target-info-list .datatable .datatable-pagination .datatable-select-wrapper .datatable-select-text {
	font-size: 0.9rem !important;
}

.target-info-list .datatable .datatable-pagination .datatable-pagination-nav {
	font-size: 0.9rem !important;
}

.target-info-list .datatable .datatable-pagination .datatable-select-wrapper .form-outline {
	margin-top: 0rem;
}

.target-info-list .datatable .datatable-pagination .datatable-select-wrapper .form-outline .form-control {
	margin-top: 1rem;
}

.event-log-list-block .datatable .datatable-inner .datatable-table .datatable-header>tr>th {
	font-size: 0.9rem !important;
	text-align: center;
}

.event-log-list-block .datatable .datatable-inner .datatable-table .datatable-body>tr>td {
	font-size: 0.9rem !important;
	text-align: center;
}

.event-log-list-block .datatable .datatable-pagination .datatable-select-wrapper .datatable-select-text {
	font-size: 0.9rem !important;
}

.event-log-list-block .datatable .datatable-pagination .datatable-pagination-nav {
	font-size: 0.9rem !important;
}

.event-log-list-block .datatable .datatable-pagination .datatable-select-wrapper .form-outline {
	margin-top: 0rem;
}

.monitor-pic {
	width: 13rem;
}

.event-log-list .form-outline .mb-4 {
	margin: 0rem!important;
}

.event-log-list .form-outline .form-control {
	width: 48rem;
	height: 2.5rem;
}

.event-log-list .form-outline {
	height: 3rem!important;
}

.event-log-list-block {
	margin-bottom: 2rem;
}

.event-btns {
	margin-right: 2rem;
	display: flex;
	justify-content: flex-end;
}

.event-list-title {
	color: #0F4542;
	font-weight: 500;
	margin-top: 1rem;
	margin-bottom:1rem;
}

.event-log-list {
	padding: 1rem 1.5rem 1rem 1.5rem;
}

.monitor-pic-block {
	margin: 1rem 1.5rem 3rem 2.5rem;
}

.event-status-text {
	display: flex;
	justify-content: center;
}

.stop-color {
	background-color: #EE6164 !important;
	border-color: #EE6164 !important;
}

.eventText {
	margin-left: 0.5rem;
	font-weight: 600;
}

#event-block {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

@media screen and (min-width: 0px) and (max-width: 700px) {
	.monitor-pic-block{
		display:none;
	}

	#event-block {
	
		flex-direction: column;
	}

	.sensor-monitor-side{
		min-width:10vw!important;
	}


}



.btn-secondary {
	color: #fff;
	background-color: #C046D3;
	border-color: #C046D3;
}

.btn-secondary:hover {
	color: #fff;
	background-color: #b02fc4;
	border-color: #a72cba;
}

.btn-secondary:focus,
.btn-secondary.focus {
	color: #fff;
	background-color: #b02fc4;
	border-color: #a72cba;
	box-shadow: 0 0 0 0.2rem rgba(201, 98, 218, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
	color: #fff;
	background-color: #C046D3;
	border-color: #C046D3;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #a72cba;
	border-color: #9d2ab0;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(201, 98, 218, 0.5);
}

.btn-success {
	/* color: #212529; */
	color:#fff;
	background-color: #37D1BF;
	border-color: #37D1BF;
}

.btn-success:hover {
	color: #fff;
	background-color: #2ab8a7;
	border-color: #28ad9e;
}

.btn-success:focus,
.btn-success.focus {
	color: #fff;
	background-color: #2ab8a7;
	border-color: #28ad9e;
	box-shadow: 0 0 0 0.2rem rgba(52, 183, 169, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
	color: #212529;
	background-color: #37D1BF;
	border-color: #37D1BF;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
	color: #fff;
	background-color: #28ad9e;
	border-color: #25a394;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(52, 183, 169, 0.5);
}

.btn-info {
	color: #fff;
	background-color: #1EA7C5;
	border-color: #1EA7C5;
}

.btn-info:hover {
	color: #fff;
	background-color: #198ba4;
	border-color: #178199;
}

.btn-info:focus,
.btn-info.focus {
	color: #fff;
	background-color: #198ba4;
	border-color: #178199;
	box-shadow: 0 0 0 0.2rem rgba(64, 180, 206, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
	color: #fff;
	background-color: #1EA7C5;
	border-color: #1EA7C5;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
	color: #fff;
	background-color: #178199;
	border-color: #16788e;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(64, 180, 206, 0.5);
}

.btn-warning {
	color: #212529;
	background-color: #FF9432;
	border-color: #FF9432;
}

.btn-warning:hover {
	color: #212529;
	background-color: #ff800c;
	border-color: #fe7900;
}

.btn-warning:focus,
.btn-warning.focus {
	color: #212529;
	background-color: #ff800c;
	border-color: #fe7900;
	box-shadow: 0 0 0 0.2rem rgba(222, 131, 49, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
	color: #212529;
	background-color: #FF9432;
	border-color: #FF9432;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
	color: #fff;
	background-color: #fe7900;
	border-color: #f17300;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(222, 131, 49, 0.5);
}

.btn-danger {
	color: #fff;
	background-color: #FF285C;
	border-color: #FF285C;
}

.btn-danger:hover {
	color: #fff;
	background-color: #ff023f;
	border-color: #f4003b;
}

.btn-danger:focus,
.btn-danger.focus {
	color: #fff;
	background-color: #ff023f;
	border-color: #f4003b;
	box-shadow: 0 0 0 0.2rem rgba(255, 72, 116, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
	color: #fff;
	background-color: #FF285C;
	border-color: #FF285C;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
	color: #fff;
	background-color: #f4003b;
	border-color: #e70038;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 72, 116, 0.5);
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.btn-deny {
		border-radius: 0.5rem !important;
	}
}

.btn-deny {
	color: #fff;
	background-color: #EE6164;
	border-color: #EE6164;
}

.btn-deny:hover {
	color: #fff;
	background-color: #ff023f;
	border-color: #f4003b;
}

.btn-deny:focus,
.btn-deny.focus {
	color: #fff;
	background-color: #ff023f;
	border-color: #f4003b;
	box-shadow: 0 0 0 0.2rem rgba(255, 72, 116, 0.5);
}

.btn-deny.disabled,
.btn-deny:disabled {
	color: #fff;
	background-color: #EE6164;
	border-color: #EE6164;
}

.btn-deny:not(:disabled):not(.disabled):active,
.btn-deny:not(:disabled):not(.disabled).active,
.show>.btn-deny.dropdown-toggle {
	color: #fff;
	background-color: #EE6164;
	border-color: #EE6164;
}

.btn-deny:not(:disabled):not(.disabled):active:focus,
.btn-deny:not(:disabled):not(.disabled).active:focus,
.show>.btn-deny.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 72, 116, 0.5);
}

.btn-light {
	color: #212529;
	background-color: #E8E8E8;
	border-color: #E8E8E8;
}

.btn-light:hover {
	color: #212529;
	background-color: #d5d5d5;
	border-color: #cfcfcf;
}

.btn-light:focus,
.btn-light.focus {
	color: #212529;
	background-color: #d5d5d5;
	border-color: #cfcfcf;
	box-shadow: 0 0 0 0.2rem rgba(202, 203, 203, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
	color: #212529;
	background-color: #E8E8E8;
	border-color: #E8E8E8;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
	color: #212529;
	background-color: #cfcfcf;
	border-color: #c8c8c8;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(202, 203, 203, 0.5);
}

.btn-sol-position {
	margin-right: 1vw;
}

.btn-load-position {
	margin-left: 1vw;
}

.btn-outline-primary {
	color: #25AFA8;
	border-color: #25AFA8;
}

.btn-outline-primary:hover {
	color: #212529;
	background-color: #25AFA8;
	border-color: #25AFA8;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
	box-shadow: 0 0 0 0.2rem rgba(108, 197, 29, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #25AFA8;
	background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
	color: #212529;
	background-color: #25AFA8;
	border-color: #25AFA8;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(108, 197, 29, 0.5);
}

.btn-outline-secondary {
	color: #C046D3;
	border-color: #C046D3;
}

.btn-outline-secondary:hover {
	color: #fff;
	background-color: #C046D3;
	border-color: #C046D3;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	box-shadow: 0 0 0 0.2rem rgba(192, 70, 211, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
	color: #C046D3;
	background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
	color: #fff;
	background-color: #C046D3;
	border-color: #C046D3;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(192, 70, 211, 0.5);
}

.btn-outline-success {
	color: #37D1BF;
	border-color: #37D1BF;
}

.btn-outline-success:hover {
	color: #FFF;
	background-color: #25AFA8;
	border-color: #25AFA8;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
	box-shadow: 0 0 0 0.2rem rgba(55, 209, 191, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
	color: #37D1BF;
	background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
	color: #212529;
	background-color: #37D1BF;
	border-color: #37D1BF;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(55, 209, 191, 0.5);
}

.btn-outline-info {
	color: #1EA7C5;
	border-color: #1EA7C5;
}

.btn-outline-info:hover {
	color: #fff;
	background-color: #1EA7C5;
	border-color: #1EA7C5;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
	box-shadow: 0 0 0 0.2rem rgba(30, 167, 197, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
	color: #1EA7C5;
	background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
	color: #fff;
	background-color: #1EA7C5;
	border-color: #1EA7C5;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(30, 167, 197, 0.5);
}

.btn-outline-warning {
	color: #FF9432;
	border-color: #FF9432;
}

.btn-outline-warning:hover {
	color: #212529;
	background-color: #FF9432;
	border-color: #FF9432;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 148, 50, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
	color: #FF9432;
	background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #FF9432;
	border-color: #FF9432;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 148, 50, 0.5);
}

.btn-outline-danger {
	color: #FF285C;
	border-color: #FF285C;
}

.btn-outline-danger:hover {
	color: #fff;
	background-color: #FF285C;
	border-color: #FF285C;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 40, 92, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
	color: #FF285C;
	background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
	color: #fff;
	background-color: #FF285C;
	border-color: #FF285C;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 40, 92, 0.5);
}

.btn-outline-light {
	color: #E8E8E8;
	border-color: #E8E8E8;
}

.btn-outline-light:hover {
	color: #212529;
	background-color: #E8E8E8;
	border-color: #E8E8E8;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
	box-shadow: 0 0 0 0.2rem rgba(232, 232, 232, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
	color: #E8E8E8;
	background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
	color: #212529;
	background-color: #E8E8E8;
	border-color: #E8E8E8;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(232, 232, 232, 0.5);
}


@media screen and (min-width: 0px) and (max-width: 400px) {
	.sensor-filter-text {
		font-size: 0.6rem;
	}
	.sensor-filter-title{
		font-size: 0.7rem;
	}
	.sensor-line{
		font-size: 0.7rem!important;
	}
}


.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: 0.75rem 1rem;
	margin-top: 0.4rem;
	margin-left: 0.2rem;
	margin-bottom: 0rem;
	list-style: none;
	border-radius: 0.75rem;
}

.breadcrumb-prev>a {
	font-size: 0.85rem;
	color: #25AFA8 !important;
}

.breadcrumb-current {
	font-size: 0.9rem;
	color: #0F4542 !important;
}

.breadcrumb-item {
	display: flex;
}

.breadcrumb-item+.breadcrumb-item {
	padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
	display: inline-block;
	padding-right: 0.5rem;
	color: #6c757d;
	content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
	text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
	text-decoration: none;
}

.breadcrumb-item.active {
	color: #6c757d;
}

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0.75rem;
}

.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #25AFA8;
	background-color: #fff;
	border: 1px solid #dee2e6;
}

.page-link:hover {
	z-index: 2;
	color: #478213;
	text-decoration: none;
	background-color: #e9ecef;
	border-color: #dee2e6;
}

.page-link:focus {
	z-index: 3;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(108, 197, 29, 0.25);
}

.page-item:first-child .page-link {
	margin-left: 0;
	border-top-left-radius: 0.75rem;
	border-bottom-left-radius: 0.75rem;
}

.page-item:last-child .page-link {
	border-top-right-radius: 0.75rem;
	border-bottom-right-radius: 0.75rem;
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #25AFA8;
	border-color: #25AFA8;
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	cursor: auto;
	background-color: #fff;
	border-color: #dee2e6;
}

.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 1.09375rem;
	line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 0.765625rem;
	line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}


.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	line-height: 0;
	font-size: 0.65625rem;
	background-color: #e9ecef;
	border-radius: 0.75rem;
}

.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #25AFA8;
	transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
	.progress-bar {
		transition: none;
	}
}

.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
}

.progress-bar-animated {
	animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
	.progress-bar-animated {
		animation: none;
	}
}




.table-responsive {
	margin: 0rem !important;
}


.normal-text {
	font-size: 0.8rem;
	margin-left: 0.1rem;
}

.normal-text-title{
	font-size: 1rem;
}

.player-box {
	background: #000000;
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;

}

.player-buttons {
	display: flex;
	flex-wrap: nowrap;
}


@media only screen and (min-width: 10px) and (max-width: 400px) {
	.deny-user-box {
		display: none;
	}
}

@media only screen and (min-width: 400px) and (max-width: 2500px) {
	.open-deny-user-box {
		display: none;
	}
}

@media only screen and (min-width: 10px) and (max-width: 400px) {
	.cert-box {
		display: none;
	}
	.open-cert-box {
		width: 15rem;
	}
}

@media only screen and (min-width: 400px) and (max-width: 2500px) {
	.open-cert-box {
		display: none;
	}
}

.btn-node-edit {
	background-color: transparent;
	border-color: transparent !important;
}

.btn-node-edit:hover,
.btn-node-edit:focus {
	background-color: white !important;
	border-color: white !important;
}

.seletcted-target-btn {
	padding: 0rem !important;
}


.profile-box {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

@media only screen and (max-width:780px) {
	.profile-box {
		justify-content: center;
	}
}


@media only screen and (max-width:400px) {
	.profile-head {
		height:19rem;
	}
}

.photo-content {
	position: relative;
}

.profile .profile-photo {
	max-width: 100px;
	position: relative;
	z-index: 1;
	margin-top: -40px;
	margin-right: 10px;
}

@media only screen and (max-width: 575px) {
	.profile .profile-photo {
		width: 80px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
	}
}

[direction="rtl"] .profile .photo-content .profile-photo {
	left: auto;
	right: 50px;
}

[direction="rtl"] .profile .profile-photo {
	left: auto;
	right: 30px;
}

@media only screen and (max-width: 1199px) {
	[direction="rtl"] .profile .profile-photo {
		right: 15px;
	}
}

@media only screen and (max-width: 575px) {
	[direction="rtl"] .profile .profile-photo {
		width: 80px;
		right: calc(50% - 40px);
		top: -100px;
	}
}

.profile-info {
	padding: 15px 20px;
}

@media only screen and (max-width: 575px) {
	.profile-info {
		padding: 0 0 20px;
		text-align: center;
	}
}

.profile-info h4 {
	color: #464a53 !important;
}

.profile-info h4.text-primary {
	color: #25AFA8 !important;
}

.profile-info p {
	color: #828690;
}

.profile-info .prf-col {
	min-width: 250px;
	padding: 10px 50px 0;
}

.profile-interest .row {
	margin: 0 -.70px;
}

.profile-interest .row .int-col {
	padding: 0 .70px;
}

.profile-interest .row .int-col .interest-cat {
	margin-bottom: 1.40px;
	position: relative;
	display: block;
}

.profile-interest .row .int-col .interest-cat:after {
	background: #000;
	bottom: 0;
	content: "";
	left: 0;
	opacity: 0.5;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 0;
}

.profile-interest .row .int-col .interest-cat p {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 5px;
	left: 0;
	margin: 0;
	z-index: 1;
	color: white;
	font-size: 1.20px;
}

.profile-tab .nav-item .nav-link {
	font-size: 16px;
	margin-right: 30px;
	transition: all 0.5s ease-in-out;
	border: none;
	border-bottom: .20px solid transparent;
	color: #828690;
}

.profile-tab .nav-item .nav-link:hover,
.profile-tab .nav-item .nav-link.active {
	border: 0;
	background: transparent;
	background: transparent;
	border-bottom: 0.2px solid #25AFA8;
	color: #25AFA8;
}

@media only screen and (max-width: 575px) {
	.profile-tab .nav-item .nav-link {
		margin-right: 0;
	}
}

.profile-info {
	display: flex;
}

@media only screen and (max-width: 575px) {
	.profile-info {
		display: block;
	}
}

.profile-info .profile-details {
	display: flex;
	width: 100%;
}

@media only screen and (max-width: 575px) {
	.profile-info .profile-details {
		display: block;
	}
	.profile-info .profile-details .dropdown {
		position: absolute;
		top: 30px;
		right: 30px;
	}
}

.post-input {
	margin-bottom: 30px;
}

.post-input .form-control {
	height: 75px;
	font-weight: 400;
	margin: 15px 0;
}

.profile-uoloaded-post img {
	margin-bottom: 20px;
}

.profile-uoloaded-post a h4 {
	margin-bottom: 10px;
	color: #464a53;
}

.post-details .post-meta li {
	display: inline-block;
	margin-right: 12px;
}

.post-details blockquote {
	padding: 16px 20px 16px 80px;
	font-size: 18px;
	font-style: italic;
	margin: 30px 0;
	color: #555;
	border-left: none;
	line-height: 30px;
	position: relative;
	background: #f6f6f6;
	clear: both;
	border-left: 4px solid #25AFA8;
}

.post-details blockquote:before {
	content: '\f10d';
	font-family: FontAwesome;
	color: #25AFA8;
	font-size: 35px;
	position: absolute;
	left: 20px;
	top: 20px;
	font-style: normal;
}


.media-avatar {
	padding: 25px 0;
	border-bottom: 1px solid #f0f1f5;
}

.media-avatar:last-child {
	border-bottom: 0px;
}

.media-avatar p {
	margin-bottom: 0;
}

.media-avatar .avatar-status {
	position: relative;
}

.media-avatar .avatar-status i {
	position: absolute;
	right: 0;
	bottom: 0;
}

.ct-golden-section:before {
	float: none;
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.power-title .form-control {
		height: 1.70rem;
		font-size: 0.6rem;
	}
}


.custom-file-label {
	padding: 0.5rem 0.75rem;
	border-radius: 0;
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.custom-file-label-display {
		display: none !important;
	}
}

@media only screen and (min-width: 600px) and (max-width: 3000px) {
	.custom-file-label-display {
		flex-shrink: 1;
		
	}
}
@media only screen and (min-width: 1600px) and (max-width: 3000px) {
	.custom-file-label-display {
		font-size:1rem!important;
		
	}
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
	.custom-file-label-display {
		font-size:0.7rem!important;
		
	}
}

@media only screen and (min-width: 600px) and (max-width: 1400px) {
	.custom-file-label-display {
		font-size:0.5rem!important;
	}
}

@media only screen and (min-width: 10px) and (max-width: 3000px) {
	.custom-file-label-display {
		white-space: nowrap!important;
	}
}



@media only screen and (min-width: 600px) and (max-width: 3000px) {
	.custom-file-label-hide {
		display: none !important;
	}
}

@media only screen and (min-width: 10px) and (max-width: 600px) {
	.custom-file-label-hide {
		flex-shrink: 1;
	}
}

@media only screen and (min-width: 10px) and (max-width: 600px) {
	.custom-file-shrink {
		flex-shrink: 0;
	}
}

@media only screen and (min-width: 10px) and (max-width: 600px) {
	.file-flex {
		display: flex;
		flex-wrap: wrap;
	}
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.power-text {
		margin-top: 0rem!important;
	}
}

@media only screen and (min-width: 500px) and (max-width: 2500px) {
	.power-buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1vw;
		margin-left:1vw;
		margin-top: 3vw;;
	}
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.power-buttons {
		margin-bottom: 1rem;
		display: flex;
		justify-content: center;
		flex-direction: column
	}
}

.power-buttons {
	display: flex;
	align-items: center;
	justify-content: center;

}

.drop-file-text {
	color: #25AFA8;
}

.drop-file-box {
	display: flex;
	border-color: #25AFA8;
	border-width: 1.5px;
	border-style: solid;
	padding: 5px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 0.5rem;
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.node-header-size {
		margin-left: 0rem!important;
	}
}


.node-file-title {
	display: flex;
}

.custom_file_input .custom-file-label::after {
	height: 100%;
}

.form-control:disabled,
.form-control[readonly] {
	opacity: 1;
}

.custom-file-label {
	background: #fff;
	border-color: #f0f1f5;
}




.wizard>.content>.body input.error {
	position: absolute;
	top: 100% !important;
}

.wizard {
	position: relative;
}

.wizard>.steps {
	width: 100%;
	margin-bottom: 20px;
}

@media (min-width: 1200px) {
	.wizard>.steps {
		width: 100%;
	}
}

.wizard>.steps li.current a {
	background-color: #25AFA8;
	color: #fff;
}

.wizard>.steps li.disabled a,
.wizard>.steps li.done a {
	background-color: #fff;
	color: #333;
}

.wizard>.steps li.disabled a:hover,
.wizard>.steps li.done a:hover {
	margin: 0;
	background-color: #fff;
	border-radius: 0;
	color: #333;
}

.wizard>.steps li a {
	text-align: center;
	margin: 0;
	border-radius: 0;
	background-color: #fff;
}

.wizard>.steps li a:hover {
	margin: 0;
	background-color: #fff;
	border-radius: 0;
	color: #333;
}

@media only screen and (max-width: 767px) {
	.wizard>.steps li a {
		padding: 1em 4px;
	}
}

.wizard>.steps li a .number {
	display: none;
}

.wizard .content {
	min-height: 32rem;
	margin: 0;
}

@media (max-width: 991.98px) {
	.wizard .content {
		min-height: 41rem;
	}
}

.wizard .content>.body {
	background-color: transparent;
	width: 100%;
	height: 100%;
	padding: 0;
}

@media only screen and (max-width: 575px) {
	.wizard .content>.body {
		padding: 0;
	}
}

.wizard .content>.body .form-control {
	border: 1px solid #f0f1f5;
}

.wizard .content>.body input.error {
	position: relative;
}

.wizard .content>.body label.error {
	margin-top: 5px;
	margin-left: 0;
	margin-bottom: 0;
}

.wizard .emial-setup label.mailclinet {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 10rem;
	height: 10rem;
	border-radius: 50%;
	cursor: pointer;
	background-color: #EEF5F9;
	text-align: center;
	margin: auto;
}

@media only screen and (max-width: 575px) {
	.wizard .emial-setup label.mailclinet {
		width: 7rem;
		height: 7rem;
	}
}

.wizard .emial-setup label.mailclinet .mail-icon {
	font-size: 3rem;
	display: inline-block;
	line-height: 1;
	margin-top: -1rem;
}

@media only screen and (max-width: 575px) {
	.wizard .emial-setup label.mailclinet .mail-icon {
		font-size: 2rem;
	}
}

.wizard .emial-setup label.mailclinet .mail-text {
	font-size: 1rem;
	text-align: center;
	margin-top: .5rem;
}

@media only screen and (max-width: 575px) {
	.wizard .emial-setup label.mailclinet .mail-text {
		font-size: 16px;
		line-height: 20px;
	}
}

.wizard .emial-setup label.mailclinet input[type="radio"] {
	display: none;
}

.wizard .skip-email {
	margin-top: 6rem;
}

.wizard .skip-email p {
	margin-bottom: 1rem;
}

.wizard .skip-email a {
	color: #25AFA8;
	text-decoration: underline;
}

.wizard>.actions {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 2rem;
}

.wizard>.actions li:not(.disabled) a {
	background-color: #25AFA8;
	color: #fff;
}

.wizard .actions ul li.disabled a {
	color: #333333 !important;
	background: #F7FAFC !important;
	border: 1px solid #EEEEEE;
}

.wizard .actions ul li a {
	border-radius: 4px;
	font-size: 14px;
	font-weight: 600;
	padding: 0.55em 2em;
}

.wizard>.actions a,
.wizard>.actions a:hover,
.wizard>.actions a:active {
	padding: 0.55em 2em;
}

.wizard>.content {
	background: transparent;
}

.wizard,
.tabcontrol {
	overflow: visible;
}

.step-form-horizontal .wizard {
	padding-top: 3.5rem;
}

.step-form-horizontal .wizard .steps li {
	position: relative;
}

.step-form-horizontal .wizard .steps li.done a {
	text-align: center;
	background-color: #fff;
	color: #6e6e6e;
}

.step-form-horizontal .wizard .steps li.done a:hover {
	text-align: center;
	background-color: #fff;
	color: #6e6e6e;
}

.step-form-horizontal .wizard .steps li.done a .number {
	color: #fff;
	background: #25AFA8;
	border-color: transparent;
}

.step-form-horizontal .wizard .steps li.current a,
.step-form-horizontal .wizard .steps li.disabled a {
	background-color: transparent;
	text-align: center;
	color: #fff;
}

.step-form-horizontal .wizard .steps li.current a:hover,
.step-form-horizontal .wizard .steps li.current a:active,
.step-form-horizontal .wizard .steps li.disabled a:hover,
.step-form-horizontal .wizard .steps li.disabled a:active {
	background-color: transparent;
	color: #fff;
}

.step-form-horizontal .wizard .steps li.current a .number {
	color: #25AFA8;
	background: #fff;
	border: 2px solid #25AFA8;
}

.step-form-horizontal .wizard .steps li.disabled a .number {
	color: #25AFA8;
}

.step-form-horizontal .wizard .steps li a {
	position: relative;
	z-index: 1;
}

@media (max-width: 767.98px) {
	.step-form-horizontal .wizard .steps li a {
		padding-left: 0;
		padding-right: 0;
		margin: 0;
	}
}

@media (max-width: 575.98px) {
	.step-form-horizontal .wizard .steps li a {
		font-size: 0.75rem;
		padding-top: 0.9375rem;
	}
}

.step-form-horizontal .wizard .steps li a .number {
	position: absolute;
	top: -55px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #fff;
	border: 3px solid #d7dae3;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3rem;
	border-radius: 50px;
	width: 3rem;
}

@media only screen and (max-width: 575px) {
	.step-form-horizontal .wizard .steps li a .number {
		top: -45px;
	}
}

.step-form-horizontal .wizard .steps li:not(:last-child) {
	z-index: 1;
}

.step-form-horizontal .wizard .steps li:not(:last-child)::after {
	position: absolute;
	width: 100%;
	height: 0.375rem;
	content: "";
	left: 50%;
	top: -2.1rem;
	background-color: #25AFA8;
	z-index: -1;
}

@media (max-width: 575.98px) {
	.step-form-horizontal .wizard .steps li:not(:last-child)::after {
		height: 0.375rem;
		top: -1.3125rem;
	}
}

.step-form-horizontal .wizard .steps li:not(:last-child).current::after {
	background-color: #F3F3F3;
}

.step-form-horizontal .wizard .steps li:not(:last-child).current~li::after {
	background-color: #F3F3F3;
}

.step-form-horizontal .wizard .actions {
	padding: 0;
}

@media only screen and (max-width: 575px) {
	.step-form-horizontal .wizard .actions {
		padding: 0;
	}
}


.step-form-horizontal ol {
	position: relative;
	display: flex !important;
	justify-content: space-around;
}

.step-form-horizontal ol li {
	position: relative;
	border: 0;
	margin-bottom: 0;
	background: transparent !important;
	z-index: 2;
	padding: 0 !important;
	width: 100%;
}

.step-form-horizontal ol li:after {
	content: none !important;
}

.step-form-horizontal ol li:before {
	content: "";
	height: 3px;
	width: 0;
	left: 0;
	top: 18px;
	position: absolute;
	background: #25AFA8;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
	z-index: 1;
}

.step-form-horizontal ol li.go767851704 {
	text-align: center;
	background-color: #fff;
	color: #6e6e6e;
}

.step-form-horizontal ol li.go767851704:before {
	width: 100%;
}

.step-form-horizontal ol li.go767851704:hover {
	text-align: center;
	background-color: #fff;
	color: #6e6e6e;
}

.step-form-horizontal ol li.go767851704 span {
	color: #fff;
	background: #25AFA8;
	border-color: transparent;
}

.step-form-horizontal ol li.active,
.step-form-horizontal ol li.go2478912419,
.step-form-horizontal ol li.disabled {
	background-color: transparent;
	text-align: center;
	color: #fff;
	border: 0;
}

.step-form-horizontal ol li.active:hover,
.step-form-horizontal ol li.active:active,
.step-form-horizontal ol li.go2478912419:hover,
.step-form-horizontal ol li.go2478912419:active,
.step-form-horizontal ol li.disabled:hover,
.step-form-horizontal ol li.disabled:active {
	background-color: transparent;
	color: #fff;
}

.step-form-horizontal ol li.active span,
.step-form-horizontal ol li.go2478912419 span {
	color: #25AFA8;
	background: #fff;
	border: 2px solid #25AFA8;
}

.step-form-horizontal ol li.disabled span {
	color: #25AFA8;
}

@media (max-width: 767.98px) {
	.step-form-horizontal ol li {
		padding-left: 0;
		padding-right: 0;
		margin: 0;
	}
}

@media (max-width: 575.98px) {
	.step-form-horizontal ol li {
		font-size: 0.75rem;
		padding-top: 0.9375rem;
	}
}

.step-form-horizontal ol li span {
	position: relative;
	background-color: #fff;
	border: 3px solid #d7dae3;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2.2rem;
	border-radius: 50px;
	width: 2.2rem;
	padding: 0 !important;
	box-shadow: 0 0 0 3px #fff;
	z-index: 2;
	position: relative;
}

.step-form-horizontal ol li:last-child {
	width: auto;
}

.step-form-horizontal ol::after {
	position: absolute;
	width: 100%;
	height: 4px;
	content: "";
	left: 0;
	top: 18px;
	background-color: #eee;
	z-index: 1;
}

.step-form-horizontal .actions {
	padding: 0;
}

@media only screen and (max-width: 575px) {
	.step-form-horizontal .actions {
		padding: 0;
	}
}


.MuiInput-underline:after,
.MuiInput-underline:before {
	content: none !important;
}

.calendar__head {
	background: #25AFA8;
}

body .picker-input__text,
body .MuiInputBase-input {
	box-sizing: border-box;
	border: 1px solid #eee;
	height: 48px;
	padding: 10px 16px;
}

.calendar__day--start,
.calendar__day--end,
.calendar__day--selected {
	background: #25AFA8;
}

.calendar__day--range {
	background: #c8f1a3;
}

.note-icon-caret,
.note-popover {
	display: none;
}

.note-editor.note-frame .panel-heading {
	padding: .6rem 1rem 1rem;
	z-index: 1;
}

.note-editor.note-frame {
	border-color: #f0f1f5;
}

.note-editor.note-frame .note-editing-area .note-editable {
	background-color: #fff;
}

.note-editor.note-frame .note-statusbar {
	background-color: #fff;
}

.ql-container {
	height: 25rem;
}


#loginForm {
	cursor: auto;
}

.blockMsg {
	border: 0px !important;
	width: 20% !important;
}

.blockMsg h1 {
	font-size: 16px;
	padding: 8px 0;
	margin-bottom: 0;
}


.xterm-screen{
    width:59vw!important;
    
    height:415px !important;
  }

  .xterm-text-layer{
    width:59vw!important;
    height:415px !important;
  }
  .xterm-selection-layer{
    width:59vw!important; 
    height:415px !important;
  }

  .xterm-link-layer{
    width:59vw!important;  
    height:415px !important;
  }

  .xterm-cursor-layer{
    width:59vw!important;  
    height:415px !important;
  }

  .flexible-modal-resizer{
      display:none;
  }

  .info-btn{
      height:2.5rem!important;
      font-size:0.7vw!important;
  }

  @media screen and (min-width: 800px) and (max-width: 1217px) {

    .xterm-screen{
        width:57.8vw!important;  
    }

    .xterm-text-layer{
        width:57.8vw!important;  
    
    }
    .xterm-selection-layer{
        width:57.8vw!important;  

    }

    .xterm-link-layer{
        width:57.8vw!important;  
    
    }

    .xterm-cursor-layer{
        width:57.8vw!important;  
    
    }
    .xterm-viewport{
        width:57.8vw!important; 
    }
    .player-box{
        width:57.8vw!important; 
    }
    .sol-btns-p{
        margin-left:0vw!important;
    }
}    



  @media screen and (min-width: 1217px) and (max-width: 1324px) {

    .xterm-screen{
        width:58.1vw!important;  
    }

    .xterm-text-layer{
        width:58.1vw!important;  
    
    }
    .xterm-selection-layer{
        width:58.1vw!important;  

    }

    .xterm-link-layer{
        width:58.1vw!important;  
    
    }

    .xterm-cursor-layer{
        width:58.1vw!important;  
    
    }}    
    
    @media screen and (min-width: 1324px) and (max-width: 1440px) {

        .xterm-screen{
            width:58.2vw!important;  
        }

        .xterm-text-layer{
            width:58.2vw!important;  
        
        }
        .xterm-selection-layer{
            width:58.2vw!important;  
    
        }

        .xterm-link-layer{
            width:58.2vw!important;  
        
        }

        .xterm-cursor-layer{
            width:58.2vw!important;  
        
        }}       


      
        @media screen and (min-width: 1440px) and (max-width: 1490px) {

            .xterm-screen{
                width:58.2vw!important;  
            }
  
            .xterm-text-layer{
                width:58.2vw!important;  
            
            }
            .xterm-selection-layer{
                width:58.2vw!important;  
        
            }
  
            .xterm-link-layer{
                width:58.2vw!important;  
            
            }
  
            .xterm-cursor-layer{
                width:58.2vw!important;  
            
            }}   

            @media screen and (min-width: 1490px) and (max-width: 1640px) {

                .xterm-screen{
                    width:58.6vw!important;  
                }
      
                .xterm-text-layer{
                    width:58.6vw!important;  
                
                }
                .xterm-selection-layer{
                    width:58.6vw!important;  
            
                }
      
                .xterm-link-layer{
                    width:58.6vw!important;  
                
                }
      
                .xterm-cursor-layer{
                    width:58.6vw!important;  
                
                }} 

  @media screen and (min-width: 1640px) and (max-width: 1700px) {

    .xterm-screen{
        width:44vw!important;

    }

    .xterm-text-layer{
      width:44vw!important;
    
    }
    .xterm-selection-layer{
      width:44vw!important; 

    }

    .xterm-link-layer{
      width:44vw!important;  
    
    }

    .xterm-cursor-layer{
      width:44vw!important;  
    
    }


}

@media screen and (min-width: 1700px) and (max-width: 1790px) {

    .xterm-screen{
        width:45.6vw!important;

    }

    .xterm-text-layer{
        width:45.6vw!important;
    
    }
    .xterm-selection-layer{
        width:45.6vw!important;

    }

    .xterm-link-layer{
        width:45.6vw!important;
    }

    .xterm-cursor-layer{
        width:45.6vw!important;
    
    }


}

@media screen and (min-width: 1790px) and (max-width: 1880px) {

    .xterm-screen{
        width:46vw!important;

    }

    .xterm-text-layer{
        width:46vw!important;
    
    }
    .xterm-selection-layer{
        width:46vw!important;

    }

    .xterm-link-layer{
        width:46vw!important;
    }

    .xterm-cursor-layer{
        width:46vw!important;
    
    }


}



@media screen and (min-width: 1880px) and (max-width: 2600px) {

    .xterm-screen{
        width:40vw!important;

    }

    .xterm-text-layer{
        width:40vw!important;
    
    }
    .xterm-selection-layer{
        width:40vw!important;

    }

    .xterm-link-layer{
        width:40vw!important;
    }

    .xterm-cursor-layer{
        width:40vw!important;
    
    }


}



  @media screen and (min-width: 0px) and (max-width: 1300px) {

    .info-btn{
        height:2.5rem!important;
        font-size: 0.7rem!important;
    }
    .test-speed-btn{
    
        font-size: 0.7rem!important;
    }
    .start-monitor{
        font-size: 0.7rem!important;
    }


}

@media screen and (min-width: 10px) and (max-width: 550px) {
    
    .power-title {
     max-width: 83vw!important;
    
    }
    #HS_CswBtn{
        margin:0rem 2rem 0rem 0rem;
    }
}

@media screen and (min-width: 550px) and (max-width: 1200px) {
    
    .power-title {
     max-width: 15vw!important;
     margin: 0rem 0.8rem 0rem 2rem!important;
    
    
    }
}

@media screen and (min-width: 1200px) and (max-width: 1700px) {
    
    .power-title {
     max-width: 11.5vw!important;
     
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    
    .power-title {
    
     margin: 0rem 0.8rem 0rem 2rem!important;
    }
}

@media screen and (min-width: 665px) and (max-width: 770px) {
    
    #HS_CswMsg{
        width:90%!important
    }
    .csw-delete{
        width:90%!important
    }

}




@media screen and (min-width: 0px) and (max-width: 500px) {
    
    #HS_CswMsg{
        height:15rem!important
    }
    .log-list .datatable .scrollbar-container .table {
        width:80%!important;
        }
}

.log-list .datatable .datatable-pagination .datatable-pagination-buttons{
    display:flex;
    flex-wrap: nowrap;
}



@media screen and (min-width: 1894px) and (max-width: 2700px) {
    
.console-watch-btns {

margin: 1.5rem 0rem 4.4rem 0rem!important;
}
}

@media screen and (min-width: 1270px) and (max-width: 1894px) {
    
    .console-watch-btns {
        
    margin: 1.5rem 0rem 2.15rem 0rem!important;
}
    }

@media screen and (min-width: 1257px) and (max-width: 1270px) {
    
    .console-watch-btns {

    margin: 1.5rem 0rem 3.15rem 0rem!important;
}
    }

    @media screen and (min-width: 1216px) and (max-width: 1257px) {
    
        .console-watch-btns {
  
    margin: 1.5rem 0rem 2.15rem 0rem!important;
}
        }

@media screen and (min-width: 1145px) and (max-width: 1216px) {
    
    .console-watch-btns {

margin: 1.5rem 0rem 2.1rem 0rem!important;
}
    }

    @media screen and (min-width: 1198px) and (max-width: 1210px) {
    
        .console-watch-btns {
  
    margin: 1.5rem 0rem 3.15rem 0rem!important;
}
        }
        @media screen and (min-width: 1000px) and (max-width: 1153px) {
    
            .console-watch-btns {
      
        margin: 1.5rem 0rem 2rem 0rem!important;
    }
            }

            @media screen and (min-width: 967px) and (max-width: 1000px) {
    
                .console-watch-btns {
          
            margin: 1.5rem 0rem 2rem 0rem!important;
        }
                }

                @media screen and (min-width: 895px) and (max-width: 965px) {
    
                    .console-watch-btns {
              
                margin: 1.5rem 0rem 2.1rem 0rem!important;
            }
                    }
        
    
.searchNodeBtn{
    margin:auto;
    max-width: 13vw!important;
    font-size: 0.8rem;
    height:2.5rem;
}

@media screen and (min-width: 1050px) and (max-width: 1104px) {
    
    .searchNodeBtn{

        
        max-width: 9vw!important;
    
    }

}


.tab-content-end-csw {
    flex-wrap: wrap !important;
	justify-content: flex-end !important;
    align-items: center!important;
}


@media only screen and (max-width: 575px) {
    .tab-content-end-csw {
        flex-wrap: wrap !important;
        margin: 1.5rem 0rem 0rem 0rem !important;
    
    }
}

.csw-pwr-btn{
    height:2.2rem!important;
    margin-left:1rem;

    
}

.csw-pwr-btn .btn{
    font-size:0.6rem!important;

    
}


@media screen and (min-width: 695px) and (max-width: 1199px) {
    
    .node-list-tab{
        flex-wrap: nowrap !important;
        margin: 0rem 0rem 0rem 0rem!important;
    }
}

.userjoin-pending{
	justify-content: flex-end;
}

.userjoin-pending .btn{
	padding: 0.7rem 3rem;
}

.modal-button-edit-ngroup{
	padding: 2rem 2rem 1rem 0rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.add-nodeto-group .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.add-new-nodegroup .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.cert-box .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.match-fw-block  .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.assign-contorl-userGroup  .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.user-list-box  .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.modify-userGroup .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.normal-group-array  .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.evt-box .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.task-watch-rept .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}



.change-manager-btn{
	max-width: 2.5vw!important;
	height:0rem!important;
	padding:0.7rem!important;
}

.edit-manager-btn{	
	max-width: 2.5vw!important;
	height:0rem!important;
	padding:0.7rem!important;
	margin:auto;
}


.input-submit-btn{
	width: 8rem;
	height: 40px;
	margin: 0rem 0rem 0rem 21rem;
	padding: 0;
	display: inline-block;
	line-height: 40px;
	text-align: center;
	font-size:0.8rem;
}

.newuser-btn{
	margin: unset!important;
}

.userlist-ctr-btn .btn:nth-child(1){
	margin-right:1.5rem;
}

@media only screen and (max-width: 575px) {
	.newuser-btn{
       margin: 1.5rem 0rem 0rem 0rem!important;
    }
	.userlist-ctr-btn{
		font-size: 11px!important;
	}
	.userlist-ctr-btn .btn:nth-child(1){
		margin-right:0.5rem;
	}
}




.datatable-select-wrapper{
	display:none!important;
}

.node-grouplist .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.nmc-btn-p{
	justify-content: flex-start!important;
	width:3rem;
	height:2rem;
}

.user-group-nodegroup .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.user-list-box .card-body .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.org-card-title{
	margin:0 auto!important;
}

.select-file-del-btn{
	display:flex;
	align-content: center;
	justify-content: center;
	align-items: center;

}

.nodelist-group .node-list-table .datatable .datatable-inner .datatable-table .datatable-body>tr>td{
	font-size: 0.9rem!important;
}

.edit-nodeGroup-dbtn{
	width:3rem!important;
	height:2rem!important;
}

.edit-node-group  .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.edit-user-table .datatable .datatable-pagination .datatable-pagination-buttons{
	display:flex;
}

.edit-user-table .form-outline .form-control{
	width:60rem!important;
}

.edit-user-table .datatable .datatable-inner .table tbody tr td{
	text-align: center!important;
	vertical-align: middle!important;
}

.edit-user-table .datatable .datatable-inner .table thead th{
	text-align: center!important;
	vertical-align: middle!important;
}

.sol-status{
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
}

.node-connect-status-text{
	white-space:nowrap;
	margin-right:1rem;
	margin-left:2rem;
}

.terminal-content-button{
	white-space:nowrap;
}

.terminal-content-button .btn{
	height:2.15rem!important;
	font-size:0.5rem!important;
	border-radius: 0.35rem;
}

.terminal-content-button .dropdown-toggle{
	height:2.15rem!important;
	font-size:13px!important;
}

.button-dropdown{
	margin-left:16.5vw;
	margin-top:0.5rem;
}

@media screen and (min-width: 1800px) and (max-width: 1900px) {
.button-dropdown {
	margin-left:23vw;
	}
}


@media screen and (min-width: 1700px) and (max-width: 1800px) {
.button-dropdown {
	margin-left:20vw;
	}
}

@media screen and (min-width: 1620px) and (max-width: 1700px) {
.button-dropdown {
	margin-left:18vw;
	}
}

@media screen and (min-width: 1550px) and (max-width: 1620px) {
.button-dropdown {
	margin-left:25vw;
	}
}

@media screen and (min-width: 1550px) and (max-width: 1620px) {
.button-dropdown {
	margin-left:25vw;
	}
}


@media screen and (min-width: 1100px) and (max-width: 1200px) {
.button-dropdown {
	margin-left:10vw;
	}
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
.button-dropdown {
	margin-left:8vw;
	}
}

@media screen and (min-width: 950px) and (max-width: 1000px) {
.button-dropdown {
	margin-left:6vw;
	}
}

@media screen and (min-width: 850px) and (max-width: 950px) {
	.button-dropdown {
	margin-left:2vw;
	}
}

@media screen and (min-width: 0px) and (max-width: 850px) {
	.node-connect-status-text{
		margin-right: 0rem;
}
	.button-dropdown {
		margin-left:0vw;
	}
}


.xterm-focus-light{

	border: 1px solid rgb(68.00000354647636, 194.00000363588333, 159.0000057220459);
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(68.00000354647636, 194.00000363588333, 159.0000057220459, 0.6);
}

.sensor-slider{
	width:24vw!important;
	margin-bottom: 0.7rem;

}


	.v-align-center{
		vertical-align: middle
	}

	.sol-btns{
		display:flex;
		flex-wrap: nowrap;
		align-items: center;
	}

	.Nodelistbox .node-list-table .datatable .datatable-pagination .datatable-pagination-buttons{
		display:flex;
	}

	.task-node-list .tab-content .task-datatable .datatable .datatable-pagination .datatable-pagination-buttons{
		display:flex;
	}
	

	.nodestatus-box .datatable .datatable-pagination .datatable-pagination-buttons{
		display:flex;
	}

	.my-post-content .deny-user-box .datatable .datatable-pagination .datatable-pagination-buttons{
		display:flex;
	}

	.settings-form .belong-groups .datatable .datatable-pagination .datatable-pagination-buttons{
		display:flex;
	}

	.user-modal-body .datatable-pagination .datatable-pagination-buttons{
		display:flex;
	}

	.joinp-accept-btn-l{
		margin: 0.5rem 1rem 0.25rem 1rem;
	}

	.joinp-accept-btn-r{
		margin: 0.5rem 1rem 0.25rem 0rem;
	}

	@media screen and (max-width: 500px) {
		.joinp-btn-group{
			width:100%;
			justify-content: space-evenly;
		}
		.joinp-accept-btn-l{
			margin: 1rem 0rem 0rem 0rem;
		}
	
		.joinp-accept-btn-r{
			margin: 1rem 0rem 0rem 0rem;
		}

	}

	.marg-0{
		margin:0px!important;
	}
	

	.select-file-box .datatable .datatable-pagination .datatable-pagination-buttons{
		display:flex;
	}

	.power-title{
		max-width: 11.5rem;
		min-width: 6vw;
		}

		.pwr-c-r{
			margin-right:0vw;
			margin-left:1.5vw;
		}

		@media screen and (min-width: 500px) and (max-width: 1500px) {
		
			.pwr-c-r{
				margin-left: 3vw;
			}
		
		}

		.sol-btns-p{
			margin-left:4.6vw;
		}

		.pwr-side-d{
			max-width:16vw;
		}

		.event-log-list-block .datatable .datatable-pagination .datatable-pagination-buttons{
			display:flex;
		}




		.update-fw-status-list .update-fw-block .datatable .datatable-pagination .datatable-select-wrapper .datatable-select-text {
			font-size: 0.9rem !important;
		}
		
		.update-fw-status-list .update-fw-block .datatable .datatable-pagination .datatable-pagination-nav {
			font-size: 0.9rem !important;
		}
		
		.update-fw-status-list .update-fw-block .datatable .datatable-pagination .datatable-select-wrapper .form-outline {
			margin-top: 0rem;
		}
		
		.update-fw-status-list .update-fw-block .datatable .datatable-pagination .datatable-pagination-buttons{
			display:flex;
		}
		

		.flexible-modal {
			position: relative;
			z-index: 1040!important;
			border: 1px solid #ccc;
			background: white;
			width:74vw!important;
			height:calc(100% - 15vw)!important;
			
			border-radius: 10px 10px 10px 0px;
	


		  }

		  @media screen and (min-width: 10px) and (max-width: 390px) {
			.flexible-modal {		
				width:90vw!important;
				height:calc(100% - 5vw)!important;
				
			  }
			  .info-block-support{
				height:calc(141% - 15.8vw)!important;
			  }
		}

		@media screen and (min-width: 390px) and (max-width: 400px) {
			.flexible-modal {		
				width:90vw!important;
				height:calc(100% - 5vw)!important;
			  }
			  .info-block-support{
				height:calc(137% - 15.8vw)!important;
			  }
		}



		@media screen and (min-width: 401px) and (max-width: 500px) {
			.flexible-modal {		
				width:92vw!important;
				height:50rem!important;
			  }
			  .info-block-support{
				height:45rem!important;
			  }
		}

		@media screen and (min-width: 500px) and (max-width: 550px) {
			.info-description {
		
				margin-left: 0rem!important;
				margin-top: 1rem!important;
			
			}
			.flexible-modal {		
				width:92vw!important;
				height:53rem!important;
			  }
			  .info-block-support{
				height:48rem!important;
			  }
		
		}

		@media screen and (min-width: 550px) and (max-width: 572px) {
			.info-description {
		
				margin-left: 0rem!important;
				margin-top: 1rem!important;
			
			}
			.flexible-modal {		
				width:92vw!important;
				height:53rem!important;
			  }
			  .info-block-support{
				height:48rem!important;
			  }
		
		}

		@media screen and (min-width: 572px) and (max-width: 591px) {
			.info-description {
		
				margin-left: 0rem!important;
				margin-top: 1rem!important;
			
			}
			.flexible-modal {		
				width:92vw!important;
				height:53rem!important;
			  }
			  .info-block-support{
				height:48rem!important;
			  }
		
		}


		@media screen and (min-width: 591px) and (max-width: 700px) {
			.info-description {
				width: 34vw!important;
				margin-left: 0.5rem!important;
				margin-top: 3rem!important;
				margin-bottom:1rem!important;
			}
			.flexible-modal {		
				width:92vw!important;
				height:calc(90% - 5vw)!important;
			  }
			  .info-block-support{
				height:34.9rem!important;
			  }
		
		}


		@media screen and (min-width: 700px) and (max-width: 750px) {
			.info-description {
		
				margin-left: 1rem!important;
				margin-top: 3rem!important;
			
			}
			.flexible-modal {		
				width:92vw!important;
				height:37.3rem!important;
			  }
			  .info-block-support{
				height:33.7rem!important;
			  }
		}



		@media screen and (min-width: 750px) and (max-width: 780px) {
			.info-description {
		
				margin-left: 1rem!important;
				margin-top: 3rem!important;
			
			}
			.flexible-modal {		
				width:92vw!important;
				height:37.3rem!important;
			  }
			  .info-block-support{
				height:33.7rem!important;
			  }
		
		
		}




		@media screen and (min-width: 780px) and (max-width: 818px) {
			.info-description {
		
				margin-left: 1rem!important;
				margin-top: 3rem!important;
			
			}
			.flexible-modal {		
				width:92vw!important;
				height:37.3rem!important;
			  }
			  .info-block-support{
				height:33.7rem!important;
			  }
		
		}


		@media screen and (min-width: 818px) and (max-width: 900px) {
			.info-description {
		
				margin-left: 2rem!important;
				margin-top: 3rem!important;
			
			}
			.flexible-modal {		
	  
				height:38.4rem!important;
			  }
	  
		  .info-block-support{
			height:33.7rem!important;
		  }
		
		}

		@media screen and (min-width: 900px) and (max-width: 967px) {
		
			.flexible-modal {		
	  
				height:38.4rem!important;
			  }
	  
		  .info-block-support{
			height:33.7rem!important;
		  }

		  .info-description {
		
			margin-left: 5vw!important;
		
		
		}
		
		}

		@media screen and (min-width: 967px) and (max-width: 1000px) {
		
			.flexible-modal {		
	  
				height:37.3rem!important;
			  }
	  
		  .info-block-support{
			height:33.7rem!important;
		  }

		  .info-description {
		
			margin-left: 5vw!important;
		
		
		}
		
		}

		@media screen and (min-width: 1000px) and (max-width: 1054px) {
			.flexible-modal {		
	  
				height:38.5rem!important;
			  }
	  
		  .info-block-support{
			height:33.7rem!important;
		  }
	  
	  }

	  @media screen and (min-width: 1054px) and (max-width: 1195px) {
		.flexible-modal {		
	  
			height:38.5rem!important;
		  }
  
	  .info-block-support{
		height:33.7rem!important;
	  }
  
  }

	  @media screen and (min-width: 1195px) and (max-width: 1268px) {
		.flexible-modal {		
	  
			height:38.5rem!important;
		  }
  
	  .info-block-support{
		height:33.7rem!important;
	  }
    }

	@media screen and (min-width: 1268px) and (max-width: 1300px) {
		.flexible-modal {		
	  
			height:37.5rem!important;
		  }
  
	  .info-block-support{
		height:33.9rem!important;
	  }

    }


  @media screen and (min-width: 1300px) and (max-width: 1310px) {
	
	.info-block-support{
		height:33.9rem!important;
	  }

	.flexible-modal {		
	
		height:37.4rem!important
	  }

}

@media screen and (min-width: 1310px) and (max-width: 1380px) {


	.info-block-support{
		height:33.9rem!important;
	  }

	.flexible-modal {		
	
		height:37.4rem!important
	  }

}

@media screen and (min-width: 1380px) and (max-width: 1400px) {

	.info-block-support{
		height:33.9rem!important;
	  }

	.flexible-modal {		
	
		height:37.4rem!important
	  }

}

@media screen and (min-width: 1400px) and (max-width: 1455px) {
	
	.info-block-support{
		height:33.9rem!important;
	  }

	.flexible-modal {		
	
		height:37.4rem!important
	  }

}

@media screen and (min-width: 1455px) and (max-width: 1480px) {

		.info-block-support{
			height:33.9rem!important;
		  }
	
		.flexible-modal {		
		
			height:37.4rem!important
		  }

}

@media screen and (min-width: 1480px) and (max-width: 1515px) {

		.info-block-support{
			height:33.9rem!important;
		  }
	
		.flexible-modal {		
		
			height:37.4rem!important
		  }



}
@media screen and (min-width: 1515px) and (max-width: 1640px) {
		
	.info-block-support{
		height:33.9rem!important;
	  }

	.flexible-modal {		
	
		height:37.4rem!important
	  }



}

@media screen and (min-width: 1640px) and (max-width: 1740px) {
	.flexible-modal {		
		width:60vw!important;
	
	  }
		
	.flexible-modal {		
	
		height:37.5rem!important
	  }
	.info-block-support{
	  height:calc(100%)!important;
	}

}

@media screen and (min-width: 1740px) and (max-width: 1890px) {
	.flexible-modal {		
		width:59.5vw!important;
	
	  }
		
	.flexible-modal {		
	
		height:40rem!important
	  }
	.info-block-support{
	  height:calc(107.4%)!important;
	}

}




@media screen and (min-width: 1890px) and (max-width: 2090px) {
	.flexible-modal {		

		width:52.82vw!important;
		height:39.5rem!important
	
	  }

	.info-description {
			margin-left: 7vw!important;

	}

		

}

@media screen and (min-width: 2090px) and (max-width: 2700px) {
	.flexible-modal {		
		width:52.82vw!important;
		height:37.5rem!important
	  }
	

	.info-description {
		
		margin-left: 5vw!important;

	}
	
}



@media screen and (min-width: 1890px) and (max-width: 2700px) {

	.info-block-support{
		height:35.95rem!important;
	  }

	.flexible-modal {		
	
		height:40rem!important
	  }

}
	.flexible-modal-mask {
		position: fixed;
		height: 100%;
		background: rgba(55, 55, 55, 0.6);
		top:0;
		left:0;
		right:0;
		bottom:0;
		z-index: 1000;
	 }
	.flexible-modal-resizer {
		position:absolute;
		right:0;			
		bottom:0;
		cursor:se-resize;
		margin:5px;
		border-bottom: solid 2px #333;
		border-right: solid 2px #333;
		z-index: 10000;
		}
	.flexible-modal-drag-area{
		background-color: transparent!important;
		height: 25px!important;
		width:80vw!important;
		position:absolute;
		top:0;
		cursor:move;
		z-index: 1000;
	 }
   
.addtask-side-label {
	display: flex;
	margin-bottom: 1rem;
}

.addtask-side-label:hover {
	color: #25AFA8;
}

.task-title {
	display: flex;
	justify-content: space-between;
}

.task-title-btns {
	display:flex;
}

.addtask-side-label-text {
	margin-left: 0.55rem;
}

.addtask-side-label-btn {
	border: none;
	background-color: transparent;
	color: #7e7e7e;
}

.addtask-side-label-btn-active {
	color: #25AFA8!important;
}


@media screen and (max-width: 400px) {
	.task-side-card {
		height: auto; /* 自適應高度 */
	}
	.task-side-btn-groups {
		margin: 0 auto; /* 按需居中 */
	}
}

@media screen and (min-width: 401px) and (max-width: 1200px) {
	.task-side-card {
		height: auto; /* 中小螢幕高度自適應 */
	}
	.task-side-btn-groups {
		margin-top: 1rem;
		margin-left: 0.2rem;
	}
}

@media screen and (min-width: 1201px) and (max-width: 2500px) {
	.task-side-card {
		height: 43.4rem; /* 大螢幕固定高度 */
	}
	.task-side-btn-groups {
		margin-top: 1rem;
		margin-left: 0.2rem;
	}
}



.task-card .card-body {
	padding: 0.5rem 1.385rem 1.385rem 1.385rem;
}

.task-card .card-body {
	height: 43.4rem;
}

.task-reachable-error {
	color: #ffffff!important;
	background-color: #EE6164;
	border-color: #EE6164;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	font-size: 0.70rem !important;
	padding: 0rem 1rem 0rem 1rem !important;
}

.task-reachable-success {
	color: #ffffff!important;
	background-color: #45C29F;
	border-color: #45C29F;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	font-size: 0.7rem !important;
	padding: 0rem 1rem;
}

.task-reachable-ready {
	color: #ffffff!important;
	background-color: #F29E40;
	border-color: #F29E40;
	border-radius: 0.5rem !important;
	display: flex;
	justify-content: center;
	font-size: 0.7rem !important;
}

.MuiPickersToolbar-toolbar {
	background-color: #44C29F!important;
}

.MuiPickerDTTabs-tabs {
	background-color: #44C29F!important;
}

.MuiPickersDay-daySelected {
	background-color: #44C29F!important;
}

.MuiButton-textPrimary {
	color: #44C29F!important;
}

.MuiPickersClock-pin {
	background-color: #44C29F!important;
}

.MuiPickersClockPointer-pointer {
	background-color: #44C29F!important;
}

.MuiPickersClockPointer-thumb {
	border: 14px solid #44C29F!important;
}

.MuiPickersClockPointer-noPoint {
	background-color: #44C29F!important;
}

.Mui-focused {
	border-color: #44C29F!important;
}

.MuiOutlinedInput-root {
	border: none !important;
}

.MuiInputBase-root {
	border: 0 !important;
}

body .picker-input__text,
body .MuiInputBase-input {
	border: none !important;
}

.watch-module-p80 {
	margin-top: 1.5rem;
	padding-right: 1rem;
	display: flex;
	align-items: flex-start;
	flex-wrap: nowrap;
	flex-direction: column;
	max-width: 500px;
}

.task-watch-module-title {
	display: flex;
	align-items: center;
}

.task-csw-title-text {
	font-weight: 500;
	font-size: 1.25rem;
	letter-spacing: 1px;
}

.color-gray {
	color: #7e7e7e!important;
}

.watch-module-block {
	margin: 0.5rem 0.9rem 0rem 0.9rem;
	background-color: rgba(244.3749949336052, 244.3749949336052, 244.3749949336052, 1);
	border-radius: 0.5rem;
	flex: 0 0 50%;
	max-width: 50%;
}

.watch-module {
	display: flex;
	align-items: center;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	max-width: 900px;
	margin: 1rem 0rem 0rem 2.5rem;
}

.watch-module-monitor-timeout-box {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

@media screen and (min-width: 10px) and (max-width: 996px) {
	.watch-module-block {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

@media screen and (min-width: 10px) and (max-width: 996px) {
	.watch-module {
		flex-wrap: wrap;
		margin: 1rem 0rem 0rem 0rem;
	}
}

.watch-module-box {
	padding: 1rem 1.5rem 1.5rem 1.5rem;
}

.module-watch-label {
	display: flex;
}

.csw-duration-input {
	display: flex;
}

.flex-input {
	flex: 0 0 55%;
	max-width: 55%;
}

.filter-key-input {
	display: flex;
	align-items: center;
}

.watch-module-title-color {
	color: #0F4542;
}

.watch-module-p80-input {
	display: flex;
	align-items: center;
}

.monitor-timeout-title-size {
	font-size: 0.9rem!important;
}

.word-break-normal {
	display: inline-block;
}

.watch-module-submit {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 1.5rem;
	margin-right: 2.5rem;
}

#addupateFWtask .modal-content .modal-body {
	padding: 0rem!important;
}

.mutiTimePicker-box {
	border: 1px solid #c9c9c9;
	border-radius: 0.5rem;
	display: flex;
}

.task-basic {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#actionPara{
	width: 102%!important;
	Height: 50px!important;
	margin-bottom: 0.2rem;
	font-size: 10pt;
	font-family:sans-serif!important;
}


.c-action-wrap{
    justify-content: center;
    display: flex;
    flex-direction: row;
	margin-top: 1rem;
}

.c-action-wrap >div:nth-child(1) {
    margin-right: 1rem;
}

.c-action-wrap >div:nth-child(2) {
    margin-left: 1rem;
}

.c-action-wrap .task-basic:first-child {
	align-items: flex-end; 
}

.c-action-wrap .task-basic:last-child {
	align-items: flex-start;
}

.c-action-wrap .task-basic{
	flex: 1 1 auto;
}


.c-action-wrap .task-basic .flex-input-small{
	/* width:30vh */
	width:70%;
}

.c-action-s-node{
	margin:1rem 2rem;
	line-height: 0.5rem;
	text-align: center;
}

.c-action-s-node .datatable .datatable-pagination .datatable-pagination-buttons{
    display:flex;
}

.c-action-s-node .datatable{
font-size:11pt;
}

/* .datatable-body */
.c-action_detail_wrap{
	margin: 1.5rem 1.5rem 0rem 1.5rem;
}

.c-action-warn-btn{
	margin:0rem;
	padding: 0rem;
	height: 1rem!important;
	background-color: transparent;
    border-color: transparent;
}

.c-action-warn-btn:hover,.c-action-warn-btn:focus{
	background-color: transparent!important;
    border-color: transparent!important;
	box-shadow:0 0 0 0.2rem rgba(97, 173, 31, 0)!important;
}

/* .c-action-detail-l{
	flex: 1.1 1.1;
	width: 0;
	display: flex;
	align-items: center;
	flex: 0 1 45%;
	justify-content: center;
} */

.c-action-detail-l {
	display: flex;
	flex-direction: column; /* 使內部元素垂直排列 */
	flex: 1 1 1%; /* 使用剩餘空間 */
	width: 100%;
	justify-content: flex-start; /* 避免居中，讓內容自然排列 */
	align-items: flex-start;
	padding: 0.5rem 1rem 0.5rem 1.5rem;
  }
  

.c-action-detail-r{
	background-color: #F5F5F5;
    flex: 1.25 1;
    width: 0;
    min-height: 35vh;
    border-radius: 0.3rem;
    font-size: 15px;
    color: #25AFA8;
    font-weight: 500;
    overflow-y: scroll;
}


.c-action-detail-r >div:nth-child(1) {
    margin-top: 1rem;
	margin-left:1rem;
	font-weight:lighter!important;
	font-size: 11pt;

}

.c-action-tab{
	margin-right: 2rem;
}

.c-action-detail-title{
	color:#0F4542;
}

.c-action-detail-div{
	margin-top:0.2rem;
}

.c-action-detail-txt{
	font-size: 11pt;
	color:#757575;
}

#c-action-modal-message{
    display:flex;
    flex-direction: row;
	margin-bottom:0rem!important;
}

.loading-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	/* position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.1); */
	z-index: 10;
  }
  
  .loading-text {
	margin-top: 10px;
	font-size: 14px;
	color: #333;
  }
  

#taskname {
	width: 360px!important;
	Height: 50px!important;
	margin-bottom: 2.5rem;
}

#confirmdeploybtn{
	width:10rem;
	height:2.5rem;
}

.taskname-title {
	margin-top: 1rem!important;
}

.display-hidden {
	visibility: hidden
}

.task-next-btn {
	background: rgba(37, 175, 168,1);
	border-width: 0px;
	color: rgb(255, 255, 255);
	border-radius: 4px;
	font-size: 12px;
	font-weight: 600;
	width:8rem;
	height:2.5rem;
}

.task-delete-btn {
	background: rgb(238, 97, 100);
	border-width: 0px;
	color: rgb(255, 255, 255);
	border-radius: 4px;
	font-size: 14px;
	font-weight: 600;
	padding: 0.55em 2em;
	margin-right: 1rem;
}

.power-cycle-task-block {
	display: flex;
	flex-direction: column;
	padding: 3rem 20rem 5rem 20rem;
}

.pwr-act {
	display: flex;
	margin-bottom: 2rem;
	flex-direction: column;
	margin-top: 1rem;
}

.task-detail-setting {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem 20rem 0rem 20rem;
}

.task-detail-setting-result {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-direction: row;
	margin-bottom: 2rem;
}

.task-detail-setting-result >div {
	flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
	margin:0rem 1rem 0rem 1rem;
}

@media (max-width: 576px) {
	.task-detail-setting-result {
		flex-direction: column;
		align-items: center;
	}
	.task-detail-setting-result >div {
		flex: 0 0 77.3333333333%;
		max-width: 77.3333333333%;
		margin:0rem 0rem 0rem 0rem;
	}

}



.scheduled-select-node-list {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem 3rem 0rem 3rem;
}

.scheduled-select-node-list .datatable .datatable-pagination {
	margin-top: 0.3rem!important;
}

.scheduled-select-node-list .form-outline .form-control {
	width: 55rem !important;
}

.update-fw-block .form-outline .mb-4 {
	margin: 0rem !important;
}

.scheduled-select-node-list .form-outline {
	height: 3rem!important;
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.scheduled-select-node-list .datatable .datatable-inner .table tbody tr td {
	font-size: 0.95rem !important;
	padding: 15px 20px !important;
	text-align: center;
	align-items: center;
}

.scheduled-select-node-list .datatable .datatable-inner .table thead tr th {
	font-size: 0.9rem !important;
	text-align: center;
}

.report-task-select-node-pre {
	display: flex;
	align-items: center;
}

.report-message-box {
	display: flex;
	justify-content: center;
}

.task-report-title {
	margin: 0vw 0vw 0rem 5.5vw;
	color: #0F4542;
	font-size: 1.2rem;
	font-weight: 500;
}

.report-message-padding {
	padding: 1rem 1rem 0rem 1rem !important;
}

.hex-checkbox-report {
	display: flex;
	justify-content: flex-end;
}

.task-report-status {
	display: flex;
}




.notification_dropdown .dropdown-menu-right {
	min-width: 310px;
	padding: 0rem 0 1rem;
	top: 100%;
}

.notification_dropdown .dropdown-menu-right .notification_title {
	background: #25AFA8;
	color: #fff;
	padding: 10px 20px;
}

.notification_dropdown .dropdown-menu-right .notification_title h5 {
	color: #fff;
	margin-bottom: 3px;
}

.notification_dropdown .dropdown-menu-right .media {
	width: 45px !important;
	height: 45px !important;
	font-size: 18px !important;
}

.notification_dropdown .dropdown-menu-right .media>span {
	width: 35px;
	height: 35px;
	border-radius: 50px;
	display: inline-block;
	padding: 7px 9px;
	margin-right: 10px;
}

[direction="rtl"].notification_dropdown .dropdown-menu-right .media>span {
	margin-right: 0;
	margin-left: 10px;
}

.notification_dropdown .dropdown-menu-right .media>span.success {
	background: #d5f5f1;
	color: #37D1BF;
}

.notification_dropdown .dropdown-menu-right .media>span.success [fill] {
	fill: #37D1BF;
}

.notification_dropdown .dropdown-menu-right .media>span.primary {
	background: #ddf7c7;
	color: #25AFA8;
}

.notification_dropdown .dropdown-menu-right .media>span.primary svg [fill] {
	fill: #25AFA8;
}

.notification_dropdown .dropdown-menu-right .media>span.danger {
	background: #ffeaef;
	color: #FF285C;
}

.notification_dropdown .dropdown-menu-right .media>span.danger [fill] {
	fill: #FF285C;
}

.notification_dropdown .dropdown-menu-right .media>span [fill] {
	fill: #25AFA8;
}

.notification_dropdown .dropdown-menu-right .media .notify-time {
	width: 100% !important;
	margin-right: 0 !important;
	color: #828690;
}

.notification_dropdown .dropdown-menu-right .media p {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 200px;
	margin-bottom: 0;
	margin-top: 5px;
}

@media only screen and (max-width: 575px) {
	.notification_dropdown .dropdown-menu-right .media p {
		max-width: 100px;
	}
}

.notification_dropdown .dropdown-menu-right .all-notification {
	display: block;
	padding: 15px 30px 0;
	text-align: center;
	border-top: 1px solid #E8E8E8;
}

.notification_dropdown .dropdown-menu-right .all-notification i {
	margin-left: 10px;
}

.rtl {
	text-align: right;
	direction: rtl;
}

.rtl .nav {
	padding-right: 0;
}

.rtl .navbar-nav .nav-item {
	float: right;
}

.rtl .navbar-nav .nav-item+.nav-item {
	margin-right: 1rem;
	margin-left: inherit;
}

.rtl th {
	text-align: right;
}

.rtl .alert-dismissible {
	padding-right: 1.25rem;
	padding-left: 4rem;
}

.rtl .dropdown-menu {
	right: 0;
	text-align: right;
}

.rtl .checkbox label {
	padding-right: 1.25rem;
	padding-left: inherit;
}

.rtl .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
	border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .btn-group>.btn:last-child:not(:first-child),
.rtl .btn-group>.dropdown-toggle:not(:first-child) {
	border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
	border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
	right: 0;
	left: inherit;
}

.rtl .custom-select {
	padding: 0.375rem 0.75rem 0.375rem 1.75rem;
	background: #fff url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") no-repeat left 0.75rem center;
	background-size: 8px 10px;
}

.rtl .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.rtl .input-group>.input-group-append:not(:last-child)>.btn,
.rtl .input-group>.input-group-append:not(:last-child)>.input-group-text,
.rtl .input-group>.input-group-prepend>.btn,
.rtl .input-group>.input-group-prepend>.input-group-text {
	border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group>.input-group-append>.btn,
.rtl .input-group>.input-group-append>.input-group-text,
.rtl .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.rtl .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.rtl .input-group>.input-group-prepend:not(:first-child)>.btn,
.rtl .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
	border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group>.custom-select:not(:first-child),
.rtl .input-group>.form-control:not(:first-child) {
	border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group>.custom-select:not(:last-child),
.rtl .input-group>.form-control:not(:last-child) {
	border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group>.custom-select:not(:last-child):not(:first-child),
.rtl .input-group>.form-control:not(:last-child):not(:first-child) {
	border-radius: 0;
}

.rtl .custom-control {
	padding-right: 1.5rem;
	padding-left: inherit;
	margin-right: inherit;
	margin-left: 1rem;
}

.rtl .custom-control-indicator {
	right: 0;
	left: inherit;
}

.rtl .custom-file-label::after {
	right: initial;
	left: -1px;
	border-radius: .25rem 0 0 .25rem;
}

.rtl .radio input,
.rtl .radio-inline,
.rtl .checkbox input,
.rtl .checkbox-inline input {
	margin-right: -1.25rem;
	margin-left: inherit;
}

.rtl .list-group {
	padding-right: 0;
	padding-left: 40px;
}

.rtl .close {
	float: left;
}

.rtl .modal-header .close {
	margin: -15px auto -15px -15px;
}

.rtl .modal-footer> :not(:first-child) {
	margin-right: .25rem;
}

.rtl .alert-dismissible .close {
	right: inherit;
	left: 0;
}

.rtl .dropdown-toggle::after {
	margin-right: .255em;
	margin-left: 0;
}

.rtl .form-check-input {
	margin-right: -1.25rem;
	margin-left: inherit;
}

.rtl .form-check-input1 {
	margin-right: -1.25rem;
	margin-left: inherit;
}

.rtl .form-check-label {
	padding-right: 1.25rem;
	padding-left: inherit;
}

.rtl .offset-1 {
	margin-right: 8.3333333333%;
	margin-left: 0;
}

.rtl .offset-2 {
	margin-right: 16.6666666667%;
	margin-left: 0;
}

.rtl .offset-3 {
	margin-right: 25%;
	margin-left: 0;
}

.rtl .offset-4 {
	margin-right: 33.3333333333%;
	margin-left: 0;
}

.rtl .offset-5 {
	margin-right: 41.6666666667%;
	margin-left: 0;
}

.rtl .offset-6 {
	margin-right: 50%;
	margin-left: 0;
}

.rtl .offset-7 {
	margin-right: 58.3333333333%;
	margin-left: 0;
}

.rtl .offset-8 {
	margin-right: 66.6666666667%;
	margin-left: 0;
}

.rtl .offset-9 {
	margin-right: 75%;
	margin-left: 0;
}

.rtl .offset-10 {
	margin-right: 83.3333333333%;
	margin-left: 0;
}

.rtl .offset-11 {
	margin-right: 91.6666666667%;
	margin-left: 0;
}

@media (min-width: 576px) {
	.rtl .offset-sm-0 {
		margin-right: 0;
		margin-left: 0;
	}
	.rtl .offset-sm-1 {
		margin-right: 8.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-sm-2 {
		margin-right: 16.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-sm-3 {
		margin-right: 25%;
		margin-left: 0;
	}
	.rtl .offset-sm-4 {
		margin-right: 33.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-sm-5 {
		margin-right: 41.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-sm-6 {
		margin-right: 50%;
		margin-left: 0;
	}
	.rtl .offset-sm-7 {
		margin-right: 58.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-sm-8 {
		margin-right: 66.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-sm-9 {
		margin-right: 75%;
		margin-left: 0;
	}
	.rtl .offset-sm-10 {
		margin-right: 83.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-sm-11 {
		margin-right: 91.6666666667%;
		margin-left: 0;
	}
}

@media (min-width: 768px) {
	.rtl .offset-md-0 {
		margin-right: 0;
		margin-left: 0;
	}
	.rtl .offset-md-1 {
		margin-right: 8.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-md-2 {
		margin-right: 16.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-md-3 {
		margin-right: 25%;
		margin-left: 0;
	}
	.rtl .offset-md-4 {
		margin-right: 33.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-md-5 {
		margin-right: 41.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-md-6 {
		margin-right: 50%;
		margin-left: 0;
	}
	.rtl .offset-md-7 {
		margin-right: 58.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-md-8 {
		margin-right: 66.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-md-9 {
		margin-right: 75%;
		margin-left: 0;
	}
	.rtl .offset-md-10 {
		margin-right: 83.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-md-11 {
		margin-right: 91.6666666667%;
		margin-left: 0;
	}
}

@media (min-width: 992px) {
	.rtl .offset-lg-0 {
		margin-right: 0;
		margin-left: 0;
	}
	.rtl .offset-lg-1 {
		margin-right: 8.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-lg-2 {
		margin-right: 16.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-lg-3 {
		margin-right: 25%;
		margin-left: 0;
	}
	.rtl .offset-lg-4 {
		margin-right: 33.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-lg-5 {
		margin-right: 41.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-lg-6 {
		margin-right: 50%;
		margin-left: 0;
	}
	.rtl .offset-lg-7 {
		margin-right: 58.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-lg-8 {
		margin-right: 66.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-lg-9 {
		margin-right: 75%;
		margin-left: 0;
	}
	.rtl .offset-lg-10 {
		margin-right: 83.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-lg-11 {
		margin-right: 91.6666666667%;
		margin-left: 0;
	}
}

@media (min-width: 1200px) {
	.rtl .offset-xl-0 {
		margin-right: 0;
		margin-left: 0;
	}
	.rtl .offset-xl-1 {
		margin-right: 8.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xl-2 {
		margin-right: 16.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xl-3 {
		margin-right: 25%;
		margin-left: 0;
	}
	.rtl .offset-xl-4 {
		margin-right: 33.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xl-5 {
		margin-right: 41.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xl-6 {
		margin-right: 50%;
		margin-left: 0;
	}
	.rtl .offset-xl-7 {
		margin-right: 58.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xl-8 {
		margin-right: 66.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xl-9 {
		margin-right: 75%;
		margin-left: 0;
	}
	.rtl .offset-xl-10 {
		margin-right: 83.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xl-11 {
		margin-right: 91.6666666667%;
		margin-left: 0;
	}
}

@media (min-width: 1440) {
	.rtl .offset-xxl-0 {
		margin-right: 0;
		margin-left: 0;
	}
	.rtl .offset-xxl-1 {
		margin-right: 8.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xxl-2 {
		margin-right: 16.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xxl-3 {
		margin-right: 25%;
		margin-left: 0;
	}
	.rtl .offset-xxl-4 {
		margin-right: 33.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xxl-5 {
		margin-right: 41.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xxl-6 {
		margin-right: 50%;
		margin-left: 0;
	}
	.rtl .offset-xxl-7 {
		margin-right: 58.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xxl-8 {
		margin-right: 66.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xxl-9 {
		margin-right: 75%;
		margin-left: 0;
	}
	.rtl .offset-xxl-10 {
		margin-right: 83.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xxl-11 {
		margin-right: 91.6666666667%;
		margin-left: 0;
	}
}

.rtl .mr-0,
.rtl .mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.rtl .ml-0,
.rtl .mx-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.rtl .mr-1,
.rtl .mx-1 {
	margin-right: 0 !important;
	margin-left: 0.25rem !important;
}

.rtl .ml-1,
.rtl .mx-1 {
	margin-left: 0 !important;
	margin-right: 0.25rem !important;
}

.rtl .mr-2,
.rtl .mx-2 {
	margin-right: 0 !important;
	margin-left: 0.5rem !important;
}

.rtl .ml-2,
.rtl .mx-2 {
	margin-left: 0 !important;
	margin-right: 0.5rem !important;
}

.rtl .mr-3,
.rtl .mx-3 {
	margin-right: 0 !important;
	margin-left: 1rem !important;
}

.rtl .ml-3,
.rtl .mx-3 {
	margin-left: 0 !important;
	margin-right: 1rem !important;
}

.rtl .mr-4,
.rtl .mx-4 {
	margin-right: 0 !important;
	margin-left: 1.5rem !important;
}

.rtl .ml-4,
.rtl .mx-4 {
	margin-left: 0 !important;
	margin-right: 1.5rem !important;
}

.rtl .mr-5,
.rtl .mx-5 {
	margin-right: 0 !important;
	margin-left: 3rem !important;
}

.rtl .ml-5,
.rtl .mx-5 {
	margin-left: 0 !important;
	margin-right: 3rem !important;
}

.rtl .pr-0,
.rtl .px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.rtl .pl-0,
.rtl .px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.rtl .pr-1,
.rtl .px-1 {
	padding-right: 0 !important;
	padding-left: 0.25rem !important;
}

.rtl .pl-1,
.rtl .px-1 {
	padding-left: 0 !important;
	padding-right: 0.25rem !important;
}

.rtl .pr-2,
.rtl .px-2 {
	padding-right: 0 !important;
	padding-left: 0.5rem !important;
}

.rtl .pl-2,
.rtl .px-2 {
	padding-left: 0 !important;
	padding-right: 0.5rem !important;
}

.rtl .pr-3,
.rtl .px-3 {
	padding-right: 0 !important;
	padding-left: 1rem !important;
}

.rtl .pl-3,
.rtl .px-3 {
	padding-left: 0 !important;
	padding-right: 1rem !important;
}

.rtl .pr-4,
.rtl .px-4 {
	padding-right: 0 !important;
	padding-left: 1.5rem !important;
}

.rtl .pl-4,
.rtl .px-4 {
	padding-left: 0 !important;
	padding-right: 1.5rem !important;
}

.rtl .pr-5,
.rtl .px-5 {
	padding-right: 0 !important;
	padding-left: 3rem !important;
}

.rtl .pl-5,
.rtl .px-5 {
	padding-left: 0 !important;
	padding-right: 3rem !important;
}

.rtl .mr-auto {
	margin-right: 0 !important;
	margin-left: auto !important;
}

.rtl .ml-auto {
	margin-right: auto !important;
	margin-left: 0 !important;
}

.rtl .mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

@media (min-width: 576px) {
	.rtl .mr-sm-0,
	.rtl .mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.rtl .ml-sm-0,
	.rtl .mx-sm-0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.rtl .mr-sm-1,
	.rtl .mx-sm-1 {
		margin-right: 0 !important;
		margin-left: 0.25rem !important;
	}
	.rtl .ml-sm-1,
	.rtl .mx-sm-1 {
		margin-left: 0 !important;
		margin-right: 0.25rem !important;
	}
	.rtl .mr-sm-2,
	.rtl .mx-sm-2 {
		margin-right: 0 !important;
		margin-left: 0.5rem !important;
	}
	.rtl .ml-sm-2,
	.rtl .mx-sm-2 {
		margin-left: 0 !important;
		margin-right: 0.5rem !important;
	}
	.rtl .mr-sm-3,
	.rtl .mx-sm-3 {
		margin-right: 0 !important;
		margin-left: 1rem !important;
	}
	.rtl .ml-sm-3,
	.rtl .mx-sm-3 {
		margin-left: 0 !important;
		margin-right: 1rem !important;
	}
	.rtl .mr-sm-4,
	.rtl .mx-sm-4 {
		margin-right: 0 !important;
		margin-left: 1.5rem !important;
	}
	.rtl .ml-sm-4,
	.rtl .mx-sm-4 {
		margin-left: 0 !important;
		margin-right: 1.5rem !important;
	}
	.rtl .mr-sm-5,
	.rtl .mx-sm-5 {
		margin-right: 0 !important;
		margin-left: 3rem !important;
	}
	.rtl .ml-sm-5,
	.rtl .mx-sm-5 {
		margin-left: 0 !important;
		margin-right: 3rem !important;
	}
	.rtl .pr-sm-0,
	.rtl .px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.rtl .pl-sm-0,
	.rtl .px-sm-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.rtl .pr-sm-1,
	.rtl .px-sm-1 {
		padding-right: 0 !important;
		padding-left: 0.25rem !important;
	}
	.rtl .pl-sm-1,
	.rtl .px-sm-1 {
		padding-left: 0 !important;
		padding-right: 0.25rem !important;
	}
	.rtl .pr-sm-2,
	.rtl .px-sm-2 {
		padding-right: 0 !important;
		padding-left: 0.5rem !important;
	}
	.rtl .pl-sm-2,
	.rtl .px-sm-2 {
		padding-left: 0 !important;
		padding-right: 0.5rem !important;
	}
	.rtl .pr-sm-3,
	.rtl .px-sm-3 {
		padding-right: 0 !important;
		padding-left: 1rem !important;
	}
	.rtl .pl-sm-3,
	.rtl .px-sm-3 {
		padding-left: 0 !important;
		padding-right: 1rem !important;
	}
	.rtl .pr-sm-4,
	.rtl .px-sm-4 {
		padding-right: 0 !important;
		padding-left: 1.5rem !important;
	}
	.rtl .pl-sm-4,
	.rtl .px-sm-4 {
		padding-left: 0 !important;
		padding-right: 1.5rem !important;
	}
	.rtl .pr-sm-5,
	.rtl .px-sm-5 {
		padding-right: 0 !important;
		padding-left: 3rem !important;
	}
	.rtl .pl-sm-5,
	.rtl .px-sm-5 {
		padding-left: 0 !important;
		padding-right: 3rem !important;
	}
	.rtl .mr-sm-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .ml-sm-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 768px) {
	.rtl .mr-md-0,
	.rtl .mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.rtl .ml-md-0,
	.rtl .mx-md-0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.rtl .mr-md-1,
	.rtl .mx-md-1 {
		margin-right: 0 !important;
		margin-left: 0.25rem !important;
	}
	.rtl .ml-md-1,
	.rtl .mx-md-1 {
		margin-left: 0 !important;
		margin-right: 0.25rem !important;
	}
	.rtl .mr-md-2,
	.rtl .mx-md-2 {
		margin-right: 0 !important;
		margin-left: 0.5rem !important;
	}
	.rtl .ml-md-2,
	.rtl .mx-md-2 {
		margin-left: 0 !important;
		margin-right: 0.5rem !important;
	}
	.rtl .mr-md-3,
	.rtl .mx-md-3 {
		margin-right: 0 !important;
		margin-left: 1rem !important;
	}
	.rtl .ml-md-3,
	.rtl .mx-md-3 {
		margin-left: 0 !important;
		margin-right: 1rem !important;
	}
	.rtl .mr-md-4,
	.rtl .mx-md-4 {
		margin-right: 0 !important;
		margin-left: 1.5rem !important;
	}
	.rtl .ml-md-4,
	.rtl .mx-md-4 {
		margin-left: 0 !important;
		margin-right: 1.5rem !important;
	}
	.rtl .mr-md-5,
	.rtl .mx-md-5 {
		margin-right: 0 !important;
		margin-left: 3rem !important;
	}
	.rtl .ml-md-5,
	.rtl .mx-md-5 {
		margin-left: 0 !important;
		margin-right: 3rem !important;
	}
	.rtl .pr-md-0,
	.rtl .px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.rtl .pl-md-0,
	.rtl .px-md-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.rtl .pr-md-1,
	.rtl .px-md-1 {
		padding-right: 0 !important;
		padding-left: 0.25rem !important;
	}
	.rtl .pl-md-1,
	.rtl .px-md-1 {
		padding-left: 0 !important;
		padding-right: 0.25rem !important;
	}
	.rtl .pr-md-2,
	.rtl .px-md-2 {
		padding-right: 0 !important;
		padding-left: 0.5rem !important;
	}
	.rtl .pl-md-2,
	.rtl .px-md-2 {
		padding-left: 0 !important;
		padding-right: 0.5rem !important;
	}
	.rtl .pr-md-3,
	.rtl .px-md-3 {
		padding-right: 0 !important;
		padding-left: 1rem !important;
	}
	.rtl .pl-md-3,
	.rtl .px-md-3 {
		padding-left: 0 !important;
		padding-right: 1rem !important;
	}
	.rtl .pr-md-4,
	.rtl .px-md-4 {
		padding-right: 0 !important;
		padding-left: 1.5rem !important;
	}
	.rtl .pl-md-4,
	.rtl .px-md-4 {
		padding-left: 0 !important;
		padding-right: 1.5rem !important;
	}
	.rtl .pr-md-5,
	.rtl .px-md-5 {
		padding-right: 0 !important;
		padding-left: 3rem !important;
	}
	.rtl .pl-md-5,
	.rtl .px-md-5 {
		padding-left: 0 !important;
		padding-right: 3rem !important;
	}
	.rtl .mr-md-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .ml-md-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 992px) {
	.rtl .mr-lg-0,
	.rtl .mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.rtl .ml-lg-0,
	.rtl .mx-lg-0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.rtl .mr-lg-1,
	.rtl .mx-lg-1 {
		margin-right: 0 !important;
		margin-left: 0.25rem !important;
	}
	.rtl .ml-lg-1,
	.rtl .mx-lg-1 {
		margin-left: 0 !important;
		margin-right: 0.25rem !important;
	}
	.rtl .mr-lg-2,
	.rtl .mx-lg-2 {
		margin-right: 0 !important;
		margin-left: 0.5rem !important;
	}
	.rtl .ml-lg-2,
	.rtl .mx-lg-2 {
		margin-left: 0 !important;
		margin-right: 0.5rem !important;
	}
	.rtl .mr-lg-3,
	.rtl .mx-lg-3 {
		margin-right: 0 !important;
		margin-left: 1rem !important;
	}
	.rtl .ml-lg-3,
	.rtl .mx-lg-3 {
		margin-left: 0 !important;
		margin-right: 1rem !important;
	}
	.rtl .mr-lg-4,
	.rtl .mx-lg-4 {
		margin-right: 0 !important;
		margin-left: 1.5rem !important;
	}
	.rtl .ml-lg-4,
	.rtl .mx-lg-4 {
		margin-left: 0 !important;
		margin-right: 1.5rem !important;
	}
	.rtl .mr-lg-5,
	.rtl .mx-lg-5 {
		margin-right: 0 !important;
		margin-left: 3rem !important;
	}
	.rtl .ml-lg-5,
	.rtl .mx-lg-5 {
		margin-left: 0 !important;
		margin-right: 3rem !important;
	}
	.rtl .pr-lg-0,
	.rtl .px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.rtl .pl-lg-0,
	.rtl .px-lg-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.rtl .pr-lg-1,
	.rtl .px-lg-1 {
		padding-right: 0 !important;
		padding-left: 0.25rem !important;
	}
	.rtl .pl-lg-1,
	.rtl .px-lg-1 {
		padding-left: 0 !important;
		padding-right: 0.25rem !important;
	}
	.rtl .pr-lg-2,
	.rtl .px-lg-2 {
		padding-right: 0 !important;
		padding-left: 0.5rem !important;
	}
	.rtl .pl-lg-2,
	.rtl .px-lg-2 {
		padding-left: 0 !important;
		padding-right: 0.5rem !important;
	}
	.rtl .pr-lg-3,
	.rtl .px-lg-3 {
		padding-right: 0 !important;
		padding-left: 1rem !important;
	}
	.rtl .pl-lg-3,
	.rtl .px-lg-3 {
		padding-left: 0 !important;
		padding-right: 1rem !important;
	}
	.rtl .pr-lg-4,
	.rtl .px-lg-4 {
		padding-right: 0 !important;
		padding-left: 1.5rem !important;
	}
	.rtl .pl-lg-4,
	.rtl .px-lg-4 {
		padding-left: 0 !important;
		padding-right: 1.5rem !important;
	}
	.rtl .pr-lg-5,
	.rtl .px-lg-5 {
		padding-right: 0 !important;
		padding-left: 3rem !important;
	}
	.rtl .pl-lg-5,
	.rtl .px-lg-5 {
		padding-left: 0 !important;
		padding-right: 3rem !important;
	}
	.rtl .mr-lg-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .ml-lg-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 1200px) {
	.rtl .mr-xl-0,
	.rtl .mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.rtl .ml-xl-0,
	.rtl .mx-xl-0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.rtl .mr-xl-1,
	.rtl .mx-xl-1 {
		margin-right: 0 !important;
		margin-left: 0.25rem !important;
	}
	.rtl .ml-xl-1,
	.rtl .mx-xl-1 {
		margin-left: 0 !important;
		margin-right: 0.25rem !important;
	}
	.rtl .mr-xl-2,
	.rtl .mx-xl-2 {
		margin-right: 0 !important;
		margin-left: 0.5rem !important;
	}
	.rtl .ml-xl-2,
	.rtl .mx-xl-2 {
		margin-left: 0 !important;
		margin-right: 0.5rem !important;
	}
	.rtl .mr-xl-3,
	.rtl .mx-xl-3 {
		margin-right: 0 !important;
		margin-left: 1rem !important;
	}
	.rtl .ml-xl-3,
	.rtl .mx-xl-3 {
		margin-left: 0 !important;
		margin-right: 1rem !important;
	}
	.rtl .mr-xl-4,
	.rtl .mx-xl-4 {
		margin-right: 0 !important;
		margin-left: 1.5rem !important;
	}
	.rtl .ml-xl-4,
	.rtl .mx-xl-4 {
		margin-left: 0 !important;
		margin-right: 1.5rem !important;
	}
	.rtl .mr-xl-5,
	.rtl .mx-xl-5 {
		margin-right: 0 !important;
		margin-left: 3rem !important;
	}
	.rtl .ml-xl-5,
	.rtl .mx-xl-5 {
		margin-left: 0 !important;
		margin-right: 3rem !important;
	}
	.rtl .pr-xl-0,
	.rtl .px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.rtl .pl-xl-0,
	.rtl .px-xl-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.rtl .pr-xl-1,
	.rtl .px-xl-1 {
		padding-right: 0 !important;
		padding-left: 0.25rem !important;
	}
	.rtl .pl-xl-1,
	.rtl .px-xl-1 {
		padding-left: 0 !important;
		padding-right: 0.25rem !important;
	}
	.rtl .pr-xl-2,
	.rtl .px-xl-2 {
		padding-right: 0 !important;
		padding-left: 0.5rem !important;
	}
	.rtl .pl-xl-2,
	.rtl .px-xl-2 {
		padding-left: 0 !important;
		padding-right: 0.5rem !important;
	}
	.rtl .pr-xl-3,
	.rtl .px-xl-3 {
		padding-right: 0 !important;
		padding-left: 1rem !important;
	}
	.rtl .pl-xl-3,
	.rtl .px-xl-3 {
		padding-left: 0 !important;
		padding-right: 1rem !important;
	}
	.rtl .pr-xl-4,
	.rtl .px-xl-4 {
		padding-right: 0 !important;
		padding-left: 1.5rem !important;
	}
	.rtl .pl-xl-4,
	.rtl .px-xl-4 {
		padding-left: 0 !important;
		padding-right: 1.5rem !important;
	}
	.rtl .pr-xl-5,
	.rtl .px-xl-5 {
		padding-right: 0 !important;
		padding-left: 3rem !important;
	}
	.rtl .pl-xl-5,
	.rtl .px-xl-5 {
		padding-left: 0 !important;
		padding-right: 3rem !important;
	}
	.rtl .mr-xl-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .ml-xl-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 1440) {
	.rtl .mr-xxl-0,
	.rtl .mx-xxl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.rtl .ml-xxl-0,
	.rtl .mx-xxl-0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.rtl .mr-xxl-1,
	.rtl .mx-xxl-1 {
		margin-right: 0 !important;
		margin-left: 0.25rem !important;
	}
	.rtl .ml-xxl-1,
	.rtl .mx-xxl-1 {
		margin-left: 0 !important;
		margin-right: 0.25rem !important;
	}
	.rtl .mr-xxl-2,
	.rtl .mx-xxl-2 {
		margin-right: 0 !important;
		margin-left: 0.5rem !important;
	}
	.rtl .ml-xxl-2,
	.rtl .mx-xxl-2 {
		margin-left: 0 !important;
		margin-right: 0.5rem !important;
	}
	.rtl .mr-xxl-3,
	.rtl .mx-xxl-3 {
		margin-right: 0 !important;
		margin-left: 1rem !important;
	}
	.rtl .ml-xxl-3,
	.rtl .mx-xxl-3 {
		margin-left: 0 !important;
		margin-right: 1rem !important;
	}
	.rtl .mr-xxl-4,
	.rtl .mx-xxl-4 {
		margin-right: 0 !important;
		margin-left: 1.5rem !important;
	}
	.rtl .ml-xxl-4,
	.rtl .mx-xxl-4 {
		margin-left: 0 !important;
		margin-right: 1.5rem !important;
	}
	.rtl .mr-xxl-5,
	.rtl .mx-xxl-5 {
		margin-right: 0 !important;
		margin-left: 3rem !important;
	}
	.rtl .ml-xxl-5,
	.rtl .mx-xxl-5 {
		margin-left: 0 !important;
		margin-right: 3rem !important;
	}
	.rtl .pr-xxl-0,
	.rtl .px-xxl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.rtl .pl-xxl-0,
	.rtl .px-xxl-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.rtl .pr-xxl-1,
	.rtl .px-xxl-1 {
		padding-right: 0 !important;
		padding-left: 0.25rem !important;
	}
	.rtl .pl-xxl-1,
	.rtl .px-xxl-1 {
		padding-left: 0 !important;
		padding-right: 0.25rem !important;
	}
	.rtl .pr-xxl-2,
	.rtl .px-xxl-2 {
		padding-right: 0 !important;
		padding-left: 0.5rem !important;
	}
	.rtl .pl-xxl-2,
	.rtl .px-xxl-2 {
		padding-left: 0 !important;
		padding-right: 0.5rem !important;
	}
	.rtl .pr-xxl-3,
	.rtl .px-xxl-3 {
		padding-right: 0 !important;
		padding-left: 1rem !important;
	}
	.rtl .pl-xxl-3,
	.rtl .px-xxl-3 {
		padding-left: 0 !important;
		padding-right: 1rem !important;
	}
	.rtl .pr-xxl-4,
	.rtl .px-xxl-4 {
		padding-right: 0 !important;
		padding-left: 1.5rem !important;
	}
	.rtl .pl-xxl-4,
	.rtl .px-xxl-4 {
		padding-left: 0 !important;
		padding-right: 1.5rem !important;
	}
	.rtl .pr-xxl-5,
	.rtl .px-xxl-5 {
		padding-right: 0 !important;
		padding-left: 3rem !important;
	}
	.rtl .pl-xxl-5,
	.rtl .px-xxl-5 {
		padding-left: 0 !important;
		padding-right: 3rem !important;
	}
	.rtl .mr-xxl-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .ml-xxl-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

.rtl .text-right {
	text-align: left !important;
}

.rtl .text-left {
	text-align: right !important;
}

@media (min-width: 576px) {
	.rtl .text-sm-right {
		text-align: left !important;
	}
	.rtl .text-sm-left {
		text-align: right !important;
	}
}

@media (min-width: 768px) {
	.rtl .text-md-right {
		text-align: left !important;
	}
	.rtl .text-md-left {
		text-align: right !important;
	}
}

@media (min-width: 992px) {
	.rtl .text-lg-right {
		text-align: left !important;
	}
	.rtl .text-lg-left {
		text-align: right !important;
	}
}

@media (min-width: 1200px) {
	.rtl .text-xl-right {
		text-align: left !important;
	}
	.rtl .text-xl-left {
		text-align: right !important;
	}
}

@media (min-width: 1440) {
	.rtl .text-xxl-right {
		text-align: left !important;
	}
	.rtl .text-xxl-left {
		text-align: right !important;
	}
}

.rtl .mx-0 {
	margin-right: auto;
	margin-left: 0 !important;
}

.rtl .mx-0 {
	margin-left: auto;
	margin-right: 0 !important;
}

.rtl .mx-1 {
	margin-right: auto;
	margin-left: 0.25rem !important;
}

.rtl .mx-1 {
	margin-left: auto;
	margin-right: 0.25rem !important;
}

.rtl .mx-2 {
	margin-right: auto;
	margin-left: 0.5rem !important;
}

.rtl .mx-2 {
	margin-left: auto;
	margin-right: 0.5rem !important;
}

.rtl .mx-3 {
	margin-right: auto;
	margin-left: 1rem !important;
}

.rtl .mx-3 {
	margin-left: auto;
	margin-right: 1rem !important;
}

.rtl .mx-4 {
	margin-right: auto;
	margin-left: 1.5rem !important;
}

.rtl .mx-4 {
	margin-left: auto;
	margin-right: 1.5rem !important;
}

.rtl .mx-5 {
	margin-right: auto;
	margin-left: 3rem !important;
}

.rtl .mx-5 {
	margin-left: auto;
	margin-right: 3rem !important;
}

.rtl .px-0 {
	padding-right: auto;
	padding-left: 0 !important;
}

.rtl .px-0 {
	padding-left: auto;
	padding-right: 0 !important;
}

.rtl .px-1 {
	padding-right: auto;
	padding-left: 0.25rem !important;
}

.rtl .px-1 {
	padding-left: auto;
	padding-right: 0.25rem !important;
}

.rtl .px-2 {
	padding-right: auto;
	padding-left: 0.5rem !important;
}

.rtl .px-2 {
	padding-left: auto;
	padding-right: 0.5rem !important;
}

.rtl .px-3 {
	padding-right: auto;
	padding-left: 1rem !important;
}

.rtl .px-3 {
	padding-left: auto;
	padding-right: 1rem !important;
}

.rtl .px-4 {
	padding-right: auto;
	padding-left: 1.5rem !important;
}

.rtl .px-4 {
	padding-left: auto;
	padding-right: 1.5rem !important;
}

.rtl .px-5 {
	padding-right: auto;
	padding-left: 3rem !important;
}

.rtl .px-5 {
	padding-left: auto;
	padding-right: 3rem !important;
}

.rtl .mr-auto {
	margin-right: 0 !important;
	margin-left: auto !important;
}

.rtl .mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.rtl .ml-auto {
	margin-right: auto !important;
	margin-left: 0 !important;
}

.rtl .mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

@media (min-width: 576px) {
	.rtl .mx-sm-0 {
		margin-right: auto;
		margin-left: 0 !important;
	}
	.rtl .mx-sm-0 {
		margin-left: auto;
		margin-right: 0 !important;
	}
	.rtl .mx-sm-1 {
		margin-right: auto;
		margin-left: 0.25rem !important;
	}
	.rtl .mx-sm-1 {
		margin-left: auto;
		margin-right: 0.25rem !important;
	}
	.rtl .mx-sm-2 {
		margin-right: auto;
		margin-left: 0.5rem !important;
	}
	.rtl .mx-sm-2 {
		margin-left: auto;
		margin-right: 0.5rem !important;
	}
	.rtl .mx-sm-3 {
		margin-right: auto;
		margin-left: 1rem !important;
	}
	.rtl .mx-sm-3 {
		margin-left: auto;
		margin-right: 1rem !important;
	}
	.rtl .mx-sm-4 {
		margin-right: auto;
		margin-left: 1.5rem !important;
	}
	.rtl .mx-sm-4 {
		margin-left: auto;
		margin-right: 1.5rem !important;
	}
	.rtl .mx-sm-5 {
		margin-right: auto;
		margin-left: 3rem !important;
	}
	.rtl .mx-sm-5 {
		margin-left: auto;
		margin-right: 3rem !important;
	}
	.rtl .px-sm-0 {
		padding-right: auto;
		padding-left: 0 !important;
	}
	.rtl .px-sm-0 {
		padding-left: auto;
		padding-right: 0 !important;
	}
	.rtl .px-sm-1 {
		padding-right: auto;
		padding-left: 0.25rem !important;
	}
	.rtl .px-sm-1 {
		padding-left: auto;
		padding-right: 0.25rem !important;
	}
	.rtl .px-sm-2 {
		padding-right: auto;
		padding-left: 0.5rem !important;
	}
	.rtl .px-sm-2 {
		padding-left: auto;
		padding-right: 0.5rem !important;
	}
	.rtl .px-sm-3 {
		padding-right: auto;
		padding-left: 1rem !important;
	}
	.rtl .px-sm-3 {
		padding-left: auto;
		padding-right: 1rem !important;
	}
	.rtl .px-sm-4 {
		padding-right: auto;
		padding-left: 1.5rem !important;
	}
	.rtl .px-sm-4 {
		padding-left: auto;
		padding-right: 1.5rem !important;
	}
	.rtl .px-sm-5 {
		padding-right: auto;
		padding-left: 3rem !important;
	}
	.rtl .px-sm-5 {
		padding-left: auto;
		padding-right: 3rem !important;
	}
	.rtl .mr-sm-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.rtl .ml-sm-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 768px) {
	.rtl .mx-md-0 {
		margin-right: auto;
		margin-left: 0 !important;
	}
	.rtl .mx-md-0 {
		margin-left: auto;
		margin-right: 0 !important;
	}
	.rtl .mx-md-1 {
		margin-right: auto;
		margin-left: 0.25rem !important;
	}
	.rtl .mx-md-1 {
		margin-left: auto;
		margin-right: 0.25rem !important;
	}
	.rtl .mx-md-2 {
		margin-right: auto;
		margin-left: 0.5rem !important;
	}
	.rtl .mx-md-2 {
		margin-left: auto;
		margin-right: 0.5rem !important;
	}
	.rtl .mx-md-3 {
		margin-right: auto;
		margin-left: 1rem !important;
	}
	.rtl .mx-md-3 {
		margin-left: auto;
		margin-right: 1rem !important;
	}
	.rtl .mx-md-4 {
		margin-right: auto;
		margin-left: 1.5rem !important;
	}
	.rtl .mx-md-4 {
		margin-left: auto;
		margin-right: 1.5rem !important;
	}
	.rtl .mx-md-5 {
		margin-right: auto;
		margin-left: 3rem !important;
	}
	.rtl .mx-md-5 {
		margin-left: auto;
		margin-right: 3rem !important;
	}
	.rtl .px-md-0 {
		padding-right: auto;
		padding-left: 0 !important;
	}
	.rtl .px-md-0 {
		padding-left: auto;
		padding-right: 0 !important;
	}
	.rtl .px-md-1 {
		padding-right: auto;
		padding-left: 0.25rem !important;
	}
	.rtl .px-md-1 {
		padding-left: auto;
		padding-right: 0.25rem !important;
	}
	.rtl .px-md-2 {
		padding-right: auto;
		padding-left: 0.5rem !important;
	}
	.rtl .px-md-2 {
		padding-left: auto;
		padding-right: 0.5rem !important;
	}
	.rtl .px-md-3 {
		padding-right: auto;
		padding-left: 1rem !important;
	}
	.rtl .px-md-3 {
		padding-left: auto;
		padding-right: 1rem !important;
	}
	.rtl .px-md-4 {
		padding-right: auto;
		padding-left: 1.5rem !important;
	}
	.rtl .px-md-4 {
		padding-left: auto;
		padding-right: 1.5rem !important;
	}
	.rtl .px-md-5 {
		padding-right: auto;
		padding-left: 3rem !important;
	}
	.rtl .px-md-5 {
		padding-left: auto;
		padding-right: 3rem !important;
	}
	.rtl .mr-md-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.rtl .ml-md-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 992px) {
	.rtl .mx-lg-0 {
		margin-right: auto;
		margin-left: 0 !important;
	}
	.rtl .mx-lg-0 {
		margin-left: auto;
		margin-right: 0 !important;
	}
	.rtl .mx-lg-1 {
		margin-right: auto;
		margin-left: 0.25rem !important;
	}
	.rtl .mx-lg-1 {
		margin-left: auto;
		margin-right: 0.25rem !important;
	}
	.rtl .mx-lg-2 {
		margin-right: auto;
		margin-left: 0.5rem !important;
	}
	.rtl .mx-lg-2 {
		margin-left: auto;
		margin-right: 0.5rem !important;
	}
	.rtl .mx-lg-3 {
		margin-right: auto;
		margin-left: 1rem !important;
	}
	.rtl .mx-lg-3 {
		margin-left: auto;
		margin-right: 1rem !important;
	}
	.rtl .mx-lg-4 {
		margin-right: auto;
		margin-left: 1.5rem !important;
	}
	.rtl .mx-lg-4 {
		margin-left: auto;
		margin-right: 1.5rem !important;
	}
	.rtl .mx-lg-5 {
		margin-right: auto;
		margin-left: 3rem !important;
	}
	.rtl .mx-lg-5 {
		margin-left: auto;
		margin-right: 3rem !important;
	}
	.rtl .px-lg-0 {
		padding-right: auto;
		padding-left: 0 !important;
	}
	.rtl .px-lg-0 {
		padding-left: auto;
		padding-right: 0 !important;
	}
	.rtl .px-lg-1 {
		padding-right: auto;
		padding-left: 0.25rem !important;
	}
	.rtl .px-lg-1 {
		padding-left: auto;
		padding-right: 0.25rem !important;
	}
	.rtl .px-lg-2 {
		padding-right: auto;
		padding-left: 0.5rem !important;
	}
	.rtl .px-lg-2 {
		padding-left: auto;
		padding-right: 0.5rem !important;
	}
	.rtl .px-lg-3 {
		padding-right: auto;
		padding-left: 1rem !important;
	}
	.rtl .px-lg-3 {
		padding-left: auto;
		padding-right: 1rem !important;
	}
	.rtl .px-lg-4 {
		padding-right: auto;
		padding-left: 1.5rem !important;
	}
	.rtl .px-lg-4 {
		padding-left: auto;
		padding-right: 1.5rem !important;
	}
	.rtl .px-lg-5 {
		padding-right: auto;
		padding-left: 3rem !important;
	}
	.rtl .px-lg-5 {
		padding-left: auto;
		padding-right: 3rem !important;
	}
	.rtl .mr-lg-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.rtl .ml-lg-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 1200px) {
	.rtl .mx-xl-0 {
		margin-right: auto;
		margin-left: 0 !important;
	}
	.rtl .mx-xl-0 {
		margin-left: auto;
		margin-right: 0 !important;
	}
	.rtl .mx-xl-1 {
		margin-right: auto;
		margin-left: 0.25rem !important;
	}
	.rtl .mx-xl-1 {
		margin-left: auto;
		margin-right: 0.25rem !important;
	}
	.rtl .mx-xl-2 {
		margin-right: auto;
		margin-left: 0.5rem !important;
	}
	.rtl .mx-xl-2 {
		margin-left: auto;
		margin-right: 0.5rem !important;
	}
	.rtl .mx-xl-3 {
		margin-right: auto;
		margin-left: 1rem !important;
	}
	.rtl .mx-xl-3 {
		margin-left: auto;
		margin-right: 1rem !important;
	}
	.rtl .mx-xl-4 {
		margin-right: auto;
		margin-left: 1.5rem !important;
	}
	.rtl .mx-xl-4 {
		margin-left: auto;
		margin-right: 1.5rem !important;
	}
	.rtl .mx-xl-5 {
		margin-right: auto;
		margin-left: 3rem !important;
	}
	.rtl .mx-xl-5 {
		margin-left: auto;
		margin-right: 3rem !important;
	}
	.rtl .px-xl-0 {
		padding-right: auto;
		padding-left: 0 !important;
	}
	.rtl .px-xl-0 {
		padding-left: auto;
		padding-right: 0 !important;
	}
	.rtl .px-xl-1 {
		padding-right: auto;
		padding-left: 0.25rem !important;
	}
	.rtl .px-xl-1 {
		padding-left: auto;
		padding-right: 0.25rem !important;
	}
	.rtl .px-xl-2 {
		padding-right: auto;
		padding-left: 0.5rem !important;
	}
	.rtl .px-xl-2 {
		padding-left: auto;
		padding-right: 0.5rem !important;
	}
	.rtl .px-xl-3 {
		padding-right: auto;
		padding-left: 1rem !important;
	}
	.rtl .px-xl-3 {
		padding-left: auto;
		padding-right: 1rem !important;
	}
	.rtl .px-xl-4 {
		padding-right: auto;
		padding-left: 1.5rem !important;
	}
	.rtl .px-xl-4 {
		padding-left: auto;
		padding-right: 1.5rem !important;
	}
	.rtl .px-xl-5 {
		padding-right: auto;
		padding-left: 3rem !important;
	}
	.rtl .px-xl-5 {
		padding-left: auto;
		padding-right: 3rem !important;
	}
	.rtl .mr-xl-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.rtl .ml-xl-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 1440) {
	.rtl .mx-xxl-0 {
		margin-right: auto;
		margin-left: 0 !important;
	}
	.rtl .mx-xxl-0 {
		margin-left: auto;
		margin-right: 0 !important;
	}
	.rtl .mx-xxl-1 {
		margin-right: auto;
		margin-left: 0.25rem !important;
	}
	.rtl .mx-xxl-1 {
		margin-left: auto;
		margin-right: 0.25rem !important;
	}
	.rtl .mx-xxl-2 {
		margin-right: auto;
		margin-left: 0.5rem !important;
	}
	.rtl .mx-xxl-2 {
		margin-left: auto;
		margin-right: 0.5rem !important;
	}
	.rtl .mx-xxl-3 {
		margin-right: auto;
		margin-left: 1rem !important;
	}
	.rtl .mx-xxl-3 {
		margin-left: auto;
		margin-right: 1rem !important;
	}
	.rtl .mx-xxl-4 {
		margin-right: auto;
		margin-left: 1.5rem !important;
	}
	.rtl .mx-xxl-4 {
		margin-left: auto;
		margin-right: 1.5rem !important;
	}
	.rtl .mx-xxl-5 {
		margin-right: auto;
		margin-left: 3rem !important;
	}
	.rtl .mx-xxl-5 {
		margin-left: auto;
		margin-right: 3rem !important;
	}
	.rtl .px-xxl-0 {
		padding-right: auto;
		padding-left: 0 !important;
	}
	.rtl .px-xxl-0 {
		padding-left: auto;
		padding-right: 0 !important;
	}
	.rtl .px-xxl-1 {
		padding-right: auto;
		padding-left: 0.25rem !important;
	}
	.rtl .px-xxl-1 {
		padding-left: auto;
		padding-right: 0.25rem !important;
	}
	.rtl .px-xxl-2 {
		padding-right: auto;
		padding-left: 0.5rem !important;
	}
	.rtl .px-xxl-2 {
		padding-left: auto;
		padding-right: 0.5rem !important;
	}
	.rtl .px-xxl-3 {
		padding-right: auto;
		padding-left: 1rem !important;
	}
	.rtl .px-xxl-3 {
		padding-left: auto;
		padding-right: 1rem !important;
	}
	.rtl .px-xxl-4 {
		padding-right: auto;
		padding-left: 1.5rem !important;
	}
	.rtl .px-xxl-4 {
		padding-left: auto;
		padding-right: 1.5rem !important;
	}
	.rtl .px-xxl-5 {
		padding-right: auto;
		padding-left: 3rem !important;
	}
	.rtl .px-xxl-5 {
		padding-left: auto;
		padding-right: 3rem !important;
	}
	.rtl .mr-xxl-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.rtl .ml-xxl-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

.rtl .float-left {
	float: right !important;
}

.rtl .float-right {
	float: left !important;
}

.rtl .float-none {
	float: none !important;
}

@media (min-width: 576px) {
	.rtl .float-sm-left {
		float: right !important;
	}
	.rtl .float-sm-right {
		float: left !important;
	}
	.rtl .float-sm-none {
		float: none !important;
	}
}

@media (min-width: 768px) {
	.rtl .float-md-left {
		float: right !important;
	}
	.rtl .float-md-right {
		float: left !important;
	}
	.rtl .float-md-none {
		float: none !important;
	}
}

@media (min-width: 992px) {
	.rtl .float-lg-left {
		float: right !important;
	}
	.rtl .float-lg-right {
		float: left !important;
	}
	.rtl .float-lg-none {
		float: none !important;
	}
}

@media (min-width: 1200px) {
	.rtl .float-xl-left {
		float: right !important;
	}
	.rtl .float-xl-right {
		float: left !important;
	}
	.rtl .float-xl-none {
		float: none !important;
	}
}

@media (min-width: 1440) {
	.rtl .float-xxl-left {
		float: right !important;
	}
	.rtl .float-xxl-right {
		float: left !important;
	}
	.rtl .float-xxl-none {
		float: none !important;
	}
}

[direction="rtl"] .text-right {
	text-align: left !important;
}

[direction="rtl"] .text-left {
	text-align: right !important;
}

[direction="rtl"] .border-right {
	border-left: 1px solid #f0f1f5 !important;
	border-right: 0 !important;
}

[direction="rtl"] .border-left {
	border-right: 1px solid #f0f1f5 !important;
	border-left: 0 !important;
}

[direction="rtl"] .dropdown-menu {
	left: auto;
}

[direction="rtl"] .dropdown-menu-right {
	left: 0;
	right: auto;
}

@media only screen and (max-width: 575px) {
	[direction="rtl"] .dropdown-menu-right {
		left: 15px;
	}
}

[direction="rtl"] .notification_dropdown .dropdown-menu-right .media>span {
	margin-left: 10px;
	margin-right: 0;
}

[direction="rtl"] .nav-control {
	right: auto;
	left: -4.0625rem;
}

@media (max-width: 767.98px) {
	[direction="rtl"] .nav-control {
		left: -4.0625rem;
	}
}

@media (max-width: 575.98px) {
	[direction="rtl"] .nav-control {
		left: -4.0625rem;
	}
}



[direction="rtl"] .header {
	padding: 0 0.9375rem;
	padding-right: 17.1875rem;
}

@media (max-width: 767.98px) {
	[direction="rtl"] .header {
		padding-right: 3.75rem;
		padding-left: 0;
	}
}

[direction="rtl"] .header .header-content {
	padding-left: 0;
	padding-right: 5.3125rem;
}

@media only screen and (max-width: 575px) {
	[direction="rtl"] .header .header-content {
		padding-right: 4.5rem;
	}
}

[data-sidebar-style="compact"][direction="rtl"] .header .header-content {
	padding-right: 1.875rem;
}

[data-sidebar-style="modern"][direction="rtl"] .header .header-content {
	padding-right: 5.3125rem;
}

[data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
	padding-right: 5.3125rem;
}

[direction="rtl"] .header .nav-control {
	right: 0.4375rem;
	left: auto;
}

[direction="rtl"] .header-left .search_bar .dropdown-menu,
[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
	right: 40px !important;
}

@media only screen and (max-width: 575px) {
	[direction="rtl"] .header-left .search_bar .dropdown-menu,
	[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
		right: -100px !important;
	}
}

[direction="rtl"] .header-left .search_bar .search_icon {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5rem;
	border-bottom-right-radius: 5rem;
}

@media only screen and (max-width: 575px) {
	[direction="rtl"] .header-left .search_bar .search_icon {
		border-radius: 5rem;
	}
}


.alert {
	border-radius: 0.875rem;
	padding: 1rem 1.5rem;
}

.alert p {
	line-height: 1.5;
}

.alert-rounded {
	border-radius: 30px;
}

.alert-primary {
	background: #ddf7c7;
	border-color: #ddf7c7;
	color: #25AFA8;
}

.alert-secondary {
	background: #f8ebfa;
	border-color: #f8ebfa;
	color: #C046D3;
}

.alert-success {
	background: #d5f5f1;
	border-color: #d5f5f1;
	color: #37D1BF;
}

.alert-warning {
	background: #fff9f4;
	border-color: #fff9f4;
	color: #FF9432;
}

.alert-danger {
	background: #ffeaef;
	border-color: #ffeaef;
	color: #FF285C;
}

.alert-info {
	background: #d9f4f9;
	border-color: #d9f4f9;
	color: #1EA7C5;
}

.alert-dark {
	background: #eeeeee;
	border-color: #eeeeee;
	color: #6e6e6e;
}

.alert-light {
	background: #E8E8E8;
	border-color: #E8E8E8;
	color: #6e6e6e;
}

.alert-alt.alert-primary {
	border-left: 4px solid #25AFA8;
}

.alert-alt.alert-secondary {
	border-left: 4px solid #C046D3;
}

.alert-alt.alert-success {
	border-left: 4px solid #37D1BF;
}

.alert-alt.alert-warning {
	border-left: 4px solid #FF9432;
}

.alert-alt.alert-danger {
	border-left: 4px solid #FF285C;
}

.alert-alt.alert-info {
	border-left: 4px solid #1EA7C5;
}

.alert-alt.alert-dark {
	border-left: 4px solid #6e6e6e;
}

.alert-alt.alert-light {
	border-left: 4px solid #c2c2c2;
}

.alert-alt.alert-primary.solid {
	border-left: 4px solid #2f560d !important;
}

.alert-alt.alert-secondary.solid {
	border-left: 4px solid #6f1d7c !important;
}

.alert-alt.alert-success.solid {
	border-left: 4px solid #1a6f65 !important;
}

.alert-alt.alert-warning.solid {
	border-left: 4px solid #b25500 !important;
}

.alert-alt.alert-danger.solid {
	border-left: 4px solid #a80029 !important;
}

.alert-alt.alert-info.solid {
	border-left: 4px solid #0d4956 !important;
}

.alert-alt.alert-dark.solid {
	border-left: 4px solid #2e2e2e !important;
}

.alert-alt.alert-light.solid {
	border-left: 4px solid #a8a8a8 !important;
}

.alert-dismissible.solid .close:hover {
	color: #fff;
	opacity: 1;
}

.alert.alert-primary.solid {
	background: #25AFA8;
	color: #fff;
	border-color: #25AFA8;
}

.alert.alert-secondary.solid {
	background: #C046D3;
	color: #fff;
	border-color: #C046D3;
}

.alert.alert-success.solid {
	background: #37D1BF;
	color: #fff;
	border-color: #37D1BF;
}

.alert.alert-warning.solid {
	background: #FF9432;
	color: #fff;
	border-color: #FF9432;
}

.alert.alert-danger.solid {
	background: #FF285C;
	color: #fff;
	border-color: #FF285C;
}

.alert.alert-info.solid {
	background: #1EA7C5;
	color: #fff;
	border-color: #1EA7C5;
}

.alert.alert-dark.solid {
	background: #6e6e6e;
	color: #fff;
	border-color: #6e6e6e;
}

.alert.alert-light.solid {
	background: #E8E8E8;
	color: #6e6e6e;
	border-color: #E8E8E8;
}

.alert-right-icon>span i {
	font-size: 18px;
	margin-right: 5px;
}

.alert-right-icon .close i {
	font-size: 16px;
}

.alert.alert-outline-primary {
	background: transparent;
	color: #25AFA8;
	border-color: #25AFA8;
}

.alert.alert-outline-secondary {
	background: transparent;
	color: #7e7e7e;
	border-color: #C046D3;
}

.alert.alert-outline-success {
	background: transparent;
	color: #37D1BF;
	border-color: #37D1BF;
}

.alert.alert-outline-info {
	background: transparent;
	color: #1EA7C5;
	border-color: #1EA7C5;
}

.alert.alert-outline-warning {
	background: transparent;
	color: #FF9432;
	border-color: #FF9432;
}

.alert.alert-outline-danger {
	background: transparent;
	color: #FF285C;
	border-color: #FF285C;
}

.alert.alert-outline-dark {
	background: transparent;
	color: #7e7e7e;
	border-color: #6e6e6e;
}

.alert.alert-outline-light {
	background: transparent;
	color: #6e6e6e;
	border-color: #E8E8E8;
}


.badge {
	line-height: 1.5;
	border-radius: 0.875rem;
	padding: 4px 10px;
	border: 1px solid transparent;
}

.badge-rounded {
	border-radius: 20px;
	padding: 3px 13px;
}

.badge-circle {
	border-radius: 100px;
	padding: 3px 7px;
}

.badge-outline-primary {
	border: 1px solid #25AFA8;
	color: #25AFA8;
}

.badge-outline-secondary {
	border: 1px solid #C046D3;
	color: #C046D3;
}

.badge-outline-success {
	border: 1px solid #37D1BF;
	color: #37D1BF;
}

.badge-outline-info {
	border: 1px solid #1EA7C5;
	color: #1EA7C5;
}

.badge-outline-warning {
	border: 1px solid #FF9432;
	color: #FF9432;
}

.badge-outline-danger {
	border: 1px solid #FF285C;
	color: #FF285C;
}

.badge-outline-light {
	border: 1px solid #f0f1f5;
	color: #6e6e6e;
}

.badge-outline-dark {
	border: 1px solid #6e6e6e;
	color: #6e6e6e;
}

.badge-xs {
	font-size: 10px;
	padding: 0px 5px;
	line-height: 18px;
}

.badge-sm {
	font-size: 11px;
	padding: 5px 8px;
	line-height: 11px;
}

.badge-lg {
	font-size: 14px;
	padding: 0px 10px;
	line-height: 30px;
}

.badge-xl {
	font-size: 15px;
	padding: 0px 15px;
	line-height: 35px;
}

.badge-default {
	background: #ADB6C7;
}

.badge-success {
	background-color: #37D1BF;
}

.badge-info {
	background-color: #1EA7C5;
}

.badge-primary {
	background-color: #25AFA8;
}

.badge-warning {
	background-color: #FF9432;
}

.badge-danger {
	background-color: #FF285C;
}

.badge-dark {
	background-color: #6e6e6e;
}

.badge-light {
	background-color: #E8E8E8;
}

.light.badge-default {
	background: #ADB6C7;
}

.light.badge-success {
	background-color: #d5f5f1;
	color: #37D1BF;
}

.light.badge-info {
	background-color: #d9f4f9;
	color: #1EA7C5;
}

.light.badge-primary {
	background-color: #ddf7c7;
	color: #25AFA8;
}

.light.badge-secondary {
	background-color: #f8ebfa;
	color: #C046D3;
}

.light.badge-warning {
	background-color: #fff9f4;
	color: #FF9432;
}

.light.badge-danger {
	background-color: #ffeaef;
	color: #FF285C;
}

.light.badge-dark {
	background-color: #eeeeee;
	color: #6e6e6e;
}

.bootstrap-label .label {
	display: inline-block;
	margin-right: 1rem;
}

.bootstrap-label .label:last-child {
	margin-right: 0;
}

.badge-demo .badge {
	margin-right: 5px;
	margin-bottom: 5px;
}


.light.tp-btn {
	background-color: transparent;
}

.light.btn-default {
	background: #ADB6C7;
}

.light.btn-success {
	background-color: #d5f5f1;
	border-color: #d5f5f1;
	color: #37D1BF;
}

.light.btn-success g [fill] {
	fill: #37D1BF;
}

.light.btn-success:hover {
	background-color: #37D1BF;
	border-color: #37D1BF;
	color: #fff;
}

.light.btn-success:hover g [fill] {
	fill: #fff;
}

.light.btn-info {
	background-color: #d9f4f9;
	border-color: #d9f4f9;
	color: #1EA7C5;
}

.light.btn-info g [fill] {
	fill: #1EA7C5;
}

.light.btn-info:hover {
	background-color: #1EA7C5;
	border-color: #1EA7C5;
	color: #fff;
}

.light.btn-info:hover g [fill] {
	fill: #fff;
}

.light.btn-primary {
	background-color: #ddf7c7;
	border-color: #ddf7c7;
	color: #25AFA8;
}

.light.btn-primary g [fill] {
	fill: #25AFA8;
}

.light.btn-primary:hover {
	background-color: #25AFA8;
	border-color: #25AFA8;
	color: #fff;
}

.light.btn-primary:hover g [fill] {
	fill: #fff;
}

.light.btn-primary:hover svg path {
	fill: #fff;
}

.light.btn-secondary {
	background-color: #f8ebfa;
	border-color: #f8ebfa;
	color: #C046D3;
}

.light.btn-secondary g [fill] {
	fill: #C046D3;
}

.light.btn-secondary:hover {
	background-color: #C046D3;
	border-color: #C046D3;
	color: #fff;
}

.light.btn-secondary:hover g [fill] {
	fill: #fff;
}

.light.btn-warning {
	background-color: #fff9f4;
	border-color: #fff9f4;
	color: #FF9432;
}

.light.btn-warning g [fill] {
	fill: #FF9432;
}

.light.btn-warning:hover {
	background-color: #FF9432;
	border-color: #FF9432;
	color: #fff;
}

.light.btn-warning:hover g [fill] {
	fill: #fff;
}

.light.btn-danger {
	background-color: #ffeaef;
	border-color: #ffeaef;
	color: #FF285C;
}

.light.btn-danger g [fill] {
	fill: #FF285C;
}

.light.btn-danger:hover {
	background-color: #FF285C;
	border-color: #FF285C;
	color: #fff;
}

.light.btn-danger:hover g [fill] {
	fill: #fff;
}

.light.btn-dark {
	background-color: #eeeeee;
	border-color: #eeeeee;
	color: #6e6e6e;
}

.light.btn-dark g [fill] {
	fill: #6e6e6e;
}

.light.btn-dark:hover {
	background-color: #6e6e6e;
	border-color: #6e6e6e;
	color: #fff;
}

.light.btn-dark:hover g [fill] {
	fill: #fff;
}

.btn.tp-btn {
	background-color: transparent;
	border-color: transparent;
}

.btn.tp-btn.btn-default {
	background: #ADB6C7;
}

.btn.tp-btn.btn-success {
	color: #37D1BF;
}

.btn.tp-btn.btn-success g [fill] {
	fill: #37D1BF;
}

.btn.tp-btn.btn-success:hover {
	background-color: #37D1BF;
	border-color: #37D1BF;
	color: #fff;
}

.btn.tp-btn.btn-success:hover g [fill] {
	fill: #fff;
}

.btn.tp-btn.btn-info {
	color: #1EA7C5;
}

.btn.tp-btn.btn-info g [fill] {
	fill: #1EA7C5;
}

.btn.tp-btn.btn-info:hover {
	background-color: #1EA7C5;
	border-color: #1EA7C5;
	color: #fff;
}

.btn.tp-btn.btn-info:hover g [fill] {
	fill: #fff;
}

.btn.tp-btn.btn-primary {
	color: #25AFA8;
}

.btn.tp-btn.btn-primary g [fill] {
	fill: #25AFA8;
}

.btn.tp-btn.btn-primary:hover {
	background-color: #25AFA8;
	border-color: #25AFA8;
	color: #fff;
}

.btn.tp-btn.btn-primary:hover g [fill] {
	fill: #fff;
}

.btn.tp-btn.btn-secondary {
	color: #C046D3;
}

.btn.tp-btn.btn-secondary g [fill] {
	fill: #C046D3;
}

.btn.tp-btn.btn-secondary:hover {
	background-color: #C046D3;
	border-color: #C046D3;
	color: #fff;
}

.btn.tp-btn.btn-secondary:hover g [fill] {
	fill: #fff;
}

.btn.tp-btn.btn-warning {
	color: #FF9432;
}

.btn.tp-btn.btn-warning g [fill] {
	fill: #FF9432;
}

.btn.tp-btn.btn-warning:hover {
	background-color: #FF9432;
	border-color: #FF9432;
	color: #fff;
}

.btn.tp-btn.btn-warning:hover g [fill] {
	fill: #fff;
}

.btn.tp-btn.btn-danger {
	color: #FF285C;
}

.btn.tp-btn.btn-danger g [fill] {
	fill: #FF285C;
}

.btn.tp-btn.btn-danger:hover {
	background-color: #FF285C;
	border-color: #FF285C;
	color: #fff;
}

.btn.tp-btn.btn-danger:hover g [fill] {
	fill: #fff;
}

.btn.tp-btn.btn-light {
	color: #6e6e6e;
}

.btn.tp-btn.btn-light g [fill] {
	fill: #6e6e6e;
}

.btn.tp-btn.btn-light:hover {
	background-color: #E8E8E8;
	border-color: #E8E8E8;
	color: #6e6e6e;
}

.btn.tp-btn.btn-light:hover g [fill] {
	fill: #fff;
}

.btn.tp-btn.btn-dark {
	color: #6e6e6e;
}

.btn.tp-btn.btn-dark g [fill] {
	fill: #6e6e6e;
}

.btn.tp-btn.btn-dark:hover {
	background-color: #6e6e6e;
	border-color: #6e6e6e;
	color: #fff;
}

.btn.tp-btn.btn-dark:hover g [fill] {
	fill: #fff;
}

.btn.tp-btn-light {
	background-color: transparent;
	border-color: transparent;
}

.btn.tp-btn-light.btn-success {
	color: #37D1BF;
}

.btn.tp-btn-light.btn-success g [fill] {
	fill: #37D1BF;
}

.btn.tp-btn-light.btn-success:hover {
	background-color: #d5f5f1;
	border-color: #d5f5f1;
	color: #37D1BF;
}

.btn.tp-btn-light.btn-success:hover g [fill] {
	fill: #37D1BF;
}

.btn.tp-btn-light.btn-info {
	color: #1EA7C5;
}

.btn.tp-btn-light.btn-info g [fill] {
	fill: #1EA7C5;
}

.btn.tp-btn-light.btn-info:hover {
	background-color: #d9f4f9;
	border-color: #d9f4f9;
	color: #1EA7C5;
}

.btn.tp-btn-light.btn-info:hover g [fill] {
	fill: #1EA7C5;
}

.btn.tp-btn-light.btn-primary {
	color: #25AFA8;
}

.btn.tp-btn-light.btn-primary g [fill] {
	fill: #25AFA8;
}

.btn.tp-btn-light.btn-primary:hover {
	background-color: #ddf7c7;
	border-color: #ddf7c7;
	color: #25AFA8;
}

.btn.tp-btn-light.btn-primary:hover g [fill] {
	fill: #25AFA8;
}

.btn.tp-btn-light.btn-secondary {
	color: #C046D3;
}

.btn.tp-btn-light.btn-secondary g [fill] {
	fill: #C046D3;
}

.btn.tp-btn-light.btn-secondary:hover {
	background-color: #f8ebfa;
	border-color: #f8ebfa;
	color: #C046D3;
}

.btn.tp-btn-light.btn-secondary:hover g [fill] {
	fill: #C046D3;
}

.btn.tp-btn-light.btn-warning {
	color: #FF9432;
}

.btn.tp-btn-light.btn-warning g [fill] {
	fill: #FF9432;
}

.btn.tp-btn-light.btn-warning:hover {
	background-color: #fff9f4;
	border-color: #fff9f4;
	color: #FF9432;
}

.btn.tp-btn-light.btn-warning:hover g [fill] {
	fill: #FF9432;
}

.btn.tp-btn-light.btn-danger {
	color: #FF285C;
}

.btn.tp-btn-light.btn-danger g [fill] {
	fill: #FF285C;
}

.btn.tp-btn-light.btn-danger:hover {
	background-color: #ffeaef;
	border-color: #ffeaef;
	color: #FF285C;
}

.btn.tp-btn-light.btn-danger:hover g [fill] {
	fill: #fff;
}

.btn.tp-btn-light.btn-dark {
	color: #6e6e6e;
}

.btn.tp-btn-light.btn-dark g [fill] {
	fill: #6e6e6e;
}

.btn.tp-btn-light.btn-dark:hover {
	background-color: #eeeeee;
	border-color: #eeeeee;
	color: #6e6e6e;
}

.btn.tp-btn-light.btn-dark:hover [fill] {
	fill: #fff;
}

.shadow.btn-primary {
	-webkit-box-shadow: 0 5px 15px 0 rgba(108, 197, 29, 0.2) !important;
	box-shadow: 0 5px 15px 0 rgba(108, 197, 29, 0.2) !important;
}

.shadow.btn-secondary {
	-webkit-box-shadow: 0 5px 15px 0 rgba(192, 70, 211, 0.2) !important;
	box-shadow: 0 5px 15px 0 rgba(192, 70, 211, 0.2) !important;
}

.shadow.btn-warning {
	-webkit-box-shadow: 0 5px 15px 0 rgba(255, 148, 50, 0.2) !important;
	box-shadow: 0 5px 15px 0 rgba(255, 148, 50, 0.2) !important;
}

.shadow.btn-danger {
	-webkit-box-shadow: 0 5px 15px 0 rgba(255, 40, 92, 0.2) !important;
	box-shadow: 0 5px 15px 0 rgba(255, 40, 92, 0.2) !important;
}

.shadow.btn-info {
	-webkit-box-shadow: 0 5px 15px 0 rgba(30, 167, 197, 0.2) !important;
	box-shadow: 0 5px 15px 0 rgba(30, 167, 197, 0.2) !important;
}

.shadow.btn-success {
	-webkit-box-shadow: 0 5px 15px 0 rgba(55, 209, 191, 0.2) !important;
	box-shadow: 0 5px 15px 0 rgba(55, 209, 191, 0.2) !important;
}

.btn-xxs {
	padding: 6px 15px;
	font-size: 11px;
	line-height: 1.3;
}

.btn-xs {
	font-size: 0.75rem;
	padding: 0.438rem 1rem;
	font-weight: 600;
}

@media only screen and (max-width: 1400px) {
	.btn-xs {
		font-size: 0.75rem;
		padding: 0.25rem 0.5rem;
		font-weight: 500;
	}
}

.btn-sm,
.btn-group-sm>.btn {
	font-size: 0.813rem !important;
	padding: 0.625rem 1rem;
}

@media only screen and (max-width: 1400px) {
	.btn-sm,
	.btn-group-sm>.btn {
		padding: 0.375rem 1rem;
		font-size: 0.75rem;
	}
}

.btn-md {
	font-size: 0.813rem !important;
	padding: 0.875rem 1.25rem;
}

@media only screen and (max-width: 1400px) {
	.btn-md {
		padding: 0.5rem 1rem;
		font-size: 0.813rem;
	}
}

.btn-lg,
.btn-group-lg>.btn {
	padding: 1rem 2rem;
	font-size: 1.125rem !important;
}

@media only screen and (max-width: 1400px) {
	.btn-lg,
	.btn-group-lg>.btn {
		padding: 0.75rem 1.5rem;
		font-size: 1rem !important;
	}
}

.btn-xl {
	padding: 0.6rem 1rem;
}

.btn-xl.btn-default {
	font-weight: 600;
}

.btn-square {
	border-radius: 0;
}

.btn-rounded {
	height: 3rem;
	width: 3rem;
	border-radius: 50%;
}

@media only screen and (min-width: 10px) and (max-width: 400px) {
	.btn-rounded {
		margin: 1rem 0rem 0rem 0rem;
	}
	.btn-more {
		margin-top: 1.3rem;
		padding-right: 1.5rem;
	}
}

@media only screen and (min-width: 400px) and (max-width: 700px) {
	.btn-rounded {
		margin: 2rem 0rem 0rem 0rem;
	}
	.btn-more {
		margin-top: 2rem;
	}
}

@media only screen and (min-width: 700px) and (max-width: 2500px) {
	.btn-rounded {
		margin: 0.2rem 0rem 0rem 0rem;
	}
	.btn-more {
		margin-top: 0.6rem;
		margin-left: 0.7rem;
	}
}


.toggle-dropdown::after {
	margin-left: 0.755em;
}

.social-btn-icon .btn {
	min-width: 7.5rem;
	margin-bottom: 1.5rem;
}

.social-icon .btn {
	padding: .7rem 1.4rem;
}

.btn-circle {
	height: 5rem;
	width: 5rem;
	border-radius: 50% !important;
}

.btn-circle-sm {
	width: 4.5rem;
	height: 4.5rem;
	font-size: 1.8rem;
}

.btn-circle-md {
	width: 6rem;
	height: 6rem;
	font-size: 2.5rem;
}

.btn-circle-md i {
	font-size: 2.4rem;
}

.btn-circle-lg {
	width: 8rem;
	height: 8rem;
	font-size: 3.2rem;
}

.btn-circle-lg i {
	font-size: 3.1rem;
}

.btn-page .btn {
	min-width: 110px;
	margin-right: 4px;
	margin-bottom: 8px;
}

.size-1 {
	min-width: 160px !important;
	font-size: 24px;
	padding: 0.68rem 0.75rem;
}

.size-2 {
	font-size: 20px;
	min-width: 130px !important;
	padding: 0.57rem 0.75rem;
}

.size-3 {
	font-size: 14px;
	min-width: 110px !important;
	padding: 0.536rem 0.75rem;
}

.size-4 {
	font-size: 14px;
	min-width: 100px !important;
}

.size-5 {
	font-size: 14px;
	min-width: 90px !important;
	padding: .22rem 0.75rem;
}

.size-6 {
	font-size: 13px;
	min-width: 80px !important;
	padding: 0.097rem 0.75rem;
}

.size-7 {
	font-size: 12px;
	min-width: 60px !important;
	padding: 0.001rem 0.75rem;
}

.btn-light {
	background: #E8E8E8;
	border-color: #E8E8E8;
}

.btn-light:active,
.btn-light:focus,
.btn-light:hover {
	background: white;
	border-color: white;
}

.btn-outline-primary:hover {
	color: #fff;
}

.btn-outline-light {
	color: #6e6e6e;
}

.btn-dark {
	background: #6e6e6e;
	border-color: #6e6e6e;
	color: #fff;
}

.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
	background: #555555;
	color: #fff;
	border-color: #555555;
}

.btn-group.btn-rounded .btn:first-child {
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
}

.btn-group.btn-rounded .btn:last-child {
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;
}

.card {
	/* margin-bottom: 1.875rem; */
	margin-bottom: 1.3rem;
	background-color: #fff;
	transition: all .5s ease-in-out;
	position: relative;
	border: 0px solid transparent;
	border-radius: 0.875rem;
	box-shadow: 0px 12px 23px 0px rgba(192, 70, 211, 0.04);
	height: calc(100% - 30px);
}


.card-login{
	box-shadow:none!important;
}

.card-special {
	height: calc(100% - 120px) !important;
}

.webmonitor-login{
	flex: 0 0 33.3333333333%;
max-width: 33.3333333333%;
}
@media only screen and (max-width: 575px) {
	.card {
		margin-bottom: 0.938rem;
		height: calc(100% - 0.938rem);
	}


}

@media only screen and (max-width: 1000px) {
	.webmonitor-login{
		flex: 0 0 100%;
		max-width: 100%;
	}
}

.card-body {
	padding: 1.385rem;
}

@media only screen and (max-width: 575px) {
	.card-body {
		padding: 1rem;
	}
}

.card-title {
	font-size: 13px;
	font-weight: 350;
	color: #000;
	text-transform: capitalize;
}

.card-title--large {
	font-size: 1.5rem;
}

.card-title--medium {
	font-size: 1rem;
}

.card-title--small {
	font-size: 0.875rem;
}

.card-header {
	border-color: #f0f1f5;
	position: relative;
	background: transparent;
	padding: 1.5rem 1.875rem 1.25rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media only screen and (max-width: 575px) {
	.card-header {
		padding: 1.25rem 1rem 1.25rem;
	}
}

.card-header .card-title {
	margin-bottom: 0px;
}

.card-header .subtitle {
	padding-top: 5px;
	font-size: 14px;
	line-height: 1.5;
}

.card-footer {
	border-color: #f0f1f5;
	background: transparent;
	padding: 1.25rem 1.875rem 1.25rem;
}

@media only screen and (max-width: 575px) {
	.card-footer {
		padding: 1rem;
	}
}

.transparent-card.card {
	background: transparent;
	border: 1px solid transparent;
	box-shadow: none;
}

.card-action>a {
	display: inline-block;
	width: 30px;
	height: 30px;
	line-height: 30px;
	border-radius: 5px;
	border-color: transparent;
	text-align: center;
	background: black;
	color: #fff;
	margin-right: 8px;
}

.card-action>a:last-child {
	margin-right: 0;
}

.card-action>a:hover,
.card-action>a:focus {
	background: black;
}

.card-action>a i,
.card-action>a span {
	font-size: 1rem;
}

.card-action .dropdown {
	width: 30px;
	height: 30px;
	border-radius: 5px;
	border-color: transparent;
	text-align: center;
	margin-right: 8px;
	top: -2px;
	position: relative;
	display: inline-block;
	background: black;
	color: #25AFA8;
}

.card-action .dropdown:hover,
.card-action .dropdown:focus {
	background: black;
}

.card-action .dropdown .btn {
	padding: 0;
	line-height: 27px;
	color: #fff;
}

.card-action .dropdown .btn:focus {
	box-shadow: none;
}

.card-fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999999;
	overflow: auto;
}

.card-loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.75);
	z-index: 999;
}

.card-loader i {
	margin: 0 auto;
	background: #5ba618;
	padding: 10px;
	border-radius: 50%;
	color: #fff;
	font-size: 1rem;
}

.rotate-refresh {
	-webkit-animation: mymove 0.8s infinite linear;
	animation: mymove 0.8s infinite linear;
	display: inline-block;
}

.card-header .date_picker {
	display: inline-block;
	padding: 8px;
	border: 1px solid #f0f1f5;
	cursor: pointer;
	border-radius: .375rem;
}

.card-header .border-0 {
	padding-bottom: 0;
}

@-webkit-keyframes mymove {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes mymove {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.carousel-custom-prev,
.carousel-custom-next {
	width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
	display: inline-block;
	border-radius: 50%;
	background: #fff;
	text-align: center;
}

.carousel-custom-prev i,
.carousel-custom-next i {
	font-size: 1rem;
}

.carousel-custom-prev:hover,
.carousel-custom-next:hover {
	background: linear-gradient(to right, rgba(245, 60, 121, 0.99) 0%, rgba(246, 104, 47, 0.99) 100%);
	color: #fff;
}

.carousel-custom-next {
	position: absolute;
	top: 50%;
	right: 30px;
	transform: translateY(-50%);
}

.carousel-custom-prev {
	position: absolute;
	top: 50%;
	left: 30px;
	transform: translateY(-50%);
}

.carousel-caption {
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.78);
	z-index: 0;
}

.carousel-caption h5 {
	color: #fff;
	font-size: 1.8rem;
}

.carousel-caption p {
	margin-bottom: 0;
}

.dropdown-toggle:focus {
	box-shadow: none !important;
}

.dropdown-outline {
	border: 0.1rem solid #25AFA8;
}

.dropdown-menu {
	font-size: inherit;
	border: 0;
	box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1);
	margin-top: 0;
	border-radius: 0.875rem;
}

.dropdown-menu .dropdown-item {
	font-size: 16px;
	color: #7e7e7e;
	padding: 0.5rem 1.75rem;
}

@media only screen and (max-width: 1400px) {
	.dropdown-menu .dropdown-item {
		padding: 0.375rem 1rem;
		font-size: 14px;
	}
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
	color: #25AFA8;
	background: #F4F5F9;
}

.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
	color: #25AFA8 !important;
}


[direction="rtl"] .dropdown-menu {
	right: auto !important;
}

.dropdown-toggle-split {
	padding: 0 10px;
	opacity: 0.85;
}

.dropdown-toggle-split:after {
	margin-left: 0 !important;
}

.dropdown-toggle-split:active,
.dropdown-toggle-split:focus,
.dropdown-toggle-split:hover {
	opacity: 1;
}

.dropleft .dropdown-toggle:before,
.dropright .dropdown-toggle:before,
.dropup .dropdown-toggle:after,
.dropdown-toggle:after {
	content: "\f107";
	font-family: FontAwesome;
	border: 0;
	vertical-align: middle;
	margin-left: 0.25em;
	line-height: 1;
}

.dropup .dropdown-toggle:after {
	content: "\f106";
}

.dropleft .dropdown-toggle::before {
	content: "\f104";
}

.dropright .dropdown-toggle::before {
	content: "\f105";
}

.dropright .dropdown-toggle::after {
	content: none;
}

.custom-dropdown {
	display: inline-block;
	margin-bottom: 1rem;
}

.custom-dropdown .dropdown-menu {
	border: 0px;
	min-width: 160px;
}

.card-action .custom-dropdown {
	margin: 0px;
	background: #bbee8d;
}

.card-action .custom-dropdown.show,
.card-action .custom-dropdown:focus,
.card-action .custom-dropdown:hover {
	background: #25AFA8;
	color: #fff;
}

.card-action .custom-dropdown i {
	display: inline-block;
	padding-top: 9px;
}

.dropdown .dropdown-dots {
	position: relative;
	height: 5px;
	width: 5px;
	background: rgba(110, 110, 110, 0.4);
	border-radius: 5px;
	display: block;
}

.dropdown .dropdown-dots:after,
.dropdown .dropdown-dots:before {
	content: "";
	height: 5px;
	width: 5px;
	background: rgba(110, 110, 110, 0.4);
	position: absolute;
	border-radius: 5px;
}

.dropdown .dropdown-dots:after {
	right: -8px;
}

.dropdown .dropdown-dots:before {
	left: -8px;
}

.dropdown .dropdown-dots.text-white {
	background: rgba(255, 255, 255, 0.7);
}

.dropdown .dropdown-dots.text-white:after,
.dropdown .dropdown-dots.text-white:before {
	background: rgba(255, 255, 255, 0.7);
}


.label {
	display: inline-block;
	text-align: center;
	font-size: 0.75rem;
	padding: .2rem .8rem;
}

.label-fixed {
	width: 7.5rem;
	padding: .6rem 0;
}

.label-fixed-lg {
	width: 9.5rem;
	padding: .6rem 0;
}

.label-big {
	width: 16.8rem;
	font-size: 1.4rem;
	padding: 1.1rem 0;
}

.label-xl {
	width: 10.5rem;
	padding: 1.1rem 0;
	font-size: 1.5rem;
}

.label-lg {
	width: 9.5rem;
	padding: 1.1rem 0;
}

.label-md {
	width: 8.5rem;
	padding: 1.1rem 0;
}

.label-sm {
	width: 7.5rem;
	padding: 1.1rem 0;
}

.label-default {
	background: #ADB6C7;
}

.label-primary {
	background: #25AFA8;
	color: #fff;
}

.label-secondary {
	background: #C046D3;
	color: #fff;
}

.label-info {
	background: #1EA7C5;
	color: #fff;
}

.label-success {
	background: #37D1BF;
	color: #fff;
}

.label-warning {
	background: #FF9432;
	color: #fff;
}

.label-danger {
	background: #FF285C;
	color: #fff;
}

.label-light {
	background: #E8E8E8;
	color: #000;
}

.label-dark {
	background: #6e6e6e;
	color: #fff;
}

code {
	word-break: break-word;
	padding: 2px 5px;
	border-radius: 3px;
	background: #ffd0dc;
	color: #FF285C;
}

.heading-labels {
	color: #333333;
}

.heading-labels>* {
	margin-bottom: .8rem;
}

.heading-labels h1 .label {
	font-size: 18px;
	font-weight: normal;
	padding: .4rem .9rem;
}

.heading-labels h2 .label {
	font-size: 16px;
	font-weight: normal;
	padding: 0.3rem .9rem;
}

.heading-labels h3 .label {
	font-size: 14px;
	font-weight: normal;
}

.heading-labels h4 .label {
	font-size: 12px;
	font-weight: normal;
}

.heading-labels h5 .label {
	font-size: 12px;
	font-weight: normal;
}

.heading-labels h6 .label {
	font-size: 12px;
	font-weight: normal;
}

.list-group-item {
	background-color: rgba(255, 255, 255, 0);
	border: 1px solid #f0f1f5;
	padding: 1rem 1.5rem;
	border-radius: 0;
}

.list-group-item:first-child {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.list-group-item:last-child {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.list-group-item.disabled,
.list-group-item:disabled {
	color: #fff;
	background-color: #C046D3;
	border-color: #C046D3;
}

[class*="bg-"] .list-group-item {
	border-color: rgba(255, 255, 255, 0.05);
}

.bg-warning .list-group-item {
	border-color: rgba(0, 0, 0, 0.05);
}

.media img {
	border-radius: 3px;
}

.vertical-card__menu:hover {
	box-shadow: none;
}

.vertical-card__menu--image {
	text-align: center;
}

.vertical-card__menu--image img {
	width: 100%;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.vertical-card__menu--status.closed {
	background: #FF285C;
}

.vertical-card__menu--status.closed:after {
	border-top: 10px solid #FF285C;
}

.vertical-card__menu--status.closed .ribbon-curve {
	border-top: 11px solid #FF285C;
	border-bottom: 10px solid #FF285C;
}

.vertical-card__menu--desc p {
	margin-bottom: 0.625rem;
}

.vertical-card__menu--price {
	font-size: 1.25rem;
	margin: 0;
	font-weight: 700;
}

.vertical-card__menu--rating {
	font-size: 0.75rem;
}

.vertical-card__menu--title {
	font-weight: 700;
}

.vertical-card__menu--button button {
	font-size: 0.75rem;
}

.vertical-card__menu .card-footer {
	padding: 15px 20px;
	background-color: #f0f1f5;
	border-top: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
	.vertical-card__menu .card-footer {
		padding: 15px;
	}
}

.vertical-card__menu .card-footer span {
	color: #6e6e6e;
	margin-right: 0.3125rem;
}

.horizontal-card__menu {
	box-shadow: 0 0 7px rgba(173, 173, 173, 0.32);
	display: flex;
	flex-direction: row;
}

@media (max-width: 575.98px) {
	.horizontal-card__menu {
		display: block;
	}
}

.horizontal-card__menu .card-body {
	padding: 0;
	padding: 20px 30px;
}

.horizontal-card__menu--image {
	flex-basis: 30%;
	max-width: 30%;
}

.horizontal-card__menu--image img {
	height: 100%;
	width: 100%;
}

@media (max-width: 575.98px) {
	.horizontal-card__menu--image {
		max-width: unset;
		flex-basis: 100%;
	}
}

.horizontal-card__menu--title {
	font-size: 1rem;
	font-weight: 700;
	margin-bottom: 0.3125rem;
}

.horizontal-card__menu--fav {
	margin-right: 0.5rem;
}

.horizontal-card__menu--price {
	margin: 0;
	font-size: 1rem;
	font-weight: 700;
}

.horizontal-card__menu--rating {
	font-size: 0.625rem;
}

.horizontal-card__menu--footer {
	margin-top: 10px;
}

.prev_price {
	text-decoration: line-through;
	font-size: 80%;
	opacity: 0.5;
}

.pagination .page-item .page-link:hover {
	background: #25AFA8;
	border-color: #25AFA8;
	color: #fff;
}

.pagination .page-item.active .page-link {
	background: #25AFA8;
	border-color: #25AFA8;
	color: #fff;
}

.pagination {
	margin-bottom: 20px;
}

.pagination .page-item.page-indicator .page-link {
	padding: .65rem .8rem;
	font-size: 14px;
}

.pagination .page-item.page-indicator:hover .page-link {
	color: #6e6e6e;
}

.pagination .page-item .page-link {
	text-align: center;
	padding: 0.55rem 1rem;
	font-size: 1rem;
	background: rgba(255, 255, 255, 0.15);
	color: #6e6e6e;
	border: 1px solid #f0f1f5;
	z-index: 0;
}

.pagination .page-item .page-link:hover i,
.pagination .page-item .page-link span {
	color: #fff;
}

.pagination .page-item .page-link:focus {
	outline: 0;
	box-shadow: none;
}

.pagination .page-item .page-link:hover {
	background: #25AFA8;
	color: #fff;
	border-color: #25AFA8;
}

.pagination .page-item.active .page-link {
	background-color: #25AFA8;
	border-color: #25AFA8;
	color: #fff;
	box-shadow: 0 10px 20px 0px rgba(108, 197, 29, 0.2);
}

.pagination .page-item .page-link {
	color: #6e6e6e;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.pagination .page-item:last-child .page-link {
	margin-right: 0;
}

[direction="rtl"] .pagination .page-item:first-child .page-link {
	margin-right: 0;
}

.pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
	background: transparent;
	border-color: transparent;
}

.pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
	background: #25AFA8;
	border-color: #25AFA8;
}

.pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
	background: #FF285C;
	border-color: #FF285C;
}

.pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
	background: #1EA7C5;
	border-color: #1EA7C5;
}

.pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
	background: #FF9432;
	border-color: #FF9432;
}

.pagination-primary .page-item .page-link {
	background: #ddf7c7;
	border-color: #ddf7c7;
	color: #25AFA8;
}

.pagination-primary .page-item:hover .page-link,
.pagination-primary .page-item.active .page-link {
	background: #25AFA8;
	border-color: #25AFA8;
	box-shadow: 0 10px 20px 0px rgba(108, 197, 29, 0.2);
}

.pagination-danger .page-item .page-link {
	background: #ffeaef;
	border-color: #ffeaef;
	color: #FF285C;
}

.pagination-danger .page-item:hover .page-link,
.pagination-danger .page-item.active .page-link {
	background: #FF285C;
	border-color: #FF285C;
	box-shadow: 0 10px 20px 0px rgba(255, 40, 92, 0.2);
}

.pagination-info .page-item .page-link {
	background: #d9f4f9;
	border-color: #d9f4f9;
	color: #1EA7C5;
}

.pagination-info .page-item:hover .page-link,
.pagination-info .page-item.active .page-link {
	background: #1EA7C5;
	border-color: #1EA7C5;
	box-shadow: 0 10px 20px 0px rgba(30, 167, 197, 0.2);
}

.pagination-warning .page-item .page-link {
	background: #fff9f4;
	border-color: #fff9f4;
	color: #FF9432;
}

.pagination-warning .page-item:hover .page-link,
.pagination-warning .page-item.active .page-link {
	background: #FF9432;
	border-color: #FF9432;
	box-shadow: 0 10px 20px 0px rgba(255, 148, 50, 0.2);
}

.pagination-gutter .page-item {
	margin-right: 7px;
}

.pagination-gutter .page-item .page-link {
	border-radius: 0.875rem !important;
}

.pagination-circle .page-item {
	margin-right: 7px;
}

.pagination-circle .page-item .page-link,
.pagination-circle .page-item.page-indicator .page-link {
	width: 40px;
	height: 40px;
	padding: 0;
	line-height: 40px;
	border-radius: 50% !important;
	padding: 0;
}

.pagination.pagination-md .page-item .page-link {
	width: 30px;
	height: 30px;
	line-height: 30px;
	font-size: 14px;
}

.pagination.pagination-sm .page-item.page-indicator .page-link {
	font-size: 12px;
}

.pagination.pagination-sm .page-item .page-link {
	padding: 0;
	width: 30px;
	height: 30px;
	line-height: 30px;
	font-size: 14px;
}

.pagination.pagination-xs .page-item.page-indicator .page-link {
	font-size: 10px;
}

.pagination.pagination-xs .page-item .page-link {
	padding: 0;
	width: 25px;
	height: 25px;
	line-height: 25px;
	font-size: 12px;
}

.popover {
	border: 2px solid #25AFA8;
	min-width: 210px;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
	border-radius: 0;
}

.popover-header {
	background: #25AFA8;
	color: #fff;
	font-weight: 300;
	border-radius: 0;
}

.popover-header::before {
	border-bottom: 0 !important;
}

.popover-body {
	font-size: 0.75rem;
}

.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
	margin-right: 8px;
}

.bootstrap-popover-wrapper .bootstrap-popover {
	margin-bottom: .5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover button:hover,
.bootstrap-popover-wrapper .bootstrap-popover button:focus {
	background: #25AFA8;
	color: #fff;
	box-shadow: none;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
	bottom: 0px;
	border-top-color: #25AFA8;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
	right: 0px;
	border-left-color: #25AFA8;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
	left: 0px;
	border-right-color: #25AFA8;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	top: 0px;
	border-bottom-color: #25AFA8;
}

.note-popover {
	display: none;
}

.progress-bar {
	background-color: #25AFA8;
}

.progress-bar-primary {
	background-color: #25AFA8;
}

.progress-bar-success {
	background-color: #37D1BF;
}

.progress-bar-info {
	background-color: #1EA7C5;
}

.progress-bar-danger {
	background-color: #FF285C;
}

.progress-bar-warning {
	background-color: #FF9432;
}

.progress-bar-pink {
	background-color: #e83e8c;
}

.progress {
	height: 6px;
	overflow: hidden;
	border-radius: 0.875rem;
}

.progress-bar {
	border-radius: 4px;
}

.progress-vertical {
	display: inline-block;
	margin-bottom: 0;
	margin-right: 2rem;
	min-height: 17rem;
	position: relative;
}

@media (max-width: 991.98px) {
	.progress-vertical {
		margin-right: 1rem;
	}
}

.progress-vertical-bottom {
	display: inline-block;
	margin-bottom: 0;
	margin-right: 2rem;
	min-height: 17rem;
	position: relative;
	transform: rotate(180deg);
}

@media (max-width: 991.98px) {
	.progress-vertical-bottom {
		margin-right: 1rem;
	}
}

.progress-animated {
	animation-duration: 5s;
	animation-name: myanimation;
	transition: all 5s ease 0s;
}

@keyframes myanimation {
	0% {
		width: 0;
	}
}

@keyframes myanimation {
	0% {
		width: 0;
	}
}



.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
	background-color: #F5F5F5;
	width: 8px;
	height: 8px;
}

.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #7e7e7e;
}

.multi-steps>li.is-active:before,
.multi-steps>li.is-active~li:before {
	content: counter(stepNum);
	font-family: inherit;
	font-weight: 400;
}

.multi-steps>li.is-active:after,
.multi-steps>li.is-active~li:after {
	background-color: #f0f1f5;
}

.multi-steps {
	display: table;
	table-layout: fixed;
	width: 100%;
}

.multi-steps>li {
	counter-increment: stepNum;
	text-align: center;
	display: table-cell;
	position: relative;
	color: #25AFA8;
}

@media (max-width: 575.98px) {
	.multi-steps>li {
		font-size: 0.75rem;
	}
}

.multi-steps>li:before {
	content: '\2713';
	display: block;
	margin: 0 auto 4px;
	background-color: #fff;
	width: 25px;
	height: 25px;
	line-height: 22px;
	text-align: center;
	font-weight: bold;
	position: relative;
	z-index: 1;
	border-width: 2px;
	border-style: solid;
	border-color: #25AFA8;
	border-radius: 5px;
}

@media (max-width: 575.98px) {
	.multi-steps>li:before {
		width: 25px;
		height: 25px;
		line-height: 21px;
	}
}

.multi-steps>li:after {
	content: '';
	height: 2px;
	width: 100%;
	background-color: #25AFA8;
	position: absolute;
	top: 12px;
	left: 50%;
}

[direction="rtl"] .multi-steps>li:after {
	left: auto;
	right: 50%;
}


.widget-message p {
	font-size: 14px;
	line-height: 1.5;
}

.picker .picker__frame {
	min-width: 450px !important;
	max-width: 450px !important;
}

.picker .picker__frame .picker__box {
	padding: 20px;
	border: 0;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.picker .picker__frame .picker__box .picker__header {
	margin-top: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__year {
	color: #25AFA8;
	font-style: inherit;
	font-size: 20px;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev,
.picker .picker__frame .picker__box .picker__header .picker__nav--next {
	width: 40px;
	height: 40px;
	padding: 0;
	line-height: 40px;
	border-radius: 2px;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:before,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:before {
	content: none !important;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
	font-family: fontawesome;
	border: 0;
	margin: 0 auto;
	font-size: 22px;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:hover,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:hover {
	background-color: #bbee8d !important;
	color: #25AFA8 !important;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev {
	left: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after {
	content: "\f0d9";
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next {
	right: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
	content: "\f0da";
}

.picker .picker__frame .picker__box .picker__table .picker__weekday {
	padding: 10px 0;
	font-size: 16px;
}

.picker .picker__frame .picker__box .picker__table .picker__day {
	width: 40px;
	height: 40px;
	border-radius: 50px;
	padding: 0 !important;
	font-size: 16px;
	line-height: 40px;
	margin: auto;
	border: 0 !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--today:before {
	content: none !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--highlighted {
	border: 0 !important;
	padding: 0;
	background-color: #bbee8d !important;
	color: #fff !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day:hover {
	background-color: #bbee8d !important;
	color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today {
	background-color: #bbee8d !important;
	color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today:before {
	content: none !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear,
.picker .picker__frame .picker__box .picker__footer .picker__button--close,
.picker .picker__frame .picker__box .picker__footer .picker__button--today {
	border: 0;
	border-radius: 2px;
	font-size: 16px;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--close:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--today:hover {
	background-color: #bbee8d !important;
	color: #fff !important;
}

@media only screen and (max-width: 575px) {
	.picker .picker__frame {
		min-width: 100% !important;
		max-width: 100% !important;
	}
	.picker .picker__frame .picker__box {
		padding: 15px;
		margin: 0 10px;
	}
}


@media (max-width: 575.98px) {
	.multi-steps>li:after {
		top: 12px;
	}
}

.multi-steps>li:last-child:after {
	display: none;
}

.multi-steps>li.is-active:before {
	background-color: #fff;
	border-color: #25AFA8;
}

.multi-steps>li.is-active~li {
	color: #7e7e7e;
}

.multi-steps>li.is-active~li:before {
	background-color: #f0f1f5;
	border-color: #f0f1f5;
}

.nav-pills .nav-link {
	border-radius: 0.875rem;
	padding: 0.75rem 1.25rem;
}

.default-tab .nav-link {
	background: transparent;
	border-radius: 0px;
	font-weight: 500;
}

.default-tab .nav-link i {
	display: inline-block;
	transform: scale(1.5);
	color: #25AFA8;
}

.default-tab .nav-link:focus,
.default-tab .nav-link:hover,
.default-tab .nav-link.active {
	color: #495057;
	background-color: #fff;
	border-color: #dee2e6 #dee2e6 #fff #ebeef6;
	border-radius: 0.875rem 0.875rem 0 0;
	color: #25AFA8;
}

.custom-tab-1 .nav-link {
	background: transparent;
	border-radius: 0px;
	font-weight: 500;
	border-bottom: 3px solid transparent;
	border-width: 0 0px 3px 0;
}

.custom-tab-1 .nav-link i {
	display: inline-block;
	transform: scale(1.5);
	color: #25AFA8;
}

.custom-tab-1 .nav-link:focus,
.custom-tab-1 .nav-link:hover,
.custom-tab-1 .nav-link.active {
	color: #495057;
	background-color: #fff;
	border-color: #25AFA8;
	border-radius: 0;
	color: #25AFA8;
	border-width: 0 0px 3px 0;
}

.nav-pills.light .nav-link.active,
.nav-pills.light .show>.nav-link {
	background: #ddf7c7;
	color: #25AFA8;
	box-shadow: none;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	box-shadow: 0 0 20px 0 rgba(108, 197, 29, 0.2);
}

.tooltip-wrapper button:not(:last-child) {
	margin-right: 8px;
}

.tooltip-wrapper button:hover {
	background: #25AFA8;
	color: #fff;
}

.tooltip-wrapper button {
	margin-bottom: .5rem;
}

.tooltip-wrapper button:focus {
	box-shadow: none;
}

.tooltip-inner {
	border-radius: 0;
	background: #333333;
	font-size: 12px;
	font-weight: 300;
	padding: 0.35rem 0.7rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
	border-bottom-color: #333333;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
	border-right-color: #333333;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
	border-left-color: #333333;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
	border-top-color: #333333;
}


.chart_widget_tab_one .nav-link {
	border: 1px solid #ddd;
}

.chart_widget_tab_one .nav-link.active {
	background-color: #25AFA8;
	border: 1px solid #25AFA8;
	color: #fff;
}

.chart_widget_tab_one .nav-link.active:hover {
	border: 1px solid #25AFA8;
}

.chart_widget_tab_one .nav-link:hover {
	border: 1px solid #ddd;
}

.social-icon {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 4px;
	text-align: center;
	background: #F5F9F1;
	margin-bottom: 0.5rem;
	font-size: 20px;
}

.social-icon i {
	color: #fff;
}



.social-icon2 a {
	border: 0.1rem solid #25AFA8;
	display: block;
	padding: 1rem;
	margin-top: 1.5rem;
	margin-bottom: .5rem;
	border-radius: .3rem;
	font-size: 1.6rem;
}

.social-icon2 i {
	font-size: 12px;
	color: #25AFA8;
}

.social-icon3 ul li {
	display: inline-block;
}

.social-icon3 ul li a {
	display: block;
}

.social-icon3 ul li a i {
	font-size: 1.4rem;
	padding: 1rem .7rem;
	color: #464a53;
}

.social-icon3 ul li a:hover i {
	color: #25AFA8;
}

.social-icons-muted ul li a i {
	color: #89879f;
}

.social-links a {
	padding: .5rem;
}

.widget-stat .media {
	padding: 0px 0;
	align-items: center;
}

.widget-stat .media>span {
	height: 85px;
	width: 85px;
	border-radius: 50px;
	padding: 10px 12px;
	font-size: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #464a53;
}

@media only screen and (max-width: 1400px) {
	.widget-stat .media>span {
		height: 70px;
		width: 70px;
		min-width: 70px;
	}
}

.widget-stat .media .media-body p {
	font-weight: 500;
	font-size: 16px;
	line-height: 1.5;

}

@media only screen and (max-width: 1400px) {
	.widget-stat .media .media-body p {
		font-size: 14px;
	}
}

.widget-stat .media .media-body small,
.widget-stat .media .media-body .small {
	font-size: 75%;
}

.widget-stat .media .media-body h3 {
	font-size: 30px;
	font-weight: 600;
	margin: 0;
	line-height: 1.2;
}

.widget-stat .media .media-body h4 {
	font-size: 24px;
	display: inline-block;
	vertical-align: middle;
}

.widget-stat .media .media-body span {
	margin-left: 5px;
}

.widget-stat[class*="bg-"] .media>span {
	background-color: rgba(255, 255, 255, 0.25);
	color: #fff;
}

.widget-stat[class*="bg-"] .progress {
	background-color: rgba(255, 255, 255, 0.25) !important;
}

[direction="rtl"] .widget-stat .media .media-body span {
	margin-left: 0;
	margin-right: 10px;
}

.dez-widget-1 .card {
	background: #FFE7DB;
}

.dez-widget-1 .card .card-body p {
	color: #f87533;
}

.dez-widget-1 .icon {
	float: right;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	font-size: 28px;
}

.bgl-primary {
	background: #ddf7c7;
	border-color: #ddf7c7;
}

.bgl-secondary {
	background: #f8ebfa;
	border-color: #f8ebfa;
}

.bgl-success {
	background: #d5f5f1;
	border-color: #d5f5f1;
}

.bgl-warning {
	background: #fff9f4;
	border-color: #fff9f4;
}

.bgl-danger {
	background: #ffeaef;
	border-color: #ffeaef;
}

.bgl-info {
	background: #d9f4f9;
	border-color: #d9f4f9;
}

.bg-primary-light {
	background: rgba(221, 247, 199, 0.5);
}

.bg-secondary-light {
	background: rgba(248, 235, 250, 0.5);
}

.bg-success-light {
	background: rgba(213, 245, 241, 0.5);
}

.bg-warning-light {
	background: rgba(255, 249, 244, 0.5);
}

.bg-danger-light {
	background: rgba(255, 234, 239, 0.5);
}

.bg-info-light {
	background: rgba(217, 244, 249, 0.5);
}

.bgl-dark {
	background: #eeeeee;
	border-color: #eeeeee;
}

.bgl-light {
	background: #E8E8E8;
	border-color: #E8E8E8;
}


a.badge:hover,
a.badge:focus {
	text-decoration: none;
}

.badge:empty {
	display: none;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.badge-pill {
	padding-right: 0.6em;
	padding-left: 0.6em;
	border-radius: 10rem;
}

.badge-primary {
	color: #212529;
	background-color: #25AFA8;
}

a.badge-primary:hover,
a.badge-primary:focus {
	color: #212529;
	background-color: #549916;
}

a.badge-primary:focus,
a.badge-primary.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(108, 197, 29, 0.5);
}

.badge-secondary {
	color: #fff;
	background-color: #C046D3;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
	color: #fff;
	background-color: #a72cba;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(192, 70, 211, 0.5);
}

.badge-success {
	color: #212529;
	background-color: #37D1BF;
}

a.badge-success:hover,
a.badge-success:focus {
	color: #212529;
	background-color: #28ad9e;
}

a.badge-success:focus,
a.badge-success.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(55, 209, 191, 0.5);
}

.badge-info {
	color: #fff;
	background-color: #1EA7C5;
}

a.badge-info:hover,
a.badge-info:focus {
	color: #fff;
	background-color: #178199;
}

a.badge-info:focus,
a.badge-info.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(30, 167, 197, 0.5);
}

.badge-warning {
	color: #212529;
	background-color: #FF9432;
}

a.badge-warning:hover,
a.badge-warning:focus {
	color: #212529;
	background-color: #fe7900;
}

a.badge-warning:focus,
a.badge-warning.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(255, 148, 50, 0.5);
}

.badge-danger {
	color: #fff;
	background-color: #FF285C;
}

a.badge-danger:hover,
a.badge-danger:focus {
	color: #fff;
	background-color: #f4003b;
}

a.badge-danger:focus,
a.badge-danger.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(255, 40, 92, 0.5);
}

.badge-light {
	color: #212529;
	background-color: #E8E8E8;
}

a.badge-light:hover,
a.badge-light:focus {
	color: #212529;
	background-color: #cfcfcf;
}

a.badge-light:focus,
a.badge-light.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(232, 232, 232, 0.5);
}

.badge-dark {
	color: #fff;
	background-color: #6e6e6e;
}

a.badge-dark:hover,
a.badge-dark:focus {
	color: #fff;
	background-color: #555555;
}

a.badge-dark:focus,
a.badge-dark.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(110, 110, 110, 0.5);
}

.jumbotron {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: #e9ecef;
	border-radius: 0.3rem;
}

@media (min-width: 576px) {
	.jumbotron {
		padding: 4rem 2rem;
	}
}

.jumbotron-fluid {
	padding-right: 0;
	padding-left: 0;
	border-radius: 0;
}

.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.75rem;
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: 700;
}

.alert-dismissible {
	padding-right: 3.8125rem;
}

.alert-dismissible .close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 0.75rem 1.25rem;
	color: inherit;
}

.alert-primary {
	color: #38660f;
	background-color: #e2f3d2;
	border-color: #d6efc0;
}

.alert-primary hr {
	border-top-color: #c9eaac;
}

.alert-primary .alert-link {
	color: #203a08;
}

.alert-secondary {
	color: #64246e;
	background-color: #f2daf6;
	border-color: #edcbf3;
}

.alert-secondary hr {
	border-top-color: #e6b6ee;
}

.alert-secondary .alert-link {
	color: #411748;
}

.alert-success {
	color: #1d6d63;
	background-color: #d7f6f2;
	border-color: #c7f2ed;
}

.alert-success hr {
	border-top-color: #b2ede6;
}

.alert-success .alert-link {
	color: #12453e;
}

.alert-info {
	color: #105766;
	background-color: #d2edf3;
	border-color: #c0e6ef;
}

.alert-info hr {
	border-top-color: #acdeea;
}

.alert-info .alert-link {
	color: #09313a;
}

.alert-warning {
	color: #854d1a;
	background-color: #ffead6;
	border-color: #ffe1c6;
}

.alert-warning hr {
	border-top-color: #ffd4ad;
}

.alert-warning .alert-link {
	color: #5a3412;
}

.alert-danger {
	color: #851530;
	background-color: #ffd4de;
	border-color: #ffc3d1;
}

.alert-danger hr {
	border-top-color: #ffaabd;
}

.alert-danger .alert-link {
	color: #590e20;
}

.alert-light {
	color: #797979;
	background-color: #fafafa;
	border-color: #f9f9f9;
}

.alert-light hr {
	border-top-color: #ececec;
}

.alert-light .alert-link {
	color: #606060;
}

.alert-dark {
	color: #393939;
	background-color: #e2e2e2;
	border-color: #d6d6d6;
}

.alert-dark hr {
	border-top-color: #c9c9c9;
}

.alert-dark .alert-link {
	color: #202020;
}

@keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}


.media {
	display: flex;
	align-items: flex-start;
}

.media-body {
	flex: 1;
}

.close {
	float: right;
	font-size: 1.3125rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5;
}

.close:hover {
	color: #000;
	text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
	opacity: .75;
}

.close-intab {
	margin: 0.5rem !important;
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.close-intab {
		font-size: 2rem !important;
	}
}

@media screen and (min-width: 400px) and (max-width: 2500px) {
	.close-intab {
		font-size: 2rem !important;
	}
}

@media screen and (min-width: 574px) and (max-width: 2500px) {
	.close-lg-display {
		display: none !important;
	}
}

@media(max-width:767px) {
	div.mdb-datatable div.mdb-datatable-info,
	div.mdb-datatable div.mdb-dataTables_paginate,
	div.mdb-datatable div.mdb-datatable-entries {
		font-size: .8rem !important;
	}
}

div.mdb-datatable div.mdb-datatable-info,
div.mdb-datatable div.mdb-dataTables_paginate,
div.mdb-datatable div.mdb-datatable-entries {
	padding-top: 0.5rem !important;
}

button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
}

a.close.disabled {
	pointer-events: none;
}

.toast {
	flex-basis: 350px;
	max-width: 350px;
	font-size: 0.875rem;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
	opacity: 0;
	border-radius: 0.25rem;
}

.toast:not(:last-child) {
	margin-bottom: 0.75rem;
}

.toast.showing {
	opacity: 1;
}

.toast.show {
	display: block;
	opacity: 1;
}

.toast.hide {
	display: none;
}

.toast-header {
	display: flex;
	align-items: center;
	padding: 0.25rem 0.75rem;
	color: #6c757d;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
	padding: 0.75rem;
}



.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	margin: 0;
	font-family: "Roboto", sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.765625rem;
	word-wrap: break-word;
	opacity: 0;
}

.tooltip.show {
	opacity: 0.9;
}

.tooltip .arrow {
	position: absolute;
	display: block;
	width: 0.8rem;
	height: 0.4rem;
}

.tooltip .arrow::before {
	position: absolute;
	content: "";
	border-color: transparent;
	border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
	padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
	bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
	top: 0;
	border-width: 0.4rem 0.4rem 0;
	border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
	padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
	left: 0;
	width: 0.4rem;
	height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
	right: 0;
	border-width: 0.4rem 0.4rem 0.4rem 0;
	border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
	padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
	top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
	bottom: 0;
	border-width: 0 0.4rem 0.4rem;
	border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
	padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
	right: 0;
	width: 0.4rem;
	height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
	left: 0;
	border-width: 0.4rem 0 0.4rem 0.4rem;
	border-left-color: #000;
}

.tooltip-inner {
	max-width: 200px;
	padding: 0.25rem 0.5rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 0.75rem;
}

.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-family: "Roboto", sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.765625rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
}

.popover .arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: 0.5rem;
	margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid;
}


.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^="top"]>.arrow {
	bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^="top"]>.arrow::before {
	bottom: 0;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
	bottom: 1px;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
	margin-left: 0.5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^="right"]>.arrow {
	left: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
	margin: 0.3rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^="right"]>.arrow::before {
	left: 0;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
	left: 1px;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
	margin-top: 0.5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^="bottom"]>.arrow {
	top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.arrow::before {
	top: 0;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
	top: 1px;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -0.5rem;
	content: "";
	border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
	margin-right: 0.5rem;
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^="left"]>.arrow {
	right: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
	margin: 0.3rem 0;
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^="left"]>.arrow::before {
	right: 0;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
	right: 1px;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: #fff;
}

.popover-header {
	padding: 0.5rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
	display: none;
}

.popover-body {
	padding: 0.5rem 0.75rem;
	color: #7e7e7e;
}

@keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.2em;
}

.c-action-spinner-color{
	color:#25afa8;
}


@keyframes spinner-grow {
	0% {
		transform: scale(0);
	}
	50% {
		opacity: 1;
		transform: none;
	}
}

.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
	width: 1rem;
	height: 1rem;
}

.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.bg-primary {
	background-color: #25AFA8 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
	background-color: #549916 !important;
}

.bg-secondary {
	background-color: #C046D3 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
	background-color: #a72cba !important;
}

.bg-success {
	background-color: #37D1BF !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
	background-color: #28ad9e !important;
}

.bg-info {
	background-color: #1EA7C5 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
	background-color: #178199 !important;
}

.bg-warning {
	background-color: #FF9432 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
	background-color: #fe7900 !important;
}

.bg-danger {
	background-color: #FF285C !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
	background-color: #f4003b !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
	background-color: #cfcfcf !important;
}

.bg-dark {
	background-color: #6e6e6e !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
	background-color: #555555 !important;
}

.bg-white {
	background-color: #fff !important;
}

.bg-transparent {
	background-color: transparent !important;
}

.border {
	border: 1px solid #EEEEEE !important;
}

.border-top {
	border-top: 1px solid #EEEEEE !important;
}

.border-right {
	border-right: 1px solid #EEEEEE !important;
}

.border-bottom {
	border-bottom: 1px solid #EEEEEE !important;
}

.border-left {
	border-left: 1px solid #EEEEEE !important;
}

.border-0 {
	border: 0 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-right-0 {
	border-right: 0 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-left-0 {
	border-left: 0 !important;
}

.border-primary {
	border-color: #25AFA8 !important;
}

.border-secondary {
	border-color: #C046D3 !important;
}

.border-success {
	border-color: #37D1BF !important;
}

.border-info {
	border-color: #1EA7C5 !important;
}

.border-warning {
	border-color: #FF9432 !important;
}

.border-danger {
	border-color: #FF285C !important;
}

.border-light {
	border-color: #E8E8E8 !important;
}

.border-dark {
	border-color: #6e6e6e !important;
}

.border-white {
	border-color: #fff !important;
}

.rounded-sm {
	border-radius: 0.2rem !important;
}

.rounded {
	border-radius: 0.75rem !important;
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.rounded2 {
		border-radius: 2rem !important;
		font-size: 0.6rem !important;
		padding: 0.1rem 5rem 0.1rem 5rem !important;
	}
}

@media screen and (min-width: 400px) and (max-width: 2500px) {
	.rounded2 {
		border-radius: 2rem !important;
		font-size: 0.6rem !important;
		padding: 0.1rem 1rem 0.1rem 1rem !important;
	}
}

.rounded-top {
	border-top-left-radius: 0.75rem !important;
	border-top-right-radius: 0.75rem !important;
}

.rounded-right {
	border-top-right-radius: 0.75rem !important;
	border-bottom-right-radius: 0.75rem !important;
}

.rounded-bottom {
	border-bottom-right-radius: 0.75rem !important;
	border-bottom-left-radius: 0.75rem !important;
}

.rounded-left {
	border-top-left-radius: 0.75rem !important;
	border-bottom-left-radius: 0.75rem !important;
}

.rounded-lg {
	border-radius: 0.3rem !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-pill {
	border-radius: 50rem !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.d-none {
	display: none !important;
}

.d-flex{
	display: flex !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}



.d-inline-flex {
	display: inline-flex !important;
}

@media (min-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: inline-flex !important;
	}
}

@media (min-width: 768px) {
	.d-md-none {
		display: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: flex !important;
	}
	.d-md-inline-flex {
		display: inline-flex !important;
	}
}

@media (min-width: 992px) {
	.d-lg-none {
		display: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: inline-flex !important;
	}
}

@media (min-width: 1200px) {
	.d-xl-none {
		display: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: inline-flex !important;
	}
}

@media (min-width: 1440) {
	.d-xxl-none {
		display: none !important;
	}
	.d-xxl-inline {
		display: inline !important;
	}
	.d-xxl-inline-block {
		display: inline-block !important;
	}
	.d-xxl-block {
		display: block !important;
	}
	.d-xxl-table {
		display: table !important;
	}
	.d-xxl-table-row {
		display: table-row !important;
	}
	.d-xxl-table-cell {
		display: table-cell !important;
	}
	.d-xxl-flex {
		display: flex !important;
	}
	.d-xxl-inline-flex {
		display: inline-flex !important;
	}
}

@media print {
	.d-print-none {
		display: none !important;
	}
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: flex !important;
	}
	.d-print-inline-flex {
		display: inline-flex !important;
	}
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
}

.embed-responsive::before {
	display: block;
	content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.embed-responsive-21by9::before {
	padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
	padding-top: 56.25%;
}

.embed-responsive-4by3::before {
	padding-top: 75%;
}

.embed-responsive-1by1::before {
	padding-top: 100%;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-wrap {
	flex-wrap: wrap !important;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.flex-fill {
	flex: 1 1 auto !important;
}

.flex-grow-0 {
	flex-grow: 0 !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	flex-shrink: 1 !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center {
	align-items: center !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-stretch {
	align-items: stretch !important;
}

.align-content-start {
	align-content: flex-start !important;
}

.align-content-end {
	align-content: flex-end !important;
}

.align-content-center {
	align-content: center !important;
}

.align-content-between {
	align-content: space-between !important;
}

.align-content-around {
	align-content: space-around !important;
}

.align-content-stretch {
	align-content: stretch !important;
}

.align-self-auto {
	align-self: auto !important;
}

.align-self-start {
	align-self: flex-start !important;
}

.align-self-end {
	align-self: flex-end !important;
}

.align-self-center {
	align-self: center !important;
}

.align-self-baseline {
	align-self: baseline !important;
}

.align-self-stretch {
	align-self: stretch !important;
}

@media (min-width: 576px) {
	.flex-sm-row {
		flex-direction: row !important;
	}
	.flex-sm-column {
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-sm-wrap {
		flex-wrap: wrap !important;
	}
	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-sm-fill {
		flex: 1 1 auto !important;
	}
	.flex-sm-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-sm-start {
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		justify-content: center !important;
	}
	.justify-content-sm-between {
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		justify-content: space-around !important;
	}
	.align-items-sm-start {
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		align-items: center !important;
	}
	.align-items-sm-baseline {
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		align-items: stretch !important;
	}
	.align-content-sm-start {
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		align-content: center !important;
	}
	.align-content-sm-between {
		align-content: space-between !important;
	}
	.align-content-sm-around {
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		align-self: auto !important;
	}
	.align-self-sm-start {
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		align-self: center !important;
	}
	.align-self-sm-baseline {
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		align-self: stretch !important;
	}
}

@media (min-width: 768px) {
	.flex-md-row {
		flex-direction: row !important;
	}
	.flex-md-column {
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-md-wrap {
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-md-fill {
		flex: 1 1 auto !important;
	}
	.flex-md-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-md-start {
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		justify-content: center !important;
	}
	.justify-content-md-between {
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		justify-content: space-around !important;
	}
	.align-items-md-start {
		align-items: flex-start !important;
	}
	.align-items-md-end {
		align-items: flex-end !important;
	}
	.align-items-md-center {
		align-items: center !important;
	}
	.align-items-md-baseline {
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		align-items: stretch !important;
	}
	.align-content-md-start {
		align-content: flex-start !important;
	}
	.align-content-md-end {
		align-content: flex-end !important;
	}
	.align-content-md-center {
		align-content: center !important;
	}
	.align-content-md-between {
		align-content: space-between !important;
	}
	.align-content-md-around {
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		align-content: stretch !important;
	}
	.align-self-md-auto {
		align-self: auto !important;
	}
	.align-self-md-start {
		align-self: flex-start !important;
	}
	.align-self-md-end {
		align-self: flex-end !important;
	}
	.align-self-md-center {
		align-self: center !important;
	}
	.align-self-md-baseline {
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		align-self: stretch !important;
	}
}

@media (min-width: 992px) {
	.flex-lg-row {
		flex-direction: row !important;
	}
	.flex-lg-column {
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-lg-wrap {
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-lg-fill {
		flex: 1 1 auto !important;
	}
	.flex-lg-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-lg-start {
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		justify-content: center !important;
	}
	.justify-content-lg-between {
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		justify-content: space-around !important;
	}
	.align-items-lg-start {
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		align-items: center !important;
	}
	.align-items-lg-baseline {
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		align-items: stretch !important;
	}
	.align-content-lg-start {
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		align-content: center !important;
	}
	.align-content-lg-between {
		align-content: space-between !important;
	}
	.align-content-lg-around {
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		align-self: auto !important;
	}
	.align-self-lg-start {
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		align-self: center !important;
	}
	.align-self-lg-baseline {
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		align-self: stretch !important;
	}
}

@media (min-width: 1200px) {
	.flex-xl-row {
		flex-direction: row !important;
	}
	.flex-xl-column {
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-xl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-xl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		justify-content: center !important;
	}
	.justify-content-xl-between {
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		justify-content: space-around !important;
	}
	.align-items-xl-start {
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		align-items: center !important;
	}
	.align-items-xl-baseline {
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		align-items: stretch !important;
	}
	.align-content-xl-start {
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		align-content: center !important;
	}
	.align-content-xl-between {
		align-content: space-between !important;
	}
	.align-content-xl-around {
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		align-self: auto !important;
	}
	.align-self-xl-start {
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		align-self: center !important;
	}
	.align-self-xl-baseline {
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		align-self: stretch !important;
	}
}

@media (min-width: 1440) {
	.flex-xxl-row {
		flex-direction: row !important;
	}
	.flex-xxl-column {
		flex-direction: column !important;
	}
	.flex-xxl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xxl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xxl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xxl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xxl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.flex-xxl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xxl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xxl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xxl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xxl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.justify-content-xxl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xxl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xxl-center {
		justify-content: center !important;
	}
	.justify-content-xxl-between {
		justify-content: space-between !important;
	}
	.justify-content-xxl-around {
		justify-content: space-around !important;
	}
	.align-items-xxl-start {
		align-items: flex-start !important;
	}
	.align-items-xxl-end {
		align-items: flex-end !important;
	}
	.align-items-xxl-center {
		align-items: center !important;
	}
	.align-items-xxl-baseline {
		align-items: baseline !important;
	}
	.align-items-xxl-stretch {
		align-items: stretch !important;
	}
	.align-content-xxl-start {
		align-content: flex-start !important;
	}
	.align-content-xxl-end {
		align-content: flex-end !important;
	}
	.align-content-xxl-center {
		align-content: center !important;
	}
	.align-content-xxl-between {
		align-content: space-between !important;
	}
	.align-content-xxl-around {
		align-content: space-around !important;
	}
	.align-content-xxl-stretch {
		align-content: stretch !important;
	}
	.align-self-xxl-auto {
		align-self: auto !important;
	}
	.align-self-xxl-start {
		align-self: flex-start !important;
	}
	.align-self-xxl-end {
		align-self: flex-end !important;
	}
	.align-self-xxl-center {
		align-self: center !important;
	}
	.align-self-xxl-baseline {
		align-self: baseline !important;
	}
	.align-self-xxl-stretch {
		align-self: stretch !important;
	}
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.float-none {
	float: none !important;
}

@media (min-width: 576px) {
	.float-sm-left {
		float: left !important;
	}
	.float-sm-right {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
}

@media (min-width: 768px) {
	.float-md-left {
		float: left !important;
	}
	.float-md-right {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
}

@media (min-width: 992px) {
	.float-lg-left {
		float: left !important;
	}
	.float-lg-right {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
}

@media (min-width: 1200px) {
	.float-xl-left {
		float: left !important;
	}
	.float-xl-right {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
}

@media (min-width: 1440) {
	.float-xxl-left {
		float: left !important;
	}
	.float-xxl-right {
		float: right !important;
	}
	.float-xxl-none {
		float: none !important;
	}
}

.user-select-all {
	user-select: all !important;
}

.user-select-auto {
	user-select: auto !important;
}

.user-select-none {
	user-select: none !important;
}


.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal;
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}

.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100%
}

.table-text-set {
	color: #6e6e6e !important;
}

.mdb-datatable {
	text-align: center !important;
}

.div.mdb-datatable {
	text-align: center !important;
}

.div.mdb-datatable-filter {
	text-align: center !important;
}


.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	pointer-events: auto;
	content: "";
	background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
	text-align: justify !important;
}

.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

@media (min-width: 576px) {
	.text-sm-left {
		text-align: left !important;
	}
	.text-sm-right {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}

@media (min-width: 768px) {
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}

@media (min-width: 992px) {
	.text-lg-left {
		text-align: left !important;
	}
	.text-lg-right {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}

@media (min-width: 1200px) {
	.text-xl-left {
		text-align: left !important;
	}
	.text-xl-right {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}

@media (min-width: 1440) {
	.text-xxl-left {
		text-align: left !important;
	}
	.text-xxl-right {
		text-align: right !important;
	}
	.text-xxl-center {
		text-align: center !important;
	}
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-lighter {
	font-weight: lighter !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-weight-bolder {
	font-weight: bolder !important;
}

.font-italic {
	font-style: italic !important;
}

.text-white {
	color: #fff !important;
}

.text-primary {
	color: #25AFA8 !important;
}

a.text-primary:hover,
a.text-primary:focus {
	color: #478213 !important;
}

.text-secondary {
	color: #C046D3 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
	color: #9427a5 !important;
}

.text-success {
	color: #37D1BF !important;
}

a.text-success:hover,
a.text-success:focus {
	color: #23988b !important;
}

.text-info {
	color: #1EA7C5 !important;
}

a.text-info:hover,
a.text-info:focus {
	color: #146f83 !important;
}

.text-warning {
	color: #FF9432 !important;
}

a.text-warning:hover,
a.text-warning:focus {
	color: #e56d00 !important;
}

.text-danger {
	color: #FF285C !important;
}

a.text-danger:hover,
a.text-danger:focus {
	color: #db0035 !important;
}

.text-light {
	color: #E8E8E8 !important;
}

a.text-light:hover,
a.text-light:focus {
	color: #c2c2c2 !important;
}

.text-dark {
	color: #6e6e6e !important;
}

a.text-dark:hover,
a.text-dark:focus {
	color: #484848 !important;
}

.text-body {
	color: #7e7e7e !important;
}

.text-muted {
	color: #89879f !important;
}

.text-black-50 {
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-break {
	word-break: break-word !important;
	word-wrap: break-word !important;
}

.text-reset {
	color: inherit !important;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}

@media print {
	*,
	*::before,
	*::after {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	abbr[title]::after {
		content: " (" attr(title) ")";
	}
	pre {
		white-space: pre-wrap !important;
	}
	pre,
	blockquote {
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr,
	img {
		page-break-inside: avoid;
	}
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
	@page {
		size: a3;
	}
	body {
		min-width: 992px !important;
	}
	.container {
		min-width: 992px !important;
	}
	.navbar {
		display: none;
	}
	.badge {
		border: 1px solid #000;
	}
	.table {
		border-collapse: collapse !important;
	}
	.table td,
	.table th {
		background-color: #fff !important;
	}
	.table-bordered th,
	.table-bordered td {
		border: 1px solid #dee2e6 !important;
	}
	.table-dark {
		color: inherit;
	}
	.table-dark th,
	.table-dark td,
	.table-dark thead th,
	.table-dark tbody+tbody {
		border-color: #EEEEEE;
	}
	.table .thead-dark th {
		color: inherit;
		border-color: #EEEEEE;
	}
}

.c-pointer {
	cursor: pointer;
}


.w-space-no {
	white-space: nowrap;
}

.user-list-container .row .col .card .card-body .form-outline {
	margin-top: 0rem;
}

.user-list-search-table .datatable-pagination .datatable-select-wrapper .select-wrapper .form-outline {
	height: 3rem !important;
}

.user-list-search-table .datatable-pagination {
	margin-bottom: 2.5rem!important;
}

.user-modal-body {
	padding: 0rem 1.875rem 1.875rem 1.875rem !important;
}

.card-tabs2 .nav-tabs .nav-link{
	cursor: pointer;
}

.upload-cert-hint{
	color: #0F4542 !important;
	font-size:0.85rem!important;
	font-weight: 600;
	letter-spacing: 0.75px;
  }


.user-table-card-body {
	padding: 0rem !important;
}

.userManager-check .form-check .form-check-label {
	padding-left: 1rem!important;
	font-size: 0.85rem;
}

.userManager-check .form-check {
	display: flex;
	align-items: center;
}

.userManager-check .form-check {
	padding: 0rem !important;
}

.change-tab-content {
	padding: 0.5rem 1.7rem 1rem 1.7rem;
}

@media only screen and (max-width: 500px) {
	.change-tab-content {
		padding: 0rem;
	}
}

@media only screen and (max-width: 500px) {
	.tab-submit-btn {
		margin-top: 0.5rem;
		margin-right: 0.7rem !important;
	}
}

.uerlist-btn{
	padding: 1rem 2.5rem;
	margin-right:1rem;
}

.row.spno,
.spno {
	margin-left: 0;
	margin-right: 0;
}

.row.spno [class*="col-"],
.spno [class*="col-"] {
	padding-left: 0;
	padding-right: 0;
}

.content-heading {
	font-size: 16px;
	margin-bottom: 1.875rem;
	margin-top: 3.125rem;
	border-bottom: 1px solid #f0f1f5;
	padding-bottom: 10px;
}

[direction="rtl"] .content-heading {
	text-align: right;
}

.text-ov {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}


.op1 {
	opacity: 0.1;
}

.op2 {
	opacity: 0.2;
}

.op3 {
	opacity: 0.3;
}

.op4 {
	opacity: 0.4;
}

.op5 {
	opacity: 0.5;
}

.op6 {
	opacity: 0.6;
}

.op7 {
	opacity: 0.7;
}

.op8 {
	opacity: 0.8;
}

.op9 {
	opacity: 0.9;
}

.wspace-no {
	white-space: nowrap;
}

.support-ticket {
	position: fixed;
	bottom: 30px;
	right: 15px;
	z-index: 999999;
}

.support-ticket-btn {
	width: 100px;
	background: #7CB442;
	animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
	border-radius: 50px;
	color: #fff;
	font-size: 8px;
	font-size: 16px;
	padding: 5px 10px 7px;
	text-align: center;
	display: inline-block;
	box-shadow: rgba(124, 180, 66, 0.7) 0px 8px 35px 0px;
}

.support-ticket-btn:hover,
.support-ticket-btn:focus {
	color: #fff;
}

.text-blue {
	color: #5e72e4;
}

.text-indigo {
	color: #6610f2;
}

.text-purple {
	color: #6f42c1;
}

.text-pink {
	color: #e83e8c;
}

.text-red {
	color: #EE3232;
}

.text-orange {
	color: #ff9900;
}

.text-yellow {
	color: #FFFA6F;
}

.text-green {
	color: #297F00;
}

.text-teal {
	color: #20c997;
}

.text-cyan {
	color: #3065D0;
}

.bg-gray-dark {
	background: #143b64 !important;
}

.bg-purpal {
	background: #4527a0 !important;
}

.bg-red {
	background: #F44E52 !important;
}

.bg-blue-dark {
	background: #283593 !important;
}

.bg-blue {
	background: #33A6FF !important;
}

.bg-blue-light {
	background: #3695eb !important;
}

.bg-green-light {
	background: #00838f !important;
}

.bg-green {
	background: #ff8f16 !important;
}

.bg-black {
	background: #000;
}

.text-black {
	color: #000 !important;
}

.dz-scroll {
	position: relative;
}

.h-auto {
	height: auto !important;
}

.list-inline li {
	display: inline-block;
}

.fs-12 {
	font-size: 12px !important;
	line-height: 1.3;
}

.fs-13 {
	font-size: 13px !important;
	line-height: 1.4;
}

.fs-14 {
	font-size: 14px !important;
	line-height: 1.5;
}

.fs-15 {
	font-size: 14px !important;
	line-height: 1.5;
}

.fs-16 {
	font-size: 16px !important;
	line-height: 1.5;
}

.fs-18 {
	font-size: 18px !important;
	line-height: 1.5;
}

.fs-20 {
	font-size: 20px !important;
	line-height: 1.5;
}

.fs-22 {
	font-size: 22px !important;
	line-height: 1.5;
}

.fs-24 {
	font-size: 24px !important;
	line-height: 1.4;
}

.fs-26 {
	font-size: 26px !important;
	line-height: 1.4;
}

.fs-28 {
	font-size: 28px !important;
	line-height: 1.4;
}

.fs-30 {
	font-size: 30px !important;
	line-height: 1.4;
}

.fs-32 {
	font-size: 32px !important;
	line-height: 1.25;
}

.fs-35 {
	font-size: 35px !important;
	line-height: 1.25;
}

.fs-36 {
	font-size: 36px !important;
	line-height: 1.25;
}

.fs-46 {
	font-size: 46px !important;
	line-height: 1.25;
}

.font-w100 {
	font-weight: 100;
}

.font-w200 {
	font-weight: 200;
}

.font-w300 {
	font-weight: 300;
}

.font-w400 {
	font-weight: 400;
}

.font-w500 {
	font-weight: 500;
}

.font-w600 {
	font-weight: 600;
}

.font-w700 {
	font-weight: 700;
}

.font-w800 {
	font-weight: 800;
}

.font-w900 {
	font-weight: 900;
}

.scale1 {
	transform: scale(1.1);
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	display: inline-block;
}

.scale2 {
	transform: scale(1.2);
	-moz-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	display: inline-block;
}

.scale3 {
	transform: scale(1.3);
	-moz-transform: scale(1.3);
	-webkit-transform: scale(1.3);
	-ms-transform: scale(1.3);
	-o-transform: scale(1.3);
	display: inline-block;
}

.scale4 {
	transform: scale(1.4);
	-moz-transform: scale(1.4);
	-webkit-transform: scale(1.4);
	-ms-transform: scale(1.4);
	-o-transform: scale(1.4);
	display: inline-block;
}

.scale5 {
	transform: scale(1.5);
	-moz-transform: scale(1.5);
	-webkit-transform: scale(1.5);
	-ms-transform: scale(1.5);
	-o-transform: scale(1.5);
	display: inline-block;
}

.scale-2 {
	transform: scale(2);
	-moz-transform: scale(2);
	-webkit-transform: scale(2);
	-ms-transform: scale(2);
	-o-transform: scale(2);
	display: inline-block;
}

.height10 {
	height: 10px;
}

.height20 {
	height: 20px;
}

.height30 {
	height: 30px;
}

.height40 {
	height: 40px;
}

.height50 {
	height: 50px;
}

.height60 {
	height: 60px;
}

.height70 {
	height: 70px;
}

.height80 {
	height: 80px;
}

.height90 {
	height: 90px;
}

.height100 {
	height: 100px;
}

.height110 {
	height: 110px;
}

.height120 {
	height: 120px;
}

.height130 {
	height: 130px;
}

.height140 {
	height: 140px;
}

.height150 {
	height: 150px;
}

.height160 {
	height: 160px;
}

.height170 {
	height: 170px;
}

.height180 {
	height: 180px;
}

.height190 {
	height: 190px;
}

.height200 {
	height: 200px;
}

.height210 {
	height: 210px;
}

.height220 {
	height: 220px;
}

.height230 {
	height: 230px;
}

.height240 {
	height: 240px;
}

.height250 {
	height: 250px;
}

.height260 {
	height: 260px;
}

.height270 {
	height: 270px;
}

.height280 {
	height: 280px;
}

.height290 {
	height: 290px;
}

.height300 {
	height: 300px;
}

.height310 {
	height: 310px;
}

.height320 {
	height: 320px;
}

.height330 {
	height: 330px;
}

.height340 {
	height: 340px;
}

.height350 {
	height: 350px;
}

.height360 {
	height: 360px;
}

.height370 {
	height: 370px;
}

.height380 {
	height: 380px;
}

.height390 {
	height: 390px;
}

.height400 {
	height: 400px;
}

.height750 {
	height: 750px;
}

.width10 {
	width: 10px;
}

.width20 {
	width: 20px;
}

.width30 {
	width: 30px;
}

.width40 {
	width: 40px;
}

.width50 {
	width: 50px;
}

.width60 {
	width: 60px;
}

.width70 {
	width: 70px;
}

.width80 {
	width: 80px;
}

.width90 {
	width: 90px;
}

.width100 {
	width: 100px;
}

.width110 {
	width: 110px;
}

.width120 {
	width: 120px;
}

.width130 {
	width: 130px;
}

.width140 {
	width: 140px;
}

.width150 {
	width: 150px;
}

.width160 {
	width: 160px;
}

.width170 {
	width: 170px;
}

.width180 {
	width: 180px;
}

.width190 {
	width: 190px;
}

.width200 {
	width: 200px;
}

.width210 {
	width: 210px;
}

.width220 {
	width: 220px;
}

.width230 {
	width: 230px;
}

.width240 {
	width: 240px;
}

.width250 {
	width: 250px;
}

.width260 {
	width: 260px;
}

.width270 {
	width: 270px;
}

.width280 {
	width: 280px;
}

.width290 {
	width: 290px;
}

.width300 {
	width: 300px;
}

.width310 {
	width: 310px;
}

.width320 {
	width: 320px;
}

.width330 {
	width: 330px;
}

.width340 {
	width: 340px;
}

.width350 {
	width: 350px;
}

.width360 {
	width: 360px;
}

.width370 {
	width: 370px;
}

.width380 {
	width: 380px;
}

.width390 {
	width: 390px;
}

.width400 {
	width: 400px;
}

@-webkit-keyframes crescendo {
	from {
		-webkit-transform: translateY(5px) scale(0.8);
		-ms-transform: translateY(5px) scale(0.8);
		transform: translateY(5px) scale(0.8);
	}
	to {
		-webkit-transform: translateY(0px) scale(1);
		-ms-transform: translateY(0px) scale(1);
		transform: translateY(0px) scale(1);
	}
}

@keyframes gXGDoR {
	from {
		-webkit-transform: translateY(5px) scale(0.8);
		-ms-transform: translateY(5px) scale(0.8);
		transform: translateY(5px) scale(0.8);
	}
	to {
		-webkit-transform: translateY(0px) scale(1);
		-ms-transform: translateY(0px) scale(1);
		transform: translateY(0px) scale(1);
	}
}

@keyframes crescendo {
	from {
		-webkit-transform: translateY(5px) scale(0.8);
		-ms-transform: translateY(5px) scale(0.8);
		transform: translateY(5px) scale(0.8);
	}
	to {
		-webkit-transform: translateY(0px) scale(1);
		-ms-transform: translateY(0px) scale(1);
		transform: translateY(0px) scale(1);
	}
}

@keyframes gXGDoR {
	from {
		-webkit-transform: translateY(5px) scale(0.8);
		-ms-transform: translateY(5px) scale(0.8);
		transform: translateY(5px) scale(0.8);
	}
	to {
		-webkit-transform: translateY(0px) scale(1);
		-ms-transform: translateY(0px) scale(1);
		transform: translateY(0px) scale(1);
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.col-xxl-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.col-xxl-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-xxl-2-5 {
		flex: 0 0 20%;
		max-width: 20%;
	}
	.col-xxl-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xxl-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.col-xxl-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.col-xxl-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xxl-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.col-xxl-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.col-xxl-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xxl-9 {
		flex: 0 0 80%;
		max-width: 80%;
	}
	.col-xxl-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.col-xxl-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.col-xxl-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.mb-xxl-4 {
		margin-bottom: 1.5rem !important;
	}
}

.footer {
	padding-left: 9rem;
	/* background-color: #F5F9F1; */
}

.footer .copyright {
	padding: 0.8375rem;
}

.footer .copyright p {
	text-align: center;
	margin: 0;
}

.footer .copyright a {
	color: #25AFA8;
}

[data-sidebar-style="mini"] .nav-control,
[data-layout="horizontal"] .nav-control {
	display: none;
}


.manage-node-header {
	font-size: 22px;
	line-height: 1.5;
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.manage-node-header {
		font-size: 19px !important;
		line-height: 1.15;
		padding: 0.6rem 0rem 0.6rem 0rem;
		/* text-align: center; */
		letter-spacing: 2px;
		margin-top:0.5rem;
	}
}


.nav-control {
	cursor: pointer;
	position: absolute;
	right: -4.0625rem;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	z-index: 9999;
	font-size: 1.4rem;
	padding: 2px 0.5rem 0;
	border-radius: 2px;
}

@media only screen and (max-width: 767px) {
	.nav-control {
		right: -2.75rem;
	}
	#add-custom-action-btn{
		height: 3.5rem!important;
	}
}

@media only screen and (max-width: 575px) {
	.nav-control {
		right: -3rem;
	}
}

.hamburger {
	display: inline-block;
	left: 0px;
	position: relative;
	top: 3px;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	width: 26px;
	z-index: 999;
}

.hamburger .line {
	background: #25AFA8;
	display: block;
	height: 3px;
	border-radius: 3px;
	margin-top: 6px;
	margin-bottom: 6px;
	margin-left: auto;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
	width: 20px;
}

.hamburger .line:nth-child(2) {
	width: 26px;
}

.hamburger .line:nth-child(3) {
	width: 22px;
}

.hamburger:hover {
	cursor: pointer;
}

.hamburger:hover .line {
	width: 26px;
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
	width: 10px;
	height: 2px;
}

.hamburger.is-active .line:nth-child(2) {
	-webkit-transform: translateX(0px);
	transform: translateX(0px);
	width: 22px;
	height: 2px;
}

.hamburger.is-active .line:nth-child(1) {
	-webkit-transform: translateY(4px) rotate(45deg);
	transform: translateY(4px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
	-webkit-transform: translateY(-4px) rotate(-45deg);
	transform: translateY(-4px) rotate(-45deg);
}


/* pulse in SVG */

svg.pulse-svg {
	overflow: visible;
}

svg.pulse-svg .first-circle,
svg.pulse-svg .second-circle,
svg.pulse-svg .third-circle {
	-webkit-transform: scale(0.3);
	transform: scale(0.3);
	-webkit-transform-origin: center center;
	transform-origin: center center;
	-webkit-animation: pulse-me 3s linear infinite;
	animation: pulse-me 3s linear infinite;
	fill: #25AFA8;
}

svg.pulse-svg .second-circle {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

svg.pulse-svg .third-circle {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}


/* pulse in CSS */

.pulse-css {
	width: 1rem;
	height: 1rem;
	border-radius: 0.5rem;
	border-radius: 3.5rem;
	height: 1rem;
	position: absolute;
	background: #FF507A;
	right: 5px;
	top: .6rem;
	width: 1rem;
}

.pulse-css:after,
.pulse-css:before {
	content: '';
	width: 1rem;
	height: 1rem;
	border-radius: 0.5rem;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: -.2rem;
	background-color: #FF507A;
	margin: auto;
	-webkit-transform: scale(0.3);
	transform: scale(0.3);
	-webkit-transform-origin: center center;
	transform-origin: center center;
	-webkit-animation: pulse-me 3s linear infinite;
	animation: pulse-me 3s linear infinite;
}

[direction="rtl"] .pulse-css:after,
[direction="rtl"] .pulse-css:before {
	left: auto;
	right: -.2rem;
}

@media only screen and (max-width: 991px) {
	.pulse-css {
		height: 0.5rem;
		width: 0.5rem;
	}
}

@-webkit-keyframes pulse-me {
	0% {
		-webkit-transform: scale(0.3);
		transform: scale(0.3);
		opacity: 0;
	}
	50% {
		opacity: 0.1;
	}
	70% {
		opacity: 0.09;
	}
	100% {
		-webkit-transform: scale(3);
		transform: scale(3);
		opacity: 0;
	}
}

@keyframes pulse-me {
	0% {
		-webkit-transform: scale(0.3);
		transform: scale(0.3);
		opacity: 0;
	}
	50% {
		opacity: 0.1;
	}
	70% {
		opacity: 0.09;
	}
	100% {
		-webkit-transform: scale(3);
		transform: scale(3);
		opacity: 0;
	}
}

[data-sidebar-style="full"] .header,
[data-sidebar-style="overlay"] .header {
	width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
	[data-sidebar-style="full"] .header,
	[data-sidebar-style="overlay"] .header {
		width: 100%;
		padding-left: 17rem;
	}
}

@media only screen and (max-width: 1199px) {
	[data-sidebar-style="full"] .header,
	[data-sidebar-style="overlay"] .header {
		width: 100%;
		padding-left: 5rem;
	}
}

[data-sidebar-style="mini"] .header {
	width: 100%;
	padding-left: 5rem;
}

[data-sidebar-style="compact"] .header {
	width: 100%;
	padding-left: 15rem;
}

[data-sidebar-style="compact"] .header .header-content {
	padding-left: 2.4rem;
}

[data-header-position="fixed"] .header {
	position: fixed;
	top: 0;
	width: 100%;
}

[data-header-position="fixed"] .content-body {
	padding-top: 7.5rem;
}

@media only screen and (max-width: 1199px) {
	[data-header-position="fixed"] .content-body {
		padding-top: 5rem;
	}
}


[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
	width: 1199px;
}

[data-sidebar-style="modern"] .header {
	width: 100%;
	padding-left: 9.375rem;
}


.header-left {
	height: 100%;
	display: flex;
	align-items: center;
}

.header-left .dashboard_bar {
	font-size: 28px;
	font-weight: 600;
	color: #000;
}

@media only screen and (max-width: 1400px) {
	.header-left .dashboard_bar {
		font-size: 24px;
	}
}

@media only screen and (max-width: 767px) {
	.header-left .dashboard_bar {
		display: none;
	}
}

.header-left input {
	background: #F5F9F1 !important;
	min-width: 170px;
	min-height: 40px;
	border-color: transparent;
	color: #6e6e6e !important;
	border-top-left-radius: 0.375rem;
	border-bottom-left-radius: 0.375rem;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	box-shadow: none;
	padding: 10px 20px;
	font-size: 16px;
	border: 1px solid #ebebeb;
	border-width: 0;
	font-weight: 300;
}

[direction="rtl"] .header-left input {
	border-top-right-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.header-left input:focus,
.header-left input:active,
.header-left input.active {
	border-color: transparent;
	box-shadow: none;
}

.header-left input::-webkit-input-placeholder {
	/* Edge */
	color: #6e6e6e;
}

.header-left input:-ms-input-placeholder {
	/* Internet Explorer */
	color: #6e6e6e;
}

.header-left input::placeholder {
	color: #6e6e6e;
}

.header-left .search_bar {
	display: flex;
	align-items: center;
	height: 100%;
}

@media only screen and (max-width: 767px) {
	.header-left .search_bar {
		display: none;
	}
}

.header-left .search_bar .dropdown-menu {
	box-shadow: none;
}

.header-left .search_bar .search_icon {
	background: #F5F9F1 !important;
	height: 56px;
	line-height: 22px;
	padding: 15px 20px 15px 0 !important;
	border-top-right-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
	border: 1px solid #ebebeb;
	border-width: 0;
}

.header-left .search_bar .search_icon i {
	font-size: 24px;
	color: #6e6e6e;
	line-height: 1;
}

@media only screen and (max-width: 1199px) {
	.header-left .search_bar .search_icon {
		height: 41px;
		padding: 8px 15px 8px 0 !important;
	}
}

@media only screen and (max-width: 767px) {
	.header-left .search_bar {
		position: static;
	}
	.header-left .search_bar .dropdown-menu {
		width: 300px;
		left: -15vw;
		box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.1);
		border-radius: 4px !important;
	}
}

@media only screen and (max-width: 767px) and (max-width: 575px) {
	.header-left .search_bar .dropdown-menu {
		width: 250px;
		left: -25vw;
	}
}

@media only screen and (max-width: 767px) {
	.header-left .search_bar .dropdown-menu .form-control {
		border-radius: 4px !important;
		width: 100%;
	}
	[direction="rtl"] .header-left .search_bar .dropdown-menu {
		right: -98px;
	}
}

.header-left .search_bar .dropdown-menu,
.header-left .search_bar .dropdown-menu.show {
	border: 0px;
	background-color: transparent;
	border-top-right-radius: 5rem;
	border-bottom-right-radius: 5rem;
}

@media only screen and (min-width: 768px) {
	.header-left .search_bar .dropdown-menu,
	.header-left .search_bar .dropdown-menu.show {
		left: 0;
		top: 0;
		transform: none;
		display: block;
		position: unset;
	}
}

[data-sidebar-style="compact"] .header-left {
	margin-left: 0;
}

.header-right .notification_dropdown .nav-link {
	position: relative;
	color: #25AFA8;
	background: #F6F6F6;
	border-radius: 0.875rem;
	padding: 15px;
	line-height: 1;
}

@media only screen and (max-width: 1400px) {
	.header-right .notification_dropdown .nav-link {
		padding: 10px;
	}
}

.header-right .notification_dropdown .nav-link.secondary {
	background: #f8ebfa;
	color: #C046D3;
}

.header-right .notification_dropdown .nav-link.secondary [fill] {
	fill: #C046D3;
}

.header-right .notification_dropdown .nav-link.primary {
	background: #ddf7c7;
	color: #25AFA8;
}

.header-right .notification_dropdown .nav-link.primary [fill] {
	fill: #25AFA8;
}

.header-right .notification_dropdown .nav-link.warning {
	background: #fff9f4;
	color: #FF9432;
}

.header-right .notification_dropdown .nav-link.warning [fill] {
	fill: #FF9432;
}

.header-right .notification_dropdown .nav-link.success {
	background: #d5f5f1;
	color: #37D1BF;
}

.header-right .notification_dropdown .nav-link.success [fill] {
	fill: #37D1BF;
}

.header-right .notification_dropdown .nav-link i {
	font-size: 24px;
}

@media only screen and (max-width: 1400px) {
	.header-right .notification_dropdown .nav-link i {
		font-size: 18px;
	}
}

@media only screen and (max-width: 1400px) {
	.header-right .notification_dropdown .nav-link svg {
		width: 18px;
		height: 18px;
	}
}

@media only screen and (max-width: 600px) {
	.header-right .notification_dropdown .nav-link svg {
		width: 24px;
		height: 24px;
	}
}

.header-right .notification_dropdown .nav-link .badge {
	position: absolute;
	font-size: 14px;
	border-radius: 20px;
	right: -7px;
	top: -7px;
	font-weight: normal;
	height: 26px;
	width: 26px;
	line-height: 26px;
	text-align: center;
	padding: 0px;
}

@media only screen and (max-width: 1400px) {
	.header-right .notification_dropdown .nav-link .badge {
		right: -2px;
		top: -2px;
		font-size: 12px;
		height: 18px;
		width: 18px;
		line-height: 18px;
	}
}

.header-right .notification_dropdown .dropdown-item:focus a,
.header-right .notification_dropdown .dropdown-item:active a {
	color: #fff;
}

.header-right .notification_dropdown .dropdown-item a {
	color: #6e6e6e;
}

.header-right .notification_dropdown .dropdown-item a:hover {
	text-decoration: none;
}

.header-right .dropdown-menu {
	border-width: 0;
	box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
}

.header-right .header-profile>a.nav-link {
	padding: 0 0 0 40px;
	display: flex;
	align-items: center;
	border-radius: 0.875rem 28px 28px 0.875rem;
}

@media only screen and (max-width: 575px) {
	.header-right .header-profile>a.nav-link {
		background: transparent;
		padding: 0;
	}
}

.header-right .header-profile>a.nav-link i {
	font-weight: 700;
}

.header-right .header-profile>a.nav-link .header-info {
	padding-right: 15px;
	text-align: right;
	border-left: 1px solid rgba(238, 238, 238, 0.7);
	padding-left: 0px;
}

@media only screen and (max-width: 1400px) {
	.header-right .header-profile>a.nav-link .header-info {
		padding-right: 10px;
	}
}

@media only screen and (max-width: 575px) {
	.header-right .header-profile>a.nav-link .header-info {
		display: none;
	}
}

.header-right .header-profile>a.nav-link .header-info span {
	font-size: 18px;
	display: block;
	font-weight: 600;
}

.header-right .header-profile>a.nav-link .header-info strong {
	font-weight: 600;
}

.header-right .header-profile>a.nav-link .header-info small {
	display: block;
	font-size: 13px;
	color: #89879f;
	font-weight: 400;
	line-height: 1.2;
}

@media only screen and (min-width: 500px) and (max-width: 2500px) {
	.header-right .header-profile .dropdown-menu {
		padding: 15px 0;
		min-width: 12.5rem;
	}
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.header-right .header-profile .dropdown-menu {
		padding: 15px 0px 15px 0px !important;
		min-width: 5rem;
		max-width: 6.5rem;
	}
	.profile-dropdown-icon {
		display: none;
	}
}

.header-right .header-profile .dropdown-menu a:hover,
.header-right .header-profile .dropdown-menu a:focus {
	color: #25AFA8;
}

.header-right .header-profile img {
	width: 57px;
	height: 57px;
	border-radius: 20rem;
	border: 4px solid #ffffff;
}

@media only screen and (max-width: 1400px) {
	.header-right .header-profile img {
		width: 39px;
		height: 39px;
	}
}




.header-right .header-profile .dropdown-toggle i {
	font-size: 1.25rem;
}

@media only screen and (max-width: 575px) {
	.header-right .header-profile .dropdown-toggle span {
		display: none;
	}
}

.header-right .header-profile .profile_title {
	background: #25AFA8;
	color: #fff;
	padding: 10px 20px;
}

.header-right .header-profile .profile_title h5 {
	color: #fff;
	margin-bottom: 3px;
}

.header-right .header-profile .dropdown-item {
	padding: 8px 24px;
}

.dz-fullscreen #icon-minimize {
	display: none;
}

.dz-fullscreen.active #icon-full {
	display: none;
}

.dz-fullscreen.active #icon-minimize {
	display: inline-block;
}


[direction="rtl"][data-sidebar-style="mini"] .header {
	padding: 0 0.9375rem;
	padding-right: 3.75rem;
}

[direction="rtl"][data-sidebar-style="compact"] .header {
	padding: 0 0.9375rem;
	padding-right: 9.375rem;
}

[direction="rtl"][data-sidebar-style="modern"] .header {
	padding: 0 0.9375rem;
	padding-right: 9.375rem;
}



[direction="rtl"] .list-group {
	padding-left: 0;
}

[direction="rtl"] .form-check-input {
	margin-left: -1.25rem;
	margin-right: inherit;
}

[direction="rtl"] .form-check-inline .form-check-input {
	margin-right: 0;
	margin-left: 10px;
}

[direction="rtl"] .form-check-input1 {
	margin-left: -1.25rem;
	margin-right: inherit;
}

[direction="rtl"] .form-check-inline .form-check-input1 {
	margin-right: 0;
	margin-left: 10px;
}

[direction="rtl"] .radio input,
[direction="rtl"] .radio-inline,
[direction="rtl"] .checkbox input,
[direction="rtl"] .checkbox-inline input {
	margin-left: 0;
	margin-right: 0;
}

[direction="rtl"] .content-body {
	margin-right: 17.1875rem;
	margin-left: auto;
}

[data-sidebar-style="modern"][direction="rtl"] .content-body {
	margin-right: 9.375rem;
}

[direction="rtl"] .content-body .page-titles {
	text-align: right;
}


[direction="rtl"][data-sidebar-style="overlay"] .content-body {
	margin-right: 0;
}

[direction="rtl"] #external-events .external-event:before {
	margin-right: 0;
	margin-left: .9rem;
}

[direction="rtl"] .post-input a i {
	margin-left: 15px;
	margin-right: 0;
}

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
	[direction="rtl"] .content-body {
		margin-right: 14.375rem;
	}
}

[direction="rtl"] .sidebar-right {
	left: -15.625rem;
	right: auto;
	box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1);
}

[direction="rtl"] .sidebar-right.show {
	left: 0;
	right: auto;
}

[direction="rtl"] .sidebar-right .sidebar-right-trigger {
	left: 100%;
	right: auto;
	border-radius: 0 5px 5px 0;
	box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}



html[dir="rtl"] [direction="rtl"] .footer {
	padding-right: 17.1875rem;
	padding-left: 0;
}

@media (max-width: 767.98px) {
	html[dir="rtl"] [direction="rtl"] .footer {
		padding-right: 0;
	}
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
	padding-right: 0;
}

html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
	padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
	padding-right: 0;
}

.nav-label {
	margin: 10px 30px 0;
	padding: 1.5625rem 0 10px;
	text-transform: uppercase;
	font-size: 0.75rem;
	letter-spacing: 0.05rem;
	border-top: 1px solid #eaeaea;
	color: #999999;
}

.nav-label.first {
	border: 0px;
	margin-top: 0px;
}

.nav-badge {
	position: absolute;
	right: 2.8125rem;
	top: 0.625rem;
}

.content-body {
	margin-left: 21.563rem;
	z-index: 0;
	transition: all .2s ease;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
	.content-body {
		margin-left: 17rem;
	}
}

.bell img {
	-webkit-animation: ring 8s .7s ease-in-out infinite;
	-webkit-transform-origin: 50% 4px;
	-moz-animation: ring 8s .7s ease-in-out infinite;
	-moz-transform-origin: 50% 4px;
	animation: ring 8s .7s ease-in-out infinite;
}


.sidebar-right {
	right: -15.625rem;
	position: fixed;
	top: 0;
	width: 15.625rem;
	background-color: #fff;
	height: calc(100% - 7.0625rem);
	margin-top: 5rem;
	transition: all .5s ease-in-out;
	padding-bottom: 1.875rem;
	box-shadow: -2px 3px 10px 0px rgba(119, 119, 119, 0.1);
}

[direction="rtl"] .sidebar-right .slimScrollDiv {
	overflow: visible !important;
}

.sidebar-right .sidebar-right-trigger {
	position: absolute;
	z-index: 9;
	top: 4.75rem;
	right: 100%;
	background-color: #fff;
	color: #25AFA8;
	display: inline-block;
	height: 3rem;
	width: 3rem;
	text-align: center;
	font-size: 1.75rem;
	line-height: 3rem;
	border-radius: 5px 0 0 5px;
	box-shadow: -5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

.sidebar-right .sidebar-right-trigger:hover {
	color: #25AFA8;
}

.sidebar-right.show {
	right: 0;
	z-index: 999;
}

.sidebar-right .nav-tabs {
	justify-content: space-between;
	position: sticky;
	top: 0;
	background-color: #fff;
	z-index: 2;
}

.sidebar-right .nav-tabs .nav-item {
	margin-bottom: 0;
	flex: 1;
}

.sidebar-right .nav-tabs .nav-item .nav-link {
	border: 0;
	font-size: 1.125rem;
	position: relative;
	text-align: center;
	background-color: #fff;
}

.sidebar-right .nav-tabs .nav-item .nav-link::after {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	content: "";
	background: transparent;
}

.sidebar-right .tab-content {
	padding: 1.25rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings>div {
	margin-bottom: 10px;
}

.sidebar-right .tab-content .tab-pane .admin-settings p {
	margin-bottom: 0.125rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"] {
	display: none;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]+label {
	display: inline-block;
	width: 25px;
	height: 25px;
	cursor: pointer;
	transition: all .1s ease;
	border-radius: 4px;
	margin-right: 5px;
	margin-bottom: 3px;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label {
	position: relative;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label::after {
	height: 33px;
	width: 33px;
	left: -4px;
	top: -4px;
	content: "";
	position: absolute;
	background-color: inherit;
	border-radius: 6px;
	opacity: 0.4;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #primary_color_1+label {
	background-color: #fff;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #primary_color_1+label {
	border: 1px solid #89879f;
}

@keyframes bounce {
	0% {
		transform: translateX(-8%);
		-webkit-transform: translateX(-8%);
	}
	50% {
		transform: translateX(8%);
		-webkit-transform: translateX(8%);
	}
	100% {
		transform: translateX(-8%);
		-webkit-transform: translateX(-8%);
	}
}

@-webkit-keyframes bounce {
	0% {
		transform: translateX(-8%);
		-webkit-transform: translateX(-8%);
	}
	50% {
		transform: translateX(8%);
		-webkit-transform: translateX(8%);
	}
	100% {
		transform: translateY(-8%);
		-webkit-transform: translateY(-8%);
	}
}

.nav-user {
	background: #25AFA8;
	margin-bottom: 10px;
	padding: 20px 25px 15px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
	.nav-user {
		padding: 20px 15px 15px;
	}
}

.nav-user img {
	width: 35px;
	height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
	.nav-user img {
		width: 35px;
		height: 35px;
		margin-bottom: 10px;
	}
}

.nav-user h5 {
	margin-left: 10px;
	margin-bottom: 3px;
	color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
	.nav-user h5 {
		display: none;
	}
}


.nav-user p {
	margin-left: 10px;
	margin-bottom: 8px;
	color: #afcff7;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
	.nav-user p {
		display: none;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
	.nav-user i {
		margin-top: 15px;
		display: block;
	}
}

.menu-toggle .nav-user {
	padding: 20px 15px 15px;
}

.menu-toggle .nav-user img {
	width: 35px;
	height: 35px;
	margin-bottom: 10px;
}

.menu-toggle .nav-user h5,
.menu-toggle .nav-user p {
	display: none;
}

.menu-toggle .nav-user i {
	margin-top: 15px;
	display: block;
}

.menu-toggle .nav-user .dropdown-menu {
	left: 45px !important;
	top: 22px !important;
}

@media only screen and (max-width: 576px) {
	.chatbox {
		width: 280px;
	}
	.chatbox .chatbox-close {
		right: 280px;
	}
}



@media (min-width: 992px) {
	[data-container="boxed"] #main-wrapper {
		max-width: 1199px;
		margin: 0 auto;
	}
	[direction="rtl"][data-container="boxed"] #main-wrapper {
		text-align: right;
	}
}

@media only screen and (min-width: 1350px) {
	[data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
		width: 1199px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1349px) {
	[data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
		width: 1199px;
	}
}

[data-container="boxed"] .metismenu.fixed {
	left: auto;
	max-width: 1199px;
}

[data-container="boxed"] .page-titles {
	margin-bottom: 3rem;
	padding: 15px 15px;
}

[data-container="boxed"] .content-body .container-fluid,
[data-container="boxed"] .content-body .container-sm,
[data-container="boxed"] .content-body .container-md,
[data-container="boxed"] .content-body .container-lg,
[data-container="boxed"] .content-body .container-xl {
	padding: 0.9375rem 0.9375rem 0 0.9375rem;
}

[data-container="boxed"][data-layout="vertical"] .page-titles {
	margin-left: 0;
	margin-right: 0;
}




@media (min-width: 992px) {
	[data-container="wide-boxed"] #main-wrapper {
		max-width: 1480px;
		margin: 0 auto;
	}
}

@media (min-width: 992px) {
	[data-container="wide-boxed"][data-sidebar-style="full"] .header {
		width: 100%;
	}
	[data-container="wide-boxed"][data-sidebar-style="full"] .menu-toggle .header {
		width: 100%;
	}
	[data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="full"] .header {
		max-width: 1480px;
	}
	[data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="full"] .menu-toggle .header {
		max-width: 1480px;
	}
}

@media (min-width: 992px) {
	[data-container="wide-boxed"][data-sidebar-style="mini"] .header {
		width: 100%;
	}
	[data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="mini"] .header {
		max-width: 1480px;
	}
}

@media (min-width: 992px) {
	[data-container="wide-boxed"][data-sidebar-style="compact"] .header {
		width: 100%;
	}
	[data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="compact"] .header {
		max-width: 1480px;
	}
}

@media (min-width: 992px) {
	[data-container="wide-boxed"][data-sidebar-style="overlay"] .header {
		width: 100%;
	}
	[data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="overlay"] .header {
		max-width: 1480px;
	}
}

.bg-primary-alt {
	background-color: rgba(108, 197, 29, 0.1);
}

.bg-secondary-alt {
	background-color: rgba(192, 70, 211, 0.1);
}

.bg-success-alt {
	background-color: rgba(55, 209, 191, 0.1);
}

.bg-info-alt {
	background-color: rgba(30, 167, 197, 0.1);
}

.bg-warning-alt {
	background-color: rgba(255, 148, 50, 0.1);
}

.bg-danger-alt {
	background-color: rgba(255, 40, 92, 0.1);
}

.bg-light-alt {
	background-color: rgba(232, 232, 232, 0.1);
}

.bg-dark-alt {
	background-color: rgba(110, 110, 110, 0.1);
}

[data-typography="opensans"] {
	font-family: 'Open Sans', sans-serif;
}

[data-typography="poppins"] {
	font-family: 'poppins', sans-serif;
}

[data-typography="roboto"] {
	font-family: 'Roboto', sans-serif;
}


.char-type {
	padding-top: 30px;
	padding-bottom: 30px;
}

.char-type form .form-control {
	height: 45px;
	padding-left: 18px;
	background: #F5F9F1;
	border-right: 0;
}

.char-type form .input-group-append i {
	color: #898989;
	font-size: 18px;
}

.char-type form .input-group-append .input-group-text {
	padding-left: .7rem;
	padding-right: .7rem;
	background: #F5F9F1;
	border-color: #f0f1f5;
	border-left: 0;
}

.char-type form .input-group-append .input-group-text:last-child {
	padding-right: 1.8rem;
}



#bar1 {
	height: 150px !important;
}

.c3 {
	height: 250px;
}

.c3-legend-item {
	fill: #9fabb1;
}

.c3 .c3-axis-x path,
.c3 .c3-axis-x line,
.c3 .c3-axis-y path,
.c3 .c3-axis-y line,
.tick text {
	stroke: #fff;
}

.flot-chart {
	height: 15.7rem;
}

.tooltipflot {
	background-color: transparent;
	font-size: 1.4rem;
	padding: .5rem 1rem;
	color: rgba(255, 255, 255, 0.7);
	border-radius: .2rem;
}

.legendColorBox>div {
	border: 0 !important;
	padding: 0 !important;
}

.legendLabel {
	font-size: 0.825rem;
	padding-left: .5rem;
	color: #fff;
}

.flotTip {
	background: #000;
	border: 1px solid #000;
	color: #fff;
}

.legend>div {
	background: transparent !important;
}

#balance_graph {
	height: 260px;
}


svg text {
	font-weight: 600 !important;
}




#sparkline-composite-chart canvas,
#composite-bar canvas,
#sparkline11 canvas,
#StackedBarChart canvas,
#spark-bar canvas,
#tristate canvas {
	height: 100px !important;
}


.input-rounded {
	border-radius: 100px;
}

[data-theme-version="light"] .input-primary .form-control,
[data-theme-version="light"] .input-primary .input-group-text,
[data-theme-version="light"] .input-danger .form-control,
[data-theme-version="light"] .input-danger .input-group-text,
[data-theme-version="light"] .input-info .form-control,
[data-theme-version="light"] .input-info .input-group-text,
[data-theme-version="light"] .input-success .form-control,
[data-theme-version="light"] .input-success .input-group-text,
[data-theme-version="light"] .input-warning .form-control,
[data-theme-version="light"] .input-warning .input-group-text,
[data-theme-version="light"] .input-primary-o .form-control,
[data-theme-version="light"] .input-primary-o .input-group-text,
[data-theme-version="light"] .input-danger-o .form-control,
[data-theme-version="light"] .input-danger-o .input-group-text,
[data-theme-version="light"] .input-info-o .form-control,
[data-theme-version="light"] .input-info-o .input-group-text,
[data-theme-version="light"] .input-success-o .form-control,
[data-theme-version="light"] .input-success-o .input-group-text,
[data-theme-version="light"] .input-warning-o .form-control,
[data-theme-version="light"] .input-warning-o .input-group-text,
.input-group-text {
	background: #d7dae3;
	border: 1px solid transparent;
	min-width: 50px;
	display: flex;
	justify-content: center;
	padding: 0.532rem 0.75rem;
	border-radius: 0;
}

.input-group-text i {
	font-size: 16px;
}


.input-group-prepend .btn,
.input-group-append .btn {
	z-index: 0;
}

.custom-select {
	background: none;
	border-color: #f0f1f5;
	color: #6e6e6e;
}

.custom-select:focus {
	box-shadow: none;
	border-color: #25AFA8;
	color: #25AFA8;
}

.custom-file-label:after {
	background: #25AFA8;
	border: 1px solid #f0f1f5;
	color: #fff;
	border-radius: 0;
}


input[type="checkbox"]:after {
	content: '';
	display: block;
	width: 1rem;
	height: 1rem;
	margin-top: -1px;
	margin-left: -1px;
	border: 1px solid #c2c2c2;
	border-radius: 3px;
	background: #ffffff;
	line-height: 1.3;
}

input[type="checkbox"]:checked:after {
	width: 1rem;
	height: 1rem;
	display: block;
	content: "\f00c";
	font-family: 'FontAwesome';
	color: #fff;
	font-weight: 100;
	font-size: 12px;
	text-align: center;
	border-radius: 3px;
	background: #25AFA8;
	outline: none!important;
	border: 0px !important;
}

.form-check-label {
	margin-left: 5px;
	margin-top: 3px;
}

.form-check-inline .form-check-input {
	margin-right: 0.625rem;
}

.form-check-inline .form-check-input1 {
	margin-right: 0.625rem;
}

.custom-control-label:before,
.custom-control-label:after {
	top: 2px;
	width: 1.25rem;
	height: 1.25rem;
	border-color: #e7e7e7;
}

.rtl .custom-control-label:before,
.rtl .custom-control-label:after {
	right: -1.5rem !important;
	left: inherit;
}

.custom-control {
	line-height: normal;
}

.custom-control-input:focus~.custom-control-label::before {
	box-shadow: none !important;
}

.custom-control-label::before {
	background-color: transparent;
	border-color: #E1E1E1;
	border-width: 2px;
	border-radius: 0 !important;
}

.check-xs .custom-control-label:before,
.check-xs .custom-control-label:after {
	width: 18px;
	height: 18px;
}

.check-lg .custom-control-label:before,
.check-lg .custom-control-label:after {
	width: 24px;
	height: 24px;
}

.check-xl .custom-control-label:before,
.check-xl .custom-control-label:after {
	width: 28px;
	height: 28px;
}

.checkbox-info .custom-control-label::before {
	background-color: transparent;
	border-color: #d9f4f9;
}

.checkbox-info .custom-control-input:checked~.custom-control-label::before {
	background-color: #1EA7C5;
	border-color: #1EA7C5;
}

.checkbox-danger .custom-control-label::before {
	background-color: transparent;
	border-color: #ffeaef;
}

.checkbox-danger .custom-control-input:checked~.custom-control-label::before {
	background-color: #FF285C;
	border-color: #FF285C;
}

.checkbox-success .custom-control-label::before {
	background-color: transparent;
	border-color: #d5f5f1;
}

.checkbox-success .custom-control-input:checked~.custom-control-label::before {
	background-color: #37D1BF;
	border-color: #37D1BF;
}

.checkbox-warning .custom-control-label::before {
	background-color: transparent;
	border-color: #fff9f4;
}

.checkbox-warning .custom-control-input:checked~.custom-control-label::before {
	background-color: #FF9432;
	border-color: #FF9432;
}

.checkbox-secondary .custom-control-label::before {
	background-color: transparent;
	border-color: #f8ebfa;
}

.checkbox-secondary .custom-control-input:checked~.custom-control-label::before {
	background-color: #C046D3;
	border-color: #C046D3;
}

.form-control.is-valid {
	border-color: #37D1BF !important;
	border-right: 0px !important;
}

.form-control.is-valid:focus {
	box-shadow: none;
}

.form-control.is-warning {
	border-color: #FF9432 !important;
	border-right: 0px !important;
}

.form-control.is-warning:focus {
	box-shadow: none;
}

.form-control.is-invalid {
	border-color: #FF285C !important;
	/* border-right: 0px !important; */
}

.form-control.is-invalid:focus {
	box-shadow: none;
}

.is-valid .input-group-prepend .input-group-text i {
	color: #37D1BF;
}

.is-invalid .input-group-prepend .input-group-text i {
	color: #93e44a;
}


#image {
	max-width: 100%;
}


.select2-container {
	width: 100% !important;
}

.select2-container--default .select2-selection--single {
	border-radius: 0.875rem;
	border: 1px solid #E8E8E8;
	height: 40px;
	background: #fff;
}

.select2-container--default .select2-selection--single:hover,
.select2-container--default .select2-selection--single:focus,
.select2-container--default .select2-selection--single.active {
	box-shadow: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 40px;
	color: #7e7e7e;
	padding-left: 15px;
	min-height: 40px;
}

.select2-container--default .select2-selection--multiple {
	border-color: #f0f1f5;
	border-radius: 0;
}

.select2-dropdown {
	border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #25AFA8;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	border-color: #f0f1f5;
	background: #fff;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	top: 6px;
	right: 15px;
}

.select2-container .select2-selection--multiple {
	min-height: 40px;
	color: #7e7e7e;
	border-radius: 0.875rem;
	border: 1px solid #E8E8E8;
}

.select2-dropdown {
	border-color: #E8E8E8;
}

.swal2-popup .swal2-content {
	color: #7e7e7e;
}


.go2441762052 {
	padding-bottom: 0rem!important;
	margin-right: 15rem!important;
	margin-left: 15rem!important;
}

.toast-success {
	background-color: #25AFA8;
}

.toast-info {
	background-color: #1EA7C5;
}

.toast-warning {
	background-color: #FF9432;
}

.toast-error {
	background-color: #FF285C;
}

#toast-container>div {
	box-shadow: none;
	border-radius: 0;
	width: auto;
	max-width: 250px;
	opacity: 1;
}

[direction="rtl"] #toast-container>div {
	padding: 15px 50px 15px 15px;
	background-position: calc(100% - 15px);
	text-align: right;
}

#toast-container>div:hover {
	box-shadow: none;
}

#toast-container .toast-title {
	margin-bottom: 5px;
	font-weight: 600;
}

#toast-container .toast-message {
	font-size: 12px;
}

#toast-container .toast-close-button {
	opacity: 1;
	font-size: 8px;
	font-weight: normal;
	text-shadow: none;
}

[direction="rtl"] .toast-top-right.demo_rtl_class {
	left: 12px;
	right: auto;
}


.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
	background-color: transparent;
	opacity: 0.9;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
	background-color: #DAE2F3;
	width: 4px;
}

.ps__thumb-y {
	background-color: #DAE2F3;
	width: 4px;
}

.total-average {
	position: relative;
	height: 300px;
}

.sidebar-right-inner {
	position: relative;
	height: 100%;
}



.alert-social .close:hover {
	opacity: 1 !important;
	color: #fff !important;
}

.left-icon-big .alert-left-icon-big {
	align-self: center;
	margin-right: 0.9375rem;
}

.left-icon-big .alert-left-icon-big i {
	font-size: 35px;
	line-height: 1;
}

[direction="rtl"] .left-icon-big .alert-left-icon-big,
[direction="rtl"] .alert-social .alert-social-icon {
	margin-left: 0;
	margin-right: 0.9375rem;
}


.badge-demo .badge:last-child {
	margin-right: 0;
}

.bootstrap-badge-buttons button {
	margin-right: .2rem;
	margin-bottom: 1rem;
}

.bootstrap-badge-buttons button:last-child {
	margin-right: 0;
}

.welcome-text span,
.welcome-text p {
	display: none;
}

.card-node-group-btn {
	font-size: 0.65rem !important;
	padding: 0rem !important;
}


@media screen and (min-width: 100px) and (max-width: 400px) {
	.btn-icon {
		display: none;
	}
}

.btn-profile-header {
	height: 2.5rem;
	border-radius: 0.5rem
}

@media screen and (min-width: 10px) and (max-width: 400px) {
	.btn-profile-header {
		margin-bottom: 1.5rem;
		display: none;
	}
}

@media screen and (min-width: 400px) and (max-width: 2500px) {
	.btn-profile-header {
		margin-left: auto !important;
	}
}

.sharp {
	min-width: 40px;
	padding: 7px;
	height: 40px;
	min-height: 40px;
}

.sharp.btn-xs {
	padding: 3px;
	width: 26px;
	height: 26px;
	min-width: 26px;
	min-height: 26px;
}

.btn-delete-color {
	color: #25AFA8!important;
}

.user-profile-cert-title {
	display: flex;
	justify-content: space-between;
}

.upload-cert-label {
	color: #0F4542 !important;
	margin-top: 1rem;
	font-weight:500;
}

.upload-cert-content {
	display: flex;
	align-items: center;
}

.user-profile-cert-title-tab {
	margin-left: 0rem!important;
	border-bottom: 0rem!important;
}

.user-profile-cert-title-drop{
	display: none;
}

@media only screen and (max-width: 700px) {
	.user-profile-cert-title-tab {
		display: none;
	}
	.user-profile-cert-title-drop{
		display: unset;
		/* width: 100%; */
	}
	.user-profile-cert-title {
		justify-content: flex-end;
	}
	.user-profile-cert-title-drop .dropdown >button{
		height:2.5rem;
	}
}


.profile-card-body{
	padding: 0rem!important;
}



.command-sample {
	margin-top: 1.5rem;
	margin-bottom: 2rem;
	font-size: 0.62rem;
	background-color: #f1f1f1;
	padding-top: 1rem;
	padding-right:1rem;
	padding-left:1rem;
	padding-bottom:1rem;
	border-radius: 5px;
}

.command-sample-title{
	font-weight: 600;
}

.btn-icon-right {
	border-left: 1px solid white;
	display: inline-block;
	margin: -.8rem 0 -.8rem 1rem;
	padding: 0.4375rem 0 0.4375rem 1rem;
	margin: -1rem -0.25rem -1rem 1rem;
	padding: 1rem 0 1rem 1.25rem;
}

@media only screen and (max-width: 1199px) {
	.btn-icon-right {
		padding: 1rem 0 1rem 0.9rem;
	}
}

.btn-icon-left {
	background: #fff;
	border-radius: 10rem;
	display: inline-block;
	margin: -0.5rem 0.75rem -0.5rem -1.188rem;
	padding: 0.5rem 0.80rem 0.5rem;
	float: left;
}

@media only screen and (max-width: 1199px) {
	.btn-icon-left {
		margin: -0.5rem 0.75rem -0.5rem -0.800rem;
	}
}

[direction="rtl"] .btn-icon-left {
	margin: -.5rem -1.5rem -.5rem 1rem;
}

[direction="rtl"] .btn-icon-right {
	border-left: 0px solid white;
	display: inline-block;
	margin: -.8rem 1rem -.8rem 0;
	padding: 0.4375rem 1rem 0.4375rem 0;
	border-right: 1px solid white;
}


.grid-col {
	padding: 0.5rem !important;
	background: #f2f4fa;
}

.row.grid {
	margin-bottom: 1.5rem;
	text-align: center;
}

.row.grid .grid-col:first-child {
	text-align: left;
}


.overlay-box {
	position: relative;
	z-index: 1;
}

.overlay-box:after {
	content: "";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	position: absolute;
	opacity: 0.85;
	background: #25AFA8;
	z-index: -1;
}



.tdl-holder {
	margin: 0 auto;
}

.tdl-holder ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.tdl-holder li {
	background-color: #fff;
	border-bottom: 1px solid #f0f1f5;
	list-style: none none;
	margin: 0;
	padding: 0;
}

.tdl-holder li span {
	margin-left: 35px;
	font-size: 1rem;
	vertical-align: middle;
	transition: all 0.2s linear;
}

[direction="rtl"] .tdl-holder li span {
	margin-left: auto;
	margin-right: 35px;
}

.tdl-holder label {
	cursor: pointer;
	display: block;
	line-height: 50px;
	padding-left: 1.5rem;
	position: relative;
	margin: 0 !important;
}

[direction="rtl"] .tdl-holder label {
	padding-left: 0;
	padding-right: 1.5rem;
}

.tdl-holder label:hover {
	background-color: #eef1f6;
	color: #6e6e6e;
}

.tdl-holder label:hover a {
	color: #FF285C;
}

.tdl-holder label a {
	color: #fff;
	display: inline-block;
	line-height: normal;
	height: 100%;
	text-align: center;
	text-decoration: none;
	width: 50px;
	-webkit-transition: all .2s linear;
	-moz-transition: all .2s linear;
	-o-transition: all .2s linear;
	transition: all 0.2s linear;
	padding: 18px 0px;
	font-size: 18px;
	position: absolute;
	right: 0;
}

[direction="rtl"] .tdl-holder label a {
	right: auto;
	left: 0;
}

.tdl-holder input[type=checkbox] {
	cursor: pointer;
	opacity: 0;
	position: absolute;
}

.tdl-holder input[type=checkbox]+i {
	background-color: #fff;
	border: 1px solid #e7e7e7;
	display: block;
	height: 20px;
	position: absolute;
	top: 15px;
	width: 20px;
	z-index: 1;
	border-radius: 00px;
	font-size: 11px;
	border-radius: 4px;
}

.tdl-holder input[type=checkbox]:checked+i {
	background: #25AFA8;
	border-color: transparent;
}

.tdl-holder input[type=checkbox]:checked+i::after {
	content: "\f00c";
	font-family: 'fontAwesome';
	display: block;
	left: 2px;
	position: absolute;
	top: -14px;
	z-index: 2;
	color: #fff;
}

.tdl-holder input[type=checkbox]:checked~span {
	text-decoration: line-through;
	position: relative;
}

.tdl-holder input[type=text] {
	background-color: #fff;
	height: 50px;
	margin-top: 29px;
	border-radius: 3px;
}

.tdl-holder input[type=text]::placeholder {
	color: #6e6e6e;
}

.card-list {
	overflow: unset;
	height: calc(100% - 50px);
}

.card-list .card-header {
	padding-top: 0;
	padding-bottom: 0;
}

.card-list .card-header .photo {
	overflow: hidden;
	border-radius: 5px;
	width: 100%;
}

@media only screen and (max-width: 1199px) {
	.card-list {
		height: calc(100% - 40px);
	}
}

.card-list.list-left {
	margin-top: 15px;
	margin-left: 15px;
}

.card-list.list-left .card-header {
	padding-left: 0;
}

.card-list.list-left .card-header .photo {
	margin: -15px 15px 0 -15px;
}

.card-list.list-right {
	margin-top: 15px;
	margin-right: 15px;
	text-align: right;
}

.card-list.list-right .card-header {
	padding-right: 0;
}

.card-list.list-right .card-header .photo {
	margin: -15px -15px 0 15px;
}

.card-list.list-right .card-header .photo img {
	width: 100%;
}

.card-list.list-center {
	margin-top: 15px;
}

.card-list.list-center .card-header .photo {
	margin: -15px 0 0 0;
}

.card-list .photo img {
	width: 100%;
}

.card-profile .profile-photo {
	margin-top: -20px;
}

.widget-media .timeline .timeline-panel {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #eaeaea;
	padding-bottom: 15px;
	margin-bottom: 15px;
}

.widget-media .timeline .timeline-panel .media {
	width: 50px;
	height: 50px;
	background: #eee;
	border-radius: 12px;
	overflow: hidden;
	font-size: 20px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	align-self: start;
}

.widget-media .timeline .timeline-panel .media-primary {
	background: #ddf7c7;
	color: #25AFA8;
}

.widget-media .timeline .timeline-panel .media-info {
	background: #d9f4f9;
	color: #1EA7C5;
}

.widget-media .timeline .timeline-panel .media-warning {
	background: #fff9f4;
	color: #FF9432;
}

.widget-media .timeline .timeline-panel .media-danger {
	background: #ffeaef;
	color: #FF285C;
}

.widget-media .timeline .timeline-panel .media-success {
	background: #d5f5f1;
	color: #37D1BF;
}

.widget-media .timeline .timeline-panel .media-body p {
	font-size: 14px;
	line-height: 1.5;
}

.widget-media .timeline .timeline-panel .dropdown {
	align-self: self-end;
	margin-top: 5px;
}

.widget-media .timeline li:last-child .timeline-panel {
	margin-bottom: 0;
	border-bottom: 0;
	padding-bottom: 0;
}

.card[class*="bg-"] .timeline .timeline-panel {
	border-color: rgba(255, 255, 255, 0.2) !important;
}

@media only screen and (max-width: 1199px) {
	.table {
		font-size: 0.875rem !important;
	}
}

.table strong {
	color: #6e6e6e;
}

.table td {
	border-color: #f0f1f5;
	padding: 12px 20px;
}

.table.shadow-hover tr:hover {
	background-color: #fff;
	box-shadow: 0 0px 27px rgba(0, 0, 0, 0.06);
}

.table.shadow-hover tr:hover thead th {
	border-color: #fff;
}

.table.bg-secondary-hover th,
.table.bg-secondary-hover td {
	border: none;
	font-weight: 500;
}

.table.bg-secondary-hover tr:hover {
	background: #C046D3;
}

.table.bg-secondary-hover tr:hover td,
.table.bg-secondary-hover tr:hover th {
	color: #fff !important;
}

.table.bg-info-hover th,
.table.bg-info-hover td {
	border: none;
	font-weight: 500;
}

.table.bg-info-hover tr:hover {
	background: #1EA7C5;
}

.table.bg-info-hover tr:hover td,
.table.bg-info-hover tr:hover th {
	color: #fff !important;
}

.table.bg-warning-hover th,
.table.bg-warning-hover td {
	border: none;
	font-weight: 500;
}

.table.bg-warning-hover tr:hover {
	background: #FF9432;
}

.table.bg-warning-hover tr:hover td,
.table.bg-warning-hover tr:hover th {
	color: #fff !important;
}

.table thead th {
	border-bottom-width: 1px;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0.5px;
	padding: 15px 20px;
	border-color: #f0f1f5;
}

.table tbody tr td {
	border-color: #f0f1f5;
}

.table:not(.table-bordered) thead th {
	border-top: none;
}

.table .thead-primary th {
	background-color: #25AFA8;
	color: #fff;
}

.table .thead-info th {
	background-color: #1EA7C5;
	color: #fff;
}

.table .thead-warning th {
	background-color: #FF9432;
	color: #fff;
}

.table .thead-danger th {
	background-color: #FF285C;
	color: #fff;
}

.table .thead-success th {
	background-color: #37D1BF;
	color: #fff;
}

.table.primary-table-bordered {
	border: 1px solid #f0f1f5;
}

.table.primary-table-bg-hover thead th {
	background-color: #60af1a;
	color: #fff;
	border-bottom: none;
}

.table.primary-table-bg-hover tbody tr {
	background-color: #25AFA8;
	color: #fff;
	transition: all .1s ease;
}

.table.primary-table-bg-hover tbody tr:hover {
	background-color: #78db20;
}

.table.primary-table-bg-hover tbody tr:not(:last-child) td,
.table.primary-table-bg-hover tbody tr:not(:last-child) th {
	border-bottom: 1px solid #60af1a;
}

.table-responsive-tiny {
	min-width: 18.75rem;
}

.table-responsive-sm {
	min-width: 30rem !important;
}

.table-responsive-md {
	min-width: 36rem !important;
}

.table-responsive-lg {
	min-width: 60.9375rem !important;
}

.table-responsive-xl {
	min-width: 70.9375rem !important;
}

.table-primary,
.table-primary>th,
.table-primary>td {
	background-color: #ddf7c7;
	color: #25AFA8;
}

.table-success,
.table-success>th,
.table-success>td {
	background-color: #d5f5f1;
	color: #37D1BF;
}

.table-info,
.table-info>th,
.table-info>td {
	background-color: #d9f4f9;
	color: #1EA7C5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
	background-color: #fff9f4;
	color: #FF9432;
}

.table-danger,
.table-danger>th,
.table-danger>td {
	background-color: #ffeaef;
	color: #FF285C;
}

.table-active,
.table-active>th,
.table-active>td {
	background-color: #f2f4fa;
}

.card-table th:first-child,
.card-table td:first-child {
	padding-left: 30px;
}

@media only screen and (max-width: 575px) {
	.card-table th:first-child,
	.card-table td:first-child {
		padding-left: 15px;
	}
}

.card-table th:last-child,
.card-table td:last-child {
	padding-right: 30px;
}

@media only screen and (max-width: 575px) {
	.card-table th:last-child,
	.card-table td:last-child {
		padding-right: 15px;
	}
}

.bootgrid-header {
	padding: 0 !important;
	margin: 0;
}

@media only screen and (max-width: 575px) {
	.bootgrid-header .actionBar {
		padding: 0;
	}
	.bootgrid-header .search {
		margin: 0 0 10px 0;
	}
}

table#example {
	padding: 0rem 0 2rem 0;
}

table.dataTable {
	color: #3e4954;
	border-radius: 0.875rem;
}

#example2_wrapper .dataTables_scrollBody {
	max-height: 33.25rem !important;
}

#employees,
#custommers {
	padding: .5rem 0 1rem 0;
}

.dataTables_wrapper .dataTables_paginate {
	margin: 10px 0;
	padding: 5px;
	background: #ddf7c7;
	border-radius: 18px;
}

@media only screen and (max-width: 1400px) {
	.dataTables_wrapper .dataTables_paginate {
		padding: 5px 0;
	}
}

table.dataTable thead th,
table.dataTable thead td {
	padding: 22px 15px;
	border-bottom: 1px solid #f0f1f5;
}

@media only screen and (max-width: 1400px) {
	table.dataTable thead th,
	table.dataTable thead td {
		padding: 8px 15px;
	}
}

table.dataTable tfoot th,
table.dataTable tfoot td {
	border-top: 0;
}

table.dataTable tbody td {
	background: #F9F9F9 !important;
}

table.dataTable thead th {
	color: #000;
	font-size: 18px;
	white-space: nowrap;
	font-weight: 600;
}

@media only screen and (max-width: 1400px) {
	table.dataTable thead th {
		font-size: 14px;
	}
}

table.dataTable tbody td {
	font-size: 16px;
	padding: 22px 15px;
}

@media only screen and (max-width: 1400px) {
	table.dataTable tbody td {
		padding: 8px 15px;
		font-size: 14px;
	}
}

table.dataTable tr.selected {
	color: #25AFA8;
}

table.dataTable tfoot th {
	color: #6e6e6e;
	font-weight: 600;
}

.dataTables_wrapper input[type="search"],
.dataTables_wrapper input[type="text"],
.dataTables_wrapper select {
	border: 1px solid #e2e2e2;
	padding: .3rem 0.5rem;
	color: #715d5d;
	border-radius: 5px;
}

.dataTables_wrapper .dataTables_length {
	margin-bottom: 15px;
}



table.dataTable.no-footer {
	border-bottom: 0;
}

.min-w850 {
	min-width: 850px;
}

.rounded-lg {
	min-width: 30px;
}

.dataTables_scroll {
	padding: 1rem 0;
}

.dataTables_scrollFoot {
	padding-top: 1rem;
}

@media (max-width: 991.98px) {
	.dataTables_wrapper .dataTables_length,
	.dataTables_wrapper .dataTables_filter {
		text-align: left;
	}
}

.dataTablesCard {
	background-color: #fff;
	box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
	border-radius: 0;
}

.dataTablesCard tbody tr:hover {
	background: #fff !important;
}

.dataTablesCard tbody tr:hover td {
	background: transparent !important;
}

.dataTables_wrapper .dataTables_info {
	padding: 10px 0;
}

@media only screen and (max-width: 1400px) {
	.dataTables_wrapper .dataTables_info {
		padding: 8px 0;
	}
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
	border-color: #f0f1f5;
}

table.dataTable thead .sorting {
	background-position: center right 15px;
}

.dataTables_wrapper .dataTables_paginate span {
	border-radius: 0;
	display: inline-block;
	margin: 0 0;
}

.dataTables_wrapper .dataTables_paginate span a {
	color: #fff;
	background: transparent !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	padding: 13px 10px;
	color: #25AFA8 !important;
	font-size: 16px;
	margin: 0;
	display: inline-block;
	min-width: 50px;
	min-height: 50px;
	border-radius: 18px;
	border: 0 !important;
}

@media only screen and (max-width: 1400px) {
	.dataTables_wrapper .dataTables_paginate .paginate_button {
		padding: 8px 15px;
		font-size: 14px;
	}
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
	color: #fff !important;
	background: #25AFA8 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
	background: transparent !important;
	color: #25AFA8 !important;
	padding: 13px 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
	background: #25AFA8 !important;
	color: #fff !important;
}

.fooicon {
	font-size: 1.25rem;
	color: #6e6e6e;
}

.jsgrid-table .jsgrid-header-row>.jsgrid-header-cell {
	color: #6e6e6e;
}

.jsgrid-table>tbody>tr>td {
	padding: 1.2em;
}

.jsgrid-table .jsgrid-insert-row input,
.jsgrid-table .jsgrid-insert-row select,
.jsgrid-table .jsgrid-edit-row input,
.jsgrid-table .jsgrid-edit-row select {
	border: 1px solid #DDDFE1;
}

.jsgrid .jsgrid-button {
	border: 0 !important;
	margin-left: 10px;
}

.error-page .error-text {
	font-size: 150px;
	line-height: 1;
}

@media only screen and (max-width: 575px) {
	.error-page .error-text {
		font-size: 80px;
	}
}

.error-page h4 {
	font-size: 40px;
	margin-bottom: 5px;
}

@media only screen and (max-width: 575px) {
	.error-page h4 {
		font-size: 20px;
	}
}

.error-page p {
	font-size: 16px;
}

@media only screen and (max-width: 575px) {
	.error-page p {
		font-size: 14px;
	}
}

.error-page-area {
	height: calc(100vh - 220px);
}

@media only screen and (max-width: 1199px) {
	.error-page-area {
		height: calc(100vh - 180px);
	}
}

.authincation-content {
	background: #fff;
	box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
	border-radius: 5px;
}

.auth-form {
	padding: 25px 25px;
}

@media only screen and (max-width: 575px) {
	.auth-form {
		padding: 30px;
	}
}

.auth-form .btn {
	height: 50px;
	font-weight: 700;
}

.auth-form .page-back {
	display: inline-block;
	margin-bottom: 15px;
}

.authincation-page-area {
	height: calc(100vh - 280px);
}

@media only screen and (max-width: 1199px) {
	.authincation-page-area {
		height: calc(100vh - 220px);
	}
}

.iconbox {
	position: relative;
	padding-left: 70px;
}

.iconbox i {
	background: #25AFA8;
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 50px;
	text-align: center;
	font-size: 28px;
	color: #fff;
	position: absolute;
	left: 0;
	top: 0;
}

.iconbox p {
	margin: 0;
	color: #484848;
	font-size: 18px;
	line-height: 1.3;
	font-weight: 500;
}

.iconbox small {
	margin-bottom: 5px;
	font-size: 16px;
	display: block;
}

.date-icon {
	height: 60px;
	width: 60px;
	font-size: 24px;
	line-height: 60px;
	text-align: center;
	color: #25AFA8;
	background: rgba(108, 197, 29, 0.2);
	border-radius: 1.25rem;
}

.plan-list .list-row {
	border: 1px solid #EAEAEA;
	border-radius: 1.25rem;
}

.plan-list .list-row .list-icon {
	height: 76px;
	width: 76px;
	font-weight: 600;
	line-height: 20px;
	text-align: center;
	border-radius: 1.25rem;
	border: 1px solid rgba(108, 197, 29, 0.2);
}

@media only screen and (max-width: 575px) {
	.plan-list .list-row .list-icon {
		height: 55px;
		width: 55px;
	}
	.plan-list .list-row .list-icon p {
		font-size: 20px !important;
		margin-top: 0px !important;
	}
}

.plan-list .list-row:hover {
	border-color: #25AFA8;
}

.plan-list .list-row:hover .list-icon {
	background: #25AFA8;
}

.plan-list .list-row:hover .list-icon p,
.plan-list .list-row:hover .list-icon span {
	color: #fff !important;
}

.plan-list .list-row .info {
	width: 37%;
}

@media only screen and (max-width: 575px) {
	.plan-list .list-row .info {
		width: 73%;
	}
}

.items-table tbody tr {
	padding: 0;
	border-radius: 0.875rem;
	position: relative;
	box-shadow: 0 1px 0 0 #f8f8f8;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.items-table tbody tr .dropdown {
	opacity: 0;
}

.items-table tbody tr.active,
.items-table tbody tr:hover {
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	z-index: 1;
}

.items-table tbody tr.active .dropdown,
.items-table tbody tr:hover .dropdown {
	opacity: 1;
}

.items-table tbody tr th,
.items-table tbody tr td {
	border: 0;
	padding: 18px 8px;
}

@media only screen and (max-width: 575px) {
	.items-table {
		width: 500px;
	}
}

.star-review i {
	font-size: 16px;
}

.star-review i.text-gray {
	color: #d9d9d9;
}

.star-review span {
	color: #000;
}

.form-head .breadcrumb {
	background: transparent;
	padding: 0;
}

.form-head .search-area {
	max-width: 800px;
	border-radius: 0;
}

@media only screen and (max-width: 1400px) {
	.form-head .search-area {
		max-width: 250px;
	}
}

@media only screen and (max-width: 575px) {
	.form-head .search-area {
		max-width: 250px;
	}
}

.form-head .search-area .form-control {
	border: 0;
	height: 56px;
	padding: 10px 15px;
	font-size: 16px;
	background: #f1f1f1;
	border-radius: 0;
}

@media only screen and (max-width: 1400px) {
	.form-head .search-area .form-control {
		height: 41px;
		padding: 10px 15px;
		font-size: 13px;
	}
}

.form-head .search-area .input-group-append .input-group-text {
	background: #f1f1f1;
	padding: 0 5px 0 30px;
}

@media only screen and (max-width: 1400px) {
	.form-head .search-area .input-group-append .input-group-text {
		padding: 0 5px 0 30px;
	}
}

.form-head .search-area .input-group-append .input-group-text i {
	font-size: 20px;
	color: #7e7e7e;
}

@media only screen and (max-width: 1400px) {
	.form-head .search-area .input-group-append .input-group-text i {
		font-size: 18px;
	}
}

.form-head .dropdown .btn i {
	transform: scale(1.3);
	-moz-transform: scale(1.3);
	-webkit-transform: scale(1.3);
	-ms-transform: scale(1.3);
	-o-transform: scale(1.3);
	display: inline-block;
}

.form-head .dropdown:after {
	color: #25AFA8;
	font-size: 25px;
}

.form-head .btn i {
	line-height: 1;
	transform: scale(1.3);
	display: inline-block;
	margin-right: 5px;
}

.form-head .btn-outline-primary {
	border-color: #eee;
}

.form-head .btn-outline-primary:hover {
	border-color: #25AFA8;
}

@media only screen and (max-width: 767px) {
	.form-head>* {
		margin-right: 5px !important;
		margin-bottom: 10px;
		display: inline-block;
		vertical-align: baseline;
		margin-left: 0 !important;
	}
}

@media only screen and (max-width: 767px) {
	.form-head {
		display: block !important;
	}
}

.rounded {
	border-radius: 0.875rem !important;
}

.btn-link {
	font-weight: 500;
	font-size: 16px;
}

@media only screen and (max-width: 1400px) {
	.btn-link {
		font-size: 14px;
	}
}


.widget-buyer .media img {
	width: 30px;
}

.widget-buyer .media .media-body h5 {
	font-size: 14px;
	margin-bottom: 0px;
}

.widget-buyer .media .media-body p {
	font-size: 13px;
}

.recentOrderTable table {
	margin-bottom: 0;
	overflow: hidden;
}

.recentOrderTable table thead th {
	font-size: 12px;
}

.recentOrderTable table tbody td {
	color: #6e6e6e;
	font-weight: 500;
	line-height: 40px;
}

.recentOrderTable table tbody td img {
	border-radius: 50px;
}

.recentOrderTable table tbody td .custom-dropdown i {
	padding: 0 10px;
	cursor: pointer;
}

.activity_overview .nav-tabs {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.activity_overview .custom-tab-1 .nav-link {
	color: rgba(255, 255, 255, 0.5);
	padding-left: 0;
	padding-right: 0;
	margin-right: 30px;
}

.activity_overview .custom-tab-1 .nav-link:hover,
.activity_overview .custom-tab-1 .nav-link.active {
	background: transparent;
	color: #fff;
	border-color: #fff;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
	background-color: transparent !important;
	opacity: 0.9;
}

@media only screen and (max-width: 575px) {
	.chart-link {
		display: flex;
		align-items: center;
	}
}

@media only screen and (max-width: 1199px) {
	.chart-link {
		display: flex;
		align-items: center;
	}
}

@media only screen and (min-width: 1200px) {
	.chart-link {
		display: flex;
		align-items: center;
	}
}

.chart-link .week-link {
	display: inline-block;
}

.chart-link a {
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	margin-right: 10px;
}

.chart-link a i {
	font-size: 13px;
}

.chart-link a i.text-primary {
	color: #25AFA8;
}

.chart-link a i.text-muted {
	color: #DAE2F3 !important;
}

.chart-link .form-control {
	max-width: 97px;
	display: inline-block;
}

.input-group.input-group-lg .form-control,
.input-group.input-group-lg .input-group-text {
	height: 80px;
	padding: 10px 30px;
}

@media only screen and (max-width: 575px) {
	.input-group.input-group-lg .form-control,
	.input-group.input-group-lg .input-group-text {
		height: 50px;
		padding: 10px 15px;
		font-size: 14px;
	}
}

.input-group.input-group-lg .input-group-text {
	width: 200px;
	text-align: left;
}

@media only screen and (max-width: 575px) {
	.input-group.input-group-lg .input-group-text {
		width: 120px;
		padding: 10px 15px;
		height: 50px;
		font-size: 14px;
	}
}

.card-tabs .nav-tabs {
	border-bottom: 0px;
	background: transparent;
	padding: 0;
	border-radius: 0;
	flex-wrap: unset;
}
.card-tabs2{
	display:flex;
}


.card-tabs2 .nav-tabs {
	border-bottom: 0px;
	background: transparent;
	padding: 0;
	border-radius: 0;
	flex-wrap: unset;
}

.card-tabs3 .nav-tabs {
	border-bottom: 0px;
	background: transparent;
	padding: 0;
	border-radius: 0;
	flex-wrap: unset;
}

.card-tabs4 .nav-tabs {
	cursor:pointer;
	border-bottom: 0px;
	background: transparent;
	padding: 0;
	border-radius: 0;
	flex-wrap: unset;
}

.card-tabs1 .nav-tabs {
	border-bottom: 0px;
	background: transparent;
	padding: 0;
	border-radius: 0;
	flex-wrap: unset;
}

.card-tabs .nav-tabs .nav-link {
	border-radius: 0;
	padding: 12px 20px;
	font-weight: 500;
	position: relative;
	background: transparent;
	font-size: 14px;
	border: 0;
	border-bottom: 0px solid #C0C0C0;
}

.card-tabs2 .nav-tabs .nav-link {
	border-radius: 0;
	padding: 12px 20px;
	font-weight: 500;
	position: relative;
	background: transparent;
	font-size: 14px;
	border: 0;
	border-bottom: 0px solid #C0C0C0;
}

.card-tabs3 .nav-tabs .nav-link {
	border-radius: 0;
	padding: 12px 20px;
	font-weight: 500;
	position: relative;
	background: transparent;
	font-size: 14px;
	border: 0;
	border-bottom: 0px solid #C0C0C0;
}

.card-tabs4 .nav-tabs .nav-link {
	border-radius: 0;
	padding: 12px 20px;
	font-weight: 500;
	position: relative;
	background: transparent;
	font-size: 14px;
	border: 0;
	border-bottom: 0px solid #C0C0C0;
}

.card-tabs1 .nav-tabs .nav-link {
	border-radius: 0;
	padding: 12px 20px;
	font-weight: 500;
	position: relative;
	background: transparent;
	font-size: 14px;
	border: 0;
	border-bottom: 0px solid #C0C0C0;
}

.card-tabs3 .nav-tabs .nav-link {
	border-radius: 0;
	padding: 12px 20px;
	font-weight: 500;
	position: relative;
	background: transparent;
	font-size: 14px;
	border: 0;
	border-bottom: 0px solid #C0C0C0;
}

.card-tabs4 .nav-tabs .nav-link {
	border-radius: 0;
	padding: 12px 20px;
	font-weight: 500;
	position: relative;
	background: transparent;
	font-size: 14px;
	border: 0;
	border-bottom: 0px solid #C0C0C0;
}

.card-tabs .nav-tabs .nav-link:after {
	position: absolute;
	bottom: -5px;
	left: 50%;
	transform: translatex(-50%);
	content: "";
	height: 4px;
	width: 0;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
	background: #25AFA8;
}

.card-tabs2 .nav-tabs .nav-link:after {
	position: absolute;
	bottom: -5px;
	left: 50%;
	transform: translatex(-50%);
	content: "";
	height: 4px;
	width: 0;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
	background: #25AFA8;
}

.card-tabs3 .nav-tabs .nav-link:after {
	position: absolute;
	bottom: -5px;
	left: 50%;
	transform: translatex(-50%);
	content: "";
	height: 4px;
	width: 0;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
	background: #25AFA8;
}

.card-tabs4 .nav-tabs .nav-link:after {
	position: absolute;
	bottom: -5px;
	left: 50%;
	transform: translatex(-50%);
	content: "";
	height: 4px;
	width: 0;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
	background: #25AFA8;
}

.card-tabs1 .nav-tabs .nav-link:after {
	position: absolute;
	bottom: -5px;
	left: 50%;
	transform: translatex(-50%);
	content: "";
	height: 4px;
	width: 0;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
	background: #25AFA8;
}

.card-tabs .nav-tabs .nav-link.active {
	color: #25AFA8;
}

.card-tabs .nav-tabs .nav-link.active:after {
	width: 100%;
}

.card-tabs1 .nav-tabs .nav-link.active {
	color: #25AFA8;
}

.card-tabs1 .nav-tabs .nav-link.active:after {
	width: 100%;
}

.card-tabs2 .nav-tabs .nav-link.active {
	color: #25AFA8;
}

.card-tabs2 .nav-tabs .nav-link.active:after {
	width: 100%;
}

.card-tabs3 .nav-tabs .nav-link.active {
	color: #25AFA8;
}

.card-tabs3 .nav-tabs .nav-link.active:after {
	width: 100%;
}

.card-tabs4 .nav-tabs .nav-link.active:after {
	width: 100%;
}

@media only screen and (min-width: 700px) and (max-width: 1600px) {
	.card-tabs .nav-tabs .nav-link {
		padding: 8px 15px;
		font-weight: 400;
		font-size: 13px;
	}
}

@media only screen and (min-width: 700px) and (max-width: 1600px) {
	.card-tabs2 .nav-tabs .nav-link {
		padding: 8px 15px;
		font-weight: 400;
		font-size: 13px;
	}
}

@media only screen and (min-width: 1500px) and (max-width: 1600px) {
	.card-tabs3 .nav-tabs .nav-link {
		padding: 8px 15px;
		font-weight: 400;
		font-size: 12px;
	}
}

@media only screen and (min-width: 1300px) and (max-width: 1500px) {
	.card-tabs3 .nav-tabs .nav-link {
		padding: 8px 7px;
		font-weight: 400;
		font-size: 12px;
	}
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
	.card-tabs3 .nav-tabs .nav-link {
		padding: 8px 5px;
		font-weight: 400;
		font-size: 11px;
	}
	.card-tabs2 .nav-tabs .nav-link {
		padding: 8px 10px;
	}
}


@media only screen and (min-width: 500px) and (max-width: 600px) {
	.card-tabs3 .nav-tabs .nav-link {
		padding: 8px 6px;
		font-weight: 400;
		font-size: 14px;
	}
}

@media only screen and (min-width: 600px) and (max-width: 700px) {
	.card-tabs3 .nav-tabs .nav-link {
		padding: 8px 6px;
		font-weight: 400;
		font-size: 11px;
	}
}

@media only screen and (min-width: 700px) and (max-width: 1300px) {
	.card-tabs3 .nav-tabs .nav-link {
		padding: 8px 5px;
		font-weight: 400;
		font-size: 10px;
	}
}




@media only screen and (min-width: 700px) and (max-width: 1600px) {
	.card-tabs4 .nav-tabs .nav-link {
		padding: 8px 15px;
		font-weight: 400;
		font-size: 13px;
	}
}

@media only screen and (min-width: 700px) and (max-width: 1600px) {
	.card-tabs1 .nav-tabs .nav-link {
		padding: 8px 15px;
		font-weight: 400;
		font-size: 13px;
	}
}

@media only screen and (min-width: 10px) and (max-width: 450px) {
	.card-tabs .nav-tabs .nav-item-tab {
		padding: 7px 7px;
		font-size: 10.5px;
	}
	.card-tabs {
		display: flex;
		margin: 0.65rem 1.6rem 0rem 0rem !important;
		justify-content: flex-end;
	}
}

@media only screen and (min-width: 10px) and (max-width: 390px) {
	.card-tabs3 .nav-tabs .nav-item-tab {
		padding: 0.5px 4.5px;
		font-size: 10px;
	}

}
@media only screen and (min-width: 390px) and (max-width: 450px) {
	.card-tabs3 .nav-tabs .nav-item-tab {
		padding: 0.5px 7px;
		font-size: 10px;
	}
	.card-tabs3 {
		display: flex;
		margin: 0.65rem 1.0rem 0.2rem 0rem !important;
		justify-content: flex-end;
		flex-direction: column;
	}
	.card-tabs4 .nav-tabs .nav-item-tab {
		padding: 0.5px 7px;
		font-size: 12px;
	}
	.card-tabs4 {
		display: flex;
		margin: 0.65rem 1.0rem 0.2rem 0rem !important;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center
	}
}

@media only screen and (min-width: 10px) and (max-width: 450px) {
	.card-tabs2 .nav-tabs .nav-item-tab {
		font-size: 10.5px;
	}
	.card-tabs2 {
		margin: 0rem 1.4rem 0rem 0.5rem !important;
		justify-content: space-around!important;
	}
}

@media only screen and (min-width: 10px) and (max-width: 450px) {
	.card-tabs1 .nav-tabs .nav-item-tab1 {
		font-size: 11px;
	}
	.card-tabs1 {
		display: flex;
		margin: 0.3rem 1.0rem 0rem 0rem !important;
		justify-content: flex-end;
	}
}

.node-search-block .node-search-input {
	border-top-left-radius: 0.7rem!important;
	border-bottom-left-radius: 0.7rem!important;
}


@media only screen and (min-width: 10px) and (max-width: 500px) {
	.search-grouplist-input {
		margin: 0rem !important;
		padding: 1rem !important;
	}
}

@media only screen and (min-width: 500px) and (max-width: 2500px) {
	.Nodelistbox .node-list-table {
		padding: 1rem!important;
		margin-right: 1.4rem !important;
		
	}
}

@media only screen and (min-width: 0px) and (max-width: 987px) {
	.Nodelistbox .node-list-table {
			max-width:97%!important;
	}
}


.change-tab-content .modify-tab-content .datatable .datatable-inner .datatable-table .datatable-body>tr>td>div {
	padding: 0rem 0rem 1rem 2.2rem;
}

.change-tab-content .modify-tab-content .datatable .datatable-inner .datatable-table .datatable-body>tr>td {
	vertical-align: middle;
	;
}

.warningtext {
	color: #EE6164;
}

.edit-node-group {
	margin-top: 1rem;
	padding: 1.5rem;
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.edit-node-group {
		margin-top: 0rem;
		padding: 0rem;
	}
}

.edit-node-group .datatable .datatable-inner .datatable-table .datatable-header>tr>th {
	font-size: 0.8rem !important;
}

.edit-node-group .datatable .datatable-inner .datatable-table .datatable-body>tr>td {
	font-size: 0.8rem !important;
}

.log-list {
	margin-top: 0rem;
	padding: 1.5rem;
}

.log-list .form-outline .form-control {
	width: 40rem;
}

.log-list .form-outline .mb-4 {
	margin-bottom: 0rem!important;
}

.log-list .form-outline {
	height: 3rem!important;
}

.log-list .datatable .datatable-pagination .datatable-select-wrapper .form-outline {
	margin-top: 0rem!important;
	margin-right: 0.5rem!important;
}

.log-list .datatable .datatable-pagination .datatable-select-wrapper .datatable-select-text {
	font-size: 0.9rem !important;
}

.log-list .datatable .datatable-pagination .datatable-pagination-nav {
	font-size: 0.9rem !important;
}

.log-list .datatable .datatable-inner .datatable-table .datatable-header>tr>th {
	font-size: 0.9rem !important;
	text-align: center;
}

.log-list .datatable .datatable-inner .datatable-table .datatable-body>tr>td {
	font-size: 0.9rem !important;
	text-align: center;
}

@media only screen and (min-width: 10px) and (max-width: 500px) {
	.log-list {
		margin-top: 0.5rem;
		padding: 0rem;
	}
	.log-list .datatable .datatable-inner .datatable-table .datatable-header>tr>th {
		font-size: 0.65rem !important;
		text-align: center;
	}
	.log-list .datatable .datatable-inner .datatable-table .datatable-body>tr>td {
		font-size: 0.65rem !important;
		text-align: center;
	}
	.log-list .form-outline .form-control {
		margin-top: 0.5rem;
		width: 20rem;
		height: 2rem;
	}
	.log-list .datatable .datatable-pagination .datatable-select-wrapper .datatable-select-text {
		font-size: 0.7rem !important;
	}
	.log-list .datatable .datatable-pagination .datatable-pagination-nav {
		font-size: 0.7rem !important;
	}
}

.node-join-list-box .user-table-card-body>edit-user .datatable-inner .datatable-table .datatable-header>tr>th {
	font-size: 0.8rem !important;
}

.node-join-list-box .user-table-card-body>edit-user .datatable-inner .datatable-table .datatable-body>tr>td {
	font-size: 0.8rem !important;
}

.Nodelistbox .node-list-table .datatable .datatable-inner .datatable-table .datatable-header>tr>th {
	font-size: 0.85rem !important;
}

.Nodelistbox .node-list-table .datatable .datatable-inner .datatable-table .datatable-body>tr>td {
	font-size: 0.85rem !important;
}

.Nodelistbox .node-list-table .datatable .datatable-inner .datatable-table .datatable-header>tr>th>div>input {
	position: relative !important;
	outline: none!important;
	border: none !important;
}

@media only screen and (max-width: 500px) {
	.change-tab-content .modify-tab-content .datatable .datatable-inner .datatable-table .datatable-body>tr>td>div {
		padding: 0rem 0rem 1rem 1.3rem;
	}
}

.card-tabs .nav-tabs.tabs-lg .nav-link {
	padding: 23px 30px;
	font-size: 16px;
}

.card-tabs2 .nav-tabs.tabs-lg .nav-link {
	padding: 23px 30px;
	font-size: 16px;
}

.card-tabs1 .nav-tabs.tabs-lg .nav-link {
	padding: 23px 30px;
	font-size: 16px;
}

@media only screen and (max-width: 767px) {
	.card-tabs .nav-tabs.tabs-lg .nav-link {
		padding: 10px 15px;
		font-size: 14px;
	}
}

@media only screen and (max-width: 767px) {
	.card-tabs2 .nav-tabs.tabs-lg .nav-link {
		padding: 10px 15px;
		font-size: 14px;
	}
}

@media only screen and (max-width: 767px) {
	.card-tabs1 .nav-tabs.tabs-lg .nav-link {
		padding: 10px 15px;
		font-size: 14px;
	}
}

.card-tabs.style-1 .nav-tabs .nav-link {
	font-size: 16px;
	color: #000;
}

.card-tabs2.style-1 .nav-tabs .nav-link {
	font-size: 16px;
	color: #000;
}

.card-tabs1.style-1 .nav-tabs .nav-link {
	font-size: 16px;
	color: #000;
}

.card-tabs.style-1 .nav-tabs .nav-link:after {
	content: none;
}

.card-tabs2.style-1 .nav-tabs .nav-link:after {
	content: none;
}

.card-tabs1.style-1 .nav-tabs .nav-link:after {
	content: none;
}

.card-tabs.style-1 .nav-tabs .nav-link span {
	position: absolute;
	bottom: -4px;
	left: 50%;
	transform: translatex(-50%);
	content: "";
	height: 4px;
	width: 0;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.card-tabs2.style-1 .nav-tabs .nav-link span {
	position: absolute;
	bottom: -4px;
	left: 50%;
	transform: translatex(-50%);
	content: "";
	height: 4px;
	width: 0;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.card-tabs1.style-1 .nav-tabs .nav-link span {
	position: absolute;
	bottom: -4px;
	left: 50%;
	transform: translatex(-50%);
	content: "";
	height: 4px;
	width: 0;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.card-tabs .style-1 .nav-tabs .nav-link.active span {
	width: 100%;
}

.card-tabs2 .style-1 .nav-tabs .nav-link.active span {
	width: 100%;
}

.card-tabs1.style-1 .nav-tabs .nav-link.active span {
	width: 100%;
}

.filter-key {
	padding: 0rem 2rem 0rem 2rem;
}

.console-watch-btns {
	display: flex;
	justify-content: center;
	margin: 1.5rem 0rem 1.5rem 0rem;
}

@media only screen and (max-width: 575px) {
	.nav-tabs {
		margin-top: 0.3rem;
		flex-wrap: wrap !important;
	}
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
	.nav-tabs {
		flex-wrap: wrap !important;
		margin: 0rem 1rem 0rem 1rem;
		justify-content: space-around !important;
	}
	.console-message-tab {
		margin: 0rem;
	}
}



@media only screen and (min-width: 800px) and (max-width: 1600px) {
	.nav-tabs {
		flex-wrap: wrap !important;
		margin: 0rem 4rem 0rem 5rem;
		justify-content: space-around !important;
	}
	.console-message-tab {
		margin: 0rem;
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1600px) {
	.nav-item:not(.header-nav-item) {
		margin: 0rem 1rem 0rem 1rem !important;
	}
}

.tab-content-center {
	flex-wrap: wrap !important;
	justify-content: center !important;
}

.node-search-block {
	display: flex;
	padding: 1rem 1rem 0rem 1rem;
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.node-search-block {
		margin: 0rem 0.6rem 0rem 0.6rem;
	}
}

.btn-node-search {
	height: 2.5rem;
	border-top-right-radius: 0.6rem;
	border-bottom-right-radius: 0.6rem;
	border-bottom-left-radius: 0rem;
	border-top-left-radius: 0rem;
	display : flex;
	align-items : center;
}

@media only screen and (max-width: 575px) {
	.tab-content-end {
		flex-wrap: wrap !important;
		margin: 1.5rem 0rem 0rem 0rem !important;
		justify-content: end !important;
	}
}

.tab-content-end {
	flex-wrap: wrap !important;
	justify-content: flex-end !important;
}

.info-content-end {
	flex-wrap: wrap !important;
	margin: 2rem 0rem 3rem 40rem;
	justify-content: flex-end !important;
}

.revenue-chart .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
	display: flex !important;
	margin-right: 30px !important;
	margin-bottom: 30px !important;
}

.revenue-chart-bar {
	margin-bottom: -30px;
}

@media only screen and (max-width: 575px) {
	.revenue-chart-bar {
		margin-bottom: 20px;
		float: none;
		padding-left: 20px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.revenue-chart-bar {
		margin-bottom: 20px;
		float: none;
		padding-left: 20px;
	}
}

#activeUser {
	height: 215px !important;
}

#activeUser {
	height: 180px !important;
}

span#counter {
	font-size: 30px;
	font-weight: 700;
	color: #25AFA8;
}

.social-graph-wrapper {
	text-align: center;
	padding: 20px;
	position: relative;
	color: #fff;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.social-graph-wrapper.widget-facebook {
	background-color: #3b5998;
}

.social-graph-wrapper.widget-twitter {
	background-color: #1da1f2;
}

.social-graph-wrapper.widget-linkedin {
	background-color: #007bb6;
}

.social-graph-wrapper.widget-googleplus {
	background-color: #db4439;
}

.social-graph-wrapper .s-icon {
	font-size: 24px;
	position: relative;
	padding: 0 10px;
}

.recent-stats i {
	font-size: 11px;
	position: relative;
	top: -1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	#lineChart_3Kk {
		height: 140px !important;
	}
}


/* add menu sidebar */

.star-icons i {
	font-size: 22px;
	color: #25AFA8;
}

.star-icons i:last-child {
	color: rgba(108, 197, 29, 0.1);
}



.featured-menus.owl-carousel .profile-image {
	width: 30px;
}

.featured-menus.owl-carousel .owl-prev,
.featured-menus.owl-carousel .owl-next {
	height: 68px;
	width: 68px;
	background: #fff;
	color: #25AFA8;
	line-height: 68px;
	border-radius: 100%;
	position: absolute;
	font-size: 35px;
	text-align: center;
	top: 50%;
	box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 1 !important;
}

.featured-menus.owl-carousel .owl-prev {
	left: -34px;
}

.featured-menus.owl-carousel .owl-next {
	right: -34px;
}

@media only screen and (max-width: 575px) {
	.featured-menus.owl-carousel .owl-prev {
		left: 0;
	}
	.featured-menus.owl-carousel .owl-next {
		right: 0;
	}
}

.search-area {
	width: 300px;
}

@media only screen and (max-width: 1600px) {
	.search-area {
		width: 140px!important;
	}
}


@media only screen and (max-width: 1350px) {
	.search-area {
		display: none!important;
	}
	.header-right .header-profile>a.nav-link {
		padding: unset!important;
	}
}


.search-area .form-control {
	background: #F3F3F3;
	border-width: 0px;
	padding-left: 20px;
	border-radius: 0.4rem 0 0 0.4rem;
}

.search-area .input-group-text {
	background: #F3F3F3;
	border-width: 0px;
	padding-right: 25px;
	border-radius: 0 0.4rem 0.4rem 0;
	cursor: pointer;
}

.activity-icon {
	height: 50px;
	text-align: center;
	width: 50px;
	min-width: 50px;
	border-radius: 50px;
	line-height: 46px;
}

.border-2 {
	border-width: 2px !important;
}

.map-bx {
	position: relative;
	margin-bottom: 50px;
}

.map-bx img {
	max-width: 100%;
	object-fit: cover;
	object-position: center;
	height: 430px;
}

.map-bx .map-info {
	width: 340px;
	position: absolute;
	right: 30px;
	bottom: -50px;
}

@media only screen and (max-width: 767px) {
	.map-bx .map-info {
		width: 100%;
		position: unset;
		margin-top: 30px;
	}
}

@media only screen and (max-width: 767px) {
	.menu-list>img {
		float: left;
	}
	.menu-list ul {
		float: left;
		flex-flow: wrap;
		display: flex;
	}
	.menu-list ul li {
		margin-right: 25px;
	}
}

@media only screen and (max-width: 575px) {
	.menu-list>img {
		width: 90px;
		height: 110px;
		object-fit: cover;
	}
}

.plus-icon {
	height: 60px;
	width: 60px;
	min-width: 60px;
	display: inline-block;
	line-height: 60px;
	text-align: center;
}

@media only screen and (max-width: 767px) {
	.plus-icon {
		height: 40px;
		width: 40px;
		min-width: 40px;
		line-height: 40px;
	}
}


/*
===================================
    list view
===================================*/

.new-arrival-content .item {
	font-size: 12px;
	color: #6e6e6e;
}

.new-arrival-content h4 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 15px;
}

.new-arrival-content h4 a {
	color: #000;
}

.new-arrival-content .price {
	font-weight: 600;
	color: #25AFA8;
	font-size: 24px;
	margin-bottom: 0;
	float: right;
}

.new-arrival-content p {
	font-size: 14px;
	color: #828690;
	margin-bottom: 3px;
	line-height: 24px;
}

.new-arrival-content .text-content {
	margin-top: 10px;
}

@media only screen and (max-width: 575px) {
	.new-arrival-content .price {
		float: left;
		margin-top: 10px;
	}
}

.new-arrival-content.text-center .price {
	float: unset;
}

.new-arrival-content.text-center .price {
	float: unset;
}

.product-detail-content .price {
	float: unset;
	margin-bottom: 10px;
}

.success-icon {
	color: #37D1BF;
	font-size: 16px;
}

.comment-review {
	margin-bottom: 15px;
	display: table;
	width: 100%;
}

.comment-review .client-review {
	color: #828690;
	padding-right: 20px;
	text-decoration: underline !important;
	font-size: 14px;
}

.comment-review .span {
	color: #828690;
	font-size: 14px;
}

.star-rating li {
	display: inline-block;
}

.star-rating li i {
	color: gold;
}

.rtl {
	text-align: right;
	direction: rtl;
}

.rtl .nav {
	padding-right: 0;
}

.rtl .navbar-nav .nav-item {
	float: right;
}

.rtl .navbar-nav .nav-item+.nav-item {
	margin-right: 1rem;
	margin-left: inherit;
}

.rtl th {
	text-align: right;
}

.rtl .alert-dismissible {
	padding-right: 1.25rem;
	padding-left: 4rem;
}

.rtl .dropdown-menu {
	right: 0;
	text-align: right;
}

.rtl .checkbox label {
	padding-right: 1.25rem;
	padding-left: inherit;
}

.rtl .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
	border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .btn-group>.btn:last-child:not(:first-child),
.rtl .btn-group>.dropdown-toggle:not(:first-child) {
	border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
	border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
	right: 0;
	left: inherit;
}

.rtl .custom-select {
	padding: 0.375rem 0.75rem 0.375rem 1.75rem;
	background: #fff url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") no-repeat left 0.75rem center;
	background-size: 8px 10px;
}

.rtl .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.rtl .input-group>.input-group-append:not(:last-child)>.btn,
.rtl .input-group>.input-group-append:not(:last-child)>.input-group-text,
.rtl .input-group>.input-group-prepend>.btn,
.rtl .input-group>.input-group-prepend>.input-group-text {
	border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group>.input-group-append>.btn,
.rtl .input-group>.input-group-append>.input-group-text,
.rtl .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.rtl .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.rtl .input-group>.input-group-prepend:not(:first-child)>.btn,
.rtl .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
	border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group>.custom-select:not(:first-child),
.rtl .input-group>.form-control:not(:first-child) {
	border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group>.custom-select:not(:last-child),
.rtl .input-group>.form-control:not(:last-child) {
	border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group>.custom-select:not(:last-child):not(:first-child),
.rtl .input-group>.form-control:not(:last-child):not(:first-child) {
	border-radius: 0;
}

.rtl .custom-control {
	padding-right: 1.5rem;
	padding-left: inherit;
	margin-right: inherit;
	margin-left: 1rem;
}

.rtl .custom-control-indicator {
	right: 0;
	left: inherit;
}

.rtl .custom-file-label::after {
	right: initial;
	left: -1px;
	border-radius: .25rem 0 0 .25rem;
}

.rtl .radio input,
.rtl .radio-inline,
.rtl .checkbox input,
.rtl .checkbox-inline input {
	margin-right: -1.25rem;
	margin-left: inherit;
}

.rtl .list-group {
	padding-right: 0;
	padding-left: 40px;
}

.rtl .close {
	float: left;
}

.rtl .modal-header .close {
	margin: -15px auto -15px -15px;
}

.rtl .modal-footer> :not(:first-child) {
	margin-right: .25rem;
}

.rtl .alert-dismissible .close {
	right: inherit;
	left: 0;
}

.rtl .dropdown-toggle::after {
	margin-right: .255em;
	margin-left: 0;
}

.rtl .form-check-input {
	margin-right: -1.25rem;
	margin-left: inherit;
}

.rtl .form-check-input1 {
	margin-right: -1.25rem;
	margin-left: inherit;
}

.rtl .form-check-label {
	padding-right: 1.25rem;
	padding-left: inherit;
}

.rtl .offset-1 {
	margin-right: 8.3333333333%;
	margin-left: 0;
}

.rtl .offset-2 {
	margin-right: 16.6666666667%;
	margin-left: 0;
}

.rtl .offset-3 {
	margin-right: 25%;
	margin-left: 0;
}

.rtl .offset-4 {
	margin-right: 33.3333333333%;
	margin-left: 0;
}

.rtl .offset-5 {
	margin-right: 41.6666666667%;
	margin-left: 0;
}

.rtl .offset-6 {
	margin-right: 50%;
	margin-left: 0;
}

.rtl .offset-7 {
	margin-right: 58.3333333333%;
	margin-left: 0;
}

.rtl .offset-8 {
	margin-right: 66.6666666667%;
	margin-left: 0;
}

.rtl .offset-9 {
	margin-right: 75%;
	margin-left: 0;
}

.rtl .offset-10 {
	margin-right: 83.3333333333%;
	margin-left: 0;
}

.rtl .offset-11 {
	margin-right: 91.6666666667%;
	margin-left: 0;
}

@media (min-width: 576px) {
	.rtl .offset-sm-0 {
		margin-right: 0;
		margin-left: 0;
	}
	.rtl .offset-sm-1 {
		margin-right: 8.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-sm-2 {
		margin-right: 16.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-sm-3 {
		margin-right: 25%;
		margin-left: 0;
	}
	.rtl .offset-sm-4 {
		margin-right: 33.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-sm-5 {
		margin-right: 41.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-sm-6 {
		margin-right: 50%;
		margin-left: 0;
	}
	.rtl .offset-sm-7 {
		margin-right: 58.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-sm-8 {
		margin-right: 66.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-sm-9 {
		margin-right: 75%;
		margin-left: 0;
	}
	.rtl .offset-sm-10 {
		margin-right: 83.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-sm-11 {
		margin-right: 91.6666666667%;
		margin-left: 0;
	}
}

@media (min-width: 768px) {
	.rtl .offset-md-0 {
		margin-right: 0;
		margin-left: 0;
	}
	.rtl .offset-md-1 {
		margin-right: 8.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-md-2 {
		margin-right: 16.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-md-3 {
		margin-right: 25%;
		margin-left: 0;
	}
	.rtl .offset-md-4 {
		margin-right: 33.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-md-5 {
		margin-right: 41.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-md-6 {
		margin-right: 50%;
		margin-left: 0;
	}
	.rtl .offset-md-7 {
		margin-right: 58.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-md-8 {
		margin-right: 66.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-md-9 {
		margin-right: 75%;
		margin-left: 0;
	}
	.rtl .offset-md-10 {
		margin-right: 83.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-md-11 {
		margin-right: 91.6666666667%;
		margin-left: 0;
	}
}

@media (min-width: 992px) {
	.rtl .offset-lg-0 {
		margin-right: 0;
		margin-left: 0;
	}
	.rtl .offset-lg-1 {
		margin-right: 8.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-lg-2 {
		margin-right: 16.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-lg-3 {
		margin-right: 25%;
		margin-left: 0;
	}
	.rtl .offset-lg-4 {
		margin-right: 33.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-lg-5 {
		margin-right: 41.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-lg-6 {
		margin-right: 50%;
		margin-left: 0;
	}
	.rtl .offset-lg-7 {
		margin-right: 58.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-lg-8 {
		margin-right: 66.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-lg-9 {
		margin-right: 75%;
		margin-left: 0;
	}
	.rtl .offset-lg-10 {
		margin-right: 83.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-lg-11 {
		margin-right: 91.6666666667%;
		margin-left: 0;
	}
}

@media (min-width: 1200px) {
	.rtl .offset-xl-0 {
		margin-right: 0;
		margin-left: 0;
	}
	.rtl .offset-xl-1 {
		margin-right: 8.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xl-2 {
		margin-right: 16.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xl-3 {
		margin-right: 25%;
		margin-left: 0;
	}
	.rtl .offset-xl-4 {
		margin-right: 33.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xl-5 {
		margin-right: 41.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xl-6 {
		margin-right: 50%;
		margin-left: 0;
	}
	.rtl .offset-xl-7 {
		margin-right: 58.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xl-8 {
		margin-right: 66.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xl-9 {
		margin-right: 75%;
		margin-left: 0;
	}
	.rtl .offset-xl-10 {
		margin-right: 83.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xl-11 {
		margin-right: 91.6666666667%;
		margin-left: 0;
	}
}

@media (min-width: 1440) {
	.rtl .offset-xxl-0 {
		margin-right: 0;
		margin-left: 0;
	}
	.rtl .offset-xxl-1 {
		margin-right: 8.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xxl-2 {
		margin-right: 16.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xxl-3 {
		margin-right: 25%;
		margin-left: 0;
	}
	.rtl .offset-xxl-4 {
		margin-right: 33.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xxl-5 {
		margin-right: 41.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xxl-6 {
		margin-right: 50%;
		margin-left: 0;
	}
	.rtl .offset-xxl-7 {
		margin-right: 58.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xxl-8 {
		margin-right: 66.6666666667%;
		margin-left: 0;
	}
	.rtl .offset-xxl-9 {
		margin-right: 75%;
		margin-left: 0;
	}
	.rtl .offset-xxl-10 {
		margin-right: 83.3333333333%;
		margin-left: 0;
	}
	.rtl .offset-xxl-11 {
		margin-right: 91.6666666667%;
		margin-left: 0;
	}
}

.rtl .mr-0,
.rtl .mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.rtl .ml-0,
.rtl .mx-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.rtl .mr-1,
.rtl .mx-1 {
	margin-right: 0 !important;
	margin-left: 0.25rem !important;
}

.rtl .ml-1,
.rtl .mx-1 {
	margin-left: 0 !important;
	margin-right: 0.25rem !important;
}

.rtl .mr-2,
.rtl .mx-2 {
	margin-right: 0 !important;
	margin-left: 0.5rem !important;
}

.rtl .ml-2,
.rtl .mx-2 {
	margin-left: 0 !important;
	margin-right: 0.5rem !important;
}

.rtl .mr-3,
.rtl .mx-3 {
	margin-right: 0 !important;
	margin-left: 1rem !important;
}

.rtl .ml-3,
.rtl .mx-3 {
	margin-left: 0 !important;
	margin-right: 1rem !important;
}

.rtl .mr-4,
.rtl .mx-4 {
	margin-right: 0 !important;
	margin-left: 1.5rem !important;
}

.rtl .ml-4,
.rtl .mx-4 {
	margin-left: 0 !important;
	margin-right: 1.5rem !important;
}

.rtl .mr-5,
.rtl .mx-5 {
	margin-right: 0 !important;
	margin-left: 3rem !important;
}

.rtl .ml-5,
.rtl .mx-5 {
	margin-left: 0 !important;
	margin-right: 3rem !important;
}

.rtl .pr-0,
.rtl .px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.rtl .pl-0,
.rtl .px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.rtl .pr-1,
.rtl .px-1 {
	padding-right: 0 !important;
	padding-left: 0.25rem !important;
}

.rtl .pl-1,
.rtl .px-1 {
	padding-left: 0 !important;
	padding-right: 0.25rem !important;
}

.rtl .pr-2,
.rtl .px-2 {
	padding-right: 0 !important;
	padding-left: 0.5rem !important;
}

.rtl .pl-2,
.rtl .px-2 {
	padding-left: 0 !important;
	padding-right: 0.5rem !important;
}

.rtl .pr-3,
.rtl .px-3 {
	padding-right: 0 !important;
	padding-left: 1rem !important;
}

.rtl .pl-3,
.rtl .px-3 {
	padding-left: 0 !important;
	padding-right: 1rem !important;
}

.rtl .pr-4,
.rtl .px-4 {
	padding-right: 0 !important;
	padding-left: 1.5rem !important;
}

.rtl .pl-4,
.rtl .px-4 {
	padding-left: 0 !important;
	padding-right: 1.5rem !important;
}

.rtl .pr-5,
.rtl .px-5 {
	padding-right: 0 !important;
	padding-left: 3rem !important;
}

.rtl .pl-5,
.rtl .px-5 {
	padding-left: 0 !important;
	padding-right: 3rem !important;
}

.rtl .mr-auto {
	margin-right: 0 !important;
	margin-left: auto !important;
}

.rtl .ml-auto {
	margin-right: auto !important;
	margin-left: 0 !important;
}

.rtl .mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

@media (min-width: 576px) {
	.rtl .mr-sm-0,
	.rtl .mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.rtl .ml-sm-0,
	.rtl .mx-sm-0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.rtl .mr-sm-1,
	.rtl .mx-sm-1 {
		margin-right: 0 !important;
		margin-left: 0.25rem !important;
	}
	.rtl .ml-sm-1,
	.rtl .mx-sm-1 {
		margin-left: 0 !important;
		margin-right: 0.25rem !important;
	}
	.rtl .mr-sm-2,
	.rtl .mx-sm-2 {
		margin-right: 0 !important;
		margin-left: 0.5rem !important;
	}
	.rtl .ml-sm-2,
	.rtl .mx-sm-2 {
		margin-left: 0 !important;
		margin-right: 0.5rem !important;
	}
	.rtl .mr-sm-3,
	.rtl .mx-sm-3 {
		margin-right: 0 !important;
		margin-left: 1rem !important;
	}
	.rtl .ml-sm-3,
	.rtl .mx-sm-3 {
		margin-left: 0 !important;
		margin-right: 1rem !important;
	}
	.rtl .mr-sm-4,
	.rtl .mx-sm-4 {
		margin-right: 0 !important;
		margin-left: 1.5rem !important;
	}
	.rtl .ml-sm-4,
	.rtl .mx-sm-4 {
		margin-left: 0 !important;
		margin-right: 1.5rem !important;
	}
	.rtl .mr-sm-5,
	.rtl .mx-sm-5 {
		margin-right: 0 !important;
		margin-left: 3rem !important;
	}
	.rtl .ml-sm-5,
	.rtl .mx-sm-5 {
		margin-left: 0 !important;
		margin-right: 3rem !important;
	}
	.rtl .pr-sm-0,
	.rtl .px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.rtl .pl-sm-0,
	.rtl .px-sm-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.rtl .pr-sm-1,
	.rtl .px-sm-1 {
		padding-right: 0 !important;
		padding-left: 0.25rem !important;
	}
	.rtl .pl-sm-1,
	.rtl .px-sm-1 {
		padding-left: 0 !important;
		padding-right: 0.25rem !important;
	}
	.rtl .pr-sm-2,
	.rtl .px-sm-2 {
		padding-right: 0 !important;
		padding-left: 0.5rem !important;
	}
	.rtl .pl-sm-2,
	.rtl .px-sm-2 {
		padding-left: 0 !important;
		padding-right: 0.5rem !important;
	}
	.rtl .pr-sm-3,
	.rtl .px-sm-3 {
		padding-right: 0 !important;
		padding-left: 1rem !important;
	}
	.rtl .pl-sm-3,
	.rtl .px-sm-3 {
		padding-left: 0 !important;
		padding-right: 1rem !important;
	}
	.rtl .pr-sm-4,
	.rtl .px-sm-4 {
		padding-right: 0 !important;
		padding-left: 1.5rem !important;
	}
	.rtl .pl-sm-4,
	.rtl .px-sm-4 {
		padding-left: 0 !important;
		padding-right: 1.5rem !important;
	}
	.rtl .pr-sm-5,
	.rtl .px-sm-5 {
		padding-right: 0 !important;
		padding-left: 3rem !important;
	}
	.rtl .pl-sm-5,
	.rtl .px-sm-5 {
		padding-left: 0 !important;
		padding-right: 3rem !important;
	}
	.rtl .mr-sm-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .ml-sm-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 768px) {
	.rtl .mr-md-0,
	.rtl .mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.rtl .ml-md-0,
	.rtl .mx-md-0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.rtl .mr-md-1,
	.rtl .mx-md-1 {
		margin-right: 0 !important;
		margin-left: 0.25rem !important;
	}
	.rtl .ml-md-1,
	.rtl .mx-md-1 {
		margin-left: 0 !important;
		margin-right: 0.25rem !important;
	}
	.rtl .mr-md-2,
	.rtl .mx-md-2 {
		margin-right: 0 !important;
		margin-left: 0.5rem !important;
	}
	.rtl .ml-md-2,
	.rtl .mx-md-2 {
		margin-left: 0 !important;
		margin-right: 0.5rem !important;
	}
	.rtl .mr-md-3,
	.rtl .mx-md-3 {
		margin-right: 0 !important;
		margin-left: 1rem !important;
	}
	.rtl .ml-md-3,
	.rtl .mx-md-3 {
		margin-left: 0 !important;
		margin-right: 1rem !important;
	}
	.rtl .mr-md-4,
	.rtl .mx-md-4 {
		margin-right: 0 !important;
		margin-left: 1.5rem !important;
	}
	.rtl .ml-md-4,
	.rtl .mx-md-4 {
		margin-left: 0 !important;
		margin-right: 1.5rem !important;
	}
	.rtl .mr-md-5,
	.rtl .mx-md-5 {
		margin-right: 0 !important;
		margin-left: 3rem !important;
	}
	.rtl .ml-md-5,
	.rtl .mx-md-5 {
		margin-left: 0 !important;
		margin-right: 3rem !important;
	}
	.rtl .pr-md-0,
	.rtl .px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.rtl .pl-md-0,
	.rtl .px-md-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.rtl .pr-md-1,
	.rtl .px-md-1 {
		padding-right: 0 !important;
		padding-left: 0.25rem !important;
	}
	.rtl .pl-md-1,
	.rtl .px-md-1 {
		padding-left: 0 !important;
		padding-right: 0.25rem !important;
	}
	.rtl .pr-md-2,
	.rtl .px-md-2 {
		padding-right: 0 !important;
		padding-left: 0.5rem !important;
	}
	.rtl .pl-md-2,
	.rtl .px-md-2 {
		padding-left: 0 !important;
		padding-right: 0.5rem !important;
	}
	.rtl .pr-md-3,
	.rtl .px-md-3 {
		padding-right: 0 !important;
		padding-left: 1rem !important;
	}
	.rtl .pl-md-3,
	.rtl .px-md-3 {
		padding-left: 0 !important;
		padding-right: 1rem !important;
	}
	.rtl .pr-md-4,
	.rtl .px-md-4 {
		padding-right: 0 !important;
		padding-left: 1.5rem !important;
	}
	.rtl .pl-md-4,
	.rtl .px-md-4 {
		padding-left: 0 !important;
		padding-right: 1.5rem !important;
	}
	.rtl .pr-md-5,
	.rtl .px-md-5 {
		padding-right: 0 !important;
		padding-left: 3rem !important;
	}
	.rtl .pl-md-5,
	.rtl .px-md-5 {
		padding-left: 0 !important;
		padding-right: 3rem !important;
	}
	.rtl .mr-md-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .ml-md-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 992px) {
	.rtl .mr-lg-0,
	.rtl .mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.rtl .ml-lg-0,
	.rtl .mx-lg-0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.rtl .mr-lg-1,
	.rtl .mx-lg-1 {
		margin-right: 0 !important;
		margin-left: 0.25rem !important;
	}
	.rtl .ml-lg-1,
	.rtl .mx-lg-1 {
		margin-left: 0 !important;
		margin-right: 0.25rem !important;
	}
	.rtl .mr-lg-2,
	.rtl .mx-lg-2 {
		margin-right: 0 !important;
		margin-left: 0.5rem !important;
	}
	.rtl .ml-lg-2,
	.rtl .mx-lg-2 {
		margin-left: 0 !important;
		margin-right: 0.5rem !important;
	}
	.rtl .mr-lg-3,
	.rtl .mx-lg-3 {
		margin-right: 0 !important;
		margin-left: 1rem !important;
	}
	.rtl .ml-lg-3,
	.rtl .mx-lg-3 {
		margin-left: 0 !important;
		margin-right: 1rem !important;
	}
	.rtl .mr-lg-4,
	.rtl .mx-lg-4 {
		margin-right: 0 !important;
		margin-left: 1.5rem !important;
	}
	.rtl .ml-lg-4,
	.rtl .mx-lg-4 {
		margin-left: 0 !important;
		margin-right: 1.5rem !important;
	}
	.rtl .mr-lg-5,
	.rtl .mx-lg-5 {
		margin-right: 0 !important;
		margin-left: 3rem !important;
	}
	.rtl .ml-lg-5,
	.rtl .mx-lg-5 {
		margin-left: 0 !important;
		margin-right: 3rem !important;
	}
	.rtl .pr-lg-0,
	.rtl .px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.rtl .pl-lg-0,
	.rtl .px-lg-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.rtl .pr-lg-1,
	.rtl .px-lg-1 {
		padding-right: 0 !important;
		padding-left: 0.25rem !important;
	}
	.rtl .pl-lg-1,
	.rtl .px-lg-1 {
		padding-left: 0 !important;
		padding-right: 0.25rem !important;
	}
	.rtl .pr-lg-2,
	.rtl .px-lg-2 {
		padding-right: 0 !important;
		padding-left: 0.5rem !important;
	}
	.rtl .pl-lg-2,
	.rtl .px-lg-2 {
		padding-left: 0 !important;
		padding-right: 0.5rem !important;
	}
	.rtl .pr-lg-3,
	.rtl .px-lg-3 {
		padding-right: 0 !important;
		padding-left: 1rem !important;
	}
	.rtl .pl-lg-3,
	.rtl .px-lg-3 {
		padding-left: 0 !important;
		padding-right: 1rem !important;
	}
	.rtl .pr-lg-4,
	.rtl .px-lg-4 {
		padding-right: 0 !important;
		padding-left: 1.5rem !important;
	}
	.rtl .pl-lg-4,
	.rtl .px-lg-4 {
		padding-left: 0 !important;
		padding-right: 1.5rem !important;
	}
	.rtl .pr-lg-5,
	.rtl .px-lg-5 {
		padding-right: 0 !important;
		padding-left: 3rem !important;
	}
	.rtl .pl-lg-5,
	.rtl .px-lg-5 {
		padding-left: 0 !important;
		padding-right: 3rem !important;
	}
	.rtl .mr-lg-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .ml-lg-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 1200px) {
	.rtl .mr-xl-0,
	.rtl .mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.rtl .ml-xl-0,
	.rtl .mx-xl-0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.rtl .mr-xl-1,
	.rtl .mx-xl-1 {
		margin-right: 0 !important;
		margin-left: 0.25rem !important;
	}
	.rtl .ml-xl-1,
	.rtl .mx-xl-1 {
		margin-left: 0 !important;
		margin-right: 0.25rem !important;
	}
	.rtl .mr-xl-2,
	.rtl .mx-xl-2 {
		margin-right: 0 !important;
		margin-left: 0.5rem !important;
	}
	.rtl .ml-xl-2,
	.rtl .mx-xl-2 {
		margin-left: 0 !important;
		margin-right: 0.5rem !important;
	}
	.rtl .mr-xl-3,
	.rtl .mx-xl-3 {
		margin-right: 0 !important;
		margin-left: 1rem !important;
	}
	.rtl .ml-xl-3,
	.rtl .mx-xl-3 {
		margin-left: 0 !important;
		margin-right: 1rem !important;
	}
	.rtl .mr-xl-4,
	.rtl .mx-xl-4 {
		margin-right: 0 !important;
		margin-left: 1.5rem !important;
	}
	.rtl .ml-xl-4,
	.rtl .mx-xl-4 {
		margin-left: 0 !important;
		margin-right: 1.5rem !important;
	}
	.rtl .mr-xl-5,
	.rtl .mx-xl-5 {
		margin-right: 0 !important;
		margin-left: 3rem !important;
	}
	.rtl .ml-xl-5,
	.rtl .mx-xl-5 {
		margin-left: 0 !important;
		margin-right: 3rem !important;
	}
	.rtl .pr-xl-0,
	.rtl .px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.rtl .pl-xl-0,
	.rtl .px-xl-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.rtl .pr-xl-1,
	.rtl .px-xl-1 {
		padding-right: 0 !important;
		padding-left: 0.25rem !important;
	}
	.rtl .pl-xl-1,
	.rtl .px-xl-1 {
		padding-left: 0 !important;
		padding-right: 0.25rem !important;
	}
	.rtl .pr-xl-2,
	.rtl .px-xl-2 {
		padding-right: 0 !important;
		padding-left: 0.5rem !important;
	}
	.rtl .pl-xl-2,
	.rtl .px-xl-2 {
		padding-left: 0 !important;
		padding-right: 0.5rem !important;
	}
	.rtl .pr-xl-3,
	.rtl .px-xl-3 {
		padding-right: 0 !important;
		padding-left: 1rem !important;
	}
	.rtl .pl-xl-3,
	.rtl .px-xl-3 {
		padding-left: 0 !important;
		padding-right: 1rem !important;
	}
	.rtl .pr-xl-4,
	.rtl .px-xl-4 {
		padding-right: 0 !important;
		padding-left: 1.5rem !important;
	}
	.rtl .pl-xl-4,
	.rtl .px-xl-4 {
		padding-left: 0 !important;
		padding-right: 1.5rem !important;
	}
	.rtl .pr-xl-5,
	.rtl .px-xl-5 {
		padding-right: 0 !important;
		padding-left: 3rem !important;
	}
	.rtl .pl-xl-5,
	.rtl .px-xl-5 {
		padding-left: 0 !important;
		padding-right: 3rem !important;
	}
	.rtl .mr-xl-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .ml-xl-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 1440) {
	.rtl .mr-xxl-0,
	.rtl .mx-xxl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.rtl .ml-xxl-0,
	.rtl .mx-xxl-0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.rtl .mr-xxl-1,
	.rtl .mx-xxl-1 {
		margin-right: 0 !important;
		margin-left: 0.25rem !important;
	}
	.rtl .ml-xxl-1,
	.rtl .mx-xxl-1 {
		margin-left: 0 !important;
		margin-right: 0.25rem !important;
	}
	.rtl .mr-xxl-2,
	.rtl .mx-xxl-2 {
		margin-right: 0 !important;
		margin-left: 0.5rem !important;
	}
	.rtl .ml-xxl-2,
	.rtl .mx-xxl-2 {
		margin-left: 0 !important;
		margin-right: 0.5rem !important;
	}
	.rtl .mr-xxl-3,
	.rtl .mx-xxl-3 {
		margin-right: 0 !important;
		margin-left: 1rem !important;
	}
	.rtl .ml-xxl-3,
	.rtl .mx-xxl-3 {
		margin-left: 0 !important;
		margin-right: 1rem !important;
	}
	.rtl .mr-xxl-4,
	.rtl .mx-xxl-4 {
		margin-right: 0 !important;
		margin-left: 1.5rem !important;
	}
	.rtl .ml-xxl-4,
	.rtl .mx-xxl-4 {
		margin-left: 0 !important;
		margin-right: 1.5rem !important;
	}
	.rtl .mr-xxl-5,
	.rtl .mx-xxl-5 {
		margin-right: 0 !important;
		margin-left: 3rem !important;
	}
	.rtl .ml-xxl-5,
	.rtl .mx-xxl-5 {
		margin-left: 0 !important;
		margin-right: 3rem !important;
	}
	.rtl .pr-xxl-0,
	.rtl .px-xxl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.rtl .pl-xxl-0,
	.rtl .px-xxl-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.rtl .pr-xxl-1,
	.rtl .px-xxl-1 {
		padding-right: 0 !important;
		padding-left: 0.25rem !important;
	}
	.rtl .pl-xxl-1,
	.rtl .px-xxl-1 {
		padding-left: 0 !important;
		padding-right: 0.25rem !important;
	}
	.rtl .pr-xxl-2,
	.rtl .px-xxl-2 {
		padding-right: 0 !important;
		padding-left: 0.5rem !important;
	}
	.rtl .pl-xxl-2,
	.rtl .px-xxl-2 {
		padding-left: 0 !important;
		padding-right: 0.5rem !important;
	}
	.rtl .pr-xxl-3,
	.rtl .px-xxl-3 {
		padding-right: 0 !important;
		padding-left: 1rem !important;
	}
	.rtl .pl-xxl-3,
	.rtl .px-xxl-3 {
		padding-left: 0 !important;
		padding-right: 1rem !important;
	}
	.rtl .pr-xxl-4,
	.rtl .px-xxl-4 {
		padding-right: 0 !important;
		padding-left: 1.5rem !important;
	}
	.rtl .pl-xxl-4,
	.rtl .px-xxl-4 {
		padding-left: 0 !important;
		padding-right: 1.5rem !important;
	}
	.rtl .pr-xxl-5,
	.rtl .px-xxl-5 {
		padding-right: 0 !important;
		padding-left: 3rem !important;
	}
	.rtl .pl-xxl-5,
	.rtl .px-xxl-5 {
		padding-left: 0 !important;
		padding-right: 3rem !important;
	}
	.rtl .mr-xxl-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .ml-xxl-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

.rtl .text-right {
	text-align: left !important;
}

.rtl .text-left {
	text-align: right !important;
}

@media (min-width: 576px) {
	.rtl .text-sm-right {
		text-align: left !important;
	}
	.rtl .text-sm-left {
		text-align: right !important;
	}
}

@media (min-width: 768px) {
	.rtl .text-md-right {
		text-align: left !important;
	}
	.rtl .text-md-left {
		text-align: right !important;
	}
}

@media (min-width: 992px) {
	.rtl .text-lg-right {
		text-align: left !important;
	}
	.rtl .text-lg-left {
		text-align: right !important;
	}
}

@media (min-width: 1200px) {
	.rtl .text-xl-right {
		text-align: left !important;
	}
	.rtl .text-xl-left {
		text-align: right !important;
	}
}

@media (min-width: 1440) {
	.rtl .text-xxl-right {
		text-align: left !important;
	}
	.rtl .text-xxl-left {
		text-align: right !important;
	}
}

.rtl .mx-0 {
	margin-right: auto;
	margin-left: 0 !important;
}

.rtl .mx-0 {
	margin-left: auto;
	margin-right: 0 !important;
}

.rtl .mx-1 {
	margin-right: auto;
	margin-left: 0.25rem !important;
}

.rtl .mx-1 {
	margin-left: auto;
	margin-right: 0.25rem !important;
}

.rtl .mx-2 {
	margin-right: auto;
	margin-left: 0.5rem !important;
}

.rtl .mx-2 {
	margin-left: auto;
	margin-right: 0.5rem !important;
}

.rtl .mx-3 {
	margin-right: auto;
	margin-left: 1rem !important;
}

.rtl .mx-3 {
	margin-left: auto;
	margin-right: 1rem !important;
}

.rtl .mx-4 {
	margin-right: auto;
	margin-left: 1.5rem !important;
}

.rtl .mx-4 {
	margin-left: auto;
	margin-right: 1.5rem !important;
}

.rtl .mx-5 {
	margin-right: auto;
	margin-left: 3rem !important;
}

.rtl .mx-5 {
	margin-left: auto;
	margin-right: 3rem !important;
}

.rtl .px-0 {
	padding-right: auto;
	padding-left: 0 !important;
}

.rtl .px-0 {
	padding-left: auto;
	padding-right: 0 !important;
}

.rtl .px-1 {
	padding-right: auto;
	padding-left: 0.25rem !important;
}

.rtl .px-1 {
	padding-left: auto;
	padding-right: 0.25rem !important;
}

.rtl .px-2 {
	padding-right: auto;
	padding-left: 0.5rem !important;
}

.rtl .px-2 {
	padding-left: auto;
	padding-right: 0.5rem !important;
}

.rtl .px-3 {
	padding-right: auto;
	padding-left: 1rem !important;
}

.rtl .px-3 {
	padding-left: auto;
	padding-right: 1rem !important;
}

.rtl .px-4 {
	padding-right: auto;
	padding-left: 1.5rem !important;
}

.rtl .px-4 {
	padding-left: auto;
	padding-right: 1.5rem !important;
}

.rtl .px-5 {
	padding-right: auto;
	padding-left: 3rem !important;
}

.rtl .px-5 {
	padding-left: auto;
	padding-right: 3rem !important;
}

.rtl .mr-auto {
	margin-right: 0 !important;
	margin-left: auto !important;
}

.rtl .mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.rtl .ml-auto {
	margin-right: auto !important;
	margin-left: 0 !important;
}

.rtl .mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

@media (min-width: 576px) {
	.rtl .mx-sm-0 {
		margin-right: auto;
		margin-left: 0 !important;
	}
	.rtl .mx-sm-0 {
		margin-left: auto;
		margin-right: 0 !important;
	}
	.rtl .mx-sm-1 {
		margin-right: auto;
		margin-left: 0.25rem !important;
	}
	.rtl .mx-sm-1 {
		margin-left: auto;
		margin-right: 0.25rem !important;
	}
	.rtl .mx-sm-2 {
		margin-right: auto;
		margin-left: 0.5rem !important;
	}
	.rtl .mx-sm-2 {
		margin-left: auto;
		margin-right: 0.5rem !important;
	}
	.rtl .mx-sm-3 {
		margin-right: auto;
		margin-left: 1rem !important;
	}
	.rtl .mx-sm-3 {
		margin-left: auto;
		margin-right: 1rem !important;
	}
	.rtl .mx-sm-4 {
		margin-right: auto;
		margin-left: 1.5rem !important;
	}
	.rtl .mx-sm-4 {
		margin-left: auto;
		margin-right: 1.5rem !important;
	}
	.rtl .mx-sm-5 {
		margin-right: auto;
		margin-left: 3rem !important;
	}
	.rtl .mx-sm-5 {
		margin-left: auto;
		margin-right: 3rem !important;
	}
	.rtl .px-sm-0 {
		padding-right: auto;
		padding-left: 0 !important;
	}
	.rtl .px-sm-0 {
		padding-left: auto;
		padding-right: 0 !important;
	}
	.rtl .px-sm-1 {
		padding-right: auto;
		padding-left: 0.25rem !important;
	}
	.rtl .px-sm-1 {
		padding-left: auto;
		padding-right: 0.25rem !important;
	}
	.rtl .px-sm-2 {
		padding-right: auto;
		padding-left: 0.5rem !important;
	}
	.rtl .px-sm-2 {
		padding-left: auto;
		padding-right: 0.5rem !important;
	}
	.rtl .px-sm-3 {
		padding-right: auto;
		padding-left: 1rem !important;
	}
	.rtl .px-sm-3 {
		padding-left: auto;
		padding-right: 1rem !important;
	}
	.rtl .px-sm-4 {
		padding-right: auto;
		padding-left: 1.5rem !important;
	}
	.rtl .px-sm-4 {
		padding-left: auto;
		padding-right: 1.5rem !important;
	}
	.rtl .px-sm-5 {
		padding-right: auto;
		padding-left: 3rem !important;
	}
	.rtl .px-sm-5 {
		padding-left: auto;
		padding-right: 3rem !important;
	}
	.rtl .mr-sm-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.rtl .ml-sm-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 768px) {
	.rtl .mx-md-0 {
		margin-right: auto;
		margin-left: 0 !important;
	}
	.rtl .mx-md-0 {
		margin-left: auto;
		margin-right: 0 !important;
	}
	.rtl .mx-md-1 {
		margin-right: auto;
		margin-left: 0.25rem !important;
	}
	.rtl .mx-md-1 {
		margin-left: auto;
		margin-right: 0.25rem !important;
	}
	.rtl .mx-md-2 {
		margin-right: auto;
		margin-left: 0.5rem !important;
	}
	.rtl .mx-md-2 {
		margin-left: auto;
		margin-right: 0.5rem !important;
	}
	.rtl .mx-md-3 {
		margin-right: auto;
		margin-left: 1rem !important;
	}
	.rtl .mx-md-3 {
		margin-left: auto;
		margin-right: 1rem !important;
	}
	.rtl .mx-md-4 {
		margin-right: auto;
		margin-left: 1.5rem !important;
	}
	.rtl .mx-md-4 {
		margin-left: auto;
		margin-right: 1.5rem !important;
	}
	.rtl .mx-md-5 {
		margin-right: auto;
		margin-left: 3rem !important;
	}
	.rtl .mx-md-5 {
		margin-left: auto;
		margin-right: 3rem !important;
	}
	.rtl .px-md-0 {
		padding-right: auto;
		padding-left: 0 !important;
	}
	.rtl .px-md-0 {
		padding-left: auto;
		padding-right: 0 !important;
	}
	.rtl .px-md-1 {
		padding-right: auto;
		padding-left: 0.25rem !important;
	}
	.rtl .px-md-1 {
		padding-left: auto;
		padding-right: 0.25rem !important;
	}
	.rtl .px-md-2 {
		padding-right: auto;
		padding-left: 0.5rem !important;
	}
	.rtl .px-md-2 {
		padding-left: auto;
		padding-right: 0.5rem !important;
	}
	.rtl .px-md-3 {
		padding-right: auto;
		padding-left: 1rem !important;
	}
	.rtl .px-md-3 {
		padding-left: auto;
		padding-right: 1rem !important;
	}
	.rtl .px-md-4 {
		padding-right: auto;
		padding-left: 1.5rem !important;
	}
	.rtl .px-md-4 {
		padding-left: auto;
		padding-right: 1.5rem !important;
	}
	.rtl .px-md-5 {
		padding-right: auto;
		padding-left: 3rem !important;
	}
	.rtl .px-md-5 {
		padding-left: auto;
		padding-right: 3rem !important;
	}
	.rtl .mr-md-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.rtl .ml-md-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 992px) {
	.rtl .mx-lg-0 {
		margin-right: auto;
		margin-left: 0 !important;
	}
	.rtl .mx-lg-0 {
		margin-left: auto;
		margin-right: 0 !important;
	}
	.rtl .mx-lg-1 {
		margin-right: auto;
		margin-left: 0.25rem !important;
	}
	.rtl .mx-lg-1 {
		margin-left: auto;
		margin-right: 0.25rem !important;
	}
	.rtl .mx-lg-2 {
		margin-right: auto;
		margin-left: 0.5rem !important;
	}
	.rtl .mx-lg-2 {
		margin-left: auto;
		margin-right: 0.5rem !important;
	}
	.rtl .mx-lg-3 {
		margin-right: auto;
		margin-left: 1rem !important;
	}
	.rtl .mx-lg-3 {
		margin-left: auto;
		margin-right: 1rem !important;
	}
	.rtl .mx-lg-4 {
		margin-right: auto;
		margin-left: 1.5rem !important;
	}
	.rtl .mx-lg-4 {
		margin-left: auto;
		margin-right: 1.5rem !important;
	}
	.rtl .mx-lg-5 {
		margin-right: auto;
		margin-left: 3rem !important;
	}
	.rtl .mx-lg-5 {
		margin-left: auto;
		margin-right: 3rem !important;
	}
	.rtl .px-lg-0 {
		padding-right: auto;
		padding-left: 0 !important;
	}
	.rtl .px-lg-0 {
		padding-left: auto;
		padding-right: 0 !important;
	}
	.rtl .px-lg-1 {
		padding-right: auto;
		padding-left: 0.25rem !important;
	}
	.rtl .px-lg-1 {
		padding-left: auto;
		padding-right: 0.25rem !important;
	}
	.rtl .px-lg-2 {
		padding-right: auto;
		padding-left: 0.5rem !important;
	}
	.rtl .px-lg-2 {
		padding-left: auto;
		padding-right: 0.5rem !important;
	}
	.rtl .px-lg-3 {
		padding-right: auto;
		padding-left: 1rem !important;
	}
	.rtl .px-lg-3 {
		padding-left: auto;
		padding-right: 1rem !important;
	}
	.rtl .px-lg-4 {
		padding-right: auto;
		padding-left: 1.5rem !important;
	}
	.rtl .px-lg-4 {
		padding-left: auto;
		padding-right: 1.5rem !important;
	}
	.rtl .px-lg-5 {
		padding-right: auto;
		padding-left: 3rem !important;
	}
	.rtl .px-lg-5 {
		padding-left: auto;
		padding-right: 3rem !important;
	}
	.rtl .mr-lg-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.rtl .ml-lg-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 1200px) {
	.rtl .mx-xl-0 {
		margin-right: auto;
		margin-left: 0 !important;
	}
	.rtl .mx-xl-0 {
		margin-left: auto;
		margin-right: 0 !important;
	}
	.rtl .mx-xl-1 {
		margin-right: auto;
		margin-left: 0.25rem !important;
	}
	.rtl .mx-xl-1 {
		margin-left: auto;
		margin-right: 0.25rem !important;
	}
	.rtl .mx-xl-2 {
		margin-right: auto;
		margin-left: 0.5rem !important;
	}
	.rtl .mx-xl-2 {
		margin-left: auto;
		margin-right: 0.5rem !important;
	}
	.rtl .mx-xl-3 {
		margin-right: auto;
		margin-left: 1rem !important;
	}
	.rtl .mx-xl-3 {
		margin-left: auto;
		margin-right: 1rem !important;
	}
	.rtl .mx-xl-4 {
		margin-right: auto;
		margin-left: 1.5rem !important;
	}
	.rtl .mx-xl-4 {
		margin-left: auto;
		margin-right: 1.5rem !important;
	}
	.rtl .mx-xl-5 {
		margin-right: auto;
		margin-left: 3rem !important;
	}
	.rtl .mx-xl-5 {
		margin-left: auto;
		margin-right: 3rem !important;
	}
	.rtl .px-xl-0 {
		padding-right: auto;
		padding-left: 0 !important;
	}
	.rtl .px-xl-0 {
		padding-left: auto;
		padding-right: 0 !important;
	}
	.rtl .px-xl-1 {
		padding-right: auto;
		padding-left: 0.25rem !important;
	}
	.rtl .px-xl-1 {
		padding-left: auto;
		padding-right: 0.25rem !important;
	}
	.rtl .px-xl-2 {
		padding-right: auto;
		padding-left: 0.5rem !important;
	}
	.rtl .px-xl-2 {
		padding-left: auto;
		padding-right: 0.5rem !important;
	}
	.rtl .px-xl-3 {
		padding-right: auto;
		padding-left: 1rem !important;
	}
	.rtl .px-xl-3 {
		padding-left: auto;
		padding-right: 1rem !important;
	}
	.rtl .px-xl-4 {
		padding-right: auto;
		padding-left: 1.5rem !important;
	}
	.rtl .px-xl-4 {
		padding-left: auto;
		padding-right: 1.5rem !important;
	}
	.rtl .px-xl-5 {
		padding-right: auto;
		padding-left: 3rem !important;
	}
	.rtl .px-xl-5 {
		padding-left: auto;
		padding-right: 3rem !important;
	}
	.rtl .mr-xl-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.rtl .ml-xl-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@media (min-width: 1440) {
	.rtl .mx-xxl-0 {
		margin-right: auto;
		margin-left: 0 !important;
	}
	.rtl .mx-xxl-0 {
		margin-left: auto;
		margin-right: 0 !important;
	}
	.rtl .mx-xxl-1 {
		margin-right: auto;
		margin-left: 0.25rem !important;
	}
	.rtl .mx-xxl-1 {
		margin-left: auto;
		margin-right: 0.25rem !important;
	}
	.rtl .mx-xxl-2 {
		margin-right: auto;
		margin-left: 0.5rem !important;
	}
	.rtl .mx-xxl-2 {
		margin-left: auto;
		margin-right: 0.5rem !important;
	}
	.rtl .mx-xxl-3 {
		margin-right: auto;
		margin-left: 1rem !important;
	}
	.rtl .mx-xxl-3 {
		margin-left: auto;
		margin-right: 1rem !important;
	}
	.rtl .mx-xxl-4 {
		margin-right: auto;
		margin-left: 1.5rem !important;
	}
	.rtl .mx-xxl-4 {
		margin-left: auto;
		margin-right: 1.5rem !important;
	}
	.rtl .mx-xxl-5 {
		margin-right: auto;
		margin-left: 3rem !important;
	}
	.rtl .mx-xxl-5 {
		margin-left: auto;
		margin-right: 3rem !important;
	}
	.rtl .px-xxl-0 {
		padding-right: auto;
		padding-left: 0 !important;
	}
	.rtl .px-xxl-0 {
		padding-left: auto;
		padding-right: 0 !important;
	}
	.rtl .px-xxl-1 {
		padding-right: auto;
		padding-left: 0.25rem !important;
	}
	.rtl .px-xxl-1 {
		padding-left: auto;
		padding-right: 0.25rem !important;
	}
	.rtl .px-xxl-2 {
		padding-right: auto;
		padding-left: 0.5rem !important;
	}
	.rtl .px-xxl-2 {
		padding-left: auto;
		padding-right: 0.5rem !important;
	}
	.rtl .px-xxl-3 {
		padding-right: auto;
		padding-left: 1rem !important;
	}
	.rtl .px-xxl-3 {
		padding-left: auto;
		padding-right: 1rem !important;
	}
	.rtl .px-xxl-4 {
		padding-right: auto;
		padding-left: 1.5rem !important;
	}
	.rtl .px-xxl-4 {
		padding-left: auto;
		padding-right: 1.5rem !important;
	}
	.rtl .px-xxl-5 {
		padding-right: auto;
		padding-left: 3rem !important;
	}
	.rtl .px-xxl-5 {
		padding-left: auto;
		padding-right: 3rem !important;
	}
	.rtl .mr-xxl-auto {
		margin-right: 0 !important;
		margin-left: auto !important;
	}
	.rtl .mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.rtl .ml-xxl-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.rtl .mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

.rtl .float-left {
	float: right !important;
}

.rtl .float-right {
	float: left !important;
}

.rtl .float-none {
	float: none !important;
}

@media (min-width: 576px) {
	.rtl .float-sm-left {
		float: right !important;
	}
	.rtl .float-sm-right {
		float: left !important;
	}
	.rtl .float-sm-none {
		float: none !important;
	}
}

@media (min-width: 768px) {
	.rtl .float-md-left {
		float: right !important;
	}
	.rtl .float-md-right {
		float: left !important;
	}
	.rtl .float-md-none {
		float: none !important;
	}
}

@media (min-width: 992px) {
	.rtl .float-lg-left {
		float: right !important;
	}
	.rtl .float-lg-right {
		float: left !important;
	}
	.rtl .float-lg-none {
		float: none !important;
	}
}

@media (min-width: 1200px) {
	.rtl .float-xl-left {
		float: right !important;
	}
	.rtl .float-xl-right {
		float: left !important;
	}
	.rtl .float-xl-none {
		float: none !important;
	}
}

@media (min-width: 1440) {
	.rtl .float-xxl-left {
		float: right !important;
	}
	.rtl .float-xxl-right {
		float: left !important;
	}
	.rtl .float-xxl-none {
		float: none !important;
	}
}

[direction="rtl"] .text-right {
	text-align: left !important;
}

[direction="rtl"] .text-left {
	text-align: right !important;
}

[direction="rtl"] .border-right {
	border-left: 1px solid #f0f1f5 !important;
	border-right: 0 !important;
}

[direction="rtl"] .border-left {
	border-right: 1px solid #f0f1f5 !important;
	border-left: 0 !important;
}

[direction="rtl"] .dropdown-menu {
	left: auto;
}

[direction="rtl"] .dropdown-menu-right {
	left: 0;
	right: auto;
}

@media only screen and (max-width: 575px) {
	[direction="rtl"] .dropdown-menu-right {
		left: 15px;
	}
}

[direction="rtl"] .notification_dropdown .dropdown-menu-right .media>span {
	margin-left: 10px;
	margin-right: 0;
}


[direction="rtl"] .header {
	padding: 0 0.9375rem;
	padding-right: 17.1875rem;
}

@media (max-width: 767.98px) {
	[direction="rtl"] .header {
		padding-right: 3.75rem;
		padding-left: 0;
	}
}

[direction="rtl"] .header .header-content {
	padding-left: 0;
	padding-right: 5.3125rem;
}

@media only screen and (max-width: 575px) {
	[direction="rtl"] .header .header-content {
		padding-right: 4.5rem;
	}
}

[data-sidebar-style="compact"][direction="rtl"] .header .header-content {
	padding-right: 1.875rem;
}

[data-sidebar-style="modern"][direction="rtl"] .header .header-content {
	padding-right: 5.3125rem;
}

[data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
	padding-right: 5.3125rem;
}

[direction="rtl"] .header .nav-control {
	right: 0.4375rem;
	left: auto;
}

[direction="rtl"] .header-left .search_bar .dropdown-menu,
[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
	right: 40px !important;
}

@media only screen and (max-width: 575px) {
	[direction="rtl"] .header-left .search_bar .dropdown-menu,
	[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
		right: -100px !important;
	}
}

[direction="rtl"] .header-left .search_bar .search_icon {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5rem;
	border-bottom-right-radius: 5rem;
}

@media only screen and (max-width: 575px) {
	[direction="rtl"] .header-left .search_bar .search_icon {
		border-radius: 5rem;
	}
}

[direction="rtl"][data-layout="horizontal"] .header {
	padding: 0;
	padding-right: 16rem;
}

[direction="rtl"][data-layout="horizontal"] .header .header-content {
	padding-right: 1.875rem;
	padding-left: 1.875rem;
}

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="mini"] .header {
	padding-right: 6rem;
}

[direction="rtl"][data-sidebar-style="mini"] .header {
	padding: 0 0.9375rem;
	padding-right: 3.75rem;
}

[direction="rtl"][data-sidebar-style="compact"] .header {
	padding: 0 0.9375rem;
	padding-right: 9.375rem;
}

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .header {
	padding: 0;
	padding-right: 16rem;
}

[direction="rtl"][data-sidebar-style="modern"] .header {
	padding: 0 0.9375rem;
	padding-right: 9.375rem;
}

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .header {
	padding: 0;
	padding-right: 16rem;
}

[direction="rtl"] {
	text-align: right;
}


[direction="rtl"] .list-group {
	padding-left: 0;
}

[direction="rtl"] .form-check-input {
	margin-left: -1.25rem;
	margin-right: inherit;
}

[direction="rtl"] .form-check-input1 {
	margin-left: -1.25rem;
	margin-right: inherit;
}

[direction="rtl"] .form-check-inline .form-check-input {
	margin-right: 0;
	margin-left: 10px;
}

[direction="rtl"] .form-check-inline .form-check-input1 {
	margin-right: 0;
	margin-left: 10px;
}

[direction="rtl"] .radio input,
[direction="rtl"] .radio-inline,
[direction="rtl"] .checkbox input,
[direction="rtl"] .checkbox-inline input {
	margin-left: 0;
	margin-right: 0;
}

[direction="rtl"] .content-body {
	margin-right: 17.1875rem;
	margin-left: auto;
}

[data-sidebar-style="modern"][direction="rtl"] .content-body {
	margin-right: 9.375rem;
}

[direction="rtl"] .content-body .page-titles {
	text-align: right;
}

[direction="rtl"][data-sidebar-style="overlay"] .content-body {
	margin-right: 0;
}

[direction="rtl"] #external-events .external-event:before {
	margin-right: 0;
	margin-left: .9rem;
}

[direction="rtl"] .post-input a i {
	margin-left: 15px;
	margin-right: 0;
}

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
	[direction="rtl"] .content-body {
		margin-right: 14.375rem;
	}
}

[direction="rtl"] .sidebar-right {
	left: -15.625rem;
	right: auto;
	box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1);
}

[direction="rtl"] .sidebar-right.show {
	left: 0;
	right: auto;
}

[direction="rtl"] .sidebar-right .sidebar-right-trigger {
	left: 100%;
	right: auto;
	border-radius: 0 5px 5px 0;
	box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}


html[dir="rtl"] [direction="rtl"] .footer {
	padding-right: 17.1875rem;
	padding-left: 0;
}

@media (max-width: 767.98px) {
	html[dir="rtl"] [direction="rtl"] .footer {
		padding-right: 0;
	}
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
	padding-right: 0;
}

html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
	padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
	padding-right: 0;
}


.nav-display {
	visibility: hidden
}

.body .picker-input__text,
body .MuiInputBase-input {
	width: 300px!important;
	margin-right: 0rem!important;
	margin-left: 3.5rem!important;
}


tr:hover {
	background-color: #F0F0F0;
}

.form-label {
	display: none;
}

.nodestatus-box .form-outline .mb-4 {
	background-position: 98% 50%;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23A5A5A5' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
}

.swal2-loader {
	border-color: #25AFA8 transparent #25AFA8 transparent!important;
}

.swal2-title {
	color: #25AFA8!important;
	font-weight: 500!important;
}

.cert-box .form-outline .mb-4 {
	background-position: 98% 50%;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23A5A5A5' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
}

.select-file-box .form-outline .mb-4 {
	background-position: 98% 50%;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23A5A5A5' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
}

.match-fw-block .form-outline .mb-4 {
	background-position: 98% 50%;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23A5A5A5' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
}

.user-table-card-body .form-outline .mb-4 {
	background-position: 98% 50%;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23A5A5A5' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
}

.task-datatable .form-outline .mb-4 {
	background-position: 98% 50%;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23A5A5A5' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
}

.target-info-zindex {
	z-index: 10000000 !important;
	border-radius: 0.5rem !important;
}

.popover .arrow {
	margin: 0rem 5rem 0rem 0rem !important;
}

.popover-header {
	border-top-left-radius: 0.5rem !important;
	border-top-right-radius: 0.5rem !important;
}

.task-fw-detail {
	height: 20rem!important;
}

.nmc-tab-hide {
	display: none;
}

.fw-deploy-status-title {
	justify-content: flex-start!important;
}

.action-detail {
	margin-top: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.task-add-deplay-time {
	display: flex;
}

.task-time-sec-text {
	margin-left: 0.5rem;
	display: flex;
	align-items: center;
	color: #7e7e7e;
}

.task-side-label-color {
	color: #25AFA8!important;
}

.control-block-adjust {
	flex-wrap: nowrap !important;
}

.speed-process-text {
	display: flex;
	align-content: center;
}

.fs-8 {
	font-size: 8px !important;
	line-height: 1.1;
}

.start-monitor-btn-margin {
	margin-bottom: 1.0rem!important;
}

.nav-remove {
	display: none!important;
}

.form-bg {
	background-color: #FAFAFA;
}

.modal-button2 {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin: 1.5rem 0rem 1rem 0rem;
}

.modal-button2 .btn {
	margin-left: 1rem;
	padding: 1rem 3rem;
}

.page-404-body {
	margin-right: 21.563rem!important;
}

.manage-node-title-resize{
	max-width: 1220px;
}

@media screen and (min-width: 1900px) and (max-width: 2600px) {
	.header-searchbox{
		margin-right:7%;
	}
	
}

.sensor-popover{
	z-index:10000!important;
}

.sensor-warning-hide{
	display:none;
}

.sensor-warning-show{
	margin-left:1rem;
}

.header-title-dashboard{
	color:#0F4542!important;
	
}

.header-position{
	display:flex;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.dashboard-node-status{
	padding-left:0rem!important;
	display:flex;
	flex-wrap:wrap;

}

.xterm .xterm-viewport{
	overflow-y:auto!important;
}

.profile-role{
	height:1.5rem!important;
}

.task-chart{
	padding-top: 0.7rem!important;
	padding-bottom:0.7rem!important;
	position:relative;
}

.dashboard-user-status-card{
	height:24rem!important;
}


.node-status-detail{
	margin-top:2.6rem;
	margin-bottom:1.1rem;
}

.dashboard-task-row{
	flex-wrap: nowrap!important;
}

.dashboard-org-status-card{
	height:14rem!important;
}

.help-center-title{
	display:flex;
}

.help-description-text{
	white-space: pre-line;
	font-size:0.9rem!important;
}

.help-card-header{
	font-size:1rem!important;
	margin-left:1rem!important;
}

.help-accordion-text{
	font-size:0.9rem!important;
}

.help-card-header{
	font-weight:450;
}

.accordion-item .accordion__header .accordion-button {
    border: none !important;
    background-color: unset;
    width: 100%;
}
.accordion__header--indicator{
	font-size:1.5rem;
}

#nodestatus-box .datatable .datatable-pagination{
	margin-bottom: 1rem;}


    .bottom-bar{
		align-items: baseline;
		background: rgb(43, 55, 59);
		color: white;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		left: 0px;
		position: fixed;
		width: 100%;
		z-index: 999;
		bottom: 0px;
		height:4rem;
		align-items: center;
		font-size: 0.9rem;
	}

	.bottom-bar-button{
		background: rgb(255, 212, 45);
		border: 0px;
		border-radius: 0px;
		box-shadow: none;
		color: rgb(78, 80, 59);
		cursor: pointer;
		flex: 0 0 auto;
		padding: 5px 10px;
		margin: 15px;
		font-size: 13px;
	}

	.display-none{
		display:none!important;
	}

	@media screen and (min-width: 500px) and (max-width: 700px) {
		.bottom-bar{	
			height:7rem;

		}
	}
	
	@media screen and (min-width: 0px) and (max-width: 500px) {
		.bottom-bar{	
			height:9rem;
			font-size: 0.7rem;
		}
	}


	.license-banner{
		width:100%;
		background-color:#25AFA8;
		height:15rem!important;
		margin-top:5rem!important;
		color:#ffffff!important;
	}

	.license.s2 {
		padding: 55px 0 275px;
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;    
	}


	.step-form-horizontal >div:nth-child(1) >div:nth-child(3)>div{
		position: relative;
		height: 3.5rem;
		margin-bottom:3rem;
	}


	.step-form-horizontal >div:nth-child(1) >div:nth-child(3)>div >button{
		position: absolute;
	}

	.step-form-horizontal >div:nth-child(1) >div:nth-child(3)>div >button:nth-child(1){
		color: #ffffff!important;
		top:45px;
	}

	.step-form-horizontal >div:nth-child(1) >div:nth-child(3)>div >button:nth-child(2){
		top:45px;
		right:10px;
	}

	.c-delete-btn{
		height:1rem;
	}

	@media screen and (min-width: 0px) and (max-width: 500px) {
		.license.s2 {
			padding: 55px 30px 275px!important;
		
		}
	
	}


	.page-header {
		padding: 30px 0 0;
		margin: 40px 0;
		border: 0;
		border-top: 1px solid rgba(0,0,0,.12);
	}


	.li-spuare{
		list-style-type: square!important;
	}

	.light-background{
		background-color: #FAFAFA!important;
	}

	  .gif_box {
		width:580px!important;
	  }

	  .footer-main{
		  padding-left: 0rem!important;
	  }
	
	  .footer-contact{
		  margin-top:7rem!important;
	  }
	  

	  .update-status-loader{
		  width:20px!important;
		  height:20px!important;
		  margin-left:10px!important;
		  margin-top:12px!important;
	  }

	  .reset-password-card{
		margin-bottom: 1.875rem;
		background-color: #ffffff !important;
		transition: all .5s ease-in-out;
    	position: relative;
		border-radius: 0.875rem;
    	box-shadow: 0px 12px 23px 0px rgb(192 70 211 / 4%);
    	height: calc(100% - 30px);
		border: none !important;
		display: flex;
    	flex-direction: column;
    	min-width: 0;
    	word-wrap: break-word;
		background-clip: border-box;
	  }

	  .password-diaplay-btn{
		color: #7e7e7e;
		padding: 0rem 0.5rem;
		border-radius: 0.575rem;
		font-weight: 500;
		font-size: 1rem;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		vertical-align: middle;
		user-select: none;
		background-color: transparent;
		border: 1px solid transparent;
		line-height: 1;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	  }

	  .swal-button:not([disabled]):hover {
		background-color: #5aa418!important;
		}

	  .swal-button {
		background-color: #25AFA8!important;
	  }

	
	  .rstpassword-input-wrap{
		display: flex;
	  }

	  .rstpassword-input-wrap .otp-input{
		margin-right: 0.9rem;
    	text-align: center;
		height: 3.5rem;
	  }

	  @media only screen and (max-width: 500px) {
		.rstpassword-input-wrap .otp-input {
			margin-right: 0.1rem;
			height: 2.5rem;
		}
	}
	  

	  .rstpassword-otp-submit{
		margin: 2.5rem 0rem 3rem 0rem;
	  }

	  .rstpassword-otp-submit .btn-primary{
		border-radius: 0.3rem;
		width:100%;
		height:2.5rem;
		font-size: 15px!important;
   		font-weight: 500;
	  }
	

	  @keyframes shake {
		0% { transform: translateX(0); }
		10%, 90% { transform: translateX(-5px); }
		20%, 80% { transform: translateX(5px); }
		30%, 50%, 70% { transform: translateX(-10px); }
		40%, 60% { transform: translateX(10px); }
		100% { transform: translateX(0); }
	  }
	  
	  .is-invalid {
		border: 1px solid red!important;
		animation: shake 0.5s;
		animation-iteration-count: 1;
		border-radius: 0.35rem;
	  }

	  .profile-password-invalid {
		border: unset !important;
	  }

	  .profile-password-input {
		display:flex;
		align-items: center;
		position: relative;
		margin-top: 0.5rem;
	  }

	  .pro-changepassword-wrap{
		display:flex;
		margin-top: 2rem;
	  }

	  .pro-changepassword-left{
		display: flex;
		margin-top: 2rem;
		flex-direction: column;
		align-items: center;
		padding: 3.5rem 4rem 4rem 4rem;
		flex: 0 0 50%;
    	max-width: 50%;
	  }

	  .pro-password-left-card{
		background: #ffffff;
    	padding: 3rem;
    	border-radius: 0.37rem;
    	box-shadow: rgb(0 0 0 / 5%) 0px 5px 10px;
	  }

	  .pro-changepassword-right{
		padding: 2rem 9rem 8rem 6rem;
		flex: 0 0 50%;
    	max-width: 50%;
	  }

	
	@media(max-width: 1400px){
		.pro-changepassword-right{
			padding: 2rem 9rem 4rem 6rem;
		}
	}
	
	@media(max-width: 1200px){
		.pro-changepassword-right{
			padding: 2rem 6rem 4rem 4rem;
		}
		.pro-changepassword-left{
			padding: 1.5rem 1rem 1rem 1rem;
	  }
	}
	
	@media(max-width: 1100px){
		.pro-changepassword-right{
			padding: 2rem 4rem 4rem 2rem;
		}
		.pro-changepassword-left{
			padding: 0.5rem 1rem 1rem 1rem;
	  }
	  .pro-password-left-card{
		padding: 2rem;
	  }
	}
	
	@media(max-width: 900px){
		.pro-changepassword-right{
			padding: 2rem 3rem 4rem 1rem;
		}
	}


	@media(max-width: 600px){
		.pro-changepassword-wrap{
			flex-direction: column;
			align-items: center;
			margin-top: 0rem;
		  }

		  .pro-changepassword-left{
			flex: 0 0 100%;
			max-width: 100%;
			
		  }
	
		  .pro-changepassword-right{
			flex: 0 0 100%;
			max-width: 100%;
			width:67%;
			padding: 2rem 0rem;
		}


		  
	}

	@media(max-width: 500px){
		.pro-changepassword-right{
			width:77%;
		}
	}

	@media(max-width: 400px){
		.pro-changepassword-right{
			width:87%;
		}
	}


	  

	  .newpassword-input-div{
		position:relative;
	  }

	  #new-password-input{
		border-top-right-radius: 0.4rem ;
		border-bottom-right-radius: 0.4rem ;
	  }
	  

	  .newpassword-hint-div{
		font-size: 14px;
		margin-top: 1rem;
	}

	   .newpassword-hint-div >div{
		margin-bottom:0.5rem;
	}

	   .newpassword-hint-div >div >i{
		/* margin-left:0.5rem; */
		margin-right:0.5rem;
	}
	
	.admin-table .datatable .datatable-pagination .datatable-pagination-buttons{
		display:flex;
	}

	.edit-group-table .datatable .datatable-pagination .datatable-pagination-buttons{
		display:flex;
	}

	.deny-user-box .datatable .datatable-pagination .datatable-pagination-buttons{
		display:flex;
	}

	.permission-token{
		margin: 1.5rem 2.85rem;
	}

	.permission-token-admin{
		margin: 0.5rem 3.75rem;
	}

	.token-list {
		list-style-type: none;
		padding: 0;
	  }
	  
	  .token-list li {
		display: inline-block;
		padding: 5px 10px;
		margin: 8px 8px;
		border: 1px solid #ccc;
		border-radius: 1.5rem;
		background-color: #f5f5f5;
		cursor: pointer;
		user-select: none;
	  }
	  
	  .token-list li.selected {
		background-color: #25AFA8;
		color: #fff;
		border-color: #25AFA8;
	  }
	  
	  .token-list li:hover {
		opacity: 0.8;
	  }

	  .cri-button button{
		padding:5px 10px;
		border:0;
		color:#7e7e7e;
	  }

	  .cri-button button:nth-child(1){
		border-bottom-left-radius: 1.5rem!important;
		border-top-left-radius: 1.5rem!important;
	  }

	  .cri-button button:nth-child(2){
		border-bottom-right-radius: 1.5rem!important;
		border-top-right-radius: 1.5rem!important;
		border-left: 2px solid #ccc;
	  }


	  @media screen and (max-width: 1000px) {
		.token-list li {
			border-radius: 1.25rem!important;
		}

		.cri-button button{
			width:100%;
		}

		.cri-button button:nth-child(1){
			border-bottom-left-radius: 0rem!important;
			border-top-left-radius: 1.25rem!important;
			border-top-right-radius: 1.25rem!important;
		}
		.cri-button button:nth-child(2){
			border-bottom-left-radius: 1.25rem!important;
			border-bottom-right-radius: 1.25rem!important;
			border-top-left-radius: 0rem!important;
			border-top-right-radius: 0rem!important;
			border-left: 0px solid #ccc!important;
		}
	
	  }


	  

	  .cri-button button.selected {
		background-color: #25AFA8;
		color: #fff;
		border-color: #25AFA8;
	  }

	  .cri-button button.selected:nth-child(2) {
		border-left: 2px solid #fff;
		background-color: #25AFA8;
	  }
	  
	  .token-list li.selected.disabled {
		background-color: #80c9c6;
		border-color: #80c9c6;
		pointer-events: none;
		cursor: not-allowed;
	  }

	  .cri-button >button.selected.disabled{
		background-color: #80c9c6;
		pointer-events: none;
		cursor: not-allowed;
	  }
	  

	  .multi-select-dropdown {
		position: relative;
		width: 100%;
	  }
	  
	  .selected-permissions {
		cursor: pointer;
		text-decoration: underline;
		color: #25afa8;
		font-size: 14px;
	  }
	  
	  .permissions-list {
		position: absolute;
		width: 100%;
		background-color: white;
		border: 1px solid #ccc;
		padding: 0;
		margin: 0;
		list-style-type: none;
		max-height: 200px;
		overflow-y: auto;
		z-index: 1;
	  }
	  
	  .permissions-list li {
		padding: 10px;
		cursor: pointer;
	  }
	  
	  .permissions-list li:hover {
		background-color: #f0f3f4;
	  }
	  

	  .sub-permissions-list {
		list-style-type: none;
		padding-left: 20px;
		margin: 0;
	  }
	  
	  .multi-select-dropdown .selected-permissions .fa-edit {
		margin-left: 8px;
	  }
	  

	  .manage-user-card{
		box-shadow: 0px 12px 23px 0px rgb(132 132 132 / 10%)!important;
	  }

	  .nav-select-group{
		position: relative;
    	color: #25AFA8;
    	background: #F6F6F6;
    	border-radius: 0.5rem;
    	padding: 15px;
    	line-height: 1;
		margin-right:1rem;
		font-weight: 500;
		width: 13rem;
    	display: flex;
    	justify-content: center;
	  }

	  @media screen and (max-width: 1550px) {
		.nav-select-group{
			margin-right:0rem;
		}
	  }



	  @media screen and (max-width: 575px) {
		.nav-select-group{
			width: 7rem!important;
			margin-right: unset!important;
			font-size: 14px;
		}
	  }



	  .swal2-styled.swal2-confirm {
		background-color: #25AFA8!important;
	  }

	  .userManager-check .form-check .form-check-label{
			margin-top:0rem;
	  }

	  @media only screen and (max-width: 575px) {
		.newpassword-hint-div{
			font-size: 12px;
		}
	}


	.nodegroup-submit .new-nodegroup-submit{
		height: unset!important;
		padding: 0.7rem 1rem;
	}

	.nodegrouplist-edit-btn{
		justify-content: flex-start;
		padding: 0rem 0.5rem;
		height:2rem;
	}

	.nodegrouplist-edit-btn:hover, .nodegrouplist-edit-btn:focus {
		background-color: transparent !important;
		border-color: transparent !important;
	}

	.swal-button--cancel{
		background-color: #e64942!important;
		color: #fff !important;
	}

	.swal-button--cancel:not([disabled]):hover{
		background-color: #f4746e!important;
	}

	.manage-fw-btns{
		display: flex;
		justify-content: space-between;
	}

	.filelist-wrap{
		height:650px;
	}

	.btn-primary[aria-disabled="true"] {
		background-color: rgba(209, 209, 209, 1);
		border-color: rgba(209, 209, 209, 1);
	}
	
	.manage-fw-btns >div:nth-child(2){
		display: flex;
	}

	.manage-fw-btns >div:nth-child(1){
		font-size: 21px;
    	color: #0F4542;
    	font-weight: 500;
		display: flex;
		align-items: center;
    	margin-left: 1rem;
	}

	.head-nav-dropdown{
		margin-left: 0.1rem;
	}

	
	.head-nav-dropdown .dropdown-menu-right {
		min-width: 240px;
    	top: 100%;
	}

	@media only screen and (max-width: 575px) {
		.head-nav-dropdown{
			margin-left: 0rem!important;
		}
		.head-nav-dropdown .dropdown-menu-right {
			min-width: 205px;
		}
		.navgroup-dropdown-item >div{
			font-size:13px;
		}
		.dropdown-menu.dropdown-menu-right{
			left: -3rem !important;
		}
	}

	.navgroup-dropdown-item{
		cursor: pointer;
	}

	.navgroup-dropdown-item:hover{
		background-color: #f0f3f4;
		color:#25afa8;
	}

	.navgroup-dropdown-item >div{
		display: flex;
    	align-items: center;
    	justify-content: center;
		padding:0.5rem 0rem;
	}

	.selectgroup-text{
		display: flex;
	}
	.selectgroup-text >span{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.action-detail .selected-file-block .selected-file{
		margin-bottom:2rem;
	}

	.dashboard-title{
		display: flex;
		font-weight: 600;
    	font-size: 25px;
    	color: #383838;
		margin:2.5rem 1rem;
		justify-content: center;
	
	}

	.dashboard-title >div >span{
		text-decoration: underline;
		cursor: pointer;
		color: #25afa8;
	}

	.dashbaord-row-wrap{
		display: flex;
		flex-direction: row;
	}

	@media only screen and (max-width: 575px) {
		.dashbaord-row-wrap{
			flex-direction: column;
		}
	}


	.rounded-pill{border-radius:50rem!important}


	.dashboard-permission{
		color:#ffffff;
		padding:0.2rem 0.6rem;
		font-size: 13px;
	}

	.dashboard-permission-wrap{
		display: flex;
		flex-wrap:wrap;
	}

	.dashboard-taskchart{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.dashbaord-evt-card{
		height:unset;
		margin-bottom:1rem!important;
	}

	.dashbaord-evt-title{
		font-size: 20px;
	}

	.dashbaord-evt-title >span{
		color: #F44E52!important;
		font-size: 21px;
	}

	.dashbaord-evt-card .task-chart .dashboard-title{
		margin: 1rem!important;
	}

	
	@media only screen and (max-width: 575px) {
		.dashbaord-evt-card .task-chart .dashboard-title{
			margin: 0.5rem!important;
		}
		
		.dashbaord-evt-title{
			font-size: 18px;
		}
	}


	.dash-task-card{
		padding:0rem 2.5rem 2.5rem 2.5rem;
		height: unset!important;
	}

	.dashbaord-more-icon{
		position: absolute;
		top:18%;
		right:3%;
	}

	.dashbaord-more-icon-2{
		position: absolute;
		top:2%;
		right:3%;
	}

	.dashboard-activegroup{
		font-size: 16px;
   		margin-top: 0.5rem;
    	color: #25afa8;
    	font-weight: 600;
    	margin-bottom: 0.5rem;
	}

	.dashboard-card{
		background-color: #ffffff !important;
	}

	.permission-card{
		height: unset !important;
	}

	.dashboard-notifi{
		margin:1rem;
	}

	.dashboard-notifi >span:nth-of-type(1){
		font-weight: 600;
		color:#000;
	}

	.dashboard-notifi >span:nth-of-type(2),	.dashboard-notifi >span:nth-of-type(3){
		font-weight: 500;
		color:#25afa8;
		margin-right:0.3rem;
	}
	
	.dashboard-evt-header{
		position:relative;
		padding-bottom:1.25rem;
	}

	.dashboard-evt-header>div:nth-child(1){
		display: flex;
		align-items: center;
	}

	.dashboard-evt-title{
		font-weight: 600;
		font-size: 16px !important;
   		line-height: 1.5;
		color:#000;
		margin-left:0.5rem;
	}

	.dashbaord-evt-view{
		cursor:pointer;	
		padding:0% 19%;
	}

	.evt-box .datatable .datatable-inner .table tbody tr td {
		font-size: 0.8rem !important;
	}

	
@keyframes change-background {
    from {
        background-color: #fafafa;
        box-shadow: 0 0 30px #fafafa;
      }
    
      to {
        background-color: #00EC5E;    
        box-shadow: 0 0 100px #00EC5E;
      }
  }


#circle {
    width: 10px;
    height: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin-right: 0.2rem;
}

.breath-light{
    animation: change-background 1s ease-in-out infinite;
    animation-direction: alternate;
	padding-left: 5%;
	padding-right:5%;
}

.online-light{
    background-color: #6BD978;
}

.offline-light{
    background-color: #b9b9b9;
}

.dashboard-light{
	display: flex;
	align-items: center;
}

.cri-button{
	padding: 0px !important;;
}

.check-icon {
	color: #25afa8;
	margin-right: 5px;
}

.dashboard-organization-h2{
	font-weight: 600;
	font-size: 28px;
    line-height: 1.4;
	color: #000 !important;
	margin-bottom: 0 !important;
}

@media (max-width: 420px) {
	.dashboard-organization-h2{
		font-size: 22px;
	}
}

#overlay {
    position: fixed;  /* Fixed position covers the entire screen */
    top: 0;
    left: 0;
    width: 100vw;     /* Full width */
    height: 100vh;    /* Full height */
	background-color: rgba(255, 255, 255, 0.5);
	animation: fadeInOut 2s infinite;
    z-index: 9999;    /* Ensure it sits on top of everything else */
}



.node-c-action-progress{
	margin-top: 0.5rem;
    width: 25%;
}

.loader-container {
	display: flex;
	flex-direction: column; 
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
  }

  .loading-text {
	color:#25afa8;
	margin-top: 5px; 
	font-size: 20px;
  }

  .loading-text-2 {
	color:#25afa8;
	font-size: 15px;
	margin-left:0.5rem;
  }

  @keyframes fadeOut {
	from {
	  opacity: 1;
	}
	to {
	  opacity: 0;
	}
  }
  
  .loader-container.fade-out {
	animation: fadeOut 5s forwards;
  }

  .reset-password-contend-body{
	margin-left: unset!important;
  }

  .reset-footer{
	padding-left: unset!important;
  }

  .bi-x-circle-fill:hover{
	fill: #C03840 !important;
	cursor: pointer;
  }

  .provision-name-warn{
	color:#ff4824;
	font-size: 12px;
  }

  .cert-copy-tooltip{
	opacity: 1!important;
	visibility: visible!important;
	background-color:#25afa8 !important;
	border: 0 !important;
	margin-left: 72%!important;
  }

  .cert-copy-tooltip .tooltip-inner{
	background-color:#25afa8 !important;
	border: 0 !important;
  }

  .command-sample-title-div{
	display: flex;
	justify-content: space-between;
	align-items: center;
  }

  .command-sample-title-div >div{
	cursor: pointer;
  }

  .command-sample-title-div >div:hover{
	color:#25afa8;
  }

  .custom-action-table{
	line-height: 0.5rem!important;
  }

  @media (max-width: 1000px) {
	.custom-action-table{
		line-height: 1rem!important;
	  }
}


.c-action-align{
	justify-content: center;
}

.c-action-progress .progress{
	width:60%;
}

.c-action-delete-btn{
	height:0;
	padding: 0;
	border: none; 
}

.c-action-delete-btn:hover,
.c-action-delete-btn:focus {
    height: 0;
	padding: 0;
    border: none;
    box-shadow: none; 
    background-color: transparent;
    color: inherit; 
}

.c-action-back-btn{
    border-width: 1px;
	color:rgba(37, 175, 168, 1);
	border-color:rgba(37, 175, 168, 1);
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    width: 8rem;
    height: 2.5rem;
}

.c-action-back-btn:hover {
    border-color: rgba(28, 150, 145, 1);
    color: white;
    background-color: rgba(37, 175, 168, 1); 
}

.a-action-para-input{
	font-family: sans-serif;
	font-size:10pt;
}

.selected-btn {
	/* background-color: #25afa8;  */
	color: #25afa8; /* 文字顏色 */
  }
  


.disabled-row input[type="checkbox"] {
	pointer-events: none; 
	opacity: 0.5; 
	cursor: not-allowed; 
  }


  .disabled-row {
	background-color: #f5f5f5; 
	color: #aaa; 
	pointer-events: none;
	cursor: not-allowed;
  }
  
  .disabled-row:hover {
	background-color: #e9e9e9; 
  }
  
  .temp-test-btn{
	height:2.5rem!important;
  }

  .disabled{
	pointer-events: none;
	opacity: 0.5;
}

.info-act-l-div{
	padding: 1rem 0rem 2rem 0rem;
}

.info-act-l-div-top{
	width:100%;
	display: flex;
    flex-direction: row;
    justify-content: center;
	/* border-bottom: 1px solid #efefef; */
}

.info-act-l-div-top >div:nth-child(1){
	padding:0.25rem 0.25rem 1rem 0.25rem!important
}

.info-act-l-div-wrap{
	border-bottom: 1px solid #efefef;
	padding-bottom: 1rem;
}

.info-act-l-div-wrap2 {
  margin-top:0.5rem;
}

.info-act-card-wrap{
	display: flex;
    width: 100%;
}

.info-act-r-card{
	background-color: #f9f9f973;
    border-radius: 1rem;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    margin:1rem;
}

.info-act-r-title{
	padding: 1.5rem 1rem 0.7rem 1rem!important;
	color:#252928;
    font-weight: 600;
    font-size: 18px;
    margin-left: 1rem;
    position: relative;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.info-act-r-title >div:nth-child(1){
	display: flex;
	align-items: center;
}

/* .info-act-reload{
	color:#25afa8;
	cursor: pointer;
}

.info-act-reload:hover{
	color:#e0e0e0;
} */

.info-act-reload {
	color: #25afa8;
	cursor: pointer;
	transition: color 0.3s ease, transform 0.2s ease;
  }
  
  .info-act-reload:hover {
	color: #e0e0e0;
	transform: scale(1.1); 
  }
  
  .info-act-reload:active {
	color: #bdbdbd;
	transform: scale(0.95); 
  }
  

.info-act-l{
	width: 35%;
    position: relative;
}

.info-act-l-support{
	overflow-y: auto;
	overflow-x: hidden;
	height: 80px;
	margin:0rem 2rem 0rem 3rem;
}

@media (max-width: 1000px){
	.info-act-l-support{
		margin:0rem 1rem 0rem 1rem;
	}
}



.info-act-l-support::-webkit-scrollbar {
    width: 7px;
}

.info-act-l-support::-webkit-scrollbar-thumb {
	background-color: #25afa8;
	border-radius: 10px;
}

.info-act-l-support::-webkit-scrollbar-track {
	background-color: #E0E0E0;
}

@media screen and (min-width: 700px) and (max-width: 1200px){
    .info-act-r-title .we-report-filter{
        display:none;
    }
}

@media (max-width: 700px){
	.info-act-r-card{
		margin:0rem;
		box-shadow: rgb(99 99 99 / 20%) 1px 7px 8px 0px;
	    border-top-left-radius:0rem;
        border-top-right-radius:0rem;
	}
	.info-act-card-wrap{
        flex-direction: column;
        background: #ffffff;
    }
	.info-act-r-title{
		margin-left:0rem;
        justify-content: center;
	}
	.info-act-l{
		width: 100%;
        border-bottom: 1px solid #DFDFDF; 
	}
}

.info-act-expand{
	display: flex;
    margin-right:2rem;
    justify-content: flex-end;
    cursor: pointer;
	margin-top:2rem;
}



.info-act-l-div-wrap2 >div:nth-child(1){
	padding: 1rem 2rem;
}

.info-act-l-div-item{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0.75rem 2rem;
	flex-wrap: wrap;
}

.info-act-l-div-item >div:nth-child(2){
	margin-left: 1rem;
	color:#25afa8;
}

@media (max-width: 1100px){
	.info-act-l-div-item >div:nth-child(2){
		margin-left: 0rem;
	}
}

.info-act-l-card{
	background-color: #f9f9f973;
    border-radius: 1rem;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    margin: 2rem 1.75rem;
}

.info-act-l-card >div:nth-child(1){
    display:flex;
    justify-content:center;
    font-size: 18px;
    font-weight:600;
    color:#252928;
    padding:1.5rem 0rem;
    border-bottom: 1px solid #efefef;
}

@media (max-width: 700px){
	.info-act-l-card{
        margin: 0rem 0rem;
        box-shadow: rgb(99 99 99 / 20%) -1px -2px 8px 0px;
        border-radius: 1rem 1rem 0rem 0rem;
    }
}

@media (max-width: 1200px){
	.info-act-l-card{
		margin: 2.5rem 0.5rem;
	}
}


.info-swinfo-hint{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 7vw;
	flex-direction: column;
}

.swinfo-list{
	overflow-y:auto;
	overflow-x:auto;
	width:95%;
	margin:auto;
	padding:1rem 0rem 1.5rem 0rem;
}
	
.swinfo-list .datatable .datatable-inner .table .datatable-header >tr >th{
    text-align:center;
    color: #000 !important;
}


.swinfo-list .datatable .datatable-pagination .datatable-pagination-buttons{
	display: flex;
}

.swinfo-list .datatable .datatable-inner .table .datatable-body >tr:nth-child(odd){
	background-color: #F3F3F3!important;
}

.info-edit-icon{
	margin-left:0.5rem;
}

.info-edit-icon:hover{
	color:#25afa8;
	cursor: pointer;
}

.message-line {
	margin-bottom: 2px;
	display: block;
  }

    
.custom-action-node-table .datatable .datatable-inner .datatable-table .datatable-body > tr > td:nth-child(4) {
    padding-top: 15px;
    padding-bottom: 15px;
    vertical-align: middle; 
  }



  .custom-action-node-table .datatable .datatable-inner .datatable-table .datatable-body > tr > td:not(:nth-child(4)) {
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle; 
  }

