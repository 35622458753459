
@import url(./shortcodes.css);
@import url(./responsive.css);


:root {
	--deny: #EE6164;
	--blue: #5e72e4;
	--indigo: #6610f2;
	--purple: #6f42c1;
	--pink: #e83e8c;
	--red: #EE3232;
	--orange: #ff9900;
	--yellow: #FFFA6F;
	--green: #297F00;
	--teal: #20c997;
	--cyan: #3065D0;
	--white: #fff;
	--gray: #6c757d;
	--gray-dark: #343a40;
	--primary: #25AFA8;
	--secondary: #C046D3;
	--success: #37D1BF;
	--info: #1EA7C5;
	--warning: #FF9432;
	--danger: #FF285C;
	--light: #E8E8E8;
	--dark: #6e6e6e;
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;
	--breakpoint-xxl: 1440;
	--font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	overflow-y: scroll!important; 
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

body {
	margin: 0;
	font-family: "Roboto", sans-serif;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #7e7e7e;
	text-align: left;
	background-color: #fafafa;
}

[tabindex="-1"]:focus:not(:focus-visible) {
	outline: 0 !important;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	text-decoration-skip-ink: none;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: .5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}

a {
	color: #25AFA8;
	text-decoration: none;
	background-color: transparent;
	transition: all 0.3s ease-in-out;
}

a:hover {
	color: #478213;
	text-decoration: underline;
	transition: all 0.3s ease-in-out;
}

a:focus {
	color: #25AFA8;;
    text-decoration: none;
    outline: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

a:not([href]):not([class]) {
	color: inherit;
	text-decoration: none;
}

a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}

pre,
code,
kbd,
samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em;
}

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar;
}

figure {
	margin: 0 0 1rem;
}

img {
	vertical-align: middle;
	border-style: none;
}

svg {
	overflow: hidden;
	vertical-align: middle;
}

table {
	border-collapse: collapse;
}

caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #89879f;
	text-align: left;
	caption-side: bottom;
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent;
}

label {
	display: block;
	margin-bottom: 0.5rem;
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	color: #25AFA8;
}

h1,
.h1 {
	font-size: 2.25rem;
}

h2,
.h2 {
	font-size: 1.875rem;
}

h3,
.h3 {
	font-size: 1.6rem;
}

h4,
.h4 {
	font-size: 1.125rem;
}

h5,
.h5 {
	font-size: 1rem;
}

h6,
.h6 {
	font-size: 0.938rem;
}


button {
	border-radius: 0;
}

button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

[role="button"] {
	cursor: pointer;
}

select {
	word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
	border: none!important;
	outline: none!important;
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}


* {
	outline: none;
	padding: 0;
}

*::after {
	margin: 0;
	padding: 0;
}

*::before {
	margin: 0;
	padding: 0;
}

::selection {
	color: #fff;
	background: #25AFA8;
}

body {
	overflow-x: hidden;
	height: 100%;
	position: relative;
	max-width: 100%;
	font-size: 1rem;
	padding-right: unset!important;
}

@media only screen and (max-width: 991px) {
	body {
		font-size: 0.875rem;
	}
}

p {
	line-height: 1.8;
}

.box-shadow-none {
	box-shadow: none !important;
}

#main-wrapper {
	opacity: 0;
	transition: all 0.25s ease-in;
	overflow: hidden;
	position: relative;
}

#main-wrapper.show {
	opacity: 1;
}

.rounded-lg {
	border-radius: 0.875rem;
}

.rounded-xl {
	border-radius: 12px !important;
}

ul {
	padding: 0;
	margin: 0;
}

li {
	list-style: none;
	
}

a {
	color: #7e7e7e;
}

a:hover,
a:focus,
a.active {
	text-decoration: none;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.active {
	text-decoration: none;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
	cursor: pointer;
}

template {
	display: none;
}

[hidden] {
	display: none !important;
}


.btn-block {
	display: block;
	width: 100%;
}

.btn-block+.btn-block {
	margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
	width: 100%;
}


.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.container .row .col-md-12 {
	padding-right: 0px !important;
	padding-left: 0px !important;
}

.user-list-container {
	padding-right: 0px !important;
	padding-left: 0px !important;
}

@media (min-width: 576px) {
	.container,
	.container-sm {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container,
	.container-sm,
	.container-md {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg {
		max-width: 1100px;
	}
}

@media (min-width: 1200px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1140px;
	}
}

@media (min-width: 1400px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1340px;
	}
}

@media (min-width: 1600px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1440px;
	}
}

@media (min-width: 1800px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1640px;
	}
}


.content-body .container-fluid,
.content-body .container-sm,
.content-body .container-md,
.content-body .container-lg,
.content-body .container-xl {
	padding-top: 10px;
	padding-right: 40px;
	padding-left: 40px;
}

@media only screen and (max-width: 1600px) {
	.content-body .container-fluid,
	.content-body .container-sm,
	.content-body .container-md,
	.content-body .container-lg,
	.content-body .container-xl {
		padding-top: 30px;
		padding-right: 30px;
		padding-left: 30px;
	}
}

@media only screen and (max-width: 767px) {
	.content-body .container-fluid,
	.content-body .container-sm,
	.content-body .container-md,
	.content-body .container-lg,
	.content-body .container-xl {
		padding-top: 20px;
		padding-right: 20px;
		padding-left: 20px;
	}
}

@media only screen and (max-width: 575px) {
	.content-body .container-fluid,
	.content-body .container-sm,
	.content-body .container-md,
	.content-body .container-lg,
	.content-body .container-xl {
		padding-top: 15px;
		padding-right: 15px;
		padding-left: 15px;
	}
}


.header {
	height: 7.5rem;
	z-index: 1;
	position: relative;
	padding: 0rem;
	background-color: #FBFBFB;
	padding-left: 21.563rem;
	transition: all .2s ease;
}

@media only screen and (max-width: 1400px) {
	.header {
		padding-left: 17rem;
	}
}

.header .header-content {
	height: 100%;
	padding-left: 5.3125rem;
	padding-right: 1.0rem;
	align-items: center;
	display: flex;
}

@media only screen and (max-width: 767px) {
	.header .header-content {
		padding-left: 3.75rem;
		padding-right: 0.938rem;
	}
}

.header .navbar {
	padding: 0;
	height: 100%;
	width: 100%;
}

.header .navbar .navbar-collapse {
	height: 100%;
	width: 100%;
}

@media only screen and (max-width: 1199px) {
	.header {
		height: 5rem;
	}
}


@media screen and (min-width: 10px) and (max-width: 766px) {
	.header {
		z-index: 3;
	}

}


@media screen and (min-width: 766px) and (max-width: 2700px) {
	.header {
		height: 11.6%;
	}

}


.order-first {
	order: -1;
}

.order-last {
	order: 13;
}

.order-0 {
	order: 0;
}

.order-1 {
	order: 1;
}

.order-2 {
	order: 2;
}

.order-3 {
	order: 3;
}

.order-4 {
	order: 4;
}

.order-5 {
	order: 5;
}

.order-6 {
	order: 6;
}

.order-7 {
	order: 7;
}

.order-8 {
	order: 8;
}

.order-9 {
	order: 9;
}

.order-10 {
	order: 10;
}

.order-11 {
	order: 11;
}

.order-12 {
	order: 12;
}

.offset-1 {
	margin-left: 8.3333333333%;
}

.offset-2 {
	margin-left: 16.6666666667%;
}

.offset-3 {
	margin-left: 25%;
}

.offset-4 {
	margin-left: 33.3333333333%;
}

.offset-5 {
	margin-left: 41.6666666667%;
}

.offset-6 {
	margin-left: 50%;
}

.offset-7 {
	margin-left: 58.3333333333%;
}

.offset-8 {
	margin-left: 66.6666666667%;
}

.offset-9 {
	margin-left: 75%;
}

.offset-10 {
	margin-left: 83.3333333333%;
}

.offset-11 {
	margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
	.col-sm {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-sm-1>* {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.row-cols-sm-2>* {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.row-cols-sm-3>* {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.row-cols-sm-4>* {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.row-cols-sm-5>* {
		flex: 0 0 20%;
		max-width: 20%;
	}
	.row-cols-sm-6>* {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-sm-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.col-sm-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-sm-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-sm-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.col-sm-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.col-sm-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-sm-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.col-sm-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.col-sm-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-sm-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.col-sm-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-sm-first {
		order: -1;
	}
	.order-sm-last {
		order: 13;
	}
	.order-sm-0 {
		order: 0;
	}
	.order-sm-1 {
		order: 1;
	}
	.order-sm-2 {
		order: 2;
	}
	.order-sm-3 {
		order: 3;
	}
	.order-sm-4 {
		order: 4;
	}
	.order-sm-5 {
		order: 5;
	}
	.order-sm-6 {
		order: 6;
	}
	.order-sm-7 {
		order: 7;
	}
	.order-sm-8 {
		order: 8;
	}
	.order-sm-9 {
		order: 9;
	}
	.order-sm-10 {
		order: 10;
	}
	.order-sm-11 {
		order: 11;
	}
	.order-sm-12 {
		order: 12;
	}
	.offset-sm-0 {
		margin-left: 0;
	}
	.offset-sm-1 {
		margin-left: 8.3333333333%;
	}
	.offset-sm-2 {
		margin-left: 16.6666666667%;
	}
	.offset-sm-3 {
		margin-left: 25%;
	}
	.offset-sm-4 {
		margin-left: 33.3333333333%;
	}
	.offset-sm-5 {
		margin-left: 41.6666666667%;
	}
	.offset-sm-6 {
		margin-left: 50%;
	}
	.offset-sm-7 {
		margin-left: 58.3333333333%;
	}
	.offset-sm-8 {
		margin-left: 66.6666666667%;
	}
	.offset-sm-9 {
		margin-left: 75%;
	}
	.offset-sm-10 {
		margin-left: 83.3333333333%;
	}
	.offset-sm-11 {
		margin-left: 91.6666666667%;
	}
}

@media (min-width: 768px) {
	.col-md {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-md-1>* {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.row-cols-md-2>* {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.row-cols-md-3>* {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.row-cols-md-4>* {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.row-cols-md-5>* {
		flex: 0 0 20%;
		max-width: 20%;
	}
	.row-cols-md-6>* {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-md-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.col-md-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-md-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-md-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.col-md-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-md-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.col-md-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.col-md-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-md-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.col-md-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.col-md-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-md-first {
		order: -1;
	}
	.order-md-last {
		order: 13;
	}
	.order-md-0 {
		order: 0;
	}
	.order-md-1 {
		order: 1;
	}
	.order-md-2 {
		order: 2;
	}
	.order-md-3 {
		order: 3;
	}
	.order-md-4 {
		order: 4;
	}
	.order-md-5 {
		order: 5;
	}
	.order-md-6 {
		order: 6;
	}
	.order-md-7 {
		order: 7;
	}
	.order-md-8 {
		order: 8;
	}
	.order-md-9 {
		order: 9;
	}
	.order-md-10 {
		order: 10;
	}
	.order-md-11 {
		order: 11;
	}
	.order-md-12 {
		order: 12;
	}
	.offset-md-0 {
		margin-left: 0;
	}
	.offset-md-1 {
		margin-left: 8.3333333333%;
	}
	.offset-md-2 {
		margin-left: 16.6666666667%;
	}
	.offset-md-3 {
		margin-left: 25%;
	}
	.offset-md-4 {
		margin-left: 33.3333333333%;
	}
	.offset-md-5 {
		margin-left: 41.6666666667%;
	}
	.offset-md-6 {
		margin-left: 50%;
	}
	.offset-md-7 {
		margin-left: 58.3333333333%;
	}
	.offset-md-8 {
		margin-left: 66.6666666667%;
	}
	.offset-md-9 {
		margin-left: 75%;
	}
	.offset-md-10 {
		margin-left: 83.3333333333%;
	}
	.offset-md-11 {
		margin-left: 91.6666666667%;
	}
}

@media (min-width: 992px) {
	.col-lg {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-lg-1>* {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.row-cols-lg-2>* {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.row-cols-lg-3>* {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.row-cols-lg-4>* {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.row-cols-lg-5>* {
		flex: 0 0 20%;
		max-width: 20%;
	}
	.row-cols-lg-6>* {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-lg-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.col-lg-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-lg-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-lg-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.col-lg-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.col-lg-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.col-lg-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.col-lg-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-lg-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.col-lg-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.col-lg-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-lg-first {
		order: -1;
	}
	.order-lg-last {
		order: 13;
	}
	.order-lg-0 {
		order: 0;
	}
	.order-lg-1 {
		order: 1;
	}
	.order-lg-2 {
		order: 2;
	}
	.order-lg-3 {
		order: 3;
	}
	.order-lg-4 {
		order: 4;
	}
	.order-lg-5 {
		order: 5;
	}
	.order-lg-6 {
		order: 6;
	}
	.order-lg-7 {
		order: 7;
	}
	.order-lg-8 {
		order: 8;
	}
	.order-lg-9 {
		order: 9;
	}
	.order-lg-10 {
		order: 10;
	}
	.order-lg-11 {
		order: 11;
	}
	.order-lg-12 {
		order: 12;
	}
	.offset-lg-0 {
		margin-left: 0;
	}
	.offset-lg-1 {
		margin-left: 8.3333333333%;
	}
	.offset-lg-2 {
		margin-left: 16.6666666667%;
	}
	.offset-lg-3 {
		margin-left: 25%;
	}
	.offset-lg-4 {
		margin-left: 33.3333333333%;
	}
	.offset-lg-5 {
		margin-left: 41.6666666667%;
	}
	.offset-lg-6 {
		margin-left: 50%;
	}
	.offset-lg-7 {
		margin-left: 58.3333333333%;
	}
	.offset-lg-8 {
		margin-left: 66.6666666667%;
	}
	.offset-lg-9 {
		margin-left: 75%;
	}
	.offset-lg-10 {
		margin-left: 83.3333333333%;
	}
	.offset-lg-11 {
		margin-left: 91.6666666667%;
	}
}

@media (min-width: 1200px) {
	.col-xl {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-xl-1>* {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.row-cols-xl-2>* {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.row-cols-xl-3>* {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.row-cols-xl-4>* {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.row-cols-xl-5>* {
		flex: 0 0 20%;
		max-width: 20%;
	}
	.row-cols-xl-6>* {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-xl-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.col-xl-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-xl-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xl-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.col-xl-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.col-xl-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xl-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.col-xl-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.col-xl-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xl-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.col-xl-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.col-xl-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-xl-first {
		order: -1;
	}
	.order-xl-last {
		order: 13;
	}
	.order-xl-0 {
		order: 0;
	}
	.order-xl-1 {
		order: 1;
	}
	.order-xl-2 {
		order: 2;
	}
	.order-xl-3 {
		order: 3;
	}
	.order-xl-4 {
		order: 4;
	}
	.order-xl-5 {
		order: 5;
	}
	.order-xl-6 {
		order: 6;
	}
	.order-xl-7 {
		order: 7;
	}
	.order-xl-8 {
		order: 8;
	}
	.order-xl-9 {
		order: 9;
	}
	.order-xl-10 {
		order: 10;
	}
	.order-xl-11 {
		order: 11;
	}
	.order-xl-12 {
		order: 12;
	}
	.offset-xl-0 {
		margin-left: 0;
	}
	.offset-xl-1 {
		margin-left: 8.3333333333%;
	}
	.offset-xl-2 {
		margin-left: 16.6666666667%;
	}
	.offset-xl-3 {
		margin-left: 25%;
	}
	.offset-xl-4 {
		margin-left: 33.3333333333%;
	}
	.offset-xl-5 {
		margin-left: 41.6666666667%;
	}
	.offset-xl-6 {
		margin-left: 50%;
	}
	.offset-xl-7 {
		margin-left: 58.3333333333%;
	}
	.offset-xl-8 {
		margin-left: 66.6666666667%;
	}
	.offset-xl-9 {
		margin-left: 75%;
	}
	.offset-xl-10 {
		margin-left: 83.3333333333%;
	}
	.offset-xl-11 {
		margin-left: 91.6666666667%;
	}
}

@media (min-width: 1440) {
	.col-xxl {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-xxl-1>* {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.row-cols-xxl-2>* {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.row-cols-xxl-3>* {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.row-cols-xxl-4>* {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.row-cols-xxl-5>* {
		flex: 0 0 20%;
		max-width: 20%;
	}
	.row-cols-xxl-6>* {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-xxl-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-xxl-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.col-xxl-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.col-xxl-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xxl-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.col-xxl-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.col-xxl-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xxl-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.col-xxl-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.col-xxl-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xxl-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.col-xxl-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.col-xxl-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-xxl-first {
		order: -1;
	}
	.order-xxl-last {
		order: 13;
	}
	.order-xxl-0 {
		order: 0;
	}
	.order-xxl-1 {
		order: 1;
	}
	.order-xxl-2 {
		order: 2;
	}
	.order-xxl-3 {
		order: 3;
	}
	.order-xxl-4 {
		order: 4;
	}
	.order-xxl-5 {
		order: 5;
	}
	.order-xxl-6 {
		order: 6;
	}
	.order-xxl-7 {
		order: 7;
	}
	.order-xxl-8 {
		order: 8;
	}
	.order-xxl-9 {
		order: 9;
	}
	.order-xxl-10 {
		order: 10;
	}
	.order-xxl-11 {
		order: 11;
	}
	.order-xxl-12 {
		order: 12;
	}
	.offset-xxl-0 {
		margin-left: 0;
	}
	.offset-xxl-1 {
		margin-left: 8.3333333333%;
	}
	.offset-xxl-2 {
		margin-left: 16.6666666667%;
	}
	.offset-xxl-3 {
		margin-left: 25%;
	}
	.offset-xxl-4 {
		margin-left: 33.3333333333%;
	}
	.offset-xxl-5 {
		margin-left: 41.6666666667%;
	}
	.offset-xxl-6 {
		margin-left: 50%;
	}
	.offset-xxl-7 {
		margin-left: 58.3333333333%;
	}
	.offset-xxl-8 {
		margin-left: 66.6666666667%;
	}
	.offset-xxl-9 {
		margin-left: 75%;
	}
	.offset-xxl-10 {
		margin-left: 83.3333333333%;
	}
	.offset-xxl-11 {
		margin-left: 91.6666666667%;
	}
}


.form-inline {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}

.form-inline .form-check {
	width: 100%;
}

@media (min-width: 576px) {
	.form-inline label {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
	}
	.form-inline .form-group {
		display: flex;
		flex: 0 0 auto;
		flex-flow: row wrap;
		align-items: center;
		margin-bottom: 0;
	}
	.form-inline .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}
	.form-inline .form-control-plaintext {
		display: inline-block;
	}
	.form-inline .input-group,
	.form-inline .custom-select {
		width: auto;
	}
	.form-inline .form-check {
		display: flex;
		align-items: center;
		justify-content: center;
		width: auto;
		padding-left: 0;
	}
	.form-inline .form-check-input {
		position: relative;
		flex-shrink: 0;
		margin-top: 0;
		margin-right: 0.25rem;
		margin-left: 0;
	}
	.form-inline .form-check-input1 {
		position: relative;
		flex-shrink: 0;
		margin-top: 0;
		margin-right: 0.25rem;
		margin-left: 0;
	}
	.form-inline .custom-control {
		align-items: center;
		justify-content: center;
	}
	.form-inline .custom-control-label {
		margin-bottom: 0;
	}
}


.lead {
	font-size: 1.09375rem;
	font-weight: 300;
}

.display-1 {
	font-size: 6rem;
	font-weight: 300;
	line-height: 1.2;
}

.display-2 {
	font-size: 5.5rem;
	font-weight: 300;
	line-height: 1.2;
}

.display-3 {
	font-size: 4.5rem;
	font-weight: 300;
	line-height: 1.2;
}

.display-4 {
	font-size: 3.5rem;
	font-weight: 300;
	line-height: 1.2;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
	font-size: 80%;
	font-weight: 400;
}

mark,
.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

nav-link:focus {
	text-decoration: none;
}

.nav-link.disabled {
	color: #6c757d;
	pointer-events: none;
	cursor: default;
}

.nav-tabs {
	border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
	margin-bottom: -1px;
}

.nav-tabs .nav-link {
	border: 1px solid transparent;
	border-top-left-radius: 0.75rem;
	border-top-right-radius: 0.75rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
	color: #6c757d;
	background-color: transparent;
	border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #495057;
	background-color: #F5F9F1;
	border-color: #dee2e6 #dee2e6 #F5F9F1;
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav-pills .nav-link {
	border-radius: 0.75rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	color: #fff;
	background-color: #25AFA8;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
	flex: 1 1 auto;
	text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
	flex-basis: 0;
	flex-grow: 1;
	text-align: center;
}

.tab-content>.tab-pane {
	display: none;
}

.tab-content>.active {
	display: block;
}

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.navbar-brand {
	display: inline-block;
	padding-top: 0.3359375rem;
	padding-bottom: 0.3359375rem;
	margin-right: 1rem;
	font-size: 1.09375rem;
	line-height: inherit;
	white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
	text-decoration: none;
}

.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	align-items: flex-end;
}

.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}

.navbar-nav .dropdown-menu {
	position: static;
	float: none;
}

.navbar-text {
	display: inline-block;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center;
}

.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.09375rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0.75rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
	text-decoration: none;
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%;
}

@media (max-width: 575.98px) {
	.navbar-expand-sm>.container,
	.navbar-expand-sm>.container-fluid,
	.navbar-expand-sm>.container-sm,
	.navbar-expand-sm>.container-md,
	.navbar-expand-sm>.container-lg,
	.navbar-expand-sm>.container-xl {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 576px) {
	.navbar-expand-sm {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-sm .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.0rem;
	}
	.navbar-expand-sm>.container,
	.navbar-expand-sm>.container-fluid,
	.navbar-expand-sm>.container-sm,
	.navbar-expand-sm>.container-md,
	.navbar-expand-sm>.container-lg,
	.navbar-expand-sm>.container-xl {
		flex-wrap: nowrap;
	}
	.navbar-expand-sm .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-sm .navbar-toggler {
		display: none;
	}
}

@media (max-width: 767.98px) {
	.navbar-expand-md>.container,
	.navbar-expand-md>.container-fluid,
	.navbar-expand-md>.container-sm,
	.navbar-expand-md>.container-md,
	.navbar-expand-md>.container-lg,
	.navbar-expand-md>.container-xl {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 768px) {
	.navbar-expand-md {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-md .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.0rem;
	}
	.navbar-expand-md>.container,
	.navbar-expand-md>.container-fluid,
	.navbar-expand-md>.container-sm,
	.navbar-expand-md>.container-md,
	.navbar-expand-md>.container-lg,
	.navbar-expand-md>.container-xl {
		flex-wrap: nowrap;
	}
	.navbar-expand-md .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-md .navbar-toggler {
		display: none;
	}
}

@media (max-width: 991.98px) {
	.navbar-expand-lg>.container,
	.navbar-expand-lg>.container-fluid,
	.navbar-expand-lg>.container-sm,
	.navbar-expand-lg>.container-md,
	.navbar-expand-lg>.container-lg,
	.navbar-expand-lg>.container-xl {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-lg .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.0rem;
	}
	.navbar-expand-lg>.container,
	.navbar-expand-lg>.container-fluid,
	.navbar-expand-lg>.container-sm,
	.navbar-expand-lg>.container-md,
	.navbar-expand-lg>.container-lg,
	.navbar-expand-lg>.container-xl {
		flex-wrap: nowrap;
	}
	.navbar-expand-lg .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-lg .navbar-toggler {
		display: none;
	}
}

@media (max-width: 1199.98px) {
	.navbar-expand-xl>.container,
	.navbar-expand-xl>.container-fluid,
	.navbar-expand-xl>.container-sm,
	.navbar-expand-xl>.container-md,
	.navbar-expand-xl>.container-lg,
	.navbar-expand-xl>.container-xl {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 1200px) {
	.navbar-expand-xl {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.0rem;
	}
	.navbar-expand-xl>.container,
	.navbar-expand-xl>.container-fluid,
	.navbar-expand-xl>.container-sm,
	.navbar-expand-xl>.container-md,
	.navbar-expand-xl>.container-lg,
	.navbar-expand-xl>.container-xl {
		flex-wrap: nowrap;
	}
	.navbar-expand-xl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xl .navbar-toggler {
		display: none;
	}
}

@media (max-width: 1439.98) {
	.navbar-expand-xxl>.container,
	.navbar-expand-xxl>.container-fluid,
	.navbar-expand-xxl>.container-sm,
	.navbar-expand-xxl>.container-md,
	.navbar-expand-xxl>.container-lg,
	.navbar-expand-xxl>.container-xl {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 1440) {
	.navbar-expand-xxl {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xxl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xxl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xxl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.0rem;
	}
	.navbar-expand-xxl>.container,
	.navbar-expand-xxl>.container-fluid,
	.navbar-expand-xxl>.container-sm,
	.navbar-expand-xxl>.container-md,
	.navbar-expand-xxl>.container-lg,
	.navbar-expand-xxl>.container-xl {
		flex-wrap: nowrap;
	}
	.navbar-expand-xxl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xxl .navbar-toggler {
		display: none;
	}
}

.navbar-expand {
	flex-flow: row nowrap;
	justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
	padding-right: 0;
	padding-left: 0;
}

.navbar-expand .navbar-nav {
	flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute;
}

.drop-down-position {
	margin-right: 5rem;
}

.navbar-expand .navbar-nav .nav-link {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
	flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
	flex-direction: column;
	display: flex !important;
	flex-basis: auto;
	flex-grow: 1!important;
}

.navbar-expand .navbar-toggler {
	display: none;
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.5);
	border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
	color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
	color: rgba(0, 0, 0, 0.9);
}


.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav-link {
	display: block;
	padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
	text-decoration: none;
}

.nav-link.disabled {
	color: #6c757d;
	pointer-events: none;
	cursor: default;
}

.nav-tabs {
	border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
	margin-bottom: -1px;
}

.nav-tabs .nav-link {
	border: 1px solid transparent;
	border-top-left-radius: 0.75rem;
	border-top-right-radius: 0.75rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
	color: #6c757d;
	background-color: transparent;
	border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #495057;
	background-color: #F5F9F1;
	border-color: #dee2e6 #dee2e6 #F5F9F1;
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav-pills .nav-link {
	border-radius: 0.75rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	color: #fff;
	background-color: #25AFA8;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
	flex: 1 1 auto;
	text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
	flex-basis: 0;
	flex-grow: 1;
	text-align: center;
}

.tab-content>.tab-pane {
	display: none;
}

.tab-content>.active {
	display: block;
}

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.navbar-brand {
	display: inline-block;
	padding-top: 0.3359375rem;
	padding-bottom: 0.3359375rem;
	margin-right: 1rem;
	font-size: 1.09375rem;
	line-height: inherit;
	white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
	text-decoration: none;
}

.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	align-items: flex-end;
}

.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}

.navbar-nav .dropdown-menu {
	position: static;
	float: none;
}

.navbar-text {
	display: inline-block;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center;
}

.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.09375rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0.75rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
	text-decoration: none;
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%;
}

@media (max-width: 575.98px) {
	.navbar-expand-sm>.container,
	.navbar-expand-sm>.container-fluid,
	.navbar-expand-sm>.container-sm,
	.navbar-expand-sm>.container-md,
	.navbar-expand-sm>.container-lg,
	.navbar-expand-sm>.container-xl {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 576px) {
	.navbar-expand-sm {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-sm .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.0rem;
	}
	.navbar-expand-sm>.container,
	.navbar-expand-sm>.container-fluid,
	.navbar-expand-sm>.container-sm,
	.navbar-expand-sm>.container-md,
	.navbar-expand-sm>.container-lg,
	.navbar-expand-sm>.container-xl {
		flex-wrap: nowrap;
	}
	.navbar-expand-sm .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-sm .navbar-toggler {
		display: none;
	}
}

@media (max-width: 767.98px) {
	.navbar-expand-md>.container,
	.navbar-expand-md>.container-fluid,
	.navbar-expand-md>.container-sm,
	.navbar-expand-md>.container-md,
	.navbar-expand-md>.container-lg,
	.navbar-expand-md>.container-xl {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 768px) {
	.navbar-expand-md {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-md .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.0rem;
	}
	.navbar-expand-md>.container,
	.navbar-expand-md>.container-fluid,
	.navbar-expand-md>.container-sm,
	.navbar-expand-md>.container-md,
	.navbar-expand-md>.container-lg,
	.navbar-expand-md>.container-xl {
		flex-wrap: nowrap;
	}
	.navbar-expand-md .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-md .navbar-toggler {
		display: none;
	}
}

@media (max-width: 991.98px) {
	.navbar-expand-lg>.container,
	.navbar-expand-lg>.container-fluid,
	.navbar-expand-lg>.container-sm,
	.navbar-expand-lg>.container-md,
	.navbar-expand-lg>.container-lg,
	.navbar-expand-lg>.container-xl {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-lg .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.0rem;
	}
	.navbar-expand-lg>.container,
	.navbar-expand-lg>.container-fluid,
	.navbar-expand-lg>.container-sm,
	.navbar-expand-lg>.container-md,
	.navbar-expand-lg>.container-lg,
	.navbar-expand-lg>.container-xl {
		flex-wrap: nowrap;
	}
	.navbar-expand-lg .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-lg .navbar-toggler {
		display: none;
	}
}

@media (max-width: 1199.98px) {
	.navbar-expand-xl>.container,
	.navbar-expand-xl>.container-fluid,
	.navbar-expand-xl>.container-sm,
	.navbar-expand-xl>.container-md,
	.navbar-expand-xl>.container-lg,
	.navbar-expand-xl>.container-xl {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 1200px) {
	.navbar-expand-xl {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.0rem;
	}
	.navbar-expand-xl>.container,
	.navbar-expand-xl>.container-fluid,
	.navbar-expand-xl>.container-sm,
	.navbar-expand-xl>.container-md,
	.navbar-expand-xl>.container-lg,
	.navbar-expand-xl>.container-xl {
		flex-wrap: nowrap;
	}
	.navbar-expand-xl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xl .navbar-toggler {
		display: none;
	}
}

@media (max-width: 1439.98) {
	.navbar-expand-xxl>.container,
	.navbar-expand-xxl>.container-fluid,
	.navbar-expand-xxl>.container-sm,
	.navbar-expand-xxl>.container-md,
	.navbar-expand-xxl>.container-lg,
	.navbar-expand-xxl>.container-xl {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 1440) {
	.navbar-expand-xxl {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xxl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xxl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xxl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.0rem;
	}
	.navbar-expand-xxl>.container,
	.navbar-expand-xxl>.container-fluid,
	.navbar-expand-xxl>.container-sm,
	.navbar-expand-xxl>.container-md,
	.navbar-expand-xxl>.container-lg,
	.navbar-expand-xxl>.container-xl {
		flex-wrap: nowrap;
	}
	.navbar-expand-xxl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xxl .navbar-toggler {
		display: none;
	}
}

.navbar-expand {
	flex-flow: row nowrap;
	justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
	padding-right: 0;
	padding-left: 0;
}

.navbar-expand .navbar-nav {
	flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute;
}

.drop-down-position {
	margin-right: 5rem;
}

.navbar-expand .navbar-nav .nav-link {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
	flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
	flex-direction: column;
	display: flex !important;
	flex-basis: auto;
	flex-grow: 1!important;
    justify-content: flex-end;
}

.navbar-expand .navbar-toggler {
	display: none;
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, 0.9);
}

@media screen and (min-width: 10px) and (max-width: 450px) {
	.navbar-nav{
		margin-right:0rem!important;
	}
}


.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.5);
	border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
	color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
	color: rgba(0, 0, 0, 0.9);
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline {
	padding-left: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;
}

.list-inline-item:not(:last-child) {
	margin-right: 0.5rem;
}

.initialism {
	font-size: 90%;
	text-transform: uppercase;
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.09375rem;
}

.mail-align {
	margin: 0rem 0rem 0rem 1rem;
}

.profile-title {
	display: flex;
	align-items: center;
	padding: 0.5rem 0rem 0rem 0rem;
	flex-wrap: wrap;
}


.overflow-auto {
	overflow: auto !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: sticky !important;
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}

@supports (position: sticky) {
	.sticky-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}


button.slick-arrow.slick-prev{
color:#25afa8!important;
}

.slick-next:before{
content: "\f105"!important;
font-family: 'Fontawesome'!important;
font-size: 60px!important;
color: #BCBCBC!important;
}

.slick-prev:before{
content: "\f104"!important;
font-family: 'Fontawesome'!important;
font-size: 60px!important;
color: #BCBCBC!important;
}

.slick-dots li button:before {
content: '_'!important;
font-size: 75px!important;	
width: 75px!important;
height: 75px!important;
}

.slick-dots li {
margin: 0 20px!important;
}

.slick-dots li.slick-active button:before {
color:#1FABAA!important;
}


.h1-section{
display:none;
}


.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

.row-cols-1>* {
	flex: 0 0 100%;
	max-width: 100%;
}

.row-cols-2>* {
	flex: 0 0 50%;
	max-width: 50%;
}

.row-cols-3>* {
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
}

.row-cols-4>* {
	flex: 0 0 25%;
	max-width: 25%;
}

.row-cols-5>* {
	flex: 0 0 20%;
	max-width: 20%;
}

.row-cols-6>* {
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-1 {
	flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
}

.col-2 {
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
}

.col-3 {
	flex: 0 0 25%;
	max-width: 25%;
}

.col-4 {
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
}

.col-5 {
	flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%;
}

.col-6 {
	flex: 0 0 50%;
	max-width: 50%;
}

.col-7 {
	flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%;
}

.col-8 {
	flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
}

.col-9 {
	flex: 0 0 75%;
	max-width: 75%;
}

.col-10 {
	flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%;
}

.col-11 {
	flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%;
}

.col-12 {
	flex: 0 0 100%;
	max-width: 100%;
}

.d-flex {
	display: flex !important;
}



.btn {
	display: inline-block;
	font-weight: 400;
	color: #7e7e7e;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.75rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	
}

@media (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}

.btn:hover {
	color: #7e7e7e;
	text-decoration: none;
}

.btn:focus,
.btn.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(108, 197, 29, 0.25);
}

.btn.disabled,
.btn:disabled {
	opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none;
}



button {
	cursor: pointer;
}

button:focus {
	outline: 0;
	box-shadow: none;
}

.btn-primary {
	color: #fff !important;
	
}

.btn {
	padding: 1rem 1rem;
	border-radius: 0.575rem;
	font-weight: 500;
	font-size: 1rem;
	text-align: center;
	display : flex;
	align-items : center;
	justify-content: center;
	
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
	outline: 0 !important;
}

@media only screen and (min-width: 700px) and (max-width: 1900px) {
	.btn {
		padding: 0.5rem 1rem;
		font-size: 0.813rem;
	}
}

.btn-transparent {
	background-color: transparent;
}

@media screen and (min-width: 100px) and (max-width: 400px) {
	.btn {
		font-size: 0.7rem;
		padding: 0.4rem 1rem;
	}
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
	box-shadow: none;
}

@media screen and (min-width: 10px) and (max-width: 500px) {
	.btn-primary {
		border-radius: 0.5rem !important;
		display: flex;
		align-items: center;
	}

}


.btn-primary {
	color: #212529;
	background-color: #25AFA8;
	border-color: #25AFA8;
	height:3rem;
	font-size: 13px!important;
}



.btn-primary:hover {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
}

.btn-primary:focus,
.btn-primary.focus {
	color: #fff;
	background-color: #5aa418;
	border-color: #549916;
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
	color: #212529;
	background-color: #b2b2b2;
	border-color: #b2b2b2;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #549916;
	border-color: #4e8d15;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 173, 31, 0.5);
}


.modal-open {
	overflow: visible;
}

.modal-open .modal {
	overflow-x: visible;
	overflow-y: hidden;
}


@media only screen and (max-width: 800px) {
	.modal-open .modal {
		overflow-y: auto;
	}
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	display: none;
	width: 100%;
	height: 100%;
	overflow: visible;
	outline: 0;
}

.modal-footer-tab {
	justify-content: space-between !important;
}

.modal-dialog {
	position: relative;
	/* margin: 0.5rem; */
	pointer-events: none;
}


@media (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		transition: none;
	}
}


.modal.fade .modal-dialog {
	left: 50%;
	top: 53%;
	transform:translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);;	
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
}

@media only screen and (max-width: 575px) {
	.modal.fade .modal-dialog {
       margin: 1rem 0rem 0rem 0rem!important;
    }
}



.modal.modal-static .modal-dialog {
	transform: scale(1.02);
}

.modal-dialog-scrollable {
	display: flex;
	max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
	max-height: calc(100vh - 1rem);
	overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
	flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
	overflow-y: auto;
}

.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
	display: block;
	height: calc(100vh - 1rem);
	height: min-content;
	content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
	max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
	content: none;
}

.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	outline: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}

.modal-backdrop.fade {
	opacity: 0;
}

.modal-backdrop.show {
	opacity: 0.5;
}

.modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
	color: #0F4542 !important;
	font-weight: 600;
    font-size: 20px;
}

.modal-button {
	padding: 1.75rem 0rem 1rem 0rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}


.modal-button2 {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.modal-task-btn{
	justify-content: flex-end;
	margin-right:2rem;
}

.taskNextBtn{
	display:flex;
	justify-content: flex-end;
	margin-right:2rem;
}

.taskNextBtn .btn{
	width:10rem;
	height:2.5rem;
}

#taskTaskSubmit2{
	width:10rem;
}

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 1rem;
}

.modal-footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 0px solid #EEEEEE;
	border-bottom-right-radius: calc(0.3rem - 1px);
	border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
	margin: 0.25rem;
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}



@media (min-width: 376px) {
	.modal-dialog {
		max-width: 370px;
	}
}

@media (min-width: 476px) {
	.modal-dialog {
		max-width: 470px;
	}
}


@media (min-width: 576px) {
	.modal-dialog {
		max-width: 570px;
	}
	.modal-dialog-scrollable {
		max-height: calc(100% - 3.5rem);
	}
	.modal-dialog-scrollable .modal-content {
		max-height: calc(100vh - 3.5rem);
	}
	.modal-dialog-centered {
		min-height: calc(100% - 3.5rem);
	}
	.modal-dialog-centered::before {
		height: calc(100vh - 3.5rem);
		height: min-content;
	}

}

@media (min-width: 992px) {
	.modal-dialog {
		max-width: 900px;
	}
	.modal-lg,
	.modal-xl {
		max-width: 990px;
	}
}

@media (min-width: 576px) {

	.modal-sm {
		max-width: 330px;
	}
}


@media (min-width: 1200px) {
	
	.modal-xl {
		max-width: 1200px;
	}
}

@media (min-width: 1400px) {
	.modal-xl {
		max-width: 1400px;
	}
}


.modal-header {
	padding: 1.5rem 1.875rem;
}

.modal-header .close {
	padding: 1.4rem 1.815rem;
	margin: 0;
	position: absolute;
	right: 0;
	float: none;
	top: 0;
	font-size: 30px;
	font-weight: 100;
}

.modal-body {
	padding: 1.875rem;
}

.modal-footer {
	padding: 1rem 1.875rem;
}

.modal-content {
	border-radius: 0.875rem;
}

.modal-backdrop {
	z-index: 10 !important;
}


.w-auto {
	width: auto !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}

.mh-100 {
	max-height: 100% !important;
}

.min-vw-100 {
	min-width: 100vw !important;
}

.min-vh-100 {
	min-height: 100vh !important;
}

.vw-100 {
	width: 100vw !important;
}

.vh-100 {
	height: 100vh !important;
}

.m-0 {
	margin: 0 !important;
}

.mt-0,
.my-0 {
	margin-top: 0 !important;
}

.mr-0,
.mx-0 {
	margin-right: 0 !important;
}

.mb-0,
.my-0 {
	margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
	margin-left: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.mt-1,
.my-1 {
	margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
	margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
	margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
	margin-left: 0.25rem !important;
}

.ml-05{
	margin-left: 0.15rem !important;
}

.ml-1-5{
	margin-left: 0.35rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.mt-2,
.my-2 {
	margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
	margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
	margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
	margin-left: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.mt-3,
.my-3 {
	margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
	margin-right: 1rem !important;
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
	margin-left: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.mt-4,
.my-4 {
	margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
	margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
	margin-left: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.mt-5,
.my-5 {
	margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
	margin-right: 3rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
	margin-left: 3rem !important;
}

.p-0 {
	padding: 0 !important;
}

.pt-0,
.py-0 {
	padding-top: 0 !important;
}

.pr-0,
.px-0 {
	padding-right: 0 !important;
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
	padding-left: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.pt-1,
.py-1 {
	padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
	padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
	padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
	padding-left: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.pt-2,
.py-2 {
	padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
	padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.pt-3,
.py-3 {
	padding-top: 1rem !important;
}

.pr-3,
.px-3 {
	padding-right: 1rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
	padding-left: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
	padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
	padding-left: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.pt-5,
.py-5 {
	padding-top: 3rem !important;
}

.pr-5,
.px-5 {
	padding-right: 3rem !important;
}

.pb-5,
.py-5 {
	padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
	padding-left: 3rem !important;
}

.m-n1 {
	margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
	margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
	margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
	margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
	margin-left: -0.25rem !important;
}

.m-n2 {
	margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
	margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
	margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
	margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
	margin-left: -0.5rem !important;
}

.m-n3 {
	margin: -1rem !important;
}

.mt-n3,
.my-n3 {
	margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
	margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
	margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
	margin-left: -1rem !important;
}

.m-n4 {
	margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
	margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
	margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
	margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
	margin-left: -1.5rem !important;
}

.m-n5 {
	margin: -3rem !important;
}

.mt-n5,
.my-n5 {
	margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
	margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
	margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
	margin-left: -3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mt-auto,
.my-auto {
	margin-top: auto !important;
}

.mr-auto,
.mx-auto {
	margin-right: auto !important;
}

.mb-auto,
.my-auto {
	margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
	margin-left: auto !important;
}

@media (min-width: 576px) {
	.m-sm-0 {
		margin: 0 !important;
	}
	.mt-sm-0,
	.my-sm-0 {
		margin-top: 0 !important;
	}
	.mr-sm-0,
	.mx-sm-0 {
		margin-right: 0 !important;
	}
	.mb-sm-0,
	.my-sm-0 {
		margin-bottom: 0 !important;
	}
	.ml-sm-0,
	.mx-sm-0 {
		margin-left: 0 !important;
	}
	.m-sm-1 {
		margin: 0.25rem !important;
	}
	.mt-sm-1,
	.my-sm-1 {
		margin-top: 0.25rem !important;
	}
	.mr-sm-1,
	.mx-sm-1 {
		margin-right: 0.25rem !important;
	}
	.mb-sm-1,
	.my-sm-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-sm-1,
	.mx-sm-1 {
		margin-left: 0.25rem !important;
	}
	.m-sm-2 {
		margin: 0.5rem !important;
	}
	.mt-sm-2,
	.my-sm-2 {
		margin-top: 0.5rem !important;
	}
	.mr-sm-2,
	.mx-sm-2 {
		margin-right: 0.5rem !important;
	}
	.mb-sm-2,
	.my-sm-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-sm-2,
	.mx-sm-2 {
		margin-left: 0.5rem !important;
	}
	.m-sm-3 {
		margin: 1rem !important;
	}
	.mt-sm-3,
	.my-sm-3 {
		margin-top: 1rem !important;
	}
	.mr-sm-3,
	.mx-sm-3 {
		margin-right: 1rem !important;
	}
	.mb-sm-3,
	.my-sm-3 {
		margin-bottom: 1rem !important;
	}
	.ml-sm-3,
	.mx-sm-3 {
		margin-left: 1rem !important;
	}
	.m-sm-4 {
		margin: 1.5rem !important;
	}
	.mt-sm-4,
	.my-sm-4 {
		margin-top: 1.5rem !important;
	}
	.mr-sm-4,
	.mx-sm-4 {
		margin-right: 1.5rem !important;
	}
	.mb-sm-4,
	.my-sm-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-sm-4,
	.mx-sm-4 {
		margin-left: 1.5rem !important;
	}
	.m-sm-5 {
		margin: 3rem !important;
	}
	.mt-sm-5,
	.my-sm-5 {
		margin-top: 3rem !important;
	}
	.mr-sm-5,
	.mx-sm-5 {
		margin-right: 3rem !important;
	}
	.mb-sm-5,
	.my-sm-5 {
		margin-bottom: 3rem !important;
	}
	.ml-sm-5,
	.mx-sm-5 {
		margin-left: 3rem !important;
	}
	.p-sm-0 {
		padding: 0 !important;
	}
	.pt-sm-0,
	.py-sm-0 {
		padding-top: 0 !important;
	}
	.pr-sm-0,
	.px-sm-0 {
		padding-right: 0 !important;
	}
	.pb-sm-0,
	.py-sm-0 {
		padding-bottom: 0 !important;
	}
	.pl-sm-0,
	.px-sm-0 {
		padding-left: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem !important;
	}
	.pt-sm-1,
	.py-sm-1 {
		padding-top: 0.25rem !important;
	}
	.pr-sm-1,
	.px-sm-1 {
		padding-right: 0.25rem !important;
	}
	.pb-sm-1,
	.py-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-sm-1,
	.px-sm-1 {
		padding-left: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem !important;
	}
	.pt-sm-2,
	.py-sm-2 {
		padding-top: 0.5rem !important;
	}
	.pr-sm-2,
	.px-sm-2 {
		padding-right: 0.5rem !important;
	}
	.pb-sm-2,
	.py-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-sm-2,
	.px-sm-2 {
		padding-left: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 1rem !important;
	}
	.pt-sm-3,
	.py-sm-3 {
		padding-top: 1rem !important;
	}
	.pr-sm-3,
	.px-sm-3 {
		padding-right: 1rem !important;
	}
	.pb-sm-3,
	.py-sm-3 {
		padding-bottom: 1rem !important;
	}
	.pl-sm-3,
	.px-sm-3 {
		padding-left: 1rem !important;
	}
	.p-sm-4 {
		padding: 1.5rem !important;
	}
	.pt-sm-4,
	.py-sm-4 {
		padding-top: 1.5rem !important;
	}
	.pr-sm-4,
	.px-sm-4 {
		padding-right: 1.5rem !important;
	}
	.pb-sm-4,
	.py-sm-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-sm-4,
	.px-sm-4 {
		padding-left: 1.5rem !important;
	}
	.p-sm-5 {
		padding: 3rem !important;
	}
	.pt-sm-5,
	.py-sm-5 {
		padding-top: 3rem !important;
	}
	.pr-sm-5,
	.px-sm-5 {
		padding-right: 3rem !important;
	}
	.pb-sm-5,
	.py-sm-5 {
		padding-bottom: 3rem !important;
	}
	.pl-sm-5,
	.px-sm-5 {
		padding-left: 3rem !important;
	}
	.m-sm-n1 {
		margin: -0.25rem !important;
	}
	.mt-sm-n1,
	.my-sm-n1 {
		margin-top: -0.25rem !important;
	}
	.mr-sm-n1,
	.mx-sm-n1 {
		margin-right: -0.25rem !important;
	}
	.mb-sm-n1,
	.my-sm-n1 {
		margin-bottom: -0.25rem !important;
	}
	.ml-sm-n1,
	.mx-sm-n1 {
		margin-left: -0.25rem !important;
	}
	.m-sm-n2 {
		margin: -0.5rem !important;
	}
	.mt-sm-n2,
	.my-sm-n2 {
		margin-top: -0.5rem !important;
	}
	.mr-sm-n2,
	.mx-sm-n2 {
		margin-right: -0.5rem !important;
	}
	.mb-sm-n2,
	.my-sm-n2 {
		margin-bottom: -0.5rem !important;
	}
	.ml-sm-n2,
	.mx-sm-n2 {
		margin-left: -0.5rem !important;
	}
	.m-sm-n3 {
		margin: -1rem !important;
	}
	.mt-sm-n3,
	.my-sm-n3 {
		margin-top: -1rem !important;
	}
	.mr-sm-n3,
	.mx-sm-n3 {
		margin-right: -1rem !important;
	}
	.mb-sm-n3,
	.my-sm-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-sm-n3,
	.mx-sm-n3 {
		margin-left: -1rem !important;
	}
	.m-sm-n4 {
		margin: -1.5rem !important;
	}
	.mt-sm-n4,
	.my-sm-n4 {
		margin-top: -1.5rem !important;
	}
	.mr-sm-n4,
	.mx-sm-n4 {
		margin-right: -1.5rem !important;
	}
	.mb-sm-n4,
	.my-sm-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-sm-n4,
	.mx-sm-n4 {
		margin-left: -1.5rem !important;
	}
	.m-sm-n5 {
		margin: -3rem !important;
	}
	.mt-sm-n5,
	.my-sm-n5 {
		margin-top: -3rem !important;
	}
	.mr-sm-n5,
	.mx-sm-n5 {
		margin-right: -3rem !important;
	}
	.mb-sm-n5,
	.my-sm-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-sm-n5,
	.mx-sm-n5 {
		margin-left: -3rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mt-sm-auto,
	.my-sm-auto {
		margin-top: auto !important;
	}
	.mr-sm-auto,
	.mx-sm-auto {
		margin-right: auto !important;
	}
	.mb-sm-auto,
	.my-sm-auto {
		margin-bottom: auto !important;
	}
	.ml-sm-auto,
	.mx-sm-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 768px) {
	.m-md-0 {
		margin: 0 !important;
	}
	.mt-md-0,
	.my-md-0 {
		margin-top: 0 !important;
	}
	.mr-md-0,
	.mx-md-0 {
		margin-right: 0 !important;
	}
	.mb-md-0,
	.my-md-0 {
		margin-bottom: 0 !important;
	}
	.ml-md-0,
	.mx-md-0 {
		margin-left: 0 !important;
	}
	.m-md-1 {
		margin: 0.25rem !important;
	}
	.mt-md-1,
	.my-md-1 {
		margin-top: 0.25rem !important;
	}
	.mr-md-1,
	.mx-md-1 {
		margin-right: 0.25rem !important;
	}
	.mb-md-1,
	.my-md-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-md-1,
	.mx-md-1 {
		margin-left: 0.25rem !important;
	}
	.m-md-2 {
		margin: 0.5rem !important;
	}
	.mt-md-2,
	.my-md-2 {
		margin-top: 0.5rem !important;
	}
	.mr-md-2,
	.mx-md-2 {
		margin-right: 0.5rem !important;
	}
	.mb-md-2,
	.my-md-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-md-2,
	.mx-md-2 {
		margin-left: 0.5rem !important;
	}
	.m-md-3 {
		margin: 1rem !important;
	}
	.mt-md-3,
	.my-md-3 {
		margin-top: 1rem !important;
	}
	.mr-md-3,
	.mx-md-3 {
		margin-right: 1rem !important;
	}
	.mb-md-3,
	.my-md-3 {
		margin-bottom: 1rem !important;
	}
	.ml-md-3,
	.mx-md-3 {
		margin-left: 1rem !important;
	}
	.m-md-4 {
		margin: 1.5rem !important;
	}
	.mt-md-4,
	.my-md-4 {
		margin-top: 1.5rem !important;
	}
	.mr-md-4,
	.mx-md-4 {
		margin-right: 1.5rem !important;
	}
	.mb-md-4,
	.my-md-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-md-4,
	.mx-md-4 {
		margin-left: 1.5rem !important;
	}
	.m-md-5 {
		margin: 3rem !important;
	}
	.mt-md-5,
	.my-md-5 {
		margin-top: 3rem !important;
	}
	.mr-md-5,
	.mx-md-5 {
		margin-right: 3rem !important;
	}
	.mb-md-5,
	.my-md-5 {
		margin-bottom: 3rem !important;
	}
	.ml-md-5,
	.mx-md-5 {
		margin-left: 3rem !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.pt-md-0,
	.py-md-0 {
		padding-top: 0 !important;
	}
	.pr-md-0,
	.px-md-0 {
		padding-right: 0 !important;
	}
	.pb-md-0,
	.py-md-0 {
		padding-bottom: 0 !important;
	}
	.pl-md-0,
	.px-md-0 {
		padding-left: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem !important;
	}
	.pt-md-1,
	.py-md-1 {
		padding-top: 0.25rem !important;
	}
	.pr-md-1,
	.px-md-1 {
		padding-right: 0.25rem !important;
	}
	.pb-md-1,
	.py-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-md-1,
	.px-md-1 {
		padding-left: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem !important;
	}
	.pt-md-2,
	.py-md-2 {
		padding-top: 0.5rem !important;
	}
	.pr-md-2,
	.px-md-2 {
		padding-right: 0.5rem !important;
	}
	.pb-md-2,
	.py-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-md-2,
	.px-md-2 {
		padding-left: 0.5rem !important;
	}
	.p-md-3 {
		padding: 1rem !important;
	}
	.pt-md-3,
	.py-md-3 {
		padding-top: 1rem !important;
	}
	.pr-md-3,
	.px-md-3 {
		padding-right: 1rem !important;
	}
	.pb-md-3,
	.py-md-3 {
		padding-bottom: 1rem !important;
	}
	.pl-md-3,
	.px-md-3 {
		padding-left: 1rem !important;
	}
	.p-md-4 {
		padding: 1.5rem !important;
	}
	.pt-md-4,
	.py-md-4 {
		padding-top: 1.5rem !important;
	}
	.pr-md-4,
	.px-md-4 {
		padding-right: 1.5rem !important;
	}
	.pb-md-4,
	.py-md-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-md-4,
	.px-md-4 {
		padding-left: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem !important;
	}
	.pt-md-5,
	.py-md-5 {
		padding-top: 3rem !important;
	}
	.pr-md-5,
	.px-md-5 {
		padding-right: 3rem !important;
	}
	.pb-md-5,
	.py-md-5 {
		padding-bottom: 3rem !important;
	}
	.pl-md-5,
	.px-md-5 {
		padding-left: 3rem !important;
	}
	.m-md-n1 {
		margin: -0.25rem !important;
	}
	.mt-md-n1,
	.my-md-n1 {
		margin-top: -0.25rem !important;
	}
	.mr-md-n1,
	.mx-md-n1 {
		margin-right: -0.25rem !important;
	}
	.mb-md-n1,
	.my-md-n1 {
		margin-bottom: -0.25rem !important;
	}
	.ml-md-n1,
	.mx-md-n1 {
		margin-left: -0.25rem !important;
	}
	.m-md-n2 {
		margin: -0.5rem !important;
	}
	.mt-md-n2,
	.my-md-n2 {
		margin-top: -0.5rem !important;
	}
	.mr-md-n2,
	.mx-md-n2 {
		margin-right: -0.5rem !important;
	}
	.mb-md-n2,
	.my-md-n2 {
		margin-bottom: -0.5rem !important;
	}
	.ml-md-n2,
	.mx-md-n2 {
		margin-left: -0.5rem !important;
	}
	.m-md-n3 {
		margin: -1rem !important;
	}
	.mt-md-n3,
	.my-md-n3 {
		margin-top: -1rem !important;
	}
	.mr-md-n3,
	.mx-md-n3 {
		margin-right: -1rem !important;
	}
	.mb-md-n3,
	.my-md-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-md-n3,
	.mx-md-n3 {
		margin-left: -1rem !important;
	}
	.m-md-n4 {
		margin: -1.5rem !important;
	}
	.mt-md-n4,
	.my-md-n4 {
		margin-top: -1.5rem !important;
	}
	.mr-md-n4,
	.mx-md-n4 {
		margin-right: -1.5rem !important;
	}
	.mb-md-n4,
	.my-md-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-md-n4,
	.mx-md-n4 {
		margin-left: -1.5rem !important;
	}
	.m-md-n5 {
		margin: -3rem !important;
	}
	.mt-md-n5,
	.my-md-n5 {
		margin-top: -3rem !important;
	}
	.mr-md-n5,
	.mx-md-n5 {
		margin-right: -3rem !important;
	}
	.mb-md-n5,
	.my-md-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-md-n5,
	.mx-md-n5 {
		margin-left: -3rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mt-md-auto,
	.my-md-auto {
		margin-top: auto !important;
	}
	.mr-md-auto,
	.mx-md-auto {
		margin-right: auto !important;
	}
	.mb-md-auto,
	.my-md-auto {
		margin-bottom: auto !important;
	}
	.ml-md-auto,
	.mx-md-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 992px) {
	.m-lg-0 {
		margin: 0 !important;
	}
	.mt-lg-0,
	.my-lg-0 {
		margin-top: 0 !important;
	}
	.mr-lg-0,
	.mx-lg-0 {
		margin-right: 0 !important;
	}
	.mb-lg-0,
	.my-lg-0 {
		margin-bottom: 0 !important;
	}
	.ml-lg-0,
	.mx-lg-0 {
		margin-left: 0 !important;
	}
	.m-lg-1 {
		margin: 0.25rem !important;
	}
	.mt-lg-1,
	.my-lg-1 {
		margin-top: 0.25rem !important;
	}
	.mr-lg-1,
	.mx-lg-1 {
		margin-right: 0.25rem !important;
	}
	.mb-lg-1,
	.my-lg-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-lg-1,
	.mx-lg-1 {
		margin-left: 0.25rem !important;
	}
	.m-lg-2 {
		margin: 0.5rem !important;
	}
	.mt-lg-2,
	.my-lg-2 {
		margin-top: 0.5rem !important;
	}
	.mr-lg-2,
	.mx-lg-2 {
		margin-right: 0.5rem !important;
	}
	.mb-lg-2,
	.my-lg-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-lg-2,
	.mx-lg-2 {
		margin-left: 0.5rem !important;
	}
	.m-lg-3 {
		margin: 1rem !important;
	}
	.mt-lg-3,
	.my-lg-3 {
		margin-top: 1rem !important;
	}
	.mr-lg-3,
	.mx-lg-3 {
		margin-right: 1rem !important;
	}
	.mb-lg-3,
	.my-lg-3 {
		margin-bottom: 1rem !important;
	}
	.ml-lg-3,
	.mx-lg-3 {
		margin-left: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem !important;
	}
	.mt-lg-4,
	.my-lg-4 {
		margin-top: 1.5rem !important;
	}
	.mr-lg-4,
	.mx-lg-4 {
		margin-right: 1.5rem !important;
	}
	.mb-lg-4,
	.my-lg-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-lg-4,
	.mx-lg-4 {
		margin-left: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem !important;
	}
	.mt-lg-5,
	.my-lg-5 {
		margin-top: 3rem !important;
	}
	.mr-lg-5,
	.mx-lg-5 {
		margin-right: 3rem !important;
	}
	.mb-lg-5,
	.my-lg-5 {
		margin-bottom: 3rem !important;
	}
	.ml-lg-5,
	.mx-lg-5 {
		margin-left: 3rem !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.pt-lg-0,
	.py-lg-0 {
		padding-top: 0 !important;
	}
	.pr-lg-0,
	.px-lg-0 {
		padding-right: 0 !important;
	}
	.pb-lg-0,
	.py-lg-0 {
		padding-bottom: 0 !important;
	}
	.pl-lg-0,
	.px-lg-0 {
		padding-left: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.pt-lg-1,
	.py-lg-1 {
		padding-top: 0.25rem !important;
	}
	.pr-lg-1,
	.px-lg-1 {
		padding-right: 0.25rem !important;
	}
	.pb-lg-1,
	.py-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-lg-1,
	.px-lg-1 {
		padding-left: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.pt-lg-2,
	.py-lg-2 {
		padding-top: 0.5rem !important;
	}
	.pr-lg-2,
	.px-lg-2 {
		padding-right: 0.5rem !important;
	}
	.pb-lg-2,
	.py-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-lg-2,
	.px-lg-2 {
		padding-left: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 1rem !important;
	}
	.pt-lg-3,
	.py-lg-3 {
		padding-top: 1rem !important;
	}
	.pr-lg-3,
	.px-lg-3 {
		padding-right: 1rem !important;
	}
	.pb-lg-3,
	.py-lg-3 {
		padding-bottom: 1rem !important;
	}
	.pl-lg-3,
	.px-lg-3 {
		padding-left: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem !important;
	}
	.pt-lg-4,
	.py-lg-4 {
		padding-top: 1.5rem !important;
	}
	.pr-lg-4,
	.px-lg-4 {
		padding-right: 1.5rem !important;
	}
	.pb-lg-4,
	.py-lg-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-lg-4,
	.px-lg-4 {
		padding-left: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem !important;
	}
	.pt-lg-5,
	.py-lg-5 {
		padding-top: 3rem !important;
	}
	.pr-lg-5,
	.px-lg-5 {
		padding-right: 3rem !important;
	}
	.pb-lg-5,
	.py-lg-5 {
		padding-bottom: 3rem !important;
	}
	.pl-lg-5,
	.px-lg-5 {
		padding-left: 3rem !important;
	}
	.m-lg-n1 {
		margin: -0.25rem !important;
	}
	.mt-lg-n1,
	.my-lg-n1 {
		margin-top: -0.25rem !important;
	}
	.mr-lg-n1,
	.mx-lg-n1 {
		margin-right: -0.25rem !important;
	}
	.mb-lg-n1,
	.my-lg-n1 {
		margin-bottom: -0.25rem !important;
	}
	.ml-lg-n1,
	.mx-lg-n1 {
		margin-left: -0.25rem !important;
	}
	.m-lg-n2 {
		margin: -0.5rem !important;
	}
	.mt-lg-n2,
	.my-lg-n2 {
		margin-top: -0.5rem !important;
	}
	.mr-lg-n2,
	.mx-lg-n2 {
		margin-right: -0.5rem !important;
	}
	.mb-lg-n2,
	.my-lg-n2 {
		margin-bottom: -0.5rem !important;
	}
	.ml-lg-n2,
	.mx-lg-n2 {
		margin-left: -0.5rem !important;
	}
	.m-lg-n3 {
		margin: -1rem !important;
	}
	.mt-lg-n3,
	.my-lg-n3 {
		margin-top: -1rem !important;
	}
	.mr-lg-n3,
	.mx-lg-n3 {
		margin-right: -1rem !important;
	}
	.mb-lg-n3,
	.my-lg-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-lg-n3,
	.mx-lg-n3 {
		margin-left: -1rem !important;
	}
	.m-lg-n4 {
		margin: -1.5rem !important;
	}
	.mt-lg-n4,
	.my-lg-n4 {
		margin-top: -1.5rem !important;
	}
	.mr-lg-n4,
	.mx-lg-n4 {
		margin-right: -1.5rem !important;
	}
	.mb-lg-n4,
	.my-lg-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-lg-n4,
	.mx-lg-n4 {
		margin-left: -1.5rem !important;
	}
	.m-lg-n5 {
		margin: -3rem !important;
	}
	.mt-lg-n5,
	.my-lg-n5 {
		margin-top: -3rem !important;
	}
	.mr-lg-n5,
	.mx-lg-n5 {
		margin-right: -3rem !important;
	}
	.mb-lg-n5,
	.my-lg-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-lg-n5,
	.mx-lg-n5 {
		margin-left: -3rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mt-lg-auto,
	.my-lg-auto {
		margin-top: auto !important;
	}
	.mr-lg-auto,
	.mx-lg-auto {
		margin-right: auto !important;
	}
	.mb-lg-auto,
	.my-lg-auto {
		margin-bottom: auto !important;
	}
	.ml-lg-auto,
	.mx-lg-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 1200px) {
	.m-xl-0 {
		margin: 0 !important;
	}
	.mt-xl-0,
	.my-xl-0 {
		margin-top: 0 !important;
	}
	.mr-xl-0,
	.mx-xl-0 {
		margin-right: 0 !important;
	}
	.mb-xl-0,
	.my-xl-0 {
		margin-bottom: 0 !important;
	}
	.ml-xl-0,
	.mx-xl-0 {
		margin-left: 0 !important;
	}
	.m-xl-1 {
		margin: 0.25rem !important;
	}
	.mt-xl-1,
	.my-xl-1 {
		margin-top: 0.25rem !important;
	}
	.mr-xl-1,
	.mx-xl-1 {
		margin-right: 0.25rem !important;
	}
	.mb-xl-1,
	.my-xl-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-xl-1,
	.mx-xl-1 {
		margin-left: 0.25rem !important;
	}
	.m-xl-2 {
		margin: 0.5rem !important;
	}
	.mt-xl-2,
	.my-xl-2 {
		margin-top: 0.5rem !important;
	}
	.mr-xl-2,
	.mx-xl-2 {
		margin-right: 0.5rem !important;
	}
	.mb-xl-2,
	.my-xl-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-xl-2,
	.mx-xl-2 {
		margin-left: 0.5rem !important;
	}
	.m-xl-3 {
		margin: 1rem !important;
	}
	.mt-xl-3,
	.my-xl-3 {
		margin-top: 1rem !important;
	}
	.mr-xl-3,
	.mx-xl-3 {
		margin-right: 1rem !important;
	}
	.mb-xl-3,
	.my-xl-3 {
		margin-bottom: 1rem !important;
	}
	.ml-xl-3,
	.mx-xl-3 {
		margin-left: 1rem !important;
	}
	.m-xl-4 {
		margin: 1.5rem !important;
	}
	.mt-xl-4,
	.my-xl-4 {
		margin-top: 1.5rem !important;
	}
	.mr-xl-4,
	.mx-xl-4 {
		margin-right: 1.5rem !important;
	}
	.mb-xl-4,
	.my-xl-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-xl-4,
	.mx-xl-4 {
		margin-left: 1.5rem !important;
	}
	.m-xl-5 {
		margin: 3rem !important;
	}
	.mt-xl-5,
	.my-xl-5 {
		margin-top: 3rem !important;
	}
	.mr-xl-5,
	.mx-xl-5 {
		margin-right: 3rem !important;
	}
	.mb-xl-5,
	.my-xl-5 {
		margin-bottom: 3rem !important;
	}
	.ml-xl-5,
	.mx-xl-5 {
		margin-left: 3rem !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.pt-xl-0,
	.py-xl-0 {
		padding-top: 0 !important;
	}
	.pr-xl-0,
	.px-xl-0 {
		padding-right: 0 !important;
	}
	.pb-xl-0,
	.py-xl-0 {
		padding-bottom: 0 !important;
	}
	.pl-xl-0,
	.px-xl-0 {
		padding-left: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem !important;
	}
	.pt-xl-1,
	.py-xl-1 {
		padding-top: 0.25rem !important;
	}
	.pr-xl-1,
	.px-xl-1 {
		padding-right: 0.25rem !important;
	}
	.pb-xl-1,
	.py-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-xl-1,
	.px-xl-1 {
		padding-left: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem !important;
	}
	.pt-xl-2,
	.py-xl-2 {
		padding-top: 0.5rem !important;
	}
	.pr-xl-2,
	.px-xl-2 {
		padding-right: 0.5rem !important;
	}
	.pb-xl-2,
	.py-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-xl-2,
	.px-xl-2 {
		padding-left: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 1rem !important;
	}
	.pt-xl-3,
	.py-xl-3 {
		padding-top: 1rem !important;
	}
	.pr-xl-3,
	.px-xl-3 {
		padding-right: 1rem !important;
	}
	.pb-xl-3,
	.py-xl-3 {
		padding-bottom: 1rem !important;
	}
	.pl-xl-3,
	.px-xl-3 {
		padding-left: 1rem !important;
	}
	.p-xl-4 {
		padding: 1.5rem !important;
	}
	.pt-xl-4,
	.py-xl-4 {
		padding-top: 1.5rem !important;
	}
	.pr-xl-4,
	.px-xl-4 {
		padding-right: 1.5rem !important;
	}
	.pb-xl-4,
	.py-xl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-xl-4,
	.px-xl-4 {
		padding-left: 1.5rem !important;
	}
	.p-xl-5 {
		padding: 3rem !important;
	}
	.pt-xl-5,
	.py-xl-5 {
		padding-top: 3rem !important;
	}
	.pr-xl-5,
	.px-xl-5 {
		padding-right: 3rem !important;
	}
	.pb-xl-5,
	.py-xl-5 {
		padding-bottom: 3rem !important;
	}
	.pl-xl-5,
	.px-xl-5 {
		padding-left: 3rem !important;
	}
	.m-xl-n1 {
		margin: -0.25rem !important;
	}
	.mt-xl-n1,
	.my-xl-n1 {
		margin-top: -0.25rem !important;
	}
	.mr-xl-n1,
	.mx-xl-n1 {
		margin-right: -0.25rem !important;
	}
	.mb-xl-n1,
	.my-xl-n1 {
		margin-bottom: -0.25rem !important;
	}
	.ml-xl-n1,
	.mx-xl-n1 {
		margin-left: -0.25rem !important;
	}
	.m-xl-n2 {
		margin: -0.5rem !important;
	}
	.mt-xl-n2,
	.my-xl-n2 {
		margin-top: -0.5rem !important;
	}
	.mr-xl-n2,
	.mx-xl-n2 {
		margin-right: -0.5rem !important;
	}
	.mb-xl-n2,
	.my-xl-n2 {
		margin-bottom: -0.5rem !important;
	}
	.ml-xl-n2,
	.mx-xl-n2 {
		margin-left: -0.5rem !important;
	}
	.m-xl-n3 {
		margin: -1rem !important;
	}
	.mt-xl-n3,
	.my-xl-n3 {
		margin-top: -1rem !important;
	}
	.mr-xl-n3,
	.mx-xl-n3 {
		margin-right: -1rem !important;
	}
	.mb-xl-n3,
	.my-xl-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-xl-n3,
	.mx-xl-n3 {
		margin-left: -1rem !important;
	}
	.m-xl-n4 {
		margin: -1.5rem !important;
	}
	.mt-xl-n4,
	.my-xl-n4 {
		margin-top: -1.5rem !important;
	}
	.mr-xl-n4,
	.mx-xl-n4 {
		margin-right: -1.5rem !important;
	}
	.mb-xl-n4,
	.my-xl-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-xl-n4,
	.mx-xl-n4 {
		margin-left: -1.5rem !important;
	}
	.m-xl-n5 {
		margin: -3rem !important;
	}
	.mt-xl-n5,
	.my-xl-n5 {
		margin-top: -3rem !important;
	}
	.mr-xl-n5,
	.mx-xl-n5 {
		margin-right: -3rem !important;
	}
	.mb-xl-n5,
	.my-xl-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-xl-n5,
	.mx-xl-n5 {
		margin-left: -3rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mt-xl-auto,
	.my-xl-auto {
		margin-top: auto !important;
	}
	.mr-xl-auto,
	.mx-xl-auto {
		margin-right: auto !important;
	}
	.mb-xl-auto,
	.my-xl-auto {
		margin-bottom: auto !important;
	}
	.ml-xl-auto,
	.mx-xl-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 1440) {
	.m-xxl-0 {
		margin: 0 !important;
	}
	.mt-xxl-0,
	.my-xxl-0 {
		margin-top: 0 !important;
	}
	.mr-xxl-0,
	.mx-xxl-0 {
		margin-right: 0 !important;
	}
	.mb-xxl-0,
	.my-xxl-0 {
		margin-bottom: 0 !important;
	}
	.ml-xxl-0,
	.mx-xxl-0 {
		margin-left: 0 !important;
	}
	.m-xxl-1 {
		margin: 0.25rem !important;
	}
	.mt-xxl-1,
	.my-xxl-1 {
		margin-top: 0.25rem !important;
	}
	.mr-xxl-1,
	.mx-xxl-1 {
		margin-right: 0.25rem !important;
	}
	.mb-xxl-1,
	.my-xxl-1 {
		margin-bottom: 0.25rem !important;
	}
	.ml-xxl-1,
	.mx-xxl-1 {
		margin-left: 0.25rem !important;
	}
	.m-xxl-2 {
		margin: 0.5rem !important;
	}
	.mt-xxl-2,
	.my-xxl-2 {
		margin-top: 0.5rem !important;
	}
	.mr-xxl-2,
	.mx-xxl-2 {
		margin-right: 0.5rem !important;
	}
	.mb-xxl-2,
	.my-xxl-2 {
		margin-bottom: 0.5rem !important;
	}
	.ml-xxl-2,
	.mx-xxl-2 {
		margin-left: 0.5rem !important;
	}
	.m-xxl-3 {
		margin: 1rem !important;
	}
	.mt-xxl-3,
	.my-xxl-3 {
		margin-top: 1rem !important;
	}
	.mr-xxl-3,
	.mx-xxl-3 {
		margin-right: 1rem !important;
	}
	.mb-xxl-3,
	.my-xxl-3 {
		margin-bottom: 1rem !important;
	}
	.ml-xxl-3,
	.mx-xxl-3 {
		margin-left: 1rem !important;
	}
	.m-xxl-4 {
		margin: 1.5rem !important;
	}
	.mt-xxl-4,
	.my-xxl-4 {
		margin-top: 1.5rem !important;
	}
	.mr-xxl-4,
	.mx-xxl-4 {
		margin-right: 1.5rem !important;
	}
	.mb-xxl-4,
	.my-xxl-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-xxl-4,
	.mx-xxl-4 {
		margin-left: 1.5rem !important;
	}
	.m-xxl-5 {
		margin: 3rem !important;
	}
	.mt-xxl-5,
	.my-xxl-5 {
		margin-top: 3rem !important;
	}
	.mr-xxl-5,
	.mx-xxl-5 {
		margin-right: 3rem !important;
	}
	.mb-xxl-5,
	.my-xxl-5 {
		margin-bottom: 3rem !important;
	}
	.ml-xxl-5,
	.mx-xxl-5 {
		margin-left: 3rem !important;
	}
	.p-xxl-0 {
		padding: 0 !important;
	}
	.pt-xxl-0,
	.py-xxl-0 {
		padding-top: 0 !important;
	}
	.pr-xxl-0,
	.px-xxl-0 {
		padding-right: 0 !important;
	}
	.pb-xxl-0,
	.py-xxl-0 {
		padding-bottom: 0 !important;
	}
	.pl-xxl-0,
	.px-xxl-0 {
		padding-left: 0 !important;
	}
	.p-xxl-1 {
		padding: 0.25rem !important;
	}
	.pt-xxl-1,
	.py-xxl-1 {
		padding-top: 0.25rem !important;
	}
	.pr-xxl-1,
	.px-xxl-1 {
		padding-right: 0.25rem !important;
	}
	.pb-xxl-1,
	.py-xxl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pl-xxl-1,
	.px-xxl-1 {
		padding-left: 0.25rem !important;
	}
	.p-xxl-2 {
		padding: 0.5rem !important;
	}
	.pt-xxl-2,
	.py-xxl-2 {
		padding-top: 0.5rem !important;
	}
	.pr-xxl-2,
	.px-xxl-2 {
		padding-right: 0.5rem !important;
	}
	.pb-xxl-2,
	.py-xxl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pl-xxl-2,
	.px-xxl-2 {
		padding-left: 0.5rem !important;
	}
	.p-xxl-3 {
		padding: 1rem !important;
	}
	.pt-xxl-3,
	.py-xxl-3 {
		padding-top: 1rem !important;
	}
	.pr-xxl-3,
	.px-xxl-3 {
		padding-right: 1rem !important;
	}
	.pb-xxl-3,
	.py-xxl-3 {
		padding-bottom: 1rem !important;
	}
	.pl-xxl-3,
	.px-xxl-3 {
		padding-left: 1rem !important;
	}
	.p-xxl-4 {
		padding: 1.5rem !important;
	}
	.pt-xxl-4,
	.py-xxl-4 {
		padding-top: 1.5rem !important;
	}
	.pr-xxl-4,
	.px-xxl-4 {
		padding-right: 1.5rem !important;
	}
	.pb-xxl-4,
	.py-xxl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-xxl-4,
	.px-xxl-4 {
		padding-left: 1.5rem !important;
	}
	.p-xxl-5 {
		padding: 3rem !important;
	}
	.pt-xxl-5,
	.py-xxl-5 {
		padding-top: 3rem !important;
	}
	.pr-xxl-5,
	.px-xxl-5 {
		padding-right: 3rem !important;
	}
	.pb-xxl-5,
	.py-xxl-5 {
		padding-bottom: 3rem !important;
	}
	.pl-xxl-5,
	.px-xxl-5 {
		padding-left: 3rem !important;
	}
	.m-xxl-n1 {
		margin: -0.25rem !important;
	}
	.mt-xxl-n1,
	.my-xxl-n1 {
		margin-top: -0.25rem !important;
	}
	.mr-xxl-n1,
	.mx-xxl-n1 {
		margin-right: -0.25rem !important;
	}
	.mb-xxl-n1,
	.my-xxl-n1 {
		margin-bottom: -0.25rem !important;
	}
	.ml-xxl-n1,
	.mx-xxl-n1 {
		margin-left: -0.25rem !important;
	}
	.m-xxl-n2 {
		margin: -0.5rem !important;
	}
	.mt-xxl-n2,
	.my-xxl-n2 {
		margin-top: -0.5rem !important;
	}
	.mr-xxl-n2,
	.mx-xxl-n2 {
		margin-right: -0.5rem !important;
	}
	.mb-xxl-n2,
	.my-xxl-n2 {
		margin-bottom: -0.5rem !important;
	}
	.ml-xxl-n2,
	.mx-xxl-n2 {
		margin-left: -0.5rem !important;
	}
	.m-xxl-n3 {
		margin: -1rem !important;
	}
	.mt-xxl-n3,
	.my-xxl-n3 {
		margin-top: -1rem !important;
	}
	.mr-xxl-n3,
	.mx-xxl-n3 {
		margin-right: -1rem !important;
	}
	.mb-xxl-n3,
	.my-xxl-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-xxl-n3,
	.mx-xxl-n3 {
		margin-left: -1rem !important;
	}
	.m-xxl-n4 {
		margin: -1.5rem !important;
	}
	.mt-xxl-n4,
	.my-xxl-n4 {
		margin-top: -1.5rem !important;
	}
	.mr-xxl-n4,
	.mx-xxl-n4 {
		margin-right: -1.5rem !important;
	}
	.mb-xxl-n4,
	.my-xxl-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-xxl-n4,
	.mx-xxl-n4 {
		margin-left: -1.5rem !important;
	}
	.m-xxl-n5 {
		margin: -3rem !important;
	}
	.mt-xxl-n5,
	.my-xxl-n5 {
		margin-top: -3rem !important;
	}
	.mr-xxl-n5,
	.mx-xxl-n5 {
		margin-right: -3rem !important;
	}
	.mb-xxl-n5,
	.my-xxl-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-xxl-n5,
	.mx-xxl-n5 {
		margin-left: -3rem !important;
	}
	.m-xxl-auto {
		margin: auto !important;
	}
	.mt-xxl-auto,
	.my-xxl-auto {
		margin-top: auto !important;
	}
	.mr-xxl-auto,
	.mx-xxl-auto {
		margin-right: auto !important;
	}
	.mb-xxl-auto,
	.my-xxl-auto {
		margin-bottom: auto !important;
	}
	.ml-xxl-auto,
	.mx-xxl-auto {
		margin-left: auto !important;
	}
}


    
.form-control {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #f0f1f5;
	border-radius: 0.75rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.form-control {
		transition: none;
	}
}

.form-control::-ms-expand {
	background-color: transparent;
	border: 0;
}

.form-control:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #495057;
}

.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #adeb77;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(108, 197, 29, 0.25);
}

.form-control::placeholder {
	color: #ABAFB3;
	opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
	appearance: none;
}

select.form-control:focus::-ms-value {
	color: #495057;
	background-color: #fff;
}

.form-control-file,
.form-control-range {
	display: block;
	width: 100%;
}


select.form-control[size],
select.form-control[multiple] {
	height: auto;
}

textarea.form-control {
	height: auto;
}

@media screen and (min-width: 0px) and (max-width: 690px) {
	textarea.form-control{
		height:25rem!important;
	
	}
}


.form-group {
	margin-bottom: 1rem;
}

.form-text {
	display: block;
	margin-top: 0.25rem;
}

.form-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
	padding-right: 5px;
	padding-left: 5px;
}

.form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem;
}

.form-check-input {
	position: absolute;
	padding: 1rem;
}


.contact-content{
	padding:0rem 8rem !important;
}

@media screen and (min-width:0px) and (max-width: 600px) {
	.contact-content{
		padding:0rem 0rem !important;
	}
}


.contact-d-fs{
	font-size:0.9rem!important;
	margin-bottom:0rem!important;
	
}
.contact-d-fs2{
	font-size:0.7rem!important;
	margin-bottom:0rem!important;
	margin-left:-2rem!important;
	margin-right:-2rem!important;
	margin-top:1rem!important;
}

.contact-submit-expl{
	display: flex;
	justify-content: center;
	
align-items: center;
align-content: center;
}

.home-nav-link{
	margin-right:1rem!important;
}

.contact-form-size{
	width:64%!important;
	margin-right:auto!important;
	margin-left:auto!important;
}



.card-header-tabtitle {
	justify-content: space-between!important;
}

.card-tab-shadow {
	box-shadow: 0 6px 4px -4px rgba(8, 21, 66, 0.08);
}

.card-header-tab {
	padding-top: 0rem !important;
	padding-left: 0rem !important;
	padding-right: 0rem !important;
}




.card-header-tab-bottom {
	padding-bottom: 0rem !important;
}


.form-control {
	border-radius: 0.5rem;
	background: #fff;
	border: 2px solid #f0f1f5;
	color: #6e6e6e;
	height: 2.7rem;
}

@media only screen and (max-width: 1199px) {
	.form-control {
		height: 41px;
	}
}

.form-control:hover,
.form-control:focus,
.form-control.active {
	box-shadow: none;
	color: #6e6e6e;
}


.header-right {
	height: 100%;
}

.header-right .nav-item {
	height: 100%;
	display: flex;
	align-items: center;
}

.header-right .nav-item .nav-link {
	color: #464a53;
	font-size: 13px;
}

.header-right .right-sidebar {
	margin-right: -30px;
}

.header-right .right-sidebar a {
	height: 80px;
	width: 80px;
	text-align: center;
	justify-content: center;
	display: flex;
	align-items: center;
	border-left: 1px solid #E8E8E8;
}

.header-right>li:not(:first-child) {
	padding-left: 1rem;
}

@media only screen and (max-width: 1199px) {
	.header-right>li:not(:first-child) {
		padding-left: 0.0rem;
	}
}

@media only screen and (max-width: 767px) {
	.header-right .notification_dropdown {
		position: static;
	}
}

.collapse:not(.show) {
	display: none;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
	.collapsing {
		transition: none;
	}
}

    
.swipe-demo-btn{
    max-width:70%;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color:#0000EE!important;
    text-decoration: underline!important;
    padding: 0rem 0rem !important;
    justify-content: flex-start!important;
    font-size:100%!important;
    }
    
    .swipe-demo-btn:hover{
    max-width:70%;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color:#0000EE!important;
    text-decoration: none!important;
    padding: 0rem 0rem !important;
    }
    
    .swipe-demo-btn:not(:disabled):not(.disabled):active,
    .swipe-demo-btn:not(:disabled):not(.disabled).active,
    .show>.swipe-demo-btn.dropdown-toggle {
    background-color: transparent;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color:#0000EE!important;
    padding: 0rem 0rem !important;
    }
    
    
    .swipe-p-t-b{
    margin-bottom:0.5rem!important;
    }
    
   
    
 
    
    

    
