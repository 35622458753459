
@media only screen and (max-width: 1600px) {
    .fact {
        margin: 0 auto;
    }
    .fact {
        top: unset;
        right: unset;
        position: relative;
        transform: unset;
    }

     .about-nmc-info-block .s2 .flat-title {
        margin-bottom: 50px;
    }
  
}


@media only screen and (max-width: 991px) {
    .shape-am .section-icons {
        display: none;
    }
}


@media only screen and (max-width: 767px) {
    .fact .col-left {
        display: block;
    }
  }


@media only screen and (max-width: 575px) {
  
    .flat-title .title-section {
        font-size: 30px !important;
        line-height: 40px !important;
    }
 
    .fact .col-left {
        display: unset;
    }
  
}


@media (max-width: 320px) {     
      .fact {
        padding: 0 15px;
    }
    
    .flat-title .sub-title:after {
        display: none;
    }
}   