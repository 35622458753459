
.switch-Grid{
    background-color: transparent!important;
    color:#25AFA8!important;
    border-color: transparent!important;
    padding-bottom:1.5rem!important;
    box-shadow: none!important;
    border-radius:0rem;
}

.switch-Grid:hover{
color: #36C95F !important;;
}

.switch-padding-list{
    padding-right:0rem!important;
}

.card-search-area{
    width: 100% !important;
    border: 0.5px solid #ccc;
    border-radius: 0.4rem;
}

.card-search-dropdown{
    border-radius:0.4rem!important;
    background-color: transparent!important;
    color:#969BA0!important;
    border-color: #CCCCCC!important;
    padding-top:0.7rem;
    padding-bottom:0.7rem;
    font-size:0.9rem;
}

.card-search-dropdown::after{
    color:#25AFA8!important;
    font-weight: 700;
    font-size:1.1rem;
}

.card-dropdown-group{
    display:flex;
    justify-content: space-between;
}

.more-filter-btn{
    color:#25AFA8!important;
    border-radius:0.4rem!important;
    border-color: #25AFA8!important;
    background-color: transparent!important;
    font-size:1.2rem;
    margin-right: 0.7rem;
    height:2.8rem;
    box-shadow: none!important;

}

.more-filter-btn >div{
    font-size:0.85rem;
    display: flex;
}


.more-filter-btn:hover{
    color:#ffffff!important;
    background-color: #25AFA8!important;
}


.card-search-node{
    font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

  .card-search-area input{
    background-color: #eee;
    border: 1px solid #ccc;
  }

  .grid-list-div{
      display: flex;
      justify-content: flex-end;
      margin-right:1.5rem;
  }

  .node-card{
    width: 110px!important;
    height: 110px!important;
    border-radius: 0.3rem;
    margin:0.6rem 0.6rem 0.6rem 0.6rem;
  }
  .node-card >div{
    font-size: 0.7rem;
    text-align:center;
    font-weight: 550;
}

  @media only screen and (max-width: 500px) {
    .node-card{
      margin: 0.4rem 0.2rem 0.4rem 0.2rem;
      width: 95px!important;
      height: 95px!important;
      
    }
    .node-card:nth-child(1){
      margin-left: 0rem;
    }

    .node-card:last-child{
      margin-right: 2px;
    }

    .node-card >div{
      font-size: 12px;
  }
  }


.node-card-img .badge {
    position: absolute;
    font-size: 14px;
    border-radius: 20px!important;
    right: 12px;
    top: 5px;
    font-weight: normal;
    height: 26px;
    width: 26px;
    line-height: 26px;
    text-align: center;
    padding: 0px;
}

.node-card-img{
    position: relative;
}

.node-card-group{
    display:flex;
    margin-left:1.3rem;
    flex-wrap: wrap;
    margin-top:1.5rem;
    height:500px;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 0 0 97%;
    max-width: 97%;
    
}
@media only screen and (max-width: 1420px) {
  .node-card-group{
    margin-top:0rem!important;
    margin-left:0rem;
    flex: 0 0 100%;
    max-width: 100%;
}
}


@media only screen and (max-width: 720px) {
  .node-card-group{
    margin-top:0rem!important;
    margin-left:0.9rem;
    margin-right:0.9rem;
    flex: 0 0 100%;
    max-width: 100%;
    gap: 16px;
}
}

@media only screen and (max-width: 400px) {
  .node-card-group{
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin-left : calc((100% - 310px - (2* var(--gap)))/2); 
    margin-right:calc((100% - 310px - (2* var(--gap)))/2); 
}


}

.manage-node-div .card-header{
    padding:1rem 0rem!important; 
}


/* offcanvas */
.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none;
    }
  }
  
  .offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
  }
  
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
    border:none;
    background-color:transparent!important;
  }
  .offcanvas-header .btn-close::after{
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "\f00d";
    font-size: 20px;
    color:#25AFA8;
  }

  .offcanvas-header .btn-close:hover::after{
    color:#68EFAF!important;
  }

  .offcanvas-body-title-div{
    align-items: center;
    justify-content: space-between;
    margin-bottom:3rem;
  }

  .offcanvas-body-title{
    line-height: 1.5;
    font-size: 22px;
    font-weight: 600;
    color:#0F4542;
  }

  .offcanvas-title {
    margin-bottom: 0;
  }

  .offcanvas-reset{
    font-size: 16px;
    color:#25AFA8;
    font-weight: 500;
    vertical-align: middle;
    text-align: center;
    margin-right:0.5rem;
  }
  
  .offcanvas-body {
    flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: scroll;
  }
  
  .offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateX(-100%);
  }
  
  .offcanvas-end {
    top: 0;
    right: 0;
    width: 400px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
  }
  
  .offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
  }
  
  .offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateY(100%);
  }
  
  .offcanvas.show {
    transform: none;
  }


  .offCanvas-div .dropdown{
    margin-top:2rem;
  }

  .offCanvas-div .dropdown .dropdown-basic{
      width: 330px!important;
      justify-content: space-between!important;
}

@media only screen and (max-width: 500px) {
  .offCanvas-div .dropdown .dropdown-basic{
    width: 300px!important;
    font-size:12px;
}
.offcanvas-body-title-div{
  margin-bottom:1rem;
}
.offcanvas-body {
  padding:0rem 1rem 5rem 1rem;
}
}

.offCanvas-div .dropdown .dropdown-menu{
    width: 315px!important;
}

.offcanvas.show {
    box-shadow: -5px 0px 2px 1px rgba(0, 0, 0, 0.02);
}
.search-pill{
    color:#ffffff;
    background-color: #58cd9a !important;
    line-height:1!important;
}

.search-pill::after{
    align-items: center;
    vertical-align:middle;
    font-family: "Font Awesome 5 Free"; 
    font-weight: 600; 
    content: "\f00d";
    font-size: 13px;
    color:#ffffff;
}

.search-pill:hover::after{
    color:#6AECAE;
}

.popover-arrow{
  left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    background:transparent !important;
    z-index: 99;
    width: 1.1rem;
    height: 1.1rem; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #c7c7c7;
    bottom: -7px;
    top:42px!important;
    transform: translate(107px, 113px)!important;
    border-top: 7px solid #ffffff!important;
   
}

.popover{
  border: none!important;
}


  #node-card-popover{
    inset: auto auto -23px 0px!important;
    box-shadow: 0px 4px 4px rgba(0.46666666865348816, 0.46666666865348816, 0.46666666865348816, 0.10000000149011612);
    min-width: 230px!important;
  }

  @media only screen and (max-width: 500px) {
    #node-card-popover{
      min-width: 150px!important;
    }
    .popover-arrow{
      transform: translate(82px, 113px)!important;
    }
  }


  .node-popover-title{
    font-weight: 700;
    font-size: 13px;
    color:#FFFFFF;
    letter-spacing:1px;
    margin-left:0.5rem;
  }

  .node-popover-text{
    font-weight: 600;
    font-size: 13px;
    color:#969BA0;
    margin-left:0.5rem;
  }

  .node-popover-text >div{
    margin-top:0.3rem;
  }

  .node-card-light{
    width: 10px;
    height: 10px;
    opacity: 1;
    border-radius: 50%;
    box-sizing: border-box;
  }

  .node-popover-title-div{
    display:flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }

  .popover-body-div{
    display:flex;
    justify-content: space-between;
    height:102px;
  }

  .popover-btn{
    height:1.5rem;
    border-radius:0.2rem;
    background-color:#44C2A0;
    border-color: #44C2A0;
    color:#FFFFFF;
    margin-top:2.5rem;
    font-size: 13px!important;
    margin-bottom:0.5rem;
    min-width: 80px;
    margin-bottom:1rem;
  }

  .popover-btn-info{
    height:1.5rem;
    border-radius:0.2rem;
    background-color:#ffffff;
    border-color: #44C2A0;
    color:#25AFA8!important;
    margin-top:2.5rem;
    font-size: 13px!important;
    margin-bottom:0.5rem;
    min-width: 80px;
  }

  .popover-btn-info:hover{
    background-color:#25AFA8;
    border-color: #25AFA8;
    color:#FFFFFF!important;
  }


  .popover-online-status{
    font-weight: 600;
    color:#EE6164;
    font-size: 13px;
    letter-spacing:1px;
    text-align: center;
    margin-top:0.2rem;
  }

  .popover-body-r{
    display:flex;
    justify-content: space-between;
    flex-direction: column;
  }


  .clear-btn{
   display:flex;
   align-content: center;
   align-items: center;
   color:#25AFA8;
   font-weight: 600;
   text-decoration: underline;
   cursor: pointer;
  }


  .card-dropdown-item{
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .node-mobile-top-button{
    display:flex;
    margin-left:0.5rem;
  }

  .list-node-switch{
    padding-bottom:1.5rem!important;
  }

  @media only screen and (max-width: 500px) {

  .list-node-switch{
    padding-bottom:0.5rem!important;
  }
  .node-card-img .img-fluid{
    max-width:80%;
  }
  }
  
  .manage-node-switch{
    display:flex;
  }

#mobile-node-add{
  border-radius:0.4rem!important;
}

svg.bi-check-lg{
  height: 20px !important;
  width: 20px !important;
}
.cancel-select svg.bi-x{
  height: 27px !important;
  width: 27px !important;
  color:#EE6164;
}

.mobile-xcheck{
  display:flex;
  flex-wrap:nowrap;
}

.nodecard-img{
  max-width:80%!important;
}

.nodecard-mobile-modal{
  padding-left:0rem!important;
}

.nodecard-mobile-modal .modal-dialog {
  margin:0rem;

}

.nodecard-mobile-modal .modal-dialog .modal-content {
  border-radius:0.3rem;
  background-image: url(../assets/images/node-card-popbg.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: auto;
  margin-right:1rem;
  margin-left:1rem;
}

.node-card-modal-div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.nodecard-mobile-modal .modal-dialog .modal-content .modal-body{
padding:0rem 0rem 1rem 0rem;
}

.node-card-modal-head{
  display: flex;
  margin-top:0.8rem;
  margin-left:1rem;
  justify-content: space-between;
}

.node-popover-text >div:first-child{
  font-weight: 600;
  font-size:15px;
}

.node-popover-modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:1rem;
}

.nodecard-mobile-modal .modal-dialog .modal-footer{
  padding:0rem;
  justify-content: center!important;
}

.footer-action{
  display: flex;

  color:#ffffff;
  padding:0.5rem 0rem 0.5rem 0rem;
}
.footer-action >div{margin-left:1rem;}

.card-close:hover{
  color: #36C95F !important;;
}

.node-card-modal-head >div:first-child{
  display:flex;
  align-items: center;
}
.node-card{
  position: relative;
}

.node-card-check{
  position: absolute;
  top:6px;
  left:6px;
  z-index: 9;
}

.node-card-check:after {
  border: 1px solid #37d1bf85!important;
  background: #f0f0f0!important;
}

.node-card-check:checked:after {
  background: #25AFA8!important;
}

.cancvas-arrow svg.bi-arrow-right-short{
  width:27px;
  height: 27px;
  color:#25AFA8;
}

#mobile-node-add:after{
  margin-left:0.5rem;
}

