
.flat-title .sub-title {
    font-size: 24px;
    position: relative;
    display: inline-block;
    font-family: 'Playball', sans-serif;
}
.flat-title.t1 .sub-title {
    color: #ff2e59;
}
.flat-title.t2 .sub-title {
    color: #627fff;
}
.flat-title .sub-title:after {
    content:'';
    top: 0;
    right: -96px;
    position: absolute;
}

.flat-title .title-section {
    font-size: 40px;
    line-height: 55px;
    letter-spacing: 2.5px;
    font-weight:700;
 
}

@media screen and (min-width: 10px) and (max-width: 1200px) {
	.flat-title .title-section {
        font-size: 35px;
        line-height: 40px;
        letter-spacing: 2px;
        font-weight: 600;
        text-align: center;
        margin-right:5rem;
	}


}


