@import url("../../icons/font-awesome-old/css/font-awesome.min.css");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

}

ul {
  list-style-type: none;
}

nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  /* margin-right: 3rem; */
}

nav ul li {
  padding: 0.5rem 1rem;
}

nav ul li a {
  font-size: 1.0rem;
  color: white;
}

.nav-link {
  font-weight: 600;
}

.btn-login {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#5bc3b6), to(#6ad893)) !important;
  background-image: linear-gradient(0deg, #5bc3b6 0%, #6ad893 100%) !important;
  border: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 5px;
  display: block;
  padding: 7px 5.5vw;
  color: white;
}



.btn-login:hover {
  color: #00706f;
  background-image: -webkit-gradient(linear, right top, left top, from(#6AD893), color-stop(51%, #79CBCA), to(#1FABAA)) !important;
  background-image: linear-gradient(to left, #6AD893 0%, #79CBCA 51%, #1FABAA 100%) !important;
  text-decoration: none;
}

.login-bg {
  /* background-color: #F5F9F1 */
  background-color: #FAFAFA
}

.login-checkbox {
  width: 0.5rem;
  height: 0.5rem !important;
}

.btn-login-text {
  background: #5BC3B6;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  padding: 7px 5.5vw;
  text-transform: uppercase;
}

.login-font {
  font-size: 0.8rem;
  padding: 0px;
}

.logincheckbox {
  font-size: 0.5rem;
}

.custom-control-input {
  width: 0.2rem;
  height: 0.2rem;
}

.card {
  border: none !important;
  background-color: #FCFCFC !important;
}

.logintext {
  font-size: 0.65rem;
}

.custom-control-label::before, .custom-control-label::after {
  width: 1rem !important;
  height: 1rem  !important;
  padding: 7px 7px;
}

.google-recaptcha-div.text-start.logintext {
  text-align: justify!important;
  margin-top:0.4rem!important;
  letter-spacing:-0.5px!important;
  color:#a7a7a7c2!important;
  }
  

.recaptcha-text{
  color:#25AFA8!important;
  
}

.grecaptcha-badge { visibility: hidden; }
